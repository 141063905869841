export default {
    enUS: {
        priceDownload: {
            export_date: 'Export date',
            export_type: 'Export type',
            download_or_not: 'Download',
            file_name: 'File name',
            download_date: 'Download date',
            full_export: 'Full export',
            partial_export: 'Partial export',
            accessory_code: 'Part code',
            price_update_date: 'price update date',
            brand: 'Brand',
        },
        boQuery: {
            part_code: 'Part No',
            part_name: 'Part Name',
            suborder_number: 'Sub-order No',
            suborder_type: 'Sub-order type',
            BO_cancel_state: 'BO cancellation status',
            brand: 'Brand',
            type_of_shipping: 'Transportation Mode ',
            order_quantity: 'Order Qty',
            BO_quantity: 'BO Qty',
            BO_reason_for_return: 'Reason of Rejection',
            apply_for_cancel: 'Cancel Application',
        },
        distributionManagement: {
            warehouse_confirm: 'Warehouse Confirm Ensure',
            delete_container_confirm: 'Delete container confirm',
            container_plans_upload: 'Container Plans Upload',
            other_expence: 'Other Expence',
            ship_name: 'Ship Name',
            bl_no: 'BL No',
            custum_no: 'Custom No',
            modify_reason: 'Modify Reason',
            container_no: 'Container No',
            register: 'Register',
            return_type: 'Return Type',
            expected_return_date: 'Expected Return Date',
            actual_return_date: 'Actual Return Date',
        },
        freeorder: {
            generate_freeorder: 'Generate Freeorder',
        },
        boTreatment: {
            dt_code: 'dt code',
            part_code: 'part code',
            part_name: 'part name',
            suborder_number: 'suborder number',
            brand: 'brand',
            suborder_type: 'suborder type',
            delivery_or_not: 'Notify packaging',
            Bo_cancel_state: 'Bo cancel state',
            mode_of_transport: 'mode of transport',
            order_quantity: 'order quantity',
            BO_number: 'BO number',
            Bo_part_state: 'Bo part state',
            SMPV_inventory: 'SMPV inventory',
            Reason_for_cancellation_of_return: 'Reason for cancellation of return',
            submit_SMPV: 'submit SMPV',
            cancellation_of_application: 'cancellation of application',
            BO_Separate_shipment: 'BO Separate shipment',
            packing_list_number: 'packing list number',
        },
    },
    nlNL: {
        priceDownload: {
            export_date: 'Exportdatum',
            export_type: 'Exporttype',
            download_or_not: 'Downloaden',
            file_name: 'Bestandsnaam',
            download_date: 'Download datum',
            full_export: 'Volledige export',
            partial_export: 'Gedeeltelijke export',
            accessory_code: 'Accessoirecode',
            price_update_date: 'Prijs update datum',
            brand: 'Merk',
        },
        boQuery: {
            part_code: 'Onderdeelnummer',
            part_name: 'Onderdeelnaam',
            suborder_number: 'Sub-order nummer',
            suborder_type: 'Type sub-order',
            BO_cancel_state: 'BO annuleringsstatus',
            brand: 'Merk',
            type_of_shipping: 'Transportmodus',
            order_quantity: 'Bestelhoeveelheid',
            BO_quantity: 'BO hoeveelheid',
            BO_reason_for_return: 'Reden van afwijzing',
            apply_for_cancel: 'Annuleringsaanvraag',
        },
        distributionManagement: {
            warehouse_confirm: 'Bevestig magazijn ontvangst',
            delete_container_confirm: 'Verwijder container bevestigen',
            container_plans_upload: 'Container plannen uploaden',
            other_expence: 'Overige uitgaven',
            ship_name: 'Schip naam',
            bl_no: 'BL nummer',
            custum_no: 'Douane nummer',
            modify_reason: 'Reden van wijziging',
            container_no: 'Container nummer',
            register: 'Registreren',
            return_type: 'Retourtype',
            expected_return_date: 'Verwachte retourdatum',
            actual_return_date: 'Feitelijke retourdatum',
        },
        freeorder: {
            generate_freeorder: 'Genereer gratis bestelling',
        },
        boTreatment: {
            dt_code: 'DT-code',
            part_code: 'Onderdeelcode',
            part_name: 'Onderdeelnaam',
            suborder_number: 'Suborder nummer',
            brand: 'Merk',
            suborder_type: 'Suborder type',
            delivery_or_not: 'Levering of niet',
            Bo_cancel_state: 'BO annuleringsstatus',
            mode_of_transport: 'Vervoerswijze',
            order_quantity: 'Bestelhoeveelheid',
            BO_number: 'BO-nummer',
            Bo_part_state: 'BO-onderdeelstatus',
            SMPV_inventory: 'SMPV-inventaris',
            Reason_for_cancellation_of_return: 'Reden voor annulering van retour',
            submit_SMPV: 'SMPV indienen',
            cancellation_of_application: 'Annulering van aanvraag',
            BO_Separate_shipment: 'BO Aparte verzending',
            packing_list_number: 'Paklijstnummer',
        },
    },
    deDE: {
        priceDownload: {
            export_date: 'Exportdatum',
            export_type: 'Exporttyp',
            download_or_not: 'Herunterladen',
            file_name: 'Dateiname',
            download_date: 'Download-Datum',
            full_export: 'Vollständiger Export',
            partial_export: 'Teilweiser Export',
            accessory_code: 'Zubehörcode',
            price_update_date: 'Aktualisierungsdatum des Preises',
            brand: 'Marke',
        },
        boQuery: {
            part_code: 'Teilenummer',
            part_name: 'Teilename',
            suborder_number: 'Unterauftragsnummer',
            suborder_type: 'Unterauftragstyp',
            BO_cancel_state: 'BO-Stornierungsstatus',
            brand: 'Marke',
            type_of_shipping: 'Transportmodus',
            order_quantity: 'Bestellmenge',
            BO_quantity: 'BO-Menge',
            BO_reason_for_return: 'Grund der Ablehnung',
            apply_for_cancel: 'Stornierungsantrag',
        },
        distributionManagement: {
            warehouse_confirm: 'Bestätigung des Lagers',
            delete_container_confirm: 'Containerlöschbestätigung',
            container_plans_upload: 'Containerpläne hochladen',
            other_expence: 'Sonstige Ausgaben',
            ship_name: 'Schiffsname',
            bl_no: 'BL-Nr.',
            custum_no: 'Kunden-Nr.',
            modify_reason: 'Änderungsgrund',
            container_no: 'Container-Nr.',
            register: 'Registrierung',
            return_type: 'Rückgabetyp',
            expected_return_date: 'Erwartetes Rückgabedatum',
            actual_return_date: 'Tatsächliches Rückgabedatum',
        },
        freeorder: {
            generate_freeorder: 'Freie Bestellung erstellen',
        },
        boTreatment: {
            dt_code: 'dt code',
            part_code: 'Teilenummer',
            part_name: 'Teilname',
            suborder_number: 'Unterauftragsnummer',
            brand: 'Marke',
            suborder_type: 'Unterauftragstyp',
            delivery_or_not: 'Notify packaging',
            Bo_cancel_state: 'Bo-Stornierungsstatus',
            mode_of_transport: 'Transportart',
            order_quantity: 'Bestellmenge',
            BO_number: 'BO-Nummer',
            Bo_part_state: 'BO-Teilestatus',
            SMPV_inventory: 'SMPV-Inventar',
            Reason_for_cancellation_of_return: 'Grund für Stornierung der Rückgabe',
            submit_SMPV: 'SMPV einreichen',
            cancellation_of_application: 'Stornierungsantrag',
            BO_Separate_shipment: 'BO separate Lieferung',
            packing_list_number: 'Packliste Nummer',
        },
    },
    frFR: {
        priceDownload: {
            export_date: 'Date d\'exportation',
            export_type: 'Type d\'exportation',
            download_or_not: 'Télécharger',
            file_name: 'Nom de fichier',
            download_date: 'Date de téléchargement',
            full_export: 'Export complet',
            partial_export: 'Export partiel',
            accessory_code: 'Code d\'accessoire',
            price_update_date: 'Date de mise à jour des prix',
            brand: 'Marque',
        },
        boQuery: {
            part_code: 'Numéro de pièce',
            part_name: 'Nom de pièce',
            suborder_number: 'Numéro de sous-commande',
            suborder_type: 'Type de sous-commande',
            BO_cancel_state: 'Statut d\'annulation BO',
            brand: 'Marque',
            type_of_shipping: 'Mode de transport',
            order_quantity: 'Quantité de commande',
            BO_quantity: 'Quantité BO',
            BO_reason_for_return: 'Raison du rejet',
            apply_for_cancel: 'Demande d\'annulation',
        },
        distributionManagement: {
            warehouse_confirm: 'Confirmation d\'entrepôt',
            delete_container_confirm: 'Confirmation de suppression de conteneurs',
            container_plans_upload: 'Téléchargement des plans de conteneurs',
            other_expence: 'Autres dépenses',
            ship_name: 'Nom de navire',
            bl_no: 'BL N°',
            custum_no: 'N° de douane',
            modify_reason: 'Raison de modification',
            container_no: 'Numéro de conteneur',
            register: 'Enregistrer',
            return_type: 'Type de retour',
            expected_return_date: 'Date de retour prévue',
            actual_return_date: 'Date de retour effective',
        },
        freeorder: {
            generate_freeorder: 'Générer la commande gratuite',
        },
        boTreatment: {
            dt_code: 'Code DT',
            part_code: 'Code de la pièce',
            part_name: 'Nom de la pièce',
            suborder_number: 'Numéro de sous-commande',
            brand: 'Marque',
            suborder_type: 'Type de sous-commande',
            delivery_or_not: 'Notification d\'emballage',
            Bo_cancel_state: 'État d\'annulation BO',
            mode_of_transport: 'Mode de transport',
            order_quantity: 'Quantité de commande',
            BO_number: 'Numéro BO',
            Bo_part_state: 'État de la pièce BO',
            SMPV_inventory: 'Inventaire SMPV',
            Reason_for_cancellation_of_return: 'Raison d\'annulation de retour',
            submit_SMPV: 'Soumettre SMPV',
            cancellation_of_application: 'Annulation de demande',
            BO_Separate_shipment: 'Expédition séparée BO',
            packing_list_number: 'Numéro de la liste d\'emballage',
        },
    },
    zhCN: {
        priceDownload: {
            export_date: '导出日期',
            export_type: '导出类型',
            download_or_not: '是否下载',
            file_name: '文件名称',
            download_date: '下载日期',
            full_export: '全量导出',
            partial_export: '部分导出',
            accessory_code: '配件代码',
            price_update_date: '价格更新日期',
            brand: '品牌',
        },
        boQuery: {
            part_code: '配件代码',
            part_name: '配件名称',
            suborder_number: '子订单编号',
            suborder_type: '子订单类型',
            BO_cancel_state: 'BO取消状态',
            brand: '品牌',
            type_of_shipping: '运输方式',
            order_quantity: '订货数量',
            BO_quantity: 'BO数量',
            BO_reason_for_return: 'BO取消退回原因',
            apply_for_cancel: '申请取消',
        },
        distributionManagement: {
            warehouse_confirm: '确认进行进仓确认',
            delete_container_confirm: '确认删除集装箱方案',
            container_plans_upload: '集装箱方案上传',
            other_expence: '其他费用',
            ship_name: '船名/航次/快递',
            bl_no: '提单号',
            custum_no: '报关单号',
            modify_reason: '修改原因',
            container_no: '集装箱号',
            register: '登记',
            return_type: '返回方式',
            expected_return_date: '预计返回日期',
            actual_return_date: '实际返回日期',
        },
        freeorder: {
            generate_freeorder: '生成免费订单',
        },
        boTreatment: {
            dt_code: 'DT代码',
            part_code: '配件代码',
            part_name: '配件名称',
            suborder_number: '子订单编号',
            brand: '品牌',
            suborder_type: '子订单类型',
            delivery_or_not: '通知包装',
            Bo_cancel_state: 'BO取消状态',
            mode_of_transport: '运输方式',
            order_quantity: '订货数量',
            BO_number: 'BO数量',
            Bo_part_state: 'BO零件状态',
            SMPV_inventory: 'SMPV库存',
            Reason_for_cancellation_of_return: 'BO取消退回原因',
            submit_SMPV: '提交SMPV',
            cancellation_of_application: '取消申请',
            BO_Separate_shipment: 'BO单独发运',
            packing_list_number: '箱单号',
        },
    },
    itIT: {
        priceDownload: {
            export_date: 'Data di esportazione',
            export_type: 'Tipo di esportazione',
            download_or_not: 'Scaricare o meno',
            file_name: 'Nome del file',
            download_date: 'Data di download',
            full_export: 'Esportazione completa',
            partial_export: 'Esportazione parziale',
            accessory_code: 'Codice accessorio',
            price_update_date: 'Data di aggiornamento del prezzo',
            brand: 'Marca',
        },
        boQuery: {
            part_code: 'Codice parte',
            part_name: 'Nome parte',
            suborder_number: 'Numero subordine',
            suborder_type: 'Tipo subordine',
            BO_cancel_state: 'Stato cancellazione BO',
            brand: 'Marca',
            type_of_shipping: 'Tipo di spedizione',
            order_quantity: 'Quantità ordine',
            BO_quantity: 'Quantità BO',
            BO_reason_for_return: 'Motivo di restituzione BO',
            apply_for_cancel: 'Richiesta annullamento',
        },
        distributionManagement: {
            warehouse_confirm: 'Conferma ricevuta in magazzino',
            delete_container_confirm: 'Conferma eliminazione schema contenitore',
            container_plans_upload: 'Caricamento schemi contenitore',
            other_expence: 'Altre spese',
            ship_name: 'Nome nave/viaggio/espresso',
            bl_no: 'Numero BL',
            custum_no: 'Numero doganale',
            modify_reason: 'Motivo della modifica',
            container_no: 'Numero di contenitore',
            register: 'Registrazione',
            return_type: 'Metodo di restituzione',
            expected_return_date: 'Data di ritorno prevista',
            actual_return_date: 'Data di ritorno effettiva',
        },
        freeorder: {
            generate_freeorder: 'Genera ordine gratuito',
        },
        boTreatment: {
            dt_code: 'Codice DT',
            part_code: 'Codice parte',
            part_name: 'Nome parte',
            suborder_number: 'Numero subordine',
            brand: 'Marca',
            suborder_type: 'Tipo subordine',
            delivery_or_not: 'Notifica imballaggio',
            Bo_cancel_state: 'Stato cancellazione BO',
            mode_of_transport: 'Modalità di trasporto',
            order_quantity: 'Quantità ordine',
            BO_number: 'Quantità BO',
            Bo_part_state: 'Stato parti BO',
            SMPV_inventory: 'Inventario SMPV',
            Reason_for_cancellation_of_return: 'Motivo di annullamento della restituzione',
            submit_SMPV: 'Invia SMPV',
            cancellation_of_application: 'Annullamento richiesta',
            BO_Separate_shipment: 'Spedizione separata BO',
            packing_list_number: 'Numero lista di imballaggio',
        },
    },
};
