import type { MessageFunction } from 'vue-i18n';
import order from './pages/order';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import { vehicle_country } from './pages/vehicle/logistics';

export default {
    license: {
        powered_by: 'Powered by SMIL',
    },
    auth: {
        login: 'Login',
        username: 'Username',
        username_placeholder: 'Enter username',
        password: 'Password',
        password_placeholder: 'Enter password',
        code: 'Verify Code',
        code_placeholder: 'Enter verify code',
        logout: 'Logout',
        logout_success: 'Logout success',
        day: 'Day',
        password_expiration: 'The password has expired, please modify it',
        expire: 'Remaining until password expiration',
        conseils: 'The password must contain uppercase letters, lowercase letters, numbers, and special characters ({msg}), and the length of the password should be between 8 to 16 characters',
    },
    button: {
        editing_position: 'Editing position',
        query: 'Query',
        old_password: 'Old password',
        new_password: 'New password',
        confirm_password: 'Confirm Password',
        change_password: 'Change Password',
        cipher: 'cipher',
        change_new_password: 'Please fill in the new password',
        show_theme_editor: 'Show/Hide Theme Editor',
        button: 'Button',
        about: 'About',
        back: 'Back',
        go: 'GO',
        home: 'Home',
        toggle_dark: 'Toggle dark mode',
        toggle_langs: 'Change languages',
        confirm: 'Confirm',
        modify: 'Edit',
        edit: 'Edit',
        new: 'New',
        cancel: 'Cancel',
        close: 'Closed',
        search: 'Search',
        reset: 'Reset',
        delete: 'Delete',
        add: 'Add',
        collapse: 'Collapse',
        expand: 'Expand',
        download_template: 'Download Template',
        batch_import: 'Batch import',
        import: 'Import',
        export: 'Export',
        export_fail: 'Export Fail',
        batch_clear_sap_code: 'Batch clear SAP code',
        batch_clear_special_purchase_cycle: 'Batch clear special purchase cycle',
        detailed: 'detailed',
        download: 'Download',
        toView: 'Check',
        toViews: 'Check',
        uploadAttachments: 'Upload',
        selectFile: 'Select File',
        fillingInstructions: 'Filling Instructions',
        release: 'Release',
        save: 'Save',
        send_back: 'Return',
        examine: 'examine',
        updateTime: 'Update validity period',
        update: 'Update',
        void: 'Void',
        more: 'More',
        refresh: 'Refresh',
        submit_sap: 'Submit SAP',
        submit: 'Submit',
        save_button: 'Save and New',
        insufficient: 'Whether to submit if the account fund is insufficient',
        yes: 'Yes',
        deny: 'Deny',
        staging: 'Save and Close',
        return: 'Return',
        table: 'No',
        select: 'Choose',
        all: 'All',
        create_sap: 'CreateSap',
        enter: 'Please Input',
        selects: 'Please Select',
        please_search: 'Please click to search',
        modify_ladder: 'Modify ladder',
        pass: 'Pass through',
        serial_number: 'No',
        individual: 'Individual',
        upload_progress: 'Upload Progress',
        port_choose: 'Port choose',
        request_code: 'Please check accessories',
        request_discuss: 'Please select a supplier',
        request_dt: 'Please check DT',
        request_thing: 'Please check the material category',
        translate: 'Only letters',
        accessories_information: 'Part information',
        select_accessories: 'Select accessories',
        binding: 'Binding',
        tick_data: 'Please check the data',
        invalid: 'Invalid',
        empty_data: 'Clear generated data',
        confirm_number: 'Confirm packing list number',
        variance_details: 'Variance Details',
        daily_record: 'Daily record',
        operation_type: 'Operation type',
        field_name: 'Field Name',
        edit_role: 'Edit Role',
        user_role: 'User Role',
        assignment_role: 'Role',
        post: 'Post',
        please_settled: 'Please select the part to be settled',
        not_sent: 'Not sent',
        sending: 'Sending',
        has_been_sent: 'Has been sent',
        fail_in_send: 'Fail in send',
        express_import: 'Express import',
        reset_password: 'ResetPWD',
        display_or_not: 'Display Or Not',
        password_reset: 'Password Reset',
        batch_cancellation: 'Batch cancellation',
        assistance: 'help',
        currentTotal: 'Currently, there are a total of',
        batch_confirmation: 'Batch Confirmation',
        batch_cancellation2: 'Batch Cancellation',
        batch_approval: 'Batch Approval',
        generate_statement: 'Generate Statement',
        tip: 'tip',
        approved: 'Approved?',
        returned_reason: 'Returned reason',
        newadd: 'Add',
    },
    radio: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        catalogue: 'Catalogue',
        individual: 'Individual',
        all: 'All',
        tips: 'Tips',
        save: 'Save',
        yes: 'Yes',
        no: 'No',
        confirmed: 'Confirmed',
        cancel: 'Cancel',
    },
    dropdown: {
        close_others: 'Close Others',
        close_right: 'Close Right',
        close_left: 'Close Left',
    },
    table: {
        action: 'Operation',
        explain: 'Explain',
        fileName: 'File Name',
        size: 'Size(MB)',
        uploader: 'Uploader',
        uploadTime: 'Upload Time',
        system: 'System',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/page`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} records in total`,
    },
    confirm: {
        confirm_deletion: 'Confirm deletion?',
        confirm_void: 'Confirm void?',
        confirm_release: 'Confirm release?',
        ack: 'Confirm',
        cancel: 'Cancel',
        non_records: 'Please choose at least one record',
    },
    request: {
        failed_placeholder: 'Request failed, backend error',
        success_placeholder: 'Request success',
        auth: {
            login_success: 'Login success',
        },
        error: {
            '404': 'Request url not found',
            '403': 'Permission denied',
        },
    },
    page: {
        global: {
            page_search: 'Page Search',
        },
        error: {
            not_found: 'Not found',
            not_found_des: 'There is nothing here',
            auth_failed: 'Auth Failed',
            auth_failed_des: 'This page is not reachable',
        },
        homepage: {
            homepage: homepage.homepage.enUS,
        },
        system: {
            menu: {
                menu: 'Menu',
                menu_name: 'Menu Name',
                menu_type: 'Menu Type',
                button_name: 'Button Name',
                purview: 'Purview Code',
                menu_new: 'New Menu',
                menu_edit: 'Modify Menu',
                icon: 'Icon',
                parent: 'Parent',
                order_number: 'Order Number',
                path: 'Path',
                status: 'Status',
            },
            dict: {
                dictionary_name: 'Dictionary Name',
                creater: 'Creater',
                dictionary_code: 'Dictionary Code',
                code_type: 'Code Type',
                code_type_name: 'Code Type Name',
                creation_time: 'Creation Time',
                dict_new: 'Dict New',
                dict_edit: 'Dict Edit',
                lang: 'Lang',
                sort: 'Sort',
            },
            dept: {
                system_selection: 'System selection',
                organizational_type: 'Organizational type',
                parent_dept: 'Parent dept',
                dept_name: 'Dept Name',
                leader: 'Leader',
                email: 'Email',
                create_by: 'Create By',
                create_date: 'Create Date',
                organization_editing: 'Organization Editing',
                phone: 'Phone',
            },
            role: {
                menu_maintenance: 'Menu',
                role_name: 'Role Name',
                role_key: 'Role Code',
                role_id: 'Display Order',
                status: 'status',
                create_date: 'Create Time',
                role_sort: 'Sort',
                role_new: 'New Role',
                role_edit: 'Edit Role',
                role_bleak: 'Bleak',
            },
            user: {
                serial_no: 'No',
                username: ' User Name',
                name: 'Name',
                sex: 'gender',
                phone_number: 'Phone Number',
                email: 'email',
                role: 'Role',
                login_time: 'Enable Status',
                password: 'Password',
                user_edit: 'User Edit',
            },
        },
        management: {
            purchasePosting: {
                order_status: 'SLK order status',
                charge_against: 'charge against',
                transportation_method:
                    'Order type, transportation method, and trade terms are different. Please check the order with the same type!',
                whether_to_send: 'Whether to send SAP',
                invoice_number: 'Invoice Number',
                invoice_reversal: 'Invoice Reversal',
                sales_purchase_tax_code: 'Sales/Purchase Tax Code',
                send_sap: 'Send SAP',
                whether_notify_invoicing: 'Whether to notify invoicing',
                import_invoices: 'Import Invoice',
                import_warehouse_time: 'Confirm import upon warehouse entry',
                invoicing_notice: 'Invoicing Notice',
                import_information: 'Import customs declaration information',
                tax_rate: 'Tax rate',
                amount_excluding_tax: 'Amount of money',
                amount_including_tax: 'Amount of money',
                invoice_tax_amount: 'Invoice tax amount',
                invoice_amount: 'Invoice amount',
                prepayment_note_no: 'Prepayment Note No',
                prepayment_not: 'Prepayment or not',
                section: 'Section',
                approved: 'Approved',
                order_submission: 'Order submission',
                list_to_be_invoiced: 'List to be invoiced',
                invoiced_but_unpaid_list: 'Invoiced but unpaid list',
                invoiced_and_paid_list: 'Invoiced and paid list',
                dn_no: 'DN No',
                sub_order_no: 'Sub-order No',
                packing_list_no: 'Packing list No',
                brand: 'Brand',
                whether_invoicable: 'Whether Invoicable',
                payment_type: 'Payment Type',
                advance_payment: 'Advance charge',
                oem_order_no: 'OEM order No',
                tax_amount: 'Tax amount',
                amount_of_money: 'Amount of money',
                payment_time: 'Payment time',
                payment_order_no: 'Payment order No',
                sap_voucher_No: 'SAP voucher No',
                export_gold_tax: 'Export gold tax',
                export_maxus: 'Export MAXUS invoicing list',
                time_of_payment: 'Time of payment',
                apply_for_payment: 'Apply for payment',
                outbound_confirmation: 'Outbound confirmation',
                export_confirmation_import: 'Export confirmation import',
            },
            preBooking: {
                booking: 'Booking',
                booking_space: 'booking space',
                packing_list_no: 'Packing list No',
                job_no: 'Job No',
                dangerous_goods_or_not: 'Dangerous goods or not',
                brand: 'Brand',
                type_of_shipping: 'Transportation Mode',
                dt_code: 'DT code',
                estimated_volume: 'Estimated volume (m³)',
                estimated_gross_weight: 'Estimated gross weight (kg)',
                estimated_net_weight: 'Estimated net weight (kg)',
                estimated: 'Estimated volume',
                volume: 'Volume (m³)',
                gross_weight: 'Gross weight (kg)',
                net_weight: 'Net weight (kg)',
                shipping_schedule: 'Job No Shipping Schedule',
                shipping_schedules: 'Job No shipping date cannot be empty',
                shipping_type: 'Shipping Type',
                box_quantity: 'Job No Estimated Box Quantity',
            },
            shoppingCart: {
                bo_cancellation: 'Reason for BO cancellation',
                external_order_no: 'External Order No',
                dcs_order_number: 'DCS order number',
                vin_requires:
                    'VIN requires a combination of 17 letters and numbers starting with 3 large and small letters',
                part_type: 'Part Type',
                submit_validation: 'Submit Validation',
                maximum_value: 'Maximum value 9999999999',
                please_select_cart: 'Please select an part to add to the shopping cart',
                please_enter_format: 'Please enter PDF, XLSX, XLS, WORD,format',
                only_supports:
                    '3. Only supports PDF, XLSX, XLS, and WORD formats with an upload limit of 5M in size',
                Only_limits_of:
                    'Note: Only supports upload limits of 5M in jpg, png, and gif formats',
                please_format: 'Please enter jpg, png, gif format',
                please_enter_an_integer: 'Please enter the correct order quantity',
                customized_orders: 'Customized orders',
                details_accessory: 'Details of part code',
                warning: 'Confirm deletion',
                please_payment_method: 'Please select payment method',
                please_port: 'Please select a port',
                please_party: 'Please select the delivery party',
                please_enter_a_note: 'Please enter a note',
                no_inventory: 'No inventory',
                notes: 'Notes',
                note_the: 'Lack of Necessary Information for Customized parts',
                download_template: 'Download template',
                single_addition: 'Single addition',
                material_category: 'parts type',
                Individual_addition: 'Individual addition',
                order_type: 'Order type',
                accessory_code: 'Part No',
                part_name: 'Part Name',
                term_of_trade: 'Trade Terms',
                logistics_mode: 'Logistics mode',
                type_of_fittings: 'Part type',
                available_balance: 'Available balance',
                payable_amount: 'Pending Payment',
                order_deadline: 'Next Order deadline',
                place_order: 'Submit',
                order_information: 'Order information',
                order_No: 'Order No',
                supplier: 'Supplier',
                creation_date: 'Create Date',
                type_of_shipping: 'Transportation Mode ',
                payment_method: 'Payment Method',
                port: 'Destination Port',
                delivered_by: 'Actual Receiver Address',
                remarks: 'Notes',
                brand: 'Brand',
                currency: 'Currency',
                number_accessories: 'Number of accessories',
                accessory_lines: 'Number of part lines',
                accessory: 'Items',
                amount_accessories: 'Part amount',
                freight: 'Freight (estimated)',
                premium: 'Insurance',
                order_amount: 'Total Amount',
                total_transportation: 'Estimated volume (m³)',
                ordinary_goods: 'ETD of Normal Parts',
                arrival_date: 'ETD of Dangerous Parts',
                accessories_information: 'Accessories information',
                whole: 'Whole',
                routine: 'Routine',
                dangerous_goods: 'Dangerous goods',
                conventional_parts: 'Conventional parts',
                chemicals: 'Chemicals',
                oily_parts: 'Oily parts',
                commodity_inspection_parts: 'Commodity inspection parts',
                payment: 'Payment voucher No. cannot be empty',
                incorrect: 'Incorrect input data',
                data_empty: 'Data cannot be empty',
                cannot_current_time: 'Cannot be greater than the current time',
                cannot_time: 'Cannot be less than the current time',
                permits: 'Permits',
                customized_parts: 'Customized parts',
                customized: 'Customized',
                accessories_selection: 'Accessories selection',
                forbidden_empty: 'Air Forbidden',
                add_cart: 'Add',
                order_number: 'No',
                replacement: 'Replacement',
                order_quantity: 'Order Qty',
                unit_price: 'Price',
                fob_unit_price: 'FOB Price',
                company: 'Unit',
                be_purchased: 'Purchaseable',
                quantity: 'Minimum order quantity',
                total_amount: 'Total amount',
                volumes: 'Volume (m³)',
                volume: 'Volume',
                enclosure: 'Attachement',
                confirmed_quantity: 'Confirmed quantity',
                courier: 'Flight/Ship Number/Courier',
                future_order: 'Future order type',
                please_future_order: 'Please Future order type',
                urgent_order_reason: 'Emergency Order Reason',
                please_urgent_order: 'Please select the reason for the urgent order',
            },
            salesquery: {
                actual_delivery: 'Actual delivery date',
                estimated_delivery: 'Estimated delivery date',
                total_packages: 'Total net weight of package',
                total_gross: 'Total gross weight of package',
                trade_mode: 'Post-transport trade mode',
                mode_before: 'Trade mode before transportation',
                port_after_transshipment: 'Port after transshipment',
                port_diversion: 'Port before diversion',
                outer: 'Package Height size',
                outer_box_size: 'Package Width size',
                outer_box: 'Package Length size',
                volume: 'Volume(m3)',
                gross_weights: 'G.W.(KGS)',
                net_weight: 'N.W.(KGS)',
                shipment_quantity: 'Shipping Qty',
                ordering_parts_code: 'Original Order Part No',
                packing_code: 'Case No',
                box_no: 'Box No',
                container_no: 'Container No',
                sales_order_information: 'Sales order information',
                packing_list_no: 'PL No',
                accessory_code: 'Part No',
                order_no: 'Order No',
                merge_ci: 'Export merged CI&PL',
                export_ci: 'CI Print',
                export_pl: 'PL Print',
                currency: 'Currency',
                parts_amount: 'Part amount',
                freight: 'Freight',
                premium: 'Insurance',
                other_expenses: 'Other Fees',
                deduction_of_claims: 'Deduction of Claims',
                total_amoun: 'Total amount',
                creation_date: 'PL Create Date',
                ship_number: 'Ship number/flight/express',
                supplier: 'Supplier',
                accessories: 'Items',
                total_accessories: 'Qty',
                gross_weight: 'G.W.(KGS)',
                total_weight: 'N.W.(KGS)',
                total_package: 'Total Volume(m³)',
                dt_code: 'DT code',
                total_volume: 'Total Volume',
                upload_date: 'Upload Date',
            },
            Balance: {
                date: 'Date',
                payment_reference: 'Payment Reference Number',
                suborder_number: 'Suborder Number',
                packing_list_no: 'Packing List No',
                amount_type: 'Amount Type',
                income_expenditure: 'Income/Expenditure',
                amount_status: 'Amount Status',
                expenditure_amount: 'Expenditure Amount',
                Amount_Income: 'Amount Waiting for Income',
                income_amount: 'Income Amount',
                account_amount_balance: 'Account Amount Balance',
                frozen_amount_balance: 'Frozen Amount Balance',
                currency: 'Currency',
                number: 'LC-Number',
                term_of_validity: 'LC-Term of Validity',
                picture: 'LC-Picture',
                confirmed: 'LC Confirmed',
                order_amount_frozen: 'Order Amount Frozen',
                refund_due_delete: 'Refund due to Documents Delete',
                deduction_of_frozen_amount: 'Deduction of Frozen Amount',
                account_amount: 'Deduction of Account Amount',
                frozen_release_amount: 'Frozen Amount Release to Account Amount',
                frozen_amount_release: 'Frozen Amount Release to Account Amount',
                bo_parts: 'Cancellation of BO Parts',
                frozen_amount: 'Frozen Amount',
            },
            suborder: {
                receipt_reminder: 'Receipt Reminder',
                approved: 'Approved',
                packing_details: 'Packing details',
                order_detail: 'Order details',
                arrive: 'Arrived',
                shipped: 'Shipped',
                complete_packaging: 'Completed Packing',
                passenger_order_number: 'Passenger car order number',
                order_details: 'Sub-order details',
                accessory: 'Part',
                order_amount: 'Total Amount',
                company: 'Company',
                name_accessories: 'Part Name',
                accessory_code: 'Part No',
                serial_no: 'No',
                submit_smpv: 'Submit SMPV',
                Please_enter_number: 'Please enter the DCS order number',
                customs_declaration: 'Customs declaration',
                customs_clearance: 'DCS customs clearance',
                sub_order_no: 'Sub-order No',
                sub_order_type: 'Sub-order type',
                brand: 'brand',
                type_hipping: 'Type of shipping',
                dt_code: 'DT code',
                sub_order_amount: 'Sub-order amount',
                trade_terms: 'Trade terms',
                passenger_number: 'Passenger car order number',
                dcs_order_number: 'DCS order No',
                remarks: 'Remarks',
                submission_date: 'Submission date',
                supplier: 'Supplier',
                mode_of_trade: 'Trade Mode',
                port: 'Destination Port',
                to: 'Actual Receiver Address',
                currency: 'Currency',
                cancel_date: 'Cancellation date',
                accessory_information: 'Part information',
            },
            query: {
                please_upload: 'Please upload',
                export_pi: 'Export PI',
                export_po: 'Export PO',
                audit_status: 'Audit status',
                cancel: 'Cancel',
                whether_submit: 'Whether to submit',
                to_cancel: 'Are you sure to cancel',
                submit_results: 'Submit Results',
                actual_arrival: 'Actual arrival date',
                estimated_arrival: 'Estimated arrival date',
                packaging_completion_date: 'Packaging completion date',
                order_no: 'Order No',
                order_status: 'Order status',
                rough_draft: 'Draft',
                funds_be_reviewed: 'Insufficient Balance',
                approved: 'Approved',
                canceled: 'Cancelled',
                order_type: 'Order type',
                creation_date: 'Creation Date',
                accessory_code: 'Part No',
                mode_transport: 'Mode of transport',
                brand: 'Brand',
                export_order_details: 'Download',
                dt_code: 'DT code',
                order_amount: 'Order amount',
                term_of_trade: 'Trade Terms',
                submission_date: 'Submission date',
                DCS_order_number: 'DCS order No',
                remarks: 'Remarks',
                upload_credit: 'Upload letter of credit',
                lc_no: 'L/C No',
                term_validity: 'Term of validity',
                amount: 'L/C amount',
                picture: 'L/C picture',
                empty: 'L/C No. cannot be empty',
                payment_registration: 'Payment Uploading',
                payment_voucher_no: 'Payment No',
                received_amount: 'Received amount',
                payment_date: 'Payment date',
                payment_amoun: 'Payment amount',
                number_lines: 'Items',
                total_accessories: 'Qty',
                estimated_premium: 'Estimated Insurance',
                estimated_freight: 'Estimated Freight',
                estimated_amount: 'Estimated total order amount',
                estimated_volume: 'Estimated volume (m³)',
                order_tracking: 'Order tracking',
                put_away: 'Collapse',
                more: 'More',
                actually_delivered: 'Actual Delivery Part No',
                confirm_quantity: 'Confirm quantity',
                package_quantity: 'Packing Qty',
                shipment_quantity: 'Shipped Qty',
                arrival_quantity: 'Arrived Qty',
                cancel_quantity: 'Cancelled Qty',
                bo_quantity: 'BO Qty',
                order_submission: 'Submit',
                warn: 'Note: 1. When arranging transferring, please mark your',
                wa: '(DT Code) and (Word: Spare Parts)',
                rn: 'for us to confirm the payment,',
                warnts: ' if you miss them, we may cannot check your payment in time',
                warns: '2. The account amount shall be subject to the actual amount received;',
                voucher: 'Please enter the payment voucher number',
                please_brand: 'Please select a brand',
                payment_dates: 'Please enter the payment date',
                payment_amount: 'Please enter the payment amount',
                credit_number: 'Please enter the letter of credit number',
                validity_period: 'Please enter the validity period',
                letter_credit: 'Please enter the amount of the letter of credit',
                upload_picture: 'Please upload a picture of the letter of credit',
                part_no: 'Part No',
                part_name: 'Part Name',
                quantity: 'Order quantity',
                accessories: 'Details of accessories',
                company: 'Shipping company',
                ships_name: 'Vessel name',
                flights: 'Voyage No.',
                customs_no: 'Customs No',
                lading_no: 'BL No.',
                flight_number: 'Flight No.',
                container_no: 'Container No',
                packing_list: 'Packing list',
                packaging_quantity: 'Packaging quantity',
                packaging_completion: 'Packaging completion date',
                shipment_quantitys: 'Shipment quantity',
                delivery_date: 'Estimated Delivery Date',
                shipment_dates: 'Actual shipment date',
                arrival_quantitys: 'Arrival quantity',
                arrival_date: 'Estimated arrival date',
                actual_arrival_date: 'Actual arrival date',
                payment_status_query: 'Payment status query',
                total_accessorie: 'Total number of accessorie',
                export_order_list: 'Export order list',
            },
            salesManagement: {
                booking_email: 'Booking email',
                additional_fee_amount: 'Additional fee amount',
                before_modifications: 'Before modification',
                after_modification: 'After modification',
                continue_importing: 'Continue importing',
                quantity_difference: 'Quantity difference',
                customs_declaration_name: 'Customs declaration name',
                customs_declaration_not: 'Customs declaration or not',
                invoice_no: 'Invoice No',
                contract_no: 'Contract No',
                attachment_fee_amount: 'Additional fee amount',
                xpense_amount: 'Expense amount',
                import_packing: 'Import MAXUS packing list',
                import_list: 'Import MG packing list',
                sales_order_list: 'Sales order list',
                job_no_list: 'Job No List',
                dt_code: 'DT Code',
                brand: 'Brand',
                delivery_number: 'Delivery note number',
                packing_list_no: 'Packing list No',
                packing_list_nos: 'Packing list No:',
                bilateral_contract_no: 'Bilateral contract No',
                contract_status: 'Contract status',
                inbound_delivery: 'Inbound delivery order No',
                supplier: 'Supplier',
                list_creation: 'Packing list creation date',
                currency: 'Currency',
                transit_status: 'Transit status',
                split_merge: 'Split/Merge',
                sap_return_information: 'SAP return information',
                send_booking: 'Whether to send booking mail',
                lcl_or_not: 'LCL or not',
                operate: 'Operate',
                modify_freight: 'Modify freight',
                modify_lc: 'Modify LC',
                detailed: 'Detailed',
                merge: 'Merge',
                split: 'Split',
                diversion: 'Transport diversion',
                lcl: 'Lcl',
                charges: 'Additional charges',
                generation: 'One-click document generation',
                deletion: 'One-click document deletion',
                bind_lc: 'Bind LC',
                unbind_lc: 'Unbind LC',
                merge_ci: 'Export merged CI&PL',
                export_ci: 'Export CI',
                export_pl: 'Export PL',
                export_list: 'Export list',
                remove_split: 'Remove split',
                additional_fees: 'After canceling the merger, the packing list, additional fees, LCL, and container records will be cleared. Do you want to continue canceling the merger?',
                system_prompts: 'When canceling the split, the system prompts: After canceling the split, the packing list will be cleared for reshipment, additional fees, LCL, and container records. Do you want to continue canceling the split',
                cancel_merge: 'Cancel Merge',
                additional_charges: 'Additional charges:',
                packing: 'Packing list No:',
                expenses: 'transportation expenses:',
                delivery: 'Outbound delivery order No:',
                binding_record: 'Modify LC binding record',
                trade_terms: 'Trade terms:',
                logistics_mode: 'Logistics mode:',
                port: 'Port:',
                payment_method: 'Payment method:',
                please_select: 'Please select trade terms',
                please_logistics_mode: 'Please select the logistics method',
                please_port: 'Please select a port',
                please_payment_method: 'Please select payment method',
                select_export_type: 'Select export type',
                plase_select_export_type: 'Please select the export type',
                lc_code: 'LC No',
                sap_return_status: 'SAP return status',
                sap_voucher_code: 'SAP voucher code',
                amount_difference: 'Amount difference',
                invoice_date: 'Invoice Date',
                invoice_import_date: 'Invoice Import Date',
                unbinding: 'Unbinding',
                no_unbinding_lc: 'No unbinding LC',
                sub_order_no: 'Sub-order No',
                submit_sap: 'Whether to submit SAP',
                case_no: 'Case No:',
                binding: 'Binding',
                outbound_delivery: 'Outbound Delivery',
                document_type: 'Document type',
                accessory_code: 'Part No',
                accessory_name: 'Part Name',
                sales_order_information: 'Sales order information',
                total_lines_of_accessories: 'Total lines of accessories:',
                total_number_of_accessories: 'Total number of accessories:',
                total_amount: 'Total amount:',
                amount_of_accessories: 'Amount of accessories:',
                premium: 'Premium:',
                volume: 'Total package volume (m³)z',
                total_gross_weight: 'Total gross weight of package:',
                weight_of_package: 'Total net weight of package:',
                container_no: 'Container No',
                actually_delivered: 'Actual Delivery Part No',
                order_parts_code: 'Original Order Part No',
                shipment_quantity: 'Shipment quantity',
                proforma_invoice: 'Proforma invoice No',
                customs_date: 'Customs declaration date',
                customs_no: 'Customs declaration No',
                net_weight: 'Net weight (kg)',
                rough_weight: 'Rough weight(kg)',
                volumes: 'Volume (m³)',
                outer_bo_size: 'Outer box size (length mm)',
                box_size: 'Outer box size (width mm)',
                box_height: 'Outer box size (height mm)',
                modification_date: 'Modification date',
                modification_by: 'Modified by',
                before_modification: 'Freight before modification',
                modified_freight: 'Modified freight',
                sales_slip: 'Sales slip',
                modification_record: 'Freight modification record',
                recommended_freight: 'Air Freight Control Parts',
            },
            price: {
                DNNumber: 'DN number',
                deliveryDate: 'Delivery date',
                purchasePrice: 'Purchase price',
                priceForDelivery: 'Passenger car delivery unit price',
                brand: 'Brand',
                part_no: 'Part No.',
                part_name: 'Part Name',
                currency: 'Currency',
                fob_unit_price: 'FOB Unit Price',
                brand_and_accessory: 'Brand and accessory code are required when querying',
            },
            payableList: {
                smil_sap_order_no: 'CI Invoice No',
                ci_date: 'CI Date',
                status: 'Status',
                bl_date: 'BL Date',
                overdue_date: 'Overdue Date',
                smil_sap_contract_no: 'SMIL SAP Contract No',
                category: 'Category',
                ci_price: 'CI Price',
                mark_payment: 'Mark Payment',
                remittance_ref: 'Remittance Ref No.',
                import_payment_list: 'Import Payment List',
            },
            lcManagement: {
                lc_no: 'LC No.',
                sap_no: 'SAP No.',
                review_status: 'Review Status',
                pending_approval: 'Pending approval',
                approved: 'Approved',
                returned: 'Returned',
                create_date: 'Create Date',
                valid_or_not: 'Valid Or Not',
                dt_code: 'DT Code',
                date: 'Date',
                amount_status: 'Amount Status',
                for_confirmation: 'Waiting for Confirmation',
                returneds: 'Returned',
                recorded: 'Recorded',
                expended: 'Expended',
                new_lc: 'New LC',
                lc_amount: 'LC Amount',
                expired_lc: 'Expired LC',
                packing_list: 'Packing List No.',
                add_lc: 'Add LC',
                lc_renewal: 'LC Renewal',
                lc_recharge: 'LC Recharge',
                edit: 'Edit',
                export: 'Export',
                review: 'Review',
                detail: 'Detail',
                approveds: 'Approved',
                return: 'Returned',
                save: 'Save',
                cancel: 'Cancel',
                upload_files: 'Upload Files',
                start_date: 'Start Date',
                end_date: 'End Date',
                updated_date: 'Updated Date',
                review_type: 'Review Type',
                review_comments: 'Review Comments',
                review_date: 'Review Date',
                recharge_amount: 'Recharge amount',
                original_end_date: 'Original End Date',
                new_end_date: 'New End Date',
                file: 'File',
                lc_balance: 'LC Balance',
                lc_total_amount: 'LC Total Amount',
                lc_file: 'LC File',
                new_lc_review: 'New LC Review',
                lc_renewal_review: 'LC Renewal Review',
                lc_recharge_review: 'LC Recharge Review',
                add_lcs: 'Add LC',
                note_documents: 'Note: You can upload Excel, PDF, WORD, PNG, JPG, JPGE,RAR, ZIP documents',
                deduction_of_lc_amount: 'Deduction of LC Amount',
                refund_due_to_documents_delete: 'Refund due to Documents Delete',
                lc_balances: 'LC Balance',
                currency: 'Currency',
                lc_balan: 'LC Balance',
                file_upload_exception: 'File upload exception',
                amount_type: 'Amount Type',
                lc_type: 'LC type',
            },
            dtOrderReview: {
                approved: 'Approved',
                returned: 'Returned',
                batch_approva: 'Confirm batch approva',
                confirmed: 'Confirmed',
                cancel: 'Cancel',
                submitted_date: 'Submitted Date',
                transportation_mode: 'Transportation Mode',
                returned_reason: 'Returned reason',
            },
            inventoryQuery: {
                inventory_Query: 'Inventory Query',
                part_no: 'Part No.',
                inventory_existing: 'Inventory Existing',
                brand: 'Brand',
                part_name: 'Part Name(CN)',
                part_name_en: 'Part Name(EN)',
            },
        },
        notice: {
            query: {
                announcement_number: 'Bulletin number',
                announcement_name: 'Bulletin name',
                announcement_type: 'Bulletin type',
                release_date: 'Release Date',
                does_attachments: 'Does it include attachments',
                release: 'release',
                announcement_status: 'Bulletin status',
                check: 'check',
                protocol: 'protocol',
                announcement_information: 'Bulletin information',
                delay_remarks: 'Delay remarks',
                delay_status: 'Delay status',
                submission_date: 'Submission_date',
            },
        },
        document: {
            document: 'Document',
            name: 'Name',
            sort: 'Sort',
            directory: 'Directory',
            parent_directory: 'Parent directory',
            new_directory: 'New directory',
            edit_directory: 'Edit directory',
            primary_directory: 'Primary directory',
            document_title: 'Document title',
            vehicle_platform: 'Vehicle platform',
            status: 'Status',
            release_status: 'Release status',
            create_time: 'Create time',
            secondary_directory: 'Secondary directory',
            model: 'Model',
            attachment_name: 'File name',
            publishing_department: 'Publishing department',
            publisher: 'Publisher',
            person_in_charge: 'Person in charge',
            remarks: 'Remarks',
            publish_objects: 'Release to',
            publish_mode: 'Release mode',
            whether_the_document_is_downloadable: 'Whether the document is downloadable',
            document_information: 'Document information',
            document_tag: 'Document tag',
            operation_record: 'Operation record',
            operation_details: 'Operation details',
            operating_time: 'Operating time',
            operator: 'Operator',
            to_be_released: 'To be released',
            published: 'Released',
            voided: 'Invalid',
        },
        announcement: {
            title: 'Title',
            publishing_department: 'Publishing department',
            status: 'Status',
            release_Date: 'Release Date',
            serialNo: 'No',
            publisher: 'Publisher',
            file_Name: 'File Name',
            details: 'Details',
            void: 'Void',
            information_detail: 'Information Detail',
            notification_Title: 'Notification Title',
            publish_Objects: 'Release to',
            publish_Mode: 'Release mode',
            bulletin_id: 'Bulletin ID',
            publish_Content: 'Release content',
            preview: 'Preview',
            attachmentUpload: 'Attachment Upload',
            supportedFormats: 'Supported Formats',
            have: 'Have',
            NoticeWaitingToBeRead: 'Notice waiting to be read',
            released: 'Released',
            voided: 'Voided',
        },
        basic: {
            accessories: {
                masterdata: {
                    english_smpv: 'English name of SMPV',
                    accessory_code: 'Part No',
                    accessory_name: 'Part Name',
                    accessory_name_zh: 'Part Name(CN)',
                    accessory_name_en: 'Part Name(EN)',
                    accessory_enm: 'English description of accessories',
                    brand: 'Brand',
                    sales_area: 'SalesArea',
                    create_data: 'Date created',
                    is_purchase: 'Purchase Status',
                    is_transportations: 'Transportation',
                    purchase_attribute: 'PurchaseAttribute',
                    is_marketable: 'Sales Status',
                    supplier_code: 'SupplierCode',
                    supplier_name: 'SupplierName',
                    country_origin: 'CountryOrigin',
                    is_transportation: 'IsTransportation',
                    magnetic_inspection: 'MagneticInspection',
                    // specialProcurementCycle: 'specialProcurementCycle',
                    material: 'Material',
                    is_max_overbooking: 'Control the maximum order quantity',
                    max_overbooking: 'Maximum order quantity allowed',
                    sap_code: 'SAP Code',
                    unit: 'Part unit',
                    accessories_edit: 'Accessories modification',
                    accessories_add: 'New accessories',
                    accessories_detailed: 'Details of accessories',
                    special_procurement_cycle: 'Special procurement cycle',
                    accessory_unit: 'Part unit',
                    name_of_customs_declaration_materials: 'Name of customs declaration materials',
                    customized_form: 'Customized form',
                    customs_declaration_unit: 'Customs declaration unit',
                    remarks: 'Remarks',
                    customs_code: 'Customs code',
                    license_material_name: 'License Material Name',
                    create_by: 'CreateBy',
                    company: 'Corporate name',
                    declare_ele: 'Specifications',
                    base_cal_unit: 'Company',
                    base_cal_unit_cn: 'Chinese unit',
                    update_by: 'Modified by',
                    update_time: 'Modification time',
                    accessories_picture: 'Part Image',
                    custom_accessories: 'Customized Attachment',
                    declaration_elements: 'Declaration elements',
                    transportation: 'No air transportation',
                    accessories_selection: 'Accessories selection',
                    enable_or_not: 'Enable or not',
                    supplier_selection: 'Supplier selection',
                    whether_glass: 'Whether glass',
                },
                orderInvoicing: {
                    partCode: 'Part Code',
                    brand: 'Brand',
                    baseQuantity: 'Basic quantity',
                    baseUnit: 'Basic unit',
                    transferQuantity: 'Conversion quantity',
                    transferUnit: 'Convert Units',
                    createBy: 'Created by',
                    createTime: 'Creation time',
                    orderInvoicing_new: 'Newly added',
                    orderInvoicing_edit: 'Edit',
                },
                billMaterials: {
                    dt_name: 'DT', // DT名称
                    part_code: 'Part code', // 零件代码
                    part_name_cn: 'Part Name(CN)', // 中文名称
                    part_name_en: 'Part Name(EN)', // 英文名称
                    apply_time: 'Application date', // 申请日期
                    brand: 'Brand', // 品牌
                },

                replace: {
                    no: 'No.',
                    group_no: 'Group No.',
                    item_no: 'Item No.',
                    leading_products: 'Old Part Number',
                    description_of_leading_products: 'Description of old Part Number',
                    subsequent_products: 'New Part Number',
                    subsequent_product_description: 'Description of new Part Number',
                    substitutive_relationship: 'Supersession Code',
                    exhaustion_strategy: 'Exhaustion strategy',
                    depletion_date: 'Exhaustion Date',
                    effective_date: 'Effective date',
                    creation_date: 'Create Date',
                    newly_added: 'Newly added',
                    newly_edit: 'Edit',
                },

                mxreplace: {
                    group_no: 'Group No',
                    project_no: 'Item No',
                    part_code: 'Part code',
                    part_name_cn: 'Part Name(CN)',
                    new_part_code: 'Alternate part code',
                    new_part_name_cn: 'Chinese name of substitute select',
                    effective_time: 'Effective date',
                    stock_deal: 'Suggestions on inventory disposal',
                    create_time: 'Creation Date',
                    replace_relate: 'Substitutive relationship',
                    brand: 'Brand',
                    replace_remarks: 'Replace remarks',
                    newly_edit: 'Edit', // edit
                    part_codes: 'Part code',
                    new_part_codes: 'Replacement part code',
                    new_part_name_cns: 'Chinese name of replacement parts',
                    stock_deals: 'Stock handling suggestions',
                },
                sap: {
                    sap_part_id: 'Primary key', // 主键
                    part_id: 'Part id', // 零件id
                    brand: 'Brand', // 项目编号
                    part_code: 'Old material No', // 零件代码
                    material_type: 'ZTG2/SAP material type', // ZTG2/SAP物料类型
                    customer_declare_material_name: 'Name of customs declaration materials', // 报关物料名称
                    material_name_en: 'English material name', // 英文物料名称
                    // brand: string; // 品牌
                    hs_code: 'HsCode', // HS编码
                    customer_declare_licence: 'License Material Name', // 许可证物料名称
                    base_cal_unit: 'BUoM', // 基本计量单位
                    sap_code: 'SAP Material No', // SAP物料号
                    sap_codes: 'Sap material type', // SAP物料号
                    gross_weight: 'Gross weight', // 毛重
                    declare_ele: 'Elements of declaration', // 申报要素
                    net_weight: 'Net weight', // 净重
                    volume: 'Volume (m³)', // 体积
                    specs: 'Specifications', // 规格
                    danger_attr: 'Product Attribute 2 (Dangerous Goods)', // 产品属性2(危险品)
                    abroad_danger_attr:
                        'Product attribute 3 (identification of foreign dangerous goods)', // 产品属性3(国外危险品标识)
                    status: 'Create Status', // 状态0-草稿，1-进行中，2-已完成
                    create_by: 'Created by', // 创建人
                    create_time: 'Creation time',
                    update_by: 'Modified by', // 创建人
                    update_time: 'Modification time',
                    sap_edit: 'Edit',
                    sap_add: 'Newly added',
                    error_reasons: 'Sap material type',
                    sap_status: 'Sap return status',
                    part_name_cn: 'Chinese material description',
                    sale_tax_class: 'Sales tax classification',
                    error_reason: 'Return information',
                },
                coo: {
                    country_id: 'Country',
                    brand: 'Brand',
                    part_code: 'Part code',
                    part_name_cn: 'Name of accessories',
                    origin_country: 'Country of origin',
                    create_by: 'Created by',
                    create_time: 'Created on',
                    coo_add: 'Newly added',
                    coo_edit: 'Edit',
                    accessories_selection: 'Accessories selection',
                },
                sapModify: {
                    gds_material_number: 'GDS material number',
                    sap_material_number: 'SAP material number',
                    chinese_name_of_material_zh: 'Chinese name of material ZH',
                    english_name_of_material_en: 'English name of material EN',
                    material_declaration_name_z1: 'Material declaration name Z1',
                    declaration_elements: 'Declaration elements',
                    classification_proposal_no: 'Classification proposal No',
                    period_of_validity_of_classification: 'Classification validity period',
                    tax_classification_code: 'Tax classification code',
                    factory: 'Factory',
                    status: 'Status',
                    sap_status: 'SAP return status',
                    commodity_import_code_number: 'Commodity/import code number',
                    sap_modify_edit: 'Edit',
                    sap_modify_add: 'Newly added',
                },
            },
            sales: {
                weight_ladder: 'Weight Ladder',
                country: 'Country',
                port: 'Port',
                portCode: 'PortCode',
                portCN: 'ChineseName',
                portUS: 'EnglishName',
                EffectiveDate: 'Effective date',
                ExpirationDate: 'Expiration Date',
                oldEffectiveTime: 'OldEffectiveTime',
                oldExpireTime: 'OldExpireTime',
                newEffectiveTime: 'NewEffectiveTime',
                newExpireTime: 'NewExpireTime',
                freight: 'Freight',
                KG: 'KG',
                KGabove: 'Above KG',
                express: 'ExpressArea',
                general: 'General',
                danger: 'Danger',
                GP20: '20GP unit price (USD)',
                GP40: '20GP unit price (USD)',
                hq40: '40hq unit price (USD)',
                region: 'ExpressArea',
                currencyCode: 'Currency code',
                description: 'Description',
                MinimumUnit: 'Minimum Unit',
                ChineseDescription: 'Chinese Description',
                symbol: 'Symbol',
                updated_date: 'Last update date',
                price_benchmark: 'Price Benchmark',
                part_description: 'Part description',
                excluding_tax: 'Purchase price excluding tax',
                tax: 'Purchase price including tax',
                price_including_tax: 'Domestic sales price including tax',
                seller: 'Seller',
                selling_party: 'Selling Party',
                price: 'Price',
                sales_ratio: 'Sales Ratio(%)',
                cifShare: {
                    trade_clause: 'Trade clause',
                    order_type: 'Order type',
                    type_of_shipping: 'Type of shipping',
                    dt: 'DT',
                    last_modified_date: 'Last modified date',
                    creation_date: 'Creation date',
                    sales_price_proportion: 'Sales price proportion（%）',
                    seller: 'Seller',
                    dt_choose: 'DT choose',
                    dt_code: 'DT code',
                    dt_name: 'Dt name',
                    country: 'Country',
                },
                cifSingle: {
                    trade_clause: 'Trade_clause',
                    accessory_type: 'Part type',
                    type_of_shipping: 'Type of shipping',
                    dt: 'DT',
                    freight_coefficient: 'Freight coefficient（%）',
                    premium_coefficient: 'Premium coefficient（%）',
                    air_freight0: 'Air freight（0-100KG）',
                    air_freight100: 'Air freight（100-500KG）',
                    air_freight500: 'Air freight（500KG+）',
                    minimum_air_freight_charge: 'Minimum air freight charge',
                    dangerous_goods_freight: 'Dangerous goods freight',
                    dangerous_goods_minimum_freight: 'Dangerous goods minimum freight',
                    dangerous_goods_ITEM_freight: 'Dangerous goods ITEM freight',
                    dangerous_goods_qty_freight: 'Dangerous goods qty freight',
                    dt_choose: 'DT choose',
                    dt_code: 'DT code',
                    dt_name: 'Dt name',
                },
                triesLimit: {
                    dt: 'DT',
                    frequency_limit: 'Frequency limit',
                    price_ratio: 'Price ratio（%）',
                    seller: 'Seller',
                    brand: 'Brand',
                    dt_choose: 'DT choose',
                    dt_code: 'DT code',
                    dt_name: 'DT name',
                },
                dcs: {
                    partial_transmission: 'Partial transmission',
                    full_transmission: 'Full transmission',
                    download_template: 'Download template',
                    upload_Excel: 'Upload Excel',
                    upload_files: 'Upload files',
                    no_files_selected: 'No files selected',
                    General_generation: 'General generation',
                    DCS_price_delivery_price: 'DCS price delivery (FOB) price',
                },
            },
            sort: {
                maxmaterial: {
                    category_code: 'parts type',
                    // category_codes: '定价大类',
                    category_desc: 'Describe',
                    maxmaterial_add: 'Newly added',
                    maxmaterial_edit: 'Edit',
                },
                minmaterial: {
                    no_air: 'No air transportation',
                    category_code: 'Parts group',
                    category_desc: 'Parts group description',
                    big_material_category_id: 'parts type',
                    pack_alone: 'Whether it is packed separately',
                    minmaterial_add: 'Newly added',
                    minmaterial_edit: 'Edit',
                    material_category_selection: 'Material category selection',
                },
                model: {
                    type_code: 'Type code',
                    brand: 'Brand',
                    parts_code: 'Parts code',
                    parts_name: 'Parts name',
                    save_add: 'Save and new',
                },
                code: {
                    type_code: 'Type code',
                    model_grade: 'Model grade',
                    save_add: 'Save and new',
                },
                marialMaint: {
                    small_material_category_id: 'Parts group', // 物料小类代码
                    brand: 'Brand',
                    create_time: 'Creation time',
                    create_by: 'Created by',
                    part_id: 'Part code', // 配件代码
                    marial_maint_add: 'Newly added',
                    marial_maint_edit: 'Eidt',
                },
            },
            packing: {
                mpq: {
                    transport: 'Mode of transport',
                    category_code: 'Small class code',
                },
                information: {
                    transport_package_No: 'Transport package No',
                    name: 'Name',
                    classification: 'Classification',
                    purpose: 'Purpose',
                    length: 'Length(mm)',
                    width: 'Width(mm)',
                    height: 'Height(mm)',
                    volume: 'Volume(CBM)',
                    specification_requirements: 'Specification requirements',
                    brand: 'Brand',
                    creation_date: 'Creation date',
                    created_by: 'Created by',
                    purchasing_unit: 'Purchasing unit',
                },
                volume: {
                    volume_factor: 'Volume factor',
                    shipping_type: 'Shipping type',
                    brand: 'Brand',
                    creation_date: 'Creation date',
                    created_by: 'Created by',
                },
                cmaintain: {
                    packing_type_code: 'Packing type code',
                    english_description: 'English description',
                },
                sap: {
                    pack_material_code: 'Packaging material No',
                    chinese_desc: 'Chinese name',
                    english_desc: 'English name',
                    sap_add: 'Newly added',
                    sap_edit: 'Edit',
                },
                minmaterial: {
                    small_material_category_code: 'Material code',
                    small_material_category_desc: 'Item description',
                    special_compute: 'Special calculation or not',
                    create_time: 'Created on',
                    create_by: 'Created by',
                    small_code: 'parts type',
                    minmaterial_add: 'Newly added',
                    minmaterial_edit: 'Edit',
                },
                maintain: {
                    package_volume: 'Package volume（m³）',
                    part_code: 'Part code',
                    brand: 'Brand',
                    minimum_number_of_packages: 'Minimum number of packages',
                    long: 'Long',
                    width: 'Width',
                    height: 'Height',
                    gross_weight: 'Gross weight',
                    net_weight: 'Net weight',
                    creation_date: 'Creation Date',
                    create_by: 'Create by',
                    revision_date: 'Revision date',
                    revision_by: 'Revision by',
                    package_type: 'Package type',
                    unit_of_length: 'Unit of length',
                    unit_of_weight: 'Unit of weight',
                    package_plan: 'Package plan',
                },
                dangerInfo: {
                    accessory_code: 'Part code',
                    nam_accessories: 'Part Name(EN)',
                    accessories_cn: 'Part Name(CN)',
                    brand: 'Brand',
                    bonfire_experiment: 'Bonfire experiment number',
                    bonfire_experiment_cn: 'Part Name(CN)',
                    bonfire_experiment_en: 'Part Name(EN)',
                    dangerous_goods_supplier: 'Dangerous goods supplier',
                    length: 'Length (mm)',
                    width: 'Width (mm)',
                    height: 'Height (mm)',
                    packing_type: 'Packing type',
                },
            },
            customs: {
                hscode: {
                    hs_code: 'Customs code', // 海关代码
                    hscode_unit: 'L/KG', // L/KG
                    is_inspect: 'Commodity inspection or not', // 是否商检，Y-是，N-否
                    tax_refund_rate: 'Tax refund rate', // 退税率
                    supervise_condition: 'Regulatory conditions', // 监管条件
                    company: 'Company', // 公司
                    country_id: 'Country', // 国家
                    create_time: 'Creation time',
                    hscode_add: 'Newly added',
                    hscode_edit: 'Edit',
                },
                export: {
                    brand: 'Brand', // 品牌
                    part_code: 'Part code', // 配件代码
                    conutry_code: 'Country code', // 国家代码
                    hscode: 'Customs code', // 海关编码
                    form: 'FORM-E', // FORM-E
                    tariff: 'TARIFF', // TARIFF
                },
                fluid: {
                    brand: 'Brand',
                    part_id: 'Part code',
                    part_id_name: 'Name of accessories',
                    fluid_add: 'Newly added',
                },
                danger: {
                    company: 'Company', // 公司
                    ship_type: 'Type of shipping', // 运输方式
                    part_code: 'Part code', // 配件代码
                    brand: 'Brand',
                    danger_type: 'Type of dangerous goods', // 危险品类型
                    danger_category: 'Classification of dangerous goods', // 危险品分类
                    chinese_part_name: 'Chinese name of accessories',
                    english_part_name: 'English name of accessories',
                    danger_add: 'Newly added',
                    danger_edit: 'Edit',
                },
                choren: {
                    chinese_unit: 'Chinese unit',
                    english_unit: 'English unit',
                    create_by: 'Created by',
                    create_time: 'Creation time',
                    choren_add: 'Newly added',
                    choren_edit: 'Edit',
                },
                maintain: {
                    dt: 'DT',
                    material_no: 'Material No',
                    brand: 'Brand',
                    customs_code: 'Customs code',
                    form_e_mark: 'Form E Mark',
                    created_on: 'Created on',
                    created_by: 'Created by',
                    maintain_add: 'Newly added',
                    maintain_edit: 'Edit',
                },
                chemicali: {
                    company: 'Company',
                    material_no: 'Material No',
                    brand: 'Brand',
                    license_or_not: 'License or not',
                    chemicali_add: 'Newly added',
                    chemicali_edit: 'Edit',
                },
            },
            salesl: {
                fixedRate: {
                    local_currency: 'Local currency',
                    name_of_local_currency: 'Name of local currency',
                    seller: 'Seller',
                    fixed_rate: 'Fixed rate',
                    freight_exchange_rate: 'Freight exchange rate',
                    settlement_currency: 'Settlement currency',
                    name_of_settlemen_currency: 'Name of settlement currency',
                    dt: 'DT',
                    brand: 'Brand',
                    effective_date: 'Effective Date',
                    expiration_date: 'Expiration Date',
                    created_on: 'Created on',
                    fixedRate_add: 'Newly added',
                    fixedRate_edit: 'Edit',
                    fixedRate_select: 'After selecting DT, the brand is not empty',
                },
                transport: {
                    dt: 'DT',
                    brand: 'Brand',
                    trade_terms: 'Trade terms',
                    type_of_shipping: 'Transportation Mode ',
                    transport_add: 'Newly added',
                    created_on: 'Created on',
                    transport_edit: 'Edit',
                },
                purchaseConfirm: {
                    accessory_code: 'Part Code',
                    effective_date: 'Effective date',
                    expiration_date: 'Expiration Date',
                    accessory_description: 'Part Description',
                    purchase_price: 'Purchase price excluding tax',
                    tax_rate: 'Tax rate',
                    currency: 'Currency',
                    corporation: 'Corporation',
                    created_on: 'Created on',
                },
            },
            sapModify: {
                gds_material_number: 'GDS material number',
                sap_material_number: 'SAP material number',
                chinese_name_of_material_zh: 'Chinese name of material ZH',
                english_name_of_material_en: 'English name of material EN',
                material_declaration_name_z1: 'Material declaration name Z1',
                declaration_elements: 'Declaration elements',
                classification_proposal_no: 'Classification proposal No',
                period_of_validity_of_classification: 'Period of validity of classification',
                tax_classification_code: 'Tax classification code',
                factory: 'Factory',
                commodity_import_code_number: 'Commodity/import code number',
                sapModify_edit: 'Edit',
                sapModify_add: 'Newly added',
            },

            customer: {
                fundPool: {
                    dt_code: 'DT Code',
                    dt_name: 'DT name',
                    dt_short_name: 'DT short name',
                    currency: 'Currency',
                    account_amount: 'Account amount',
                    freeze_amount: 'Freeze amount',
                    date: 'Date',
                    fund_status: 'Fund status',
                    fund_type: 'Fund type',
                    payment_number: 'Payment number',
                    credit_number: 'Credit number',
                    suborder_number: 'Suborder number',
                    container_number: 'Container number',
                    credit_validity_date: 'Credit validity date',
                    recorded: 'Recorded / disbursement',
                    disbursement_money: 'Disbursement money',
                    wait_recorded_money: 'Wait recorded money',
                    recorded_money: 'Recorded money',
                    service_charge: 'Service charge',
                    account_balance: 'Account balance',
                    freeze_balance: 'Freeze balance',
                    credit_picture: 'Credit picture',
                    upload_list: 'Upload List',
                    remark: 'Remark',
                    dt_payment_confirm: 'DT payment confirm',
                    credit_confirm: 'Credit confirm',
                    credit_money: 'Credit money',
                    arrival_amount: 'Arrival amount',
                    dt_selection: 'DT selection',
                },
                customer: {
                    dt_code: 'DT code',
                    dt_name: 'DT name',
                    dt_short_name: 'DT short name',
                    country: 'country',
                    sap_code: 'Sap code',
                    brand: 'Brand',
                    beneficiary_name: 'Beneficiary name',
                    beneficiary_address: 'Beneficiary address',
                    VAT: 'VAT（%）',
                    Name_of_bank_of_deposit: 'Name of bank of deposit',
                    VAT_number: 'VAT Number',
                    bank_address: 'Bank address',
                    SWIFT_CODE: 'SWIFT CODE',
                    bank_account: 'Bank account',
                    Agent_bank_address: 'Agent bank address',
                    Agent_bank_account: 'Agent bank account',
                    Agent_bank_name: 'Agent bank name',
                    IBAN: 'IBAN',
                    Agent_bank_iban: 'Agent bank iban',
                    Agent_bank_SWIFT_Code: 'Agent bank SWIFT Code',
                    Bank: 'BANK',
                    inherit_general_generation: 'Inherit general generation',
                    money: 'Money',
                    A_24_hour_hotline: 'A 24 hour hotline',
                    facsimile: 'Facsimile',
                    telephone: 'Telephone',
                    local_country: 'Local country',
                    local_city: 'Local city',
                    local_area: 'Local area',
                    Postal_code: 'Postal code',
                    email_address: 'Email address',
                    Affiliated_brand: 'Affiliated brand',
                    Company_homepage: 'Company homepage',
                    Discount_rate_for_parts_sales: 'Discount rate for parts sales',
                    Company_address: 'Company address',
                    Method_of_settlement: 'Method of settlement',
                    Sales_territory: 'Sales territory',
                    Express_freight_area: 'Express freight area',
                    Selling_party: 'Selling party',
                    Address_of_the_selling_party: 'Address of the selling party',
                    Loan_agency_information: 'Loan agency information',
                    Payment_terms: 'Payment terms',
                    Account_period: 'Account period',
                    supplier: 'Supplier',
                    Shipping_address: 'Shipping address',
                    Contact_name: 'Contact name',
                    File_delivery_address: 'File delivery address',
                    Air_address: 'Air address',
                    Express_address: 'Express address',
                    organization: 'organization',
                    Trade_clause1: 'Trade clause1',
                    Trade_clause2: 'Trade clause2',
                    beneficiary: 'Beneficiary',
                    fmc: 'FMC',
                    Swift_Code: 'Swift Code',
                    USD_Account: 'USD Account',
                    EUR_Account: 'EUR Account',
                    RMB_Account: 'RMB Account',
                    Tax_number: 'Tax number',
                    Order_clerk: 'Order clerk',
                    Method_of_payment: 'Method of payment',
                    Market_type: 'Market type',
                    MX_business_model: 'MX business model',
                    label: 'Label',
                    Air_freight_rate: 'Air freight rate（%）',
                    Ocean_freight_rate: 'Ocean freight rate（%）',
                    Express_rate: 'Express rate（%）',
                    Document_number_prefix: 'Document number prefix',
                    TT_settlement_term: 'TT settlement term',
                    LC_settlement_term: 'LC settlement term',
                    name: 'Name',
                    Contact_person: 'Contact person',
                    Contact_number: 'Contact number',
                    address: 'Address',
                    city: 'City',
                    Zip_code: 'Zip code',
                    sap_consumer_query: 'Sap consumer query',
                    customer_code: 'Customer code',
                    customer_name: 'Customer name',
                    sales_organization: 'Sales organization',
                    abbreviation: 'Abbreviation',
                    organization_name: 'Organization name',
                    customer_information: 'Customer information',
                    delivered_by: 'Delivered by',
                },
            },
        },
        components: {
            businessComponents: {
                documentType: 'Document type',
                vehicleType: 'Vehicle type',
                dealerInformation: 'Dealer Information',
                reportType: 'Report Type',
                dealerContact: 'Dealer Contact',
                contact: 'Contact #',
                occupation: 'Occupation',
                vehicleInformation: 'Vehicle information',
                mileage: 'Mileage (/km)',
                licensePlate: 'License plate #',
                platform: 'Platform',
                offLineDate: 'Off-line date',
                vehicleUse: 'Vehicle use',
                refited: 'Refited',
                nachgefüllt: 'Nachgefüllt',
                nameplateNo: 'Nameplate No',
                purchaseDate: 'Purchase date',
                accidentInformation: 'Accident information',
                timeOfAccident: 'Time of accident',
                placeOfAccident: 'Place of accident',
                roadConditions: 'Road conditions',
                pavementType: 'Pavement type',
                weather: 'Weather',
                vehicleCondition: 'Vehicle condition',
                vehicleOfTheAccident: 'Vehicle information at the time of the accident',
                vehicleCollisionPosition: 'Vehicle collision position',
                vehicleRunningStatus: 'Vehicle running status',
                ownSpeed: 'Own speed',
                vehicleSpeedOfTheOtherSide: 'Vehicle speed of the other side',
                personnelInsideTheVehicle: 'Personnel inside the vehicle',
                numberOfPeopleInTheCar: 'Number of people in the car',
                personalInjury: 'Personal injury',
                distributionOfPersonnelInTheVehicle: 'Distribution of personnel in the vehicle',
                descriptionOfPersonalInjury: 'Description of personal injury',
                airbagDeployment: 'Airbag deployment',
                safetyBeltWearing: 'Safety belt wearing',
                brakeBeforeTheAccident: 'Brake before the accident',
                turnBeforeTheIncident: 'Turn before the incident',
                whenCorrosionOccurs: 'When corrosion occurs',
                parkingTimeHours: 'Parking time (hours)',
                parkingTimeMinutes: 'Parking time (minutes)',
                drivingHours: 'Driving Hours',
                drivingMinutes: 'Driving minutes',
                drivingDistance: 'Driving distance',
                drivingSpeed: 'Driving speed',
                vehicleState: 'Vehicle state',
                fireFightingOrNot: 'Fire fighting or not',
                numberOfInjured: 'Number of injured',
                deathToll: 'Death toll',
                vehicleHelp: 'Vehicle help',
                vehiclePassingAccident: 'Vehicle passing accident',
                pastAccidents: 'Past accidents',
                isTheWarningLampOnBeforeTheEvent: 'Is the warning lamp on before the event',
                specificIconContent: 'Specific icon content',
                didYouSmellFuelBeforeTheAccident: 'Did you smell fuel before the accident',
                didYouFeelAnyAbnormalVibrationBeforeTheAccident:
                    'Did you feel any abnormal vibration before the accident',
                abnormalSoundZone: 'Abnormal sound zone',
                didYouHearAnyNoiseBeforeTheAccident: 'Did you hear any noise before the accident',
                noiseDescription: 'Noise Description',
                theAreaWhereTheVehicleSmokedBeforeTheAccident:
                    'The area where the vehicle smoked before the accident',
                otherAreas: 'Other areas',
                whetherTheInsuranceCompanyHasIssuedACertificate:
                    'Whether the insurance company has issued a certificate',
                whetherTheAuthorityIssuesTheCertificate:
                    'Whether the authority issues the certificate',
                isVDSReportAvailable: 'Is VDS report available',
                accidentProcess: 'Accident process',
                accidentProcessPlaceholder:
                    'Reported by_ The paraphrase of XXXX.\n' +
                    'At around XX a.m. on XX/XX/20XX, the party XXXX was driving a vehicle with license plate number XXXXXX Roewe RX50 1.5/AT along the national highway from south to north (with one person inside and one driver). When driving to XX Village, XX Town, XX County, XX City, XXX Province, it was found that white smoke was generated in the left cockpit.',
                accessoryMaterials: 'Part materials',
                bulkDownload: 'Bulk download',
                materialUpload: 'Material Upload',
                userReadmeAttachment: 'User Readme Attachment',
                sitePhotos: 'Site photos',
                fireAccidentIdentificationLetter: 'Fire Accident Identification Letter',
                insuranceRecordAttachment: 'Insurance Record Attachment',
                diagnosticRecords: 'Diagnostic Records',
                temperature: 'Temperature',
                please_select_the_reporting_type: 'Please select the reporting type',
                fire_accident_identification_letter_required:
                    'Fire Accident Identification Letter Required',
                please_upload_the_fire_accident_identification_letter:
                    'Please upload the fire accident identification Letter',
                insurance_record_attachment_required: 'Insurance Record Attachment Required',
                please_upload_the_insurance_record_attachment:
                    'Please upload the insurance record attachment',
                please_upload_the_diagnostic_record_attachment:
                    'Please upload the diagnostic record attachment',
                please_upload_site_photos: 'Please upload on-site photos',
            },
        },
        order: {
            claimReport: {
                total_price: 'Total Price',
                claim_qty: 'Claim Qty',
                parts_no: 'Parts Claim No',
                packing_list_no: 'Packing list No',
                submit_date: 'Submit date',
                suborder_number: 'Suborder Number',
                smpv_number: 'SMPV Number',
                part_code: 'Part Code',
                claim_type: 'Claim Type',
                current_process_status: 'Current Process Status',
                dt_name: 'DT Name',
                creation_date: 'Creation Date',
                claim_total_price: 'Claim Total Price',
                times_of_claims: 'Times Of Claims',
                part_name: 'Part Name',
                shipment_qty: 'Shipment Qty',
                received_qty: 'Received Qty',
                claim_quantity: 'Claim Qty',
                approved_qty: 'Approved Qty',
                sale_price: 'Sale Price(FOB)',
                problem_description: 'Problem Description',
                approved_date: 'SMIL Approved Date',
                audit_result: 'SMIL Audit Result',
                smil_audit_description: 'SMIL Audit Description',
                oem_approved_date: 'OEM Approved Date',
                tac_approved_date: 'TAC Approved Date',
                ome_audit_result: 'OEM Audit Result',
                tac_audit_result: 'TAC Audit Result',
                oem_audit_description: 'OEM Audit Description',
                tac_audit_description: 'TAC Audit Description',
                scrap_image_submission_time: 'Scrap image submission time',
                image_audit_time: 'SMIL scrap image audit  time',
                tac_image_audit_time: 'TAC scrap image audit  time',
                image_audit_results: 'SMIL scrap image audit  results',
                tac_image_audit_results: 'TAC scrap image audit  results',
                scrap_time: 'OEM scrap image audit  time',
                oem_image_audit_results: 'OEM scrap image audit  results',
                current_statuss: 'Current Process Status',
                update_date: 'Update Date',
                tac_review_comments: 'TAC review comments',
            },
            claim: {
                cannot_be_empty: 'Cannot be empty',
                cannot_be: 'Cannot be 0',
                creater: 'Creator',
                brand: 'Brand',
                claimAmountAll: 'Claim Total Price',
                claimAmounts: 'Claim customer email',
                creationDate: 'Creation Date',
                currentState: 'Current Process Status',
                claimNumber: 'Times of claims',
                claimName: 'Name',
                claimNameCN: 'NameCN',
                claimNameUS: 'Englishname',
                Application: 'Parts Claim No',
                orderNumber: 'Order No',
                amount: 'Please enter the amount',
                frequency: 'Please enter the number of times',
                applicationNumber: 'Parts Claim No',
                creationDateIn: 'Creation Date',
                new: 'New',
                applications: 'Claim Application List',
                creationTime: 'Creation time',
                money: 'Currency',
                containerNumber: 'Packing list No',
                pack_noCode: 'container Code',
                suborder: 'Suborder number',
                PartNumber: 'Part No',
                claimType: 'Claim Type',
                orderQuantity: 'Invoiced Quantiy',
                receivedQuantity: 'Received Quantity',
                claimNumbers: 'Claim Quantity',
                approvedQuantity: 'ApprovedQuantity',
                purchaseAmount: 'Sale Price(FOB)',
                subtotal: 'Total Price',
                scrapPhotos: 'Scrap Picture',
                accessoriesPhotos: 'Part Image',
                accessoriesLabel: 'Photos of parts labels',
                accessoriesPackagingPhotos: 'Photos of parts package',
                transportation: 'Photos of transport package',
                packingList: 'Photos of packaging list',
                claimReason: 'Problem Description',
                smil_review_comments: 'SMIL review comments',
                oem_review_comments: 'OEM review comments',
                DTNumber: 'DT number',
                claimMessage: 'Claim information',
                Circulationadvice: 'Circulationadvice',
                General_generation_name: 'General Generation Name',
                claim_order: 'Claim Order',
                audit_result: 'Audit Result',
                OEMResult: 'OEM Audit Result',
                responsible: 'Responsible',
                modification_date: 'Modification Date',
                updated_date: 'Updated Date',
                General_agent: 'General agent',
                ATA_time: 'ATA Set Days',
                claimAmount: 'Claim amount',
                pack_choose: 'Box number selection',
                suborder_choose: 'Suborder number selection',
                PartNumber_choose: 'Part number selection',
                request_pack: 'Please check the packing list number',
                request_suborder: 'Please check the suborder number',
                request_PartNumber: 'Please check the part number',
                request_Claim: 'Please check the claim part line',
                request_ATA: 'No ata time, unable claim',
                information: 'Basic information',
                completionStatus: 'Completion status',
                Handlingsuggestion: 'Handling suggestion',
                picture: 'Picture',
                greater: 'Greater than or equal to',
                less: 'Less than or equal to',
                week: 'Week',
                month: 'Month',
                quarter: 'Quarter',
                year: 'Year',
                processed_by: 'submitter',
                time_submitted: 'time submitted',
                recipient: 'Recipient',
            },
            report: {
                logisticsEfficiency: {
                    dt_code: 'DT Code',
                    dt_name: 'DT Name',
                    order_month: 'Order month',
                    order_quantity: 'Order quantity',
                    total_order_amount: 'Total order amount',
                    packaging_timeliness: 'Packaging timeliness',
                    maritime_transport_general_cargo: 'Maritime transport - general cargo',
                    air_transport: 'Air',
                    sea_transportation: 'Sea',
                    express_delivery: 'Express',
                    zh_order_quantity: 'On-time order quantity',
                    punctualit_rate: 'Punctuality rate',
                    maritime_transport_dangerous_goods: 'Maritime transport - dangerous goods',
                    otd_timeliness: 'OTD timeliness',
                    transport_prescription: 'Transport prescription',
                    lines: 'Number of order lines',
                    amount: 'Order amount',
                },
                abnormalOrder: {
                    Overexpectationprocessing: 'Overexpectation processing',
                    Overduehasbeenprocessed: 'Overdue has been processed',
                    packing: 'packing',
                    OTD: 'OTD',
                    transport: 'Transport',
                    Orderlinenumber: 'Order line number',
                    Orderlinenumberall: 'Order line number all',
                    Anomalyrate: 'Anomaly rate',
                },
            },
            distributionManagement: {
                list_information: 'Packing list information',
                container_scheme: 'Container Scheme',
                logistics_registration: 'Logistics registration',
                dt_code: 'DT Code',
                packing_list: 'Packing list No',
                date_warehousing: 'Date of warehousing',
                packaging_completed: 'Packaging completed',
                name_voyage: 'Ship name/voyage/express',
                other_expenses: 'Other expenses',
                bill_lading: 'Bill of Lading No',
                print_shipping_mark: 'Print shipping mark',
                warehousing_confirmation: 'Warehousing confirmation',
                fill_other_expenses: 'Fill in other expenses',
                type_shipping: 'Type of shipping',
                shipping_company: 'Shipping company',
                ships_name: 'Ships name',
                voyages_flights: 'The sequence of voyages or flights',
                ship_number: 'Ship number',
                customs_no: 'Customs No',
                return_lading: 'Return method of bill of lading',
                expected_date: 'Expected return date',
                actual_date: 'Actual return date',
                remark: 'Remark',
                container_no: 'Container No',
                container_type: 'Container type',
                customs_declaration: 'Customs declaration No',
                container_nos: 'Container No',
                reason_modification: 'Reason for modification',
                warehouse_release_date: 'Warehouse release date',
            },
            management: order.claim.management.enUS,
        },
        tech: {
            quality: {
                problemTracking: {
                    customs_declaration_unit: 'Customs declaration unit',
                    theme: 'Theme',
                    model: 'Model',
                    problem_state: 'Problem state',
                    TAC_submit_date: 'TAC submit date',
                    level: 'Level',
                    type: 'Type',
                    vehicle_involved: 'Vehicle involved',
                    activity_code: 'Activity code',
                    activity_name: 'Activity name',
                    connected_activities: 'Connected activities',
                    system_problems: 'System problems',
                    quantity: 'Quantity',
                    engine_type: 'Engine type',
                    gearbox_type: 'Gearbox type',
                    country: 'Country',
                    region: 'Region',
                    date_of_feedback_to_QA: 'Date of feedback to QA',
                    required_part_return_date: 'Required part return date',
                    date_of_parts_delivery_to_QA: 'Date of select delivery to QA',
                    solution_release_date: 'Solution release date',
                    solution_target_date: 'Solution target date',
                    end_date: 'End date',
                    difference: 'Difference',
                    QA_weekday: 'QA weekday',
                    fault_description: 'Fault description',
                    reason: 'Reason',
                    solution: 'Solution',
                    breakpoint: 'Breakpoint VIN/CSN/en',
                    process: 'Process',
                    details: 'Details',
                    technical_document_attachments: 'Technical document attachments',
                },
                recallActivity: {
                    activity_code: 'Activity code',
                    activity_name: 'Activity name',
                    responsible_party: 'Responsible party',
                    release_date: 'Release date',
                    data_sources: 'Data sources',
                    recall_campaign_type: 'Recall Type',
                    service_activity_code: 'SERVICE campaign CODE',
                    service_activity_name: 'SERVICE campaign name',
                    buyout_company: 'Buy-off Company',
                    audit_company: 'Approval Company',
                    currency: 'Currency',
                    money: 'Amount',
                    start_date: 'Start date',
                    end_date: 'End date',
                    failure_mode: 'Failure mode',
                    activity_programme: 'Campaign plan',
                    improve_handling: 'Improvement measures',
                    activity_information: 'Activity information',
                    vehicle_involved_list: 'Vehicles involved',
                    template_file: 'Template file',
                    browse: 'Browse',
                    download_template: 'Download template',
                    claim_vehicles_list: 'Vehicles claim occurred',
                    claim_statistics: 'Claim statistics',
                    technical_document_attachments: 'Technical document attachments',
                    vin: 'VIN',
                    is_complete: 'Completed',
                    warranty_claims: 'Warranty Claim',
                    customer: 'Customer',
                    no_file_selected: 'No file selected',
                    no_import_file_selected: 'No import file selected',
                    claim_proportion: 'Proportion of claims',
                    claim_settled_amount: 'Claim settled amount',
                    is_claim_again: 'Whether reclaim',
                    cancel_succeed: 'Cancel succeed',
                    build_by_oneself: 'Build by oneself',
                    system_import: 'Claim system import',
                    claim_system_import: 'Claim system import',
                },
            },
            management: {
                newCarDefinition: {
                    activityNumber: 'Campaign code',
                    activityName: 'Campaign name',
                    activityStatus: 'Status',
                    activityDate: 'ActivityDate',
                    startDate: 'Start date',
                    endDate: 'End date',
                    remarks: 'Remarks',
                    activity_details: 'Activity details',
                    activity_addition: 'Activity Addition',
                    confirm_enable: 'Confirm enable',
                    confirm_outage: 'Confirm outage',
                    all: 'All',
                    not_started: 'Not started',
                    on_going: 'On going',
                    closed: 'Closed',
                },
                newCarlssue: {
                    all: 'All',
                    newly_built: 'Add',
                    unpublished: 'Unpublished',
                    validity: 'Validity',
                    modelInvolved: 'Model',
                    involvedArea: 'Region',
                    state: 'State',
                    new_car_reporting_level: 'New car reporting level',
                    confirm_release: 'Confirm release',
                    maintenance: 'Maintenance',
                    model_maintenance: 'Model maintenance',
                    area_maintenance: 'Area maintenance',
                    reporting_level: 'Reporting level',
                    to_be_published: 'To be published',
                    published: 'Published',
                    modified_to_be_published: 'Modified to be published',
                },
                onSiteSupportHq: {
                    fieldSupportNumber: 'Field support number',
                    informationSlipNumber: 'Information slip number',
                    approvalStatus: 'Approval status',
                    state: 'State',
                    selectApplicationDate: 'Select the application date',
                    toBeProcessed: 'To be processed',
                    processed: 'Processed',
                    initiated: 'Initiated',
                    applicationDepartment: 'Application department',
                    applicant: 'Applicant',
                    reasonApplication: 'Reason for application',
                    applicationTime: 'Application time',
                    all: 'All',
                    pending_approva: 'Pending approva',
                    agreed: 'Agreed',
                    rejected: 'Rejected',
                    view: {
                        application_department: 'Application department',
                        responsible_person: 'Responsible person',
                        application_person: 'Application person',
                        associated_enquiry_number: 'Associated enquiry number',
                        reason_application: 'Reason for application',
                        description: 'Description(Internal application signature sheet)',
                        file_name: 'File name',
                        size: 'Size(MB)',
                        uploader: 'Uploader',
                        upload_time: 'Upload time',
                        view: 'Check',
                        download: 'Download',
                        support_department: 'Support department',
                        technical_personnel: 'Technical personnel',
                        contact_information: 'Contact information',
                        field_support_information: 'Field support information',
                        remarks: 'Remarks',
                        apply: 'Apply',
                        application_information: 'Application information',
                        field_support_personnel: 'On-site support personnel',
                        approval_record: 'Approval history',
                        submit_an_application: 'Submit an application',
                        refuse: 'Refuse',
                        agree: 'Agree',
                        apply_for: 'Apply for',
                        field_support: 'Field support',
                        reason_for_rejection: 'Reason for rejection',
                    },
                    dialog: {
                        on_site_application_details: 'On-site application details',
                        on_site_application: 'On-site application',
                        reason_for_rejection: 'Reason for rejection',
                        field_support_application: 'Field support application',
                        technical_personnel: 'Technical personnel',
                        contact_information: 'Contact Telephone',
                        field_support_time: 'On-site support time',
                        note_information: 'Note information',
                    },
                },
                filterHq: {
                    filter_information: 'Filter information',
                    filter_name: 'Filter name',
                    user_name: 'User name',
                    creation_time: 'Creation time',
                    splicing_description: 'Splicing description',
                    enable_or_not: 'Enabled or disabled',
                    association_symbol: 'Relation symbol',
                    screening_condition: 'Filtering condition',
                    conditional_value: 'Filtering condition value',
                    save: 'Save',
                    draft: 'Draft',
                    no_reply: 'No reply',
                    replied: 'Replied',
                    closed: 'Closed',
                    pre_sales: 'Pre sales',
                    after_sales: 'After sales',
                    to_be_repaired: 'To be repaired',
                    under_maintenance: 'Under maintenance',
                    completed: 'Completed',
                    not_included: 'Not included',
                    and: 'And',
                    perhaps: 'Perhaps',
                    not: 'Not',
                    enable: 'Enable',
                    judgement_logic: 'Judgement logic',
                    order_number: 'No',
                    relational_symbol: 'Relational symbol',
                    filter_criteria: 'Filter Criteria',
                    please_add_judgment_logic: 'Please add judgment logic',
                    model_platform_is: 'The model platform is',
                    subject_of_the_case_is: 'The subject of the case is',
                    overseas_branches_are: 'Overseas branches are',
                    overseas_subsidiaries_are: 'Overseas subsidiaries are',
                    general_agent_are: 'Distributor are',
                    fault_symptom_is: 'Fault phenomenon is',
                    cause_of_the_fault_is: 'Fault cause is',
                    vehicle_status_is: 'Vehicle status is',
                    case_type_is: 'Case type is',
                    case_status_is: 'Case status is',
                    all: 'All',
                },
                vehicleInfoHq: {
                    productionTimeList: 'Production time list',
                    vehicle_configuration_list: 'Vehicle configuration list',
                    accurately_traceable_parts_list: 'Accurately traceable parts list',
                    order_number: 'Order number',
                    order_type: 'Order type',
                    platform: 'Platform',
                    model_code: 'Model code',
                    date_of_production: 'Date of production',
                    certificate_of_conformity: 'Certificate of conformity',
                    certificate_printing_time: 'Certificate printing time',
                    vehicle_material_number: 'Vehicle material number',
                    description: 'Description',
                    time: 'Time',
                    feature_ID: 'Feature ID',
                    feature_description: 'Feature description',
                    feature_code: 'Feature code',
                    eigenvalue_description: 'Eigenvalue description',
                    chinese_description: 'Chinese description',
                    supplier: 'Supplier',
                    bar_code_number: 'Bar code number',
                    scanning_time: 'Scanning time',
                    key_component_type_code: 'Key component type code',
                    part_number: 'Part Number',
                    traceability_number: 'Traceability Number',
                    sub_key_type: 'Sub Key Type',
                    software: 'Software',
                    hardware: 'Hardware',
                    calibration_information: 'Calibration information',
                    supplier_name: 'Supplier name',
                    pn: 'PIN code',
                },
                progHistoryHq: {
                    name_of_maintenance_station: 'Name of Service station',
                    mileage: 'Mileage',
                    controller_name: 'Controller name',
                    function_name: 'Function',
                    time: 'Time',
                    function_execution_result: 'Function execution result',
                    channel: 'Channel',
                    hardware_number: 'Hardware number',
                    written_software_information: 'Written software information',
                    software_information_before_refresh: 'Software information before refresh',
                    updated_software_information: 'Updated software information',
                },
                salesRecordHq: {
                    material: 'Material',
                    sales_order: 'Sales order',
                    vehicle_type: 'Model',
                    shipping_area: ' Shipping area',
                    country_of_dispatch: 'Shipping country',
                    down_date: 'Off-line date',
                    port_of_destination: 'Port of destination',
                    scheduled_date_of_shipment: 'ETD',
                    actual_date_of_shipment: 'ATD',
                    current_position: ' Current position',
                    actual_arrival_date: 'ATA',
                    retail_date: ' Retail date',
                },
                claimHq: {
                    claim_information: 'Claim information',
                    Recall_and_service_activities: 'Recall & service campaign',
                    marketing_activity_list: 'Marketing campaign',
                    basic_information: 'Basic information',
                    warranty_block: 'Warranty block',
                    repair_block_code: 'Warranty Block Code',
                    claim_cycle_list: 'WTY period list',
                    WTY_Indicates_the_cycle_type: 'WTY period type',
                    start_time: 'Start time',
                    end_time: 'End time',
                    WTY_mileage: 'WTY mileage',
                    List_of_claims: 'WTY history',
                    customer: 'Customer',
                    warranty_claim: 'Warranty claim',
                    work_order: 'Work order',
                    date_of_receipt: 'Reception date',
                    maintenance_technical_date: 'Repair date',
                    mileage: 'Mileage',
                    state: 'State',
                    master_working_position: 'Main labor',
                    manual_code: 'Labor code',
                    main_component: 'Main part',
                    part_name: 'Part name',
                    recall_and_service_activities: 'Recall & service campaign',
                    recall_and_service_activity_code: 'Recall & service campaign code',
                    recall_and_service_activity_names: 'Recall & service campaign name',
                    brand: 'Brand',
                    recall_type_code: 'Recall type code',
                    recall_type_name: 'Recall type name',
                    release_date: 'Release date',
                    closing_date: 'Closing date',
                    complete: 'Complete',
                    warranty_claim_status: 'Warranty claim status',
                    end_of_maintenance_date: 'End Date of Last Repair',
                    marketing_activity_code: 'Marketing activity code',
                    marketing_activity_name: 'Marketing activity name',
                    vehicle_warranty_period: 'Vehicle warranty period',
                    marketing_activities: 'Marketing activities',
                    prohibition_of_claims: 'Prohibition of Claims',
                    prohibited_claim_code: 'Prohibited Claim Code',
                    type_of_warranty_period: 'Type of warranty period',
                    repair_end_date: 'Repair End Date',
                    reception_date: 'Reception date',
                    main_station_code: 'Main station code',
                    main_workstation_name: 'Main workstation name',
                },

                // inquiryOd: {
                //     vin: 'VIN',
                //     inquiry_no: 'inquiry no',
                //     case_theme: 'case theme',
                //     inquiry_date: 'inquiry date',
                // },
                inquiryOd: tech.management.inquiry.enUS,
                permission: tech.management.permission.enUS,
            },
        },
        statistics: statistics.inquiryStatistics.enUS,
        systemClassifyA: statistics.systemClassifyA.enUS,
        systemClassifyB: statistics.systemClassifyB.enUS,
        task: {
            china: {
                holiday: {
                    holiday_name: 'Festival name',
                    holiady_time: 'Holiday',
                    end_time: 'End date',
                    holiday_add: 'Newly added',
                    holiday_edit: 'Edit',
                },
            },
            package: {
                calendar: {
                    packing: 'SMPV Packaging processing date',
                },
            },
        },
        logistics: {
            package: {
                cycle: {
                    transport_type: 'Transport type',
                    market_type: 'Market type',
                    market_name: 'Market Name',
                    market_code: 'Market Code',
                    transport_cycle: 'Transportation period (day)',
                    Packaging_aging: 'Packaging time limit (days)',
                    OTD_aging: 'OTD time limit (days)',
                },
            },
        },
        vehicle: {
            logistics: {
                expenses: {
                    atd_begin_date: 'Atd begin date',
                    atd_end_date: 'Atd end date',
                    atd_date: 'Atd date',
                    country_of_target: 'Country of target',
                    dn: 'Dn',
                    region: 'Region',
                    model_code: 'Model code',
                    vin: 'VIN',
                    volumn: 'Volumn',
                    vdc_name: 'Vdc name',
                    pol_name: 'Pol name',
                    dt_content: 'Dt content',
                    dt_distance_km: 'Dt distance km',
                    vdc_vasc_material: 'Vdc vasc material',
                    vdc_vasc_worker: 'Vdc vasc worker',
                    domestic_carry: 'Domestic carry',
                    wharf_handle: 'Wharf handle',
                    wharf_sc: 'Wharf sc',
                    wharf_box: 'Wharf box',
                    medicated_fumigation: 'Medicated fumigation',
                    wharf_dot_check: 'Wharf dot check',
                    repair_maintenance: 'Repair maintenance',
                    refuel_storage: 'Refuel storage',
                    file_cert: 'File cert',
                    supply_chain_integration: 'Supply chain integration',
                    other_domestic_carry: 'Other domestic carry',
                    insure: 'Insure',
                    ship_money: 'Ship money',
                    ship_money_type: 'Ship money type',
                    other_interal_charge: 'Other interal charge',
                    other_interal_charge_type: 'Other interal charge type',
                    transport_company_code: 'Transport company code',
                    vessel_name_voyage: 'Vessel name voyage',
                    mode_of_transport: 'Mode of transport',
                    remark: 'Remark',
                    create_date: 'Create date',
                },
                expensesRelationship: {
                    country_name: 'Country name',
                    country_code: 'Country code',
                    model_code: 'Model code',
                    ology_code: 'Ology code',
                    ology_name: 'Ology name',
                    status: 'Status',
                    remark: 'Remark',
                    create_by: 'Create by',
                    create_name: 'Create name',
                    create_date: 'Create date',
                    update_by: 'Update by',
                    update_name: 'Update name',
                    update_date: 'Update date',
                    tache_code: 'Tache code',
                    no: 'No',
                    yes: 'Yes',
                },
                expenseAccount: {
                    ology_type: 'Ology type',
                    pack_name: 'Pack name',
                },
                country: vehicle_country.enUS,
            },
        },
    },
    validation: {
        common: {
            required: 'Item is required',
            numeric: 'Must be integer',
            decimal: 'Must be number',
            positive_interger: 'Must be positive interger',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Up to ${named('number')} digits`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `More than ${named('number')} characters`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Exceed max bytes (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Less than ${named('number')} characters`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Must be ${named('number')} characters`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Greater than ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Less than ${named('number')}`,
            up_to_2_decimal_places: 'Up to 2 decimal places',
            retain_up: 'Enter up to 10 positive integers and retain up to 4 decimal places',
            retain_ups: 'Enter up to 11 positive integers, and retain up to 4 decimal places',
            input_number: 'Please enter the number',
            only_number_a: 'Only letters or numbers can be entered',
            positive_integer: 'Enter at least one field',
            at_least_one: 'Fill in at least one',
        },
        tip: {
            incomplete_information: 'Incomplete Information',
            MG_information: 'MG orderer cannot be empty',
            MAXUS_information: 'MAXUS orderer cannot be empty',
        },
    },
    import: {
        incorrect_file_type: 'Incorrect file type',
        exceed: 'Exceed',
        upload_success: 'Upload success',
        upload_fail: 'Upload fail',
        again_upload:
            'Only image files in jpg, png, bmp, or gif formats can be uploaded. Please upload again',
    },
    export: {
        noDataToExport: 'No data to export',
    },
    select: {
        all: 'All',
    },
    checkbox: {
        select_all: 'Select All',
    },
    dialog: {
        error_message: 'Error message',
        accessory_selection: 'Part selection',
    },
    homepage: {
        empty_packing_list: 'Empty packing list',
        on_the_packaging_list: 'On the packaging list',
        pending_booking_list: 'Pending booking list',
        documents_to_be_produced: 'Documents to be produced',
        packaging_days_submitted: 'Packaging days submitted',
        is_it_overdue: 'Is it overdue',
        the_packaging_list: 'On the packaging list',
        packing_list_upload_date: 'Packing list upload date',
        upload_days: 'Upload Days',
        days: 'Days',
    },
    // 配件状态清单DT
    accessory: {
        dt_code: 'DT Code',
        brand: 'Brand',
        order_no: 'Order No.',
        dealer_code: 'Dealer Code',
        dealer_name: 'Dealer Name',
        dcs_order_no: 'DCS Order No.',
        order_type: 'Order Type',
        order_create_date: 'Order Create Date',
        order_approved_date: 'Order Approved Date',
        sub_order_no: 'Sub Order No.',
        sub_order_type: 'Sub Order Type',
        packlist_no: 'Packlist No.',
        packlist_upload_date: 'Packlist Upload Date',
        payment_method: 'Payment Method',
        transportation_mode: 'Transportation Mode',
        trade_terms: 'Trade Terms',
        port: 'Port',
        original_order_part_no: 'Original Order Part No.',
        actual_delivery_part_no: 'Actual Delivery Part No.',
        part_name_chinese: 'Part Name(Chinese)',
        part_name_english: 'Part Name(English)',
        order_qty: 'Order Qty',
        packaging_qty: 'Packaging Qty',
        shipping_qty: 'Shipping Qty',
        fob_unit_price: 'FOB Unit price',
        air_freight_control_parts: 'Air Freight Control Parts',
        country_code: 'Country Code',
        country_name: 'Country Name',
        order_nos: 'Order No.',
        order_types: 'Order Type',
        sub_order_nos: 'Sub Order No.',
        dcs_order_nos: 'DCS Order No.',
        part_no: 'Part No.',
        description: 'Description',
        amount: 'Amount',
        arrival_qty: 'Arrival Qty',
        dealer: 'dealer',
    },
};
