import type { I18nScope } from 'vue-i18n';
import type enUS from '../../locales/en-US';

export type MessageSchema = typeof enUS;

export enum Locales {
    enUS = 'en-US',
    zhCN = 'zh-CN',
    deDE = 'de-DE',
    frFR = 'fr-FR',
    nlNL = 'nl-NL',
    itIT = 'it-IT',
}

export const useTypedI18n = (local?: boolean) => {
    const params = local
        ? {
            useScope: 'local' as I18nScope,
        }
        : undefined;
    return useI18n<[MessageSchema], Locales>(params);
};

export const useLocalI18n = () => {
    const i18n = useI18n({
        useScope: 'local',
    });

    return {
        i18n,
        mergeMessages: (locales: Parameters<typeof i18n.mergeLocaleMessage>[]) => {
            locales.forEach((locale) => {
                i18n.mergeLocaleMessage(...locale);
            });
        },
    };
};
