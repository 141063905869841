export default {
    enUS: {
        department_name: 'Department name',
        order_number: 'Order number',
        encoded: 'Encoded',
        name: 'Name',
        disable_time: 'Disable time',
        disabled_person: 'Disabled person',
    },
    nlNL: {
        department_name: 'Afdelingsnaam',
        order_number: 'Ordernummer',
        encoded: 'Gecodeerd',
        name: 'Naam',
        disable_time: 'Uitschakeltijd',
        disabled_person: 'Gehandicapte persoon',
    },
    deDE: {
        department_name: 'Abteilungsname',
        order_number: 'Bestellnummer',
        encoded: 'Codiert',
        name: 'Name',
        disable_time: 'Deaktivierungszeit',
        disabled_person: 'Deaktivierte Person',
    },
    frFR: {
        department_name: 'Nom du département',
        order_number: 'Numéro de commande',
        encoded: 'Encodé',
        name: 'Nom',
        disable_time: 'Heure de désactivation',
        disabled_person: 'Personne désactivée',
    },
    zhCN: {
        department_name: '部门名称',
        order_number: '序号',
        encoded: '编码',
        name: '名称',
        disable_time: '禁用时间',
        disabled_person: '禁用人',
    },
    itIT: {
        department_name: 'Nome Dipartimento',
        order_number: 'Numero Ordine',
        encoded: 'Codifica',
        name: 'Nome',
        disable_time: 'Tempo Disabilitazione',
        disabled_person: 'Persona Disabilitata',
    },
};
