export const vehicle_country = {
    zhCN: {
        number_order: '序号',
        countryCode: '国家代码',
        country: '国家',
        countryEnName: '国家描述',
        countryCnName: '国家中文描述',
        area: '区域',
        areaCnName: '区域名称',
        areaCode: '区域编码',
        seaCost: '海运费',
        premium: '保费',
        create_time: '创建时间',
        updateByName: '更新人',
        updateTime: '更新时间',
        operation: '操作',
        remark: '备注',
        daily_list: '操作记录',
    },
    enUS: {
        number_order: '序号',
        countryCode: '国家代码',
        country: '国家',
        countryEnName: '国家描述',
        countryCnName: '国家中文描述',
        area: '区域',
        areaCnName: '区域名称',
        areaCode: '区域编码',
        seaCost: '海运费',
        premium: '保费',
        create_time: '创建时间',
        updateByName: '更新人',
        updateTime: '更新时间',
        operation: '操作',
        remark: '备注',
        daily_list: '操作记录',
    },
};

export const vehicle_premium = {
    zhCN: {
        number_order: '序号',
        number_id: '数据ID',
        brand: '品牌',
        currencyType: '币别',
        countryCode: '国家代码',
        country: '国家',
        countryEnName: '国家描述',
        countryCnName: '国家中文描述',
        area: '区域',
        areaCnName: '区域名称',
        areaCode: '区域编码',
        singlePremium: '单车保费',
        startEffectDate: '起始有效期',
        endEffectDate: '截止有效期',
        createTime: '创建时间',
        remark: '备注',
        updateBy: '更新人',
        updateTime: '更新时间',
        materialCode: '物料号',
        ID: 'ID',
        configDesc: '配置描述',
        daily_list: '操作记录',
    },
    enUS: {},
};
