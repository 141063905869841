import type { MessageFunction } from 'vue-i18n';
import order from './pages/order';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import { vehicle_country } from './pages/vehicle/logistics';

export default {
    license: {
        powered_by: 'Powered par Smil',
    },
    auth: {
        login: 'Connexion',
        username: 'Nom d\'utilisateur',
        username_placeholder: 'Entrez le nom d\'utilisateur',
        password: 'Mot de passe',
        password_placeholder: 'Entrez le mot de passe',
        code: 'Code de vérification',
        code_placeholder: 'Entrez le code de vérification',
        logout: 'Déconnexion',
        logout_success: 'Déconnexion réussie',
        day: 'Le jour',
        password_expiration: 'Le mot de passe a expiré, veuillez le modifier',
        expire: 'Garder le mot de passe jusqu\'à expiration',
        conseils:
            'Le mot de passe doit contenir des majuscules, des minuscules, des chiffres et des caractères spéciaux ({msg}) et doit contenir entre 8 et 16 caractères',
    },
    button: {
        query: 'Requête',
        show_theme_editor: 'Afficher/Masquer l\'éditeur de thème',
        change_password: 'Modifier le mot de passe',
        old_password: 'Ancien mot de passe',
        new_password: 'Nouveau mot de passe',
        confirm_password: 'Confirmer le mot de passe',
        cipher: 'Mot de passe',
        change_new_password: 'Veuillez remplir un nouveau mot de passe',
        button: 'Bouton',
        about: 'À propos',
        back: 'Retour',
        go: 'Aller',
        home: 'Accueil',
        toggle_dark: 'Basculer en mode sombre',
        toggle_langs: 'Changer de langues',
        confirm: 'Confirmer',
        modify: 'Modifier',
        edit: 'Modifier',
        new: 'Nouveau',
        cancel: 'Annuler',
        close: 'Fermé',
        search: 'Chercher',
        reset: 'Réinitialiser',
        delete: 'Supprimer',
        add: 'Ajouter',
        collapse: 'Réduire',
        expand: 'Plus',
        download_template: 'Télécharger le modèle',
        batch_import: 'Importation par lots',
        import: 'Importer',
        export: 'Exporter',
        export_fail: 'Échec de l\'exportation',
        batch_clear_sap_code: 'Effacer le code SAP par lots',
        batch_clear_special_purchase_cycle: 'Effacer la période d\'achat spéciale par lots',
        detailed: 'détaillé',
        download: 'Télécharger',
        toView: 'Vérifier',
        uploadAttachments: 'Télécharger',
        selectFile: 'Sélectionner un fichier',
        fillingInstructions: 'Instructions de remplissage',
        release: 'Publier',
        save: 'Enregistrer',
        send_back: 'Retour',
        examine: 'examiner',
        updateTime: 'Mettre à jour la période de validité',
        update: 'mettre à jour',
        void: 'annuler',
        more: 'Plus',
        refresh: 'Actualiser',
        submit_sap: 'Soumettre SAP',
        submit: 'Soumettre',
        save_button: 'Enregistrer et nouveau',
        insufficient: 'Voulez-vous soumettre en cas de fonds de compte insuffisants',
        yes: 'Oui',
        deny: 'Refuser',
        staging: 'Enregistrer et fermer',
        return: 'Retourner',
        table: 'No',
        select: 'Choisir',
        all: 'Tous',
        create_sap: 'CréerSap',
        enter: 'Entrer',
        selects: 'Sélectionner',
        modify_ladder: 'Modifier l\'échelle',
        pass: 'Passer',
        serial_number: 'No',
        individual: 'individuel',
        upload_progress: 'Progression du téléchargement',
        port_choose: 'choix de port',
        request_code: 'Veuillez vérifier les accessoires',
        request_discuss: 'Veuillez sélectionner un fournisseur',
        request_dt: 'Veuillez vérifier DT',
        request_thing: 'Veuillez vérifier la catégorie de matériau',
        translate: 'Uniquement des lettres',
        accessories_information: 'Information sur les accessoires',
        select_accessories: 'Sélectionner des accessoires',
        binding: 'Liaison',
        tick_data: 'Veuillez vérifier les données',
        invalid: 'Invalide',
        empty_data: 'Effacer les données générées',
        confirm_number: 'Confirmer le numéro de liste d\'emballage',
        variance_details: 'Détails des écarts',
        daily_record: 'enregistrement quotidien',
        operation_type: 'Type d\'opération',
        field_name: 'Nom du champ',
        edit_role: 'Modifier le rôle',
        user_role: 'Rôle de l\'utilisateur',
        assignment_role: 'Définir le rôle',
        please_settled: 'Veuillez sélectionner l\'accessoire à régler',
    },
    radio: {
        enabled: 'Activé',
        disabled: 'Désactivé',
        catalogue: 'Catalogue',
        individual: 'Individuel',
        all: 'Tous',
        tips: 'Conseils',
        save: 'Enregistrer',
        yes: 'Oui',
        no: 'Non',
    },
    dropdown: {
        close_others: 'Fermer les autres',
        close_right: 'Fermer la droite',
        close_left: 'Fermer la gauche',
    },
    table: {
        action: 'Opération',
        explain: 'Explication',
        fileName: 'Nom de fichier',
        size: 'Taille (MB)',
        uploader: 'Téléchargeur',
        uploadTime: 'Heure de téléchargement',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/pages`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} articles Données`,
    },
    confirm: {
        confirm_deletion: 'Confirmer la suppression?',
        confirm_void: 'Confirmer l\'annulation?',
        confirm_release: 'Confirmer la publication?',
        ack: 'OK',
        cancel: 'Annuler',
        non_records: 'Veuillez choisir au moins un enregistrement',
    },
    request: {
        failed_placeholder: 'Échec de la demande, erreur du serveur',
        success_placeholder: 'Demande réussie',
        auth: {
            login_success: 'Connexion réussie',
        },
        error: {
            '404': 'Url de demande introuvable',
            '403': 'Autorisation refusée',
        },
    },
    page: {
        global: {
            page_search: 'Recherche de page',
        },
        error: {
            not_found: 'Non trouvé',
            not_found_des: 'Il n\'y a rien ici',
            auth_failed: 'Authentification échouée',
            auth_failed_des: 'Cette page est inaccessible',
        },
        homepage: {
            homepage: homepage.homepage.frFR,
        },
        system: {
            menu: {
                menu: 'Menu',
                menu_name: 'Nom du menu',
                menu_type: 'Type de menu',
                menu_new: 'Nouveau menu',
                menu_edit: 'Modifier le menu',
                icon: 'Icône',
                parent: 'Parent',
                order_number: 'Numéro de commande',
                path: 'Chemin',
                status: 'Statut',
            },
            dict: {
                dictionary_name: 'Nom du dictionnaire',
                creater: 'Créateur',
                dictionary_code: 'Code du dictionnaire',
                code_type: 'Type de code',
                code_type_name: 'Nom du type de code',
                creation_time: 'Heure de création',
                dict_new: 'Nouveau dictionnaire',
                dict_edit: 'Modifier le dictionnaire',
                lang: 'Langue',
                sort: 'Trier',
            },
            dept: {
                parent_dept: 'Département parent',
                dept_name: 'Nom du département',
                leader: 'Responsable',
                email: 'E-mail',
                create_by: 'Créé par',
                create_date: 'Date de création',
                organization_editing: 'Modification d\'organisation',
                phone: 'Téléphone',
            },
            role: {
                menu_maintenance: 'Maintenance du menu',
                role_name: 'Nom de rôle',
                role_key: 'Caractère d\'autorisation',
                role_id: 'Ordre d\'affichage',
                status: 'Statut',
                create_date: 'Heure de création',
                role_sort: 'Trier',
                role_new: 'Nouveau rôle',
                role_edit: 'Modifier le rôle',
                role_bleak: 'Sombre',
            },
            user: {
                serial_no: 'No',
                username: 'Nom d\'utilisateur',
                name: 'Nom',
                sex: 'Genre',
                phone_number: 'Numéro de téléphone',
                email: 'E-mail',
                role: 'Rôle',
                login_time: 'Statut actif',
                password: 'Mot de passe',
                user_edit: 'Modifier l\'utilisateur',
            },
        },
        management: {
            purchasePosting: {
                charge_against: 'Charge Against',
                transportation_method:
                    'Le type de commande, la méthode de transport et les conditions commerciales sont différents. Veuillez vérifier la commande ayant le même type !',
                whether_to_send: 'Envoyer à SAP ou non ?',
                invoice_number: 'Numéro de facture',
                invoice_reversal: 'Annulation de facture',
                sales_purchase_tax_code: 'Code de taxe de vente/achat',
                send_sap: 'Envoyer à SAP',
                whether_notify_invoicing: 'Notifier ou non la facturation',
                import_invoices: 'Importer des factures',
                invoicing_notice: 'Avis de facturation',
                import_information: 'Importer des informations de déclaration en douane',
                tax_rate: 'Taux de taxe',
                amount_excluding_tax: 'Montant hors taxes',
                amount_including_tax: 'Montant toutes taxes comprises',
                invoice_tax_amount: 'Montant de la taxe de facture',
                invoice_amount: 'Montant de la facture',
                prepayment_note_no: 'Numéro de note de prépaiement',
                prepayment_not: 'Prépaiement ou pas',
                section: 'Section',
                approved: 'Approuvé',
                order_submission: 'Soumission de commande',
                list_to_be_invoiced: 'Liste devant être facturée',
                invoiced_but_unpaid_list: 'Liste facturée mais impayée',
                invoiced_and_paid_list: 'Liste facturée et payée',
                dn_no: 'N° DN',
                sub_order_no: 'Sous-ordre N°',
                packing_list_no: 'N° de liste d\'emballage',
                brand: 'Marque',
                whether_invoicable: 'Facturable ou non',
                payment_type: 'Type de paiement',
                advance_payment: 'Frais d\'avance',
                oem_order_no: 'N° de commande OEM',
                tax_amount: 'Montant de la taxe',
                amount_of_money: 'Montant',
                payment_time: 'Heure de paiement',
                payment_order_no: 'N° de commande de paiement',
                sap_voucher_No: 'N° de bon SAP',
                export_gold_tax: 'Taxe d\'exportation d\'or',
                export_maxus: 'Liste de facturation MAXUS d\'exportation',
                time_of_payment: 'Heure de paiement',
                apply_for_payment: 'Demande de paiement',
            },
            preBooking: {
                booking: 'Réservation',
                booking_space: 'Réservation de l\'espace',
                packing_list_no: 'N° de liste d\'emballage',
                job_no: 'N° de travail',
                dangerous_goods_or_not: 'Matières dangereuses ou non',
                brand: 'Marque',
                type_of_shipping: 'Mode de transport',
                dt_code: 'Code DT',
                estimated_volume: 'Volume estimé (m³)',
                estimated_gross_weight: 'Poids brut estimé (kg)',
                estimated_net_weight: 'Poids net estimé (kg)',
                estimated: 'Volume estimé',
                volume: 'Volume (m³)',
                gross_weight: 'Poids brut (kg)',
                net_weight: 'Poids net (kg)',
                shipping_schedule: 'Calendrier d\'expédition du N° de travail',
                shipping_schedules: 'La date d\'expédition du N° de travail ne peut pas être vide',
                shipping_type: 'Type d\'expédition',
                box_quantity: 'Quantité estimée de boîtes du N° de travail',
            },
            shoppingCart: {
                vin_requires:
                    'VIN nécessite une combinaison de 17 lettres et chiffres commençant par 3 grandes et petites lettres',
                part_type: 'Type de pièce',
                submit_validation: 'Validation de soumission',
                maximum_value: 'Valeur maximale 9999999999',
                please_select_cart:
                    'Veuillez sélectionner un accessoire à ajouter au panier d\'achat',
                please_enter_format: 'Veuillez entrer le format PDF, XLSX, XLS, WORD,',
                please_format: 'Veuillez entrer le format jpg, png, gif',
                please_enter_an_integer: 'Veuillez entrer la quantité de commande correcte',
                customized_orders: 'Commandes personnalisées',
                details_accessory: 'Détails du code d\'accessoire',
                warning: 'Confirmer la suppression',
                please_payment_method: 'Veuillez sélectionner un mode de paiement',
                please_port: 'Veuillez sélectionner un port',
                please_party: 'Veuillez sélectionner le parti de livraison',
                no_inventory: 'Pas de stock:',
                notes: 'Notes',
                note_the: 'Manque d\'informations nécessaires pour les pièces personnalisées',
                download_template: 'Télécharger le modèle',
                single_addition: 'Ajout unique',
                material_category: 'Catégorie de matériau',
                Individual_addition: 'Addition individuelle',
                order_type: 'Type de commande',
                accessory_code: 'Code d\'accessoire',
                part_name: 'Nom des accessoires',
                term_of_trade: 'Termes du commerce',
                logistics_mode: 'Mode logistique',
                type_of_fittings: 'Type de pièce',
                available_balance: 'Solde disponible',
                order_deadline: 'Prochaine date limite de commande',
                place_order: 'Soumettre',
                order_information: 'Information sur la commande',
                order_No: 'Numéro de commande',
                supplier: 'Fournisseur',
                creation_date: 'Date de création',
                type_of_shipping: 'Mode de transport ',
                payment_method: 'Méthode de paiement',
                port: 'Port de destination',
                delivered_by: 'Adresse réelle du destinataire',
                remarks: 'Remarques',
                brand: 'Marque',
                currency: 'Devise',
                accessory_lines: 'Nombre de lignes d\'accessoires',
                accessory: 'Articles',
                amount_accessories: 'Montant des pièces',
                freight: 'Fret (estimé)',
                premium: 'Assurance',
                order_amount: 'Montant de la commande',
                total_transportation: 'Volume total(m³)',
                ordinary_goods: 'ETD des pièces normales',
                arrival_date: 'ETD des pièces dangereuses',
                accessories_information: 'Informations sur les accessoires',
                whole: 'Ensemble',
                routine: 'Routine',
                dangerous_goods: 'Marchandises dangereuses',
                conventional_parts: 'Pièces conventionnelles',
                chemicals: 'Produits chimiques',
                oily_parts: 'Pièces grasses',
                commodity_inspection_parts: 'Pièces d\'inspection des marchandises',
                payment: 'Le numéro de bon de commande ne peut pas être vide',
                incorrect: 'Données d\'entrée incorrectes',
                data_empty: 'Les données ne peuvent pas être vides',
                cannot_current_time: 'Ne peut pas être supérieur à l\'heure actuelle',
                cannot_time: 'Ne peut pas être inférieur à l\'heure actuelle',
                permits: 'Permis',
                customized_parts: 'Pièces personnalisées',
                customized: 'personnalisé',
                accessories_selection: 'Sélection d\'accessoires',
                forbidden_empty: 'Air interdit',
                add_cart: 'Ajouter',
                order_number: 'N°',
                replacement: 'Remplacement',
                order_quantity: 'Qté de commande',
                unit_price: 'Prix unitaire',
                company: 'Unité',
                be_purchased: 'Achetable',
                quantity: 'Quantité minimale de commande',
                total_amount: 'Montant total',
                volumes: 'Volume (m ³)',
                volume: 'Volume',
                enclosure: 'Attachement',
                confirmed_quantity: 'Quantité confirmée',
                courier: 'Numéro de vol/navire/courrier',
            },
            salesquery: {
                actual_delivery: 'Date de livraison réelle',
                estimated_delivery: 'Date de livraison estimée',
                total_packages: 'Poids net total du colis',
                total_gross: 'Poids brut total du colis',
                trade_mode: 'Mode de commerce post-transport',
                mode_before: 'Mode de commerce avant le transport',
                port_after_transshipment: 'Port après transbordement',
                port_diversion: 'Port avant la diversion',
                outer: 'Hauteur du paquet',
                outer_box_size: 'Largeur du paquet',
                outer_box: 'Longueur du paquet',
                volume: 'Mesure(mm3)',
                gross_weights: 'Poids brut(KGS)',
                net_weight: 'Poids net(KGS)',
                shipment_quantity: 'Quantité expédiée',
                ordering_parts_code: 'Code de commande des pièces',
                packing_code: 'Numéro de caisse',
                container_no: 'Numéro de conteneur',
                sales_order_information: 'Informations de la commande de vente',
                packing_list_no: 'Numéro de liste d\'emballage',
                accessory_code: 'Numéro de pièce',
                order_no: 'Numéro de commande',
                merge_ci: 'Télécharger les CI&PL fusionnés',
                export_ci: 'Exporter CI',
                export_pl: 'Exporter PL',
                currency: 'Devise',
                parts_amount: 'Montant des pièces',
                freight: 'Fret',
                premium: 'Assurance',
                other_expenses: 'Autres frais',
                total_amoun: 'Montant total',
                creation_date: 'Date de création de la liste d\'emballage',
                ship_number: 'Numéro de navire/vol/express',
                supplier: 'Fournisseur',
                accessories: 'Articles',
                total_accessories: 'Qté',
                gross_weight: 'Poids brut(KGS)',
                total_weight: 'Poids net(KGS)',
                total_package: 'Volume total(m³)',
            },
            suborder: {
                packing_details: 'Détails d\'emballage',
                order_detail: 'Détails de commande',
                arrive: 'Arrivé',
                shipped: 'Expédié',
                complete_packaging: 'Emballage terminé',
                passenger_order_number: 'Numéro de commande de voiture de passager',
                order_details: 'Détails de sous-commande',
                accessory: 'Accessoire',
                order_amount: 'Montant total',
                company: 'Entreprise',
                name_accessories: 'Nom accessoires',
                accessory_code: 'Code accessoire',
                serial_no: 'N°',
                submit_smpv: 'Soumettre SMPV',
                sub_order_no: 'Numéro de sous-commande',
                sub_order_type: 'Type de sous-commande',
                brand: 'Marque',
                type_hipping: 'Type d\'expédition',
                dt_code: 'Code DT',
                sub_order_amount: 'Montant de sous-commande',
                trade_terms: 'Conditions commerciales',
                passenger_number: 'Numéro de commande de voiture de passager',
                dcs_order_number: 'Numéro de commande DCS',
                remarks: 'Observations',
                submission_date: 'Date de soumission',
                supplier: 'Fournisseur',
                mode_of_trade: 'Mode de commerce',
                port: 'Port',
                to: 'À',
                currency: 'Monnaie',
                cancel_date: 'Date d\'annulation',
                accessory_information: 'Informations sur les accessoires',
            },
            query: {
                export_pi: 'Exporter PI',
                audit_status: 'Statut de l\'audit',
                cancel: 'Annuler',
                whether_submit: 'Soumettre ou non',
                actual_arrival: 'Date d\'arrivée réelle',
                estimated_arrival: 'Date estimée d\'arrivée',
                packaging_completion_date: 'Date de fin d\'emballage',
                order_no: 'Numéro de commande',
                order_status: 'Statut de commande',
                rough_draft: 'Brouillon',
                funds_be_reviewed: 'Solde insuffisant',
                approved: 'Approuvé',
                canceled: 'Annulé',
                order_type: 'Type de commande',
                creation_date: 'Date de création',
                accessory_code: 'No de pièce',
                mode_transport: 'Mode de transport',
                brand: 'Marque',
                export_order_details: 'Téléchargement',
                dt_code: 'Code DT',
                order_amount: 'Montant de commande',
                term_of_trade: 'Conditions commerciales',
                submission_date: 'Date de soumission',
                DCS_order_number: 'Numéro de commande DCS',
                remarks: 'observations',
                upload_credit: 'Télécharger lettre de crédit',
                lc_no: 'Numéro de L/C',
                term_validity: 'Durée de validité',
                amount: 'Montant de L/C',
                picture: 'Image de L/C',
                empty: 'Le numéro de L/C ne peut pas être vide',
                payment_registration: 'Enregistrement de paiement',
                payment_voucher_no: 'Numéro de paiement',
                payment_date: 'Date de paiement',
                payment_amoun: 'Montant du paiement',
                number_lines: 'Nombre de lignes d\'accessoires',
                total_accessories: 'Nombre total d\'accessoires',
                estimated_premium: 'Estimation de l\'assurance',
                estimated_freight: 'Fret estimé',
                estimated_amount: 'Montant total estimé de la commande',
                order_tracking: 'Suivi de commande',
                put_away: 'Replier',
                more: 'Plus',
                actually_delivered: 'No de pièce livré réellement',
                confirm_quantity: 'Confirmer la quantité',
                package_quantity: 'Quantité d\'emballage',
                shipment_quantity: 'Quantité expédiée',
                arrival_quantity: 'Quantité arrivée',
                cancel_quantity: 'Quantité annulée',
                bo_quantity: 'Quantité BO',
                order_submission: 'Soumettre',
                warn: 'Note : 1. Lors du transfert des fonds, veuillez indiquer le paiement pour les pièces détachées ou les véhicules complets;',
                warns: 'Le montant du compte sera déduit du montant reçu réellement;',
                voucher: 'Veuillez saisir le numéro de bon de paiement',
                payment_dates: 'Veuillez saisir la date de paiement',
                payment_amount: 'Veuillez saisir le montant du paiement',
                credit_number: 'Veuillez saisir le numéro de lettre de crédit',
                validity_period: 'Veuillez saisir la période de validité',
                letter_credit: 'Veuillez entrer le montant de la lettre de crédit',
                upload_picture: 'Veuillez télécharger une image de la lettre de crédit',
                part_no: 'No de pièce',
                part_name: 'Nom de pièce',
                quantity: 'Quantité de commande',
                accessories: 'Détails des accessoires',
                company: 'Entreprise de transport',
                ships_name: 'Nom du navire',
                flights: 'La séquence de vols ou de voyages',
                customs_no: 'Numéro de douane',
                lading_no: 'Numéro de connaissement',
                flight_number: 'Numéro de vol',
                container_no: 'Numéro de conteneur',
                packing_list: 'Liste d\'emballage',
                packaging_quantity: 'Quantité d\'emballage',
                packaging_completion: 'Date de fin d\'emballage',
                shipment_quantitys: 'Quantité expédiée',
                delivery_date: 'Date de livraison estimée',
                shipment_dates: 'Date d\'expédition réelle',
                arrival_quantitys: 'Quantité arrivée',
                arrival_date: 'Date d\'arrivée estimée',
                actual_arrival_date: 'Date d\'arrivée réelle',
            },
            salesManagement: {
                before_modifications: 'Avant la modification',
                after_modification: 'Après la modification',
                continue_importing: 'Continuer l\'\'importation',
                quantity_difference: 'Différence de quantité',
                customs_declaration_name: 'Nom de la déclaration en douane',
                customs_declaration_not: 'Déclaration en douane ou non',
                invoice_no: 'Numéro de facture',
                contract_no: 'Numéro de contrat',
                attachment_fee_amount: 'Montant des frais de pièces jointes',
                xpense_amount: 'Montant des dépenses',
                import_packing: 'Liste d\'\'emballage MAXUS d\'\'importation',
                import_list: 'Liste d\'\'emballage MG d\'\'importation',
                sales_order_list: 'Liste de commandes de vente',
                job_no_list: 'Liste de numéros de travail',
                dt_code: 'Code DT',
                brand: 'Marque',
                packing_list_no: 'Numéro de liste d\'\'emballage',
                packing_list_nos: 'Numéro de liste d\'\'emballage :',
                bilateral_contract_no: 'Numéro de contrat bilatéral',
                contract_status: 'Statut du contrat',
                inbound_delivery: 'Numéro de commande d\'\'entrée',
                supplier: 'Fournisseur',
                list_creation: 'Date de création de la liste d\'\'emballage',
                currency: 'Monnaie',
                transit_status: 'Statut de transit',
                split_merge: 'Diviser / Fusionner',
                sap_return_information: 'Informations de retour SAP',
                send_booking: 'Envoyer un e-mail de réservation ou non',
                lcl_or_not: 'LCL ou non',
                operate: 'Opérer',
                modify_freight: 'Modifier le fret',
                modify_lc: 'Modifier LC',
                detailed: 'Détaillé',
                merge: 'Fusionner',
                split: 'Diviser',
                diversion: 'Déviation de transport',
                lcl: 'Lcl',
                charges: 'Frais supplémentaires',
                generation: 'Génération de document en un clic',
                deletion: 'Suppression de document en un clic',
                bind_lc: 'Lier LC',
                unbind_lc: 'Détacher LC',
                merge_ci: 'Télécharger CI et PL fusionnés',
                export_ci: 'Exporter CI',
                export_pl: 'Exporter PL',
                additional_charges: 'Frais supplémentaires :',
                packing: 'Numéro de liste d\'\'emballage :',
                expenses: 'Frais de transport :',
                delivery: 'Numéro de commande de livraison sortante :',
                binding_record: 'Modifier le registre de liaison LC',
                trade_terms: 'Termes commerciaux :',
                logistics_mode: 'Mode logistique :',
                port: 'Port :',
                payment_method: 'Méthode de paiement :',
                please_select: 'Veuillez sélectionner les conditions commerciales',
                please_logistics_mode: 'Veuillez sélectionner la méthode logistique',
                please_port: 'Veuillez sélectionner un port',
                please_payment_method: 'Veuillez sélectionner le mode de paiement',
                select_export_type: 'Sélectionnez le type d\'\'exportation',
                plase_select_export_type: 'Veuillez sélectionner le type d\'\'exportation',
                lc_code: 'Numéro LC',
                sap_return_status: 'Statut de retour SAP',
                sap_voucher_code: 'Code de pièce comptable SAP',
                amount_difference: 'Différence de montant',
                invoice_date: 'Date de facture',
                invoice_import_date: 'Date d\'\'importation de la facture',
                unbinding: 'Détachement',
                no_unbinding_lc: 'Pas de LC de détachement',
                sub_order_no: 'Numéro de sous-commande',
                submit_sap: 'Soumettre ou non à SAP',
                case_no: 'Numéro de cas :',
                binding: 'Liaison',
                outbound_delivery: 'Livraison sortante',
                document_type: 'Type de document',
                accessory_code: 'Numéro de la pièce jointe',
                accessory_name: 'Nom de la pièce jointe',
                sales_order_information: 'Informations de commande de vente',
                total_lines_of_accessories: 'Nombre total de lignes de pièces jointes :',
                total_number_of_accessories: 'Nombre total de pièces jointes :',
                total_amount: 'Montant total :',
                amount_of_accessories: 'Montant des pièces jointes :',
                premium: 'Prime :',
                volume: 'Volume total du paquet (m³)',
                total_gross_weight: 'Poids brut total du paquet :',
                weight_of_package: 'Poids net total du paquet :',
                container_no: 'Numéro de conteneur',
                actually_delivered: 'No de pièce livrée réellement',
                order_parts_code: 'Numéro de pièce originale de la commande',
                shipment_quantity: 'Quantité expédiée',
                proforma_invoice: 'Numéro de facture proforma',
                customs_date: 'Date de déclaration en douane',
                customs_no: 'Numéro de déclaration en douane',
                net_weight: 'Poids net (kg)',
                rough_weight: 'Poids brut (kg)',
                volumes: 'Volume (mm³)',
                outer_bo_size: 'Taille de la boîte extérieure (longueur mm)',
                box_size: 'Taille de la boîte (largeur mm)',
                box_height: 'Taille de la boîte (hauteur mm)',
                modification_date: 'date de modification',
                modification_by: 'Modifié par',
                before_modification: 'Fret avant modification',
                modified_freight: 'Fret modifié',
                sales_slip: 'bulletin de commande',
                modification_record: 'Registre de modification du fret',
            },
            price: {
                DNNumber: 'Numéro DN',
                deliveryDate: 'Date de livraison',
                purchasePrice: 'Prix d\'\'achat',
                priceForDelivery: 'Prix unitaire de livraison de voitures particulières',
            },
        },
        document: {
            document: 'Document',
            name: 'Nom',
            sort: 'Trier',
            directory: 'Dossier',
            parent_directory: 'Répertoire parent',
            new_directory: 'Nouveau dossier',
            edit_directory: 'Modifier le nom du dossier',
            primary_directory: 'Répertoire principal',
            document_title: 'Titre du document',
            vehicle_platform: 'Plate-forme de véhicule',
            status: 'Statut',
            release_status: 'Statut de publication',
            create_time: 'Temps de création',
            secondary_directory: 'Sous-dossier',
            model: 'Modèle',
            attachment_name: 'Nom du fichier',
            publishing_department: 'Département de publication',
            publisher: 'Editeur',
            person_in_charge: 'Personne en charge',
            remarks: 'Remarques',
            publish_objects: 'Publication vers',
            publish_mode: 'Mode de publication',
            whether_the_document_is_downloadable: 'Le document est-il téléchargeable',
            document_information: 'Informations sur le document',
            document_tag: 'Tag du document',
            operation_record: 'Enregistrement des opérations',
            operation_details: 'Détails de l\'\'opération',
            operating_time: 'Temps d\'\'opération',
            operator: 'Opérateur',
            to_be_released: 'À publier',
            published: 'Publié',
            voided: 'Invalide',
        },
        announcement: {
            title: 'Titre',
            publishing_department: 'Département de publication',
            status: 'Statut',
            release_Date: 'Date de publication',
            serialNo: 'Numéro de série',
            publisher: 'Editeur',
            file_Name: 'Nom du fichier',
            details: 'Détails',
            void: 'Invalide',
            information_detail: 'Détails de l\'\'information',
            notification_Title: 'Titre de la notification',
            publish_Objects: 'Publication vers',
            publish_Mode: 'Mode de publication',
            bulletin_id: 'ID du bulletin',
            publish_Content: 'Contenu de publication',
            preview: 'Aperçu',
            attachmentUpload: 'Téléchargement de pièces jointes',
            supportedFormats: 'Formats pris en charge',
            have: 'Avoir',
            NoticeWaitingToBeRead: 'Notification en attente de lecture',
            released: 'Publié',
            voided: 'Invalide',
        },
        basic: {
            accessories: {
                masterdata: {
                    english_smpv: 'English name of SMPV',
                    accessory_code: 'Part No',
                    accessory_name: 'Nom de pièce',
                    accessory_name_zh: 'Nom chinois des accessoires',
                    accessory_name_en: 'Nom de la pièce (EN)',
                    accessory_enm: 'Description anglaise des accessoires',
                    brand: 'Marque',
                    sales_area: 'Zone de vente',
                    create_data: 'Date de création',
                    is_purchase: 'Est-acheter',
                    is_transportations: 'Transportation',
                    purchase_attribute: 'Attribut d\'\'achat',
                    is_marketable: 'Est commercialisable',
                    supplier_code: 'Code fournisseur',
                    supplier_name: 'Nom du fournisseur',
                    country_origin: 'Pays d\'\'origine',
                    is_transportation: 'Est transporteur',
                    magnetic_inspection: 'Inspection magnétique',
                    special_procurement_cycle: 'Cycle d\'\'approvisionnement spécial',
                    material: 'Matériau',
                    is_max_overbooking: 'Contrôler la quantité maximale de commande',
                    max_overbooking: 'Quantité maximale de commande autorisée',
                    sap_code: 'SAP Code',
                    unit: 'Unité d\'\'accessoire',
                    accessories_edit: 'Modification des accessoires',
                    accessories_add: 'Nouveaux accessoires',
                    accessories_detailed: 'Détails des accessoires',
                    accessory_unit: 'Unité d\'\'accessoire',
                    name_of_customs_declaration_materials:
                        'Nom des matériaux de déclaration en douane',
                    customized_form: 'Formulaire personnalisé',
                    customs_declaration_unit: 'Unité de déclaration en douane',
                    remarks: 'Remarques',
                    customs_code: 'Code des douanes',
                    license_material_name: 'Nom des matériaux de licence',
                    create_by: 'Créé par',
                    company: 'Nom de l\'\'entreprise',
                    declare_ele: 'Spécifications',
                    base_cal_unit: 'Société',
                    base_cal_unit_cn: 'Unité chinoise',
                    update_by: 'Modifié par',
                    update_time: 'Date de modification',
                    accessories_picture: 'Image d\'\'accessoires',
                    custom_accessories: 'Accessoires personnalisés',
                    declaration_elements: 'Éléments de déclaration',
                    transportation: 'Pas de transport aérien',
                    accessories_selection: 'Sélection des accessoires',
                    enable_or_not: 'Activer ou non',
                    supplier_selection: 'Sélection des fournisseurs',
                    whether_glass: 'Verre ou non',
                },
                orderInvoicing: {
                    partCode: 'Code de la pièce',
                    brand: 'Marque',
                    baseQuantity: 'Quantité de base',
                    baseUnit: 'Unité de base',
                    transferQuantity: 'Quantité de conversion',
                    transferUnit: 'Unités de conversion',
                    createBy: 'Créé par',
                    createTime: 'Date de création',
                    orderInvoicing_new: 'nouvellement ajouté',
                    orderInvoicing_edit: 'modifier',
                },
                billMaterials: {
                    dt_name: 'DT', // Nom de DT
                    part_code: 'Code accessoire', // Code de la pièce
                    part_name_cn: 'Nom chinois des accessoires', // Nom chinois
                    part_name_en: 'Nom anglais des accessoires', // Nom anglais
                    apply_time: 'Date de demande', // Date de demande
                    brand: 'Marque', // Marque
                },
                replace: {
                    group_no: 'Numéro de groupe',
                    item_no: 'Numéro d\'article',
                    leading_products: 'Produits phares',
                    description_of_leading_products: 'Description des produits phares', // Description of leading products
                    subsequent_products: 'Produits ultérieurs', // Subsequent products
                    subsequent_product_description: 'Description du produit suivant', // Subsequent product description
                    substitutive_relationship: 'Relation substitutive', // Substitutive relationship
                    exhaustion_strategy: 'Stratégie d\'épuisement', // Exhaustion strategy
                    depletion_date: 'Date d\'épuisement', // Depletion Date
                    effective_date: 'Date d\'entrée en vigueur', // effective date
                    creation_date: 'Date de création', // Creation Date
                    newly_added: 'Nouvellement ajouté', // newly added
                    newly_edit: 'Modifier', // edit
                },
                mxreplace: {
                    group_no: 'Numéro de groupe',
                    project_no: 'Numéro d\'article',
                    part_code: 'Code d\'accessoire',
                    part_name_cn: 'Nom chinois des accessoires',
                    new_part_code: 'Code d\'accessoire alternatif',
                    new_part_name_cn: 'Nom chinois de la sélection de substitution',
                    effective_time: 'Date d\'entrée en vigueur',
                    stock_deal: 'Suggestions sur la gestion des stocks',
                    create_time: 'Date de création',
                    replace_relate: 'Relation substitutive',
                    brand: 'Marque',
                    replace_remarks: 'Remarques de remplacement',
                    newly_edit: 'Modifier', // edit
                    part_codes: 'Code d\'accessoire',
                    new_part_codes: 'Code de pièce de remplacement',
                    new_part_name_cns: 'Nom chinois des pièces de rechange',
                    stock_deals: 'Sugggestions de gestion des stocks',
                },
                sap: {
                    sap_part_id: 'Clé primaire', // 主键
                    part_id: 'ID de la pièce', // 零件id
                    brand: 'Marque', // 项目编号
                    part_code: 'Ancien numéro de matériau', // 零件代码
                    material_type: 'Type de matériau ZTG2/SAP', // ZTG2/SAP物料类型
                    customer_declare_material_name: 'Nom du matériel de déclaration en douane', // 报关物料名称
                    material_name_en: 'Nom anglais du matériau', // 英文物料名称
                    // brand: string; // 品牌
                    hs_code: 'Code HS', // HS编码
                    customer_declare_licence: 'Nom du matériel de licence de déclaration', // 许可证物料名称
                    base_cal_unit: 'unité de mesure de base', // 基本计量单位
                    sap_code: 'Numéro de matériau SAP', // SAP物料号
                    sap_codes: 'Type de matériau SAP', // SAP物料号
                    gross_weight: 'Poids brut', // 毛重
                    declare_ele: 'Éléments de déclaration', // 申报要素
                    net_weight: 'Poids net', // 净重
                    volume: 'Volume (mm ³)', // 体积
                    specs: 'Spécifications', // 规格
                    danger_attr: 'Attribut de produit 2 (matières dangereuses)', // 产品属性2(危险品)
                    abroad_danger_attr:
                        'Attribut de produit 3 (identification des matières dangereuses étrangères)', // 产品属性3(国外危险品标识)
                    status: 'Statut de création', // 状态0-草稿，1-进行中，2-已完成
                    create_by: 'Créé par', // 创建人
                    create_time: 'Date de création',
                    update_by: 'Modifié par', // 创建人
                    update_time: 'Date de modification',
                    sap_edit: 'Modifier',
                    sap_add: 'Nouvellement ajouté',
                    error_reasons: 'Type de matériau SAP',
                    sap_status: 'Statut de retour SAP',
                    part_name_cn: 'Description chinoise du matériau',
                    sale_tax_class: 'Classification fiscale des ventes',
                    error_reason: 'Information de retour',
                },
                coo: {
                    country_id: 'Pays',
                    brand: 'Marque',
                    part_code: 'Code d\'accessoire',
                    part_name_cn: 'Nom des accessoires',
                    origin_country: 'Pays d\'origine',
                    create_by: 'Créé par',
                    create_time: 'Créé le',
                    coo_add: 'Nouvellement ajouté',
                    coo_edit: 'Modifier',
                    accessories_selection: 'Sélection des accessoires',
                },
                sapModify: {
                    gds_material_number: 'Numéro de matériau GDS',
                    sap_material_number: 'Numéro de matériau SAP',
                    chinese_name_of_material_zh: 'Nom chinois du matériau ZH',
                    english_name_of_material_en: 'Nom anglais du matériau EN',
                    material_declaration_name_z1: 'Nom de la déclaration de matériau Z1',
                    declaration_elements: 'Éléments de déclaration',
                    classification_proposal_no: 'Numéro de proposition de classification',
                    period_of_validity_of_classification:
                        'Période de validité de la classification',
                    tax_classification_code: 'Code de classification fiscale',
                    factory: 'Usine',
                    status: 'Statut',
                    sap_status: 'SAP statut de retour',
                    commodity_import_code_number: 'Numéro de code d\'importation de marchandise',
                    sap_modify_edit: 'Modifier',
                    sap_modify_add: 'nouvellement ajouté',
                },
            },
            sales: {
                weight_ladder: 'Echelle de poids',
                country: 'Pays',
                port: 'Port',
                portCode: 'Code du port',
                portCN: 'Nom chinois',
                portUS: 'Nom anglais',
                EffectiveDate: 'Date d\'entrée en vigueur',
                ExpirationDate: 'Date d\'expiration',
                oldEffectiveTime: 'Ancienne période d\'efficacité',
                oldExpireTime: 'Ancienne période d\'expiration',
                newEffectiveTime: 'Nouvelle période d\'efficacité',
                newExpireTime: 'Nouvelle période d\'expiration',
                freight: 'Fret',
                KG: 'KG',
                KGabove: 'Supérieur à KG',
                express: 'Zone express',
                general: 'Général',
                danger: 'Dangereux',
                GP20: 'Prix unitaire 20GP (USD)',
                GP40: 'Prix unitaire 20GP (USD)',
                hq40: 'Prix unitaire 40hq (USD)',
                region: 'Zone express',
                currencyCode: 'Code de devise',
                description: 'Description',
                MinimumUnit: 'Unité minimale',
                ChineseDescription: 'Description chinoise',
                symbol: 'symbole',
                updated_date: 'Dernière date de mise à jour',
                price_benchmark: 'Prix de référence',
                part_description: 'Description de la pièce',
                excluding_tax: 'Prix d\'achat hors taxes',
                tax: 'Prix d\'achat toutes taxes comprises',
                price_including_tax: 'Prix de vente intérieure toutes taxes comprises',
                seller: 'Vendeur',
                selling_party: 'Partie vendante',
                price: 'Prix',
                sales_ratio: 'Taux de vente (%)',
                cifShare: {
                    trade_clause: 'Clause commerciale',
                    order_type: 'Type de commande',
                    type_of_shipping: 'Type d\'expédition',
                    dt: 'DT',
                    last_modified_date: 'Dernière date de modification',
                    creation_date: 'Date de création',
                    sales_price_proportion: 'Proportion du prix de vente (%)',
                    seller: 'Vendeur',
                    dt_choose: 'Choix DT',
                    dt_code: 'Code DT',
                    dt_name: 'Nom DT',
                    country: 'Pays',
                },
                cifSingle: {
                    trade_clause: 'Clause commerciale',
                    accessory_type: 'Type d\'accessoire',
                    type_of_shipping: 'Type d\'expédition',
                    dt: 'DT',
                    freight_coefficient: 'Coefficient de fret (%)',
                    premium_coefficient: 'Coefficient de prime (%)',
                    air_freight0: 'Fret aérien (0-100KG)',
                    air_freight100: 'Fret aérien (100-500KG)',
                    air_freight500: 'Fret aérien (500KG+)',
                    minimum_air_freight_charge: 'Frais de fret aérien minimum',
                    dangerous_goods_freight: 'Fret de marchandises dangereuses',
                    dangerous_goods_minimum_freight: 'Fret minimum de marchandises dangereuses',
                    dangerous_goods_ITEM_freight: 'Fret de marchandises dangereuses ITEM',
                    dangerous_goods_qty_freight: 'Fret de quantité de marchandises dangereuses',
                    dt_choose: 'Choix DT',
                    dt_code: 'Code DT',
                    dt_name: 'Nom DT',
                },
                triesLimit: {
                    dt: 'DT',
                    frequency_limit: 'Limite de fréquence',
                    price_ratio: 'Ratio de prix (%)',
                    seller: 'Vendeur',
                    brand: 'Marque',
                    dt_choose: 'Choix DT',
                    dt_code: 'Code DT',
                    dt_name: 'Nom DT',
                },
                dcs: {
                    partial_transmission: 'Transmission partielle',
                    full_transmission: 'Transmission complète',
                    download_template: 'Télécharger le modèle',
                    upload_Excel: 'Charger Excel',
                    upload_files: 'Charger des fichiers',
                    no_files_selected: 'Aucun fichier sélectionné',
                    General_generation: 'Génération générale',
                    DCS_price_delivery_price: 'Prix DCS livraison (FOB) prix',
                },
            },
            sort: {
                maxmaterial: {
                    category_code: 'Catégorie de matériau',
                    // category_codes: '定价大类',
                    category_desc: 'Description',
                    maxmaterial_add: 'Nouvellement ajouté',
                    maxmaterial_edit: 'Modifier',
                },
                minmaterial: {
                    no_air: 'Pas de transport aérien',
                    category_code: 'Code de catégorie de matériau',
                    category_desc: 'Description de la catégorie de matériau',
                    big_material_category_id: 'Catégorie de matériau',
                    pack_alone: 'S\'il est emballé séparément',
                    minmaterial_add: 'Nouvellement ajouté',
                    minmaterial_edit: 'Modifier',
                    material_category_selection: 'Sélection de catégorie de matériau',
                },
                model: {
                    type_code: 'Code de type',
                    brand: 'Marque',
                    parts_code: 'Code de pièces',
                    parts_name: 'Nom de pièces',
                    save_add: 'Enregistrer et nouveau',
                },
                code: {
                    type_code: 'Code de type',
                    model_grade: 'Grade de modèle',
                    save_add: 'Enregistrer et nouveau',
                },
                marialMaint: {
                    small_material_category_id: 'Code de catégorie de petit matériel', // 物料小类代码
                    brand: 'Marque',
                    create_time: 'Heure de création',
                    create_by: 'Créé par',
                    part_id: 'Code d\'accessoire', // 配件代码
                    marial_maint_add: 'Nouvellement ajouté',
                    marial_maint_edit: 'Modifier',
                },
            },
            packing: {
                mpq: {
                    transport: 'Mode de transport',
                    category_code: 'Code de la petite classe',
                },
                information: {
                    transport_package_No: 'Numéro de paquet de transport',
                    name: 'Nom',
                    classification: 'Classification',
                    purpose: 'Objectif',
                    length: 'Longueur(mm)',
                    width: 'Largeur(mm)',
                    height: 'Hauteur(mm)',
                    volume: 'Volume(CBM)',
                    specification_requirements: 'Exigences de spécifications',
                    brand: 'Marque',
                    creation_date: 'Date de création',
                    created_by: 'Créé par',
                    purchasing_unit: 'Unité d\'achat',
                },
                volume: {
                    volume_factor: 'Facteur de volume',
                    shipping_type: 'Type d\'expédition',
                    brand: 'Marque',
                    creation_date: 'Date de création',
                    created_by: 'Créé par',
                },
                cmaintain: {
                    packing_type_code: 'Code de type d\'emballage',
                    english_description: 'Description en anglais',
                },
                sap: {
                    pack_material_code: 'Numéro de matériau d\'emballage',
                    chinese_desc: 'Nom chinois',
                    english_desc: 'Nom anglais',
                    sap_add: 'Nouvellement ajouté',
                    sap_edit: 'Modifier',
                },
                minmaterial: {
                    small_material_category_code: 'Code de matériau',
                    small_material_category_desc: 'Description de l\'élément',
                    special_compute: 'Calcul spécial ou non',
                    create_time: 'Créé le',
                    create_by: 'Créé par',
                    small_code: 'Catégorie de matériau',
                    minmaterial_add: 'Nouvellement ajouté',
                    minmaterial_edit: 'Modifier',
                },
                maintain: {
                    package_volume: 'Volume du paquet (mm³)',
                    part_code: 'Code de pièce',
                    brand: 'Marque',
                    minimum_number_of_packages: 'Nombre minimum de paquets',
                    long: 'Longueur',
                    width: 'Largeur',
                    height: 'Hauteur',
                    gross_weight: 'Poids brut',
                    net_weight: 'Poids net',
                    creation_date: 'Date de création',
                    create_by: 'Créé par',
                    revision_date: 'Date de révision',
                    revision_by: 'Révisé par',
                    package_type: 'Type de paquet',
                    unit_of_length: 'Unité de longueur',
                    unit_of_weight: 'Unité de poids',
                    package_plan: 'Plan d\'emballage',
                },
                dangerInfo: {
                    accessory_code: 'Code d\'accessoire',
                    nam_accessories: 'Nom en anglais d\'accessoires',
                    accessories_cn: 'Nom chinois d\'accessoires',
                    brand: 'Marque',
                    bonfire_experiment: 'Numéro de l\'expérience de feu de joie',
                    bonfire_experiment_cn: 'Nom chinois de l\'expérience de feu de joie',
                    bonfire_experiment_en: 'Nom anglais de l\'expérience de feu de joie',
                    dangerous_goods_supplier: 'Fournisseur de matières dangereuses',
                    length: 'Longueur (mm)',
                    width: 'Largeur (mm)',
                    height: 'Hauteur (mm)',
                    packing_type: 'Type d\'emballage',
                },
            },
            customs: {
                hscode: {
                    hs_code: 'Code douanier', // 海关代码
                    hscode_unit: 'L/KG', // L/KG
                    is_inspect: 'Inspection de la marchandise ou non', // 是否商检，Y-是，N-否
                    tax_refund_rate: 'Taux de remboursement de la taxe', // 退税率
                    supervise_condition: 'Conditions de réglementation', // 监管条件
                    company: 'Entreprise', // 公司
                    country_id: 'Pays', // 国家
                    create_time: 'Temps de création',
                    hscode_add: 'Nouvellement ajouté',
                    hscode_edit: 'Modifier',
                },
                export: {
                    brand: 'Marque', // 品牌
                    part_code: 'Code d\'accessoire', // 配件代码
                    conutry_code: 'Code de pays', // 国家代码
                    hscode: 'Code douanier', // 海关编码
                    form: 'FORM-E', // FORM-E
                    tariff: 'TARIF', // TARIFF
                },
                fluid: {
                    brand: 'Marque',
                    part_id: 'Code d\'accessoire',
                    part_id_name: 'Nom des accessoires',
                    fluid_add: 'nouvellement ajouté',
                },
                danger: {
                    company: 'entreprise', // 公司
                    ship_type: 'type de livraison', // 运输方式
                    part_code: 'Code d\'accessoire', // 配件代码
                    brand: 'Marque',
                    danger_type: 'Type de matières dangereuses', // 危险品类型
                    danger_category: 'Classification des matières dangereuses', // 危险品分类
                    chinese_part_name: 'Nom chinois des accessoires',
                    english_part_name: 'Nom anglais des accessoires',
                    danger_add: 'Nouvellement ajouté',
                    danger_edit: 'Modifier',
                },
                choren: {
                    chinese_unit: 'Unité chinoise',
                    english_unit: 'Unité anglaise',
                    create_by: 'Créé par',
                    create_time: 'Temps de création',
                    choren_add: 'Nouvellement ajouté',
                    choren_edit: 'Modifier',
                },
                maintain: {
                    dt: 'DT',
                    material_no: 'Numéro de matériel',
                    brand: 'Marque',
                    customs_code: 'Code douanier',
                    form_e_mark: 'Marque Form E',
                    created_on: 'Créé le',
                    created_by: 'Créé par',
                    maintain_add: 'nouvellement ajouté',
                    maintain_edit: 'Modifier',
                },
                chemicali: {
                    company: 'Entreprise',
                    material_no: 'Numéro de matériel',
                    brand: 'marque',
                    license_or_not: 'Licence ou non',
                    chemicali_add: 'Nouvellement ajouté',
                    chemicali_edit: 'Modifier',
                },
            },
            salesl: {
                fixedRate: {
                    local_currency: 'Devise locale',
                    name_of_local_currency: 'Nom de la devise locale',
                    seller: 'Vendeur',
                    fixed_rate: 'Taux fixe',
                    settlement_currency: 'Devise de règlement',
                    name_of_settlemen_currency: 'Nom de la devise de règlement',
                    dt: 'DT',
                    brand: 'marque',
                    effective_date: 'Date d\'effet',
                    expiration_date: 'Date d\'expiration',
                    created_on: 'Créé le',
                    fixedRate_add: 'Nouvellement ajouté',
                    fixedRate_edit: 'Modifier',
                    fixedRate_select: 'Après avoir sélectionné DT, la marque n\'est pas vide',
                },
                transport: {
                    dt: 'DT',
                    brand: 'Marque',
                    trade_terms: 'Conditions commerciales',
                    type_of_shipping: 'Type d\'expédition',
                    transport_add: 'nouvellement ajouté',
                    created_on: 'Créé le',
                    transport_edit: 'Modifier',
                },
                purchaseConfirm: {
                    accessory_code: 'Code d\'accessoire',
                    effective_date: 'Date d\'effet',
                    expiration_date: 'Date d\'expiration',
                    accessory_description: 'Description de l\'accessoire',
                    purchase_price: 'Prix d\'achat hors taxes',
                    tax_rate: 'Taux de taxe',
                    currency: 'Devise',
                    corporation: 'Entreprise',
                    created_on: 'Créé le',
                },
            },
            sapModify: {
                gds_material_number: 'Numéro de matériau GDS',
                sap_material_number: 'Numéro de matériau SAP',
                chinese_name_of_material_zh: 'Nom chinois du matériau ZH',
                english_name_of_material_en: 'Nom anglais du matériau EN',
                material_declaration_name_z1: 'Nom de la déclaration de matériau Z1',
                declaration_elements: 'Éléments de déclaration',
                classification_proposal_no: 'Numéro de proposition de classification',
                period_of_validity_of_classification: 'Période de validité de la classification',
                tax_classification_code: 'Code de classification fiscale',
                factory: 'Usine',
                commodity_import_code_number: 'Numéro de code de marchandise/d\'importation',
                sapModify_edit: 'Modifier',
                sapModify_add: 'Nouvellement ajouté',
            },

            customer: {
                fundPool: {
                    dt_code: 'Dt code',
                    dt_name: 'Nom de DT',
                    dt_short_name: 'Nom court de DT',
                    currency: 'Devise',
                    account_amount: 'Montant du compte',
                    freeze_amount: 'Montant gelé',
                    date: 'Date',
                    fund_status: 'Statut du fonds',
                    fund_type: 'Type de fonds',
                    payment_number: 'Numéro de paiement',
                    credit_number: 'Numéro de crédit',
                    suborder_number: 'Numéro de sous-commande',
                    container_number: 'Numéro de conteneur',
                    credit_validity_date: 'Date de validité du crédit',
                    recorded: 'Enregistré / décaissement',
                    disbursement_money: 'Montant du décaissement',
                    wait_recorded_money: 'Montant en attente d\'enregistrement',
                    recorded_money: 'Montant enregistré',
                    service_charge: 'Frais de service',
                    account_balance: 'Solde du compte',
                    freeze_balance: 'Solde gelé',
                    credit_picture: 'Image du crédit',
                    upload_list: 'Liste de téléchargement',
                    remark: 'Remarque',
                    dt_payment_confirm: 'Confirmation de paiement DT',
                    credit_confirm: 'Confirmation de crédit',
                    credit_money: 'Montant crédité',
                    arrival_amount: 'Montant arrivée',
                    dt_selection: 'Sélection de DT',
                },
                customer: {
                    dt_code: 'Code DT',
                    dt_name: 'Nom de DT',
                    dt_short_name: 'Nom court de DT',
                    country: 'Pays',
                    sap_code: 'Code SAP',
                    brand: 'Marque',
                    beneficiary_name: 'Nom du bénéficiaire',
                    beneficiary_address: 'Adresse du bénéficiaire',
                    VAT: 'TVA（%）',
                    Name_of_bank_of_deposit: 'Nom de la banque de dépôt',
                    VAT_number: 'Numéro de TVA',
                    bank_address: 'Adresse de la banque',
                    SWIFT_CODE: 'Code SWIFT',
                    bank_account: 'Compte bancaire',
                    Agent_bank_address: 'Adresse de la banque d\'agent',
                    Agent_bank_account: 'Compte de banque de l\'agent',
                    Agent_bank_name: 'Nom de la banque de l\'agent',
                    IBAN: 'IBAN',
                    Agent_bank_iban: 'Agent Bank Iban',
                    Agent_bank_SWIFT_Code: 'Agent Bank SWIFT Code',
                    Bank: 'Banque',
                    inherit_general_generation: 'Héritage de la génération générale',
                    money: 'Argent',
                    A_24_hour_hotline: 'Ligne téléphonique 24h / 24',
                    facsimile: 'fax',
                    telephone: 'téléphone',
                    local_country: 'Pays local',
                    local_city: 'Ville locale',
                    local_area: 'Zone locale',
                    Postal_code: 'Code postal',
                    email_address: 'Adresse électronique',
                    Affiliated_brand: 'Marque affiliée',
                    Company_homepage: 'Page d\'accueil de la société',
                    Discount_rate_for_parts_sales: 'Taux de remise pour les ventes de pièces',
                    Company_address: 'Adresse de la société',
                    Method_of_settlement: 'Méthode de règlement',
                    Sales_territory: 'Territoire de vente',
                    Express_freight_area: 'Zone de fret express',
                    Selling_party: 'Partie vendante',
                    Address_of_the_selling_party: 'Adresse de la partie vendante',
                    Loan_agency_information: 'Information sur l\'agence de prêt',
                    Payment_terms: 'Modalités de paiement',
                    Account_period: 'Période de compte',
                    supplier: 'Fournisseur',
                    Shipping_address: 'Adresse d\'expédition',
                    Contact_name: 'Nom du contact',
                    File_delivery_address: 'Adresse de livraison du fichier',
                    Air_address: 'Adresse aérienne',
                    Express_address: 'Adresse express',
                    organization: 'Organisation',
                    Trade_clause1: 'Clause commerciale 1',
                    Trade_clause2: 'Clause commerciale 2',
                    beneficiary: 'Bénéficiaire',
                    fmc: 'FMC',
                    Swift_Code: 'Code Swift',
                    USD_Account: 'Compte USD',
                    EUR_Account: 'Compte EUR',
                    RMB_Account: 'Compte RMB',
                    Tax_number: 'Numéro d\'identification fiscale',
                    Order_clerk: 'Clerc de commande',
                    Method_of_payment: 'Méthode de paiement',
                    Market_type: 'Type de marché',
                    MX_business_model: 'Modèle économique MX',
                    label: 'Étiquette',
                    Air_freight_rate: 'Taux de fret aérien（%）',
                    Ocean_freight_rate: 'Taux de fret maritime（%）',
                    Express_rate: 'Taux express（%）',
                    Document_number_prefix: 'Préfixe de numéro de document',
                    TT_settlement_term: 'Modalité de règlement TT',
                    LC_settlement_term: 'Modalité de règlement LC',
                    name: 'Nom',
                    Contact_person: 'Personne à contacter',
                    Contact_number: 'Numéro de contact',
                    address: 'Adresse',
                    city: 'Ville',
                    Zip_code: 'Code postal',
                    sap_consumer_query: 'Requête de consommateur SAP',
                    customer_code: 'Code client',
                    customer_name: 'Nom du client',
                    sales_organization: 'Organisation des ventes',
                    abbreviation: 'Abréviation',
                    organization_name: 'Nom de l\'organisation',
                    customer_information: 'Informations sur le client',
                    delivered_by: 'Livré par',
                },
            },
        },
        components: {
            businessComponents: {
                documentType: 'Type de document',
                vehicleType: 'Type de véhicule',
                dealerInformation: 'Informations sur le concessionnaire',
                reportType: 'Type de rapport',
                dealerContact: 'Contact du concessionnaire',
                contact: 'Contact #',
                occupation: 'Occupation',
                vehicleInformation: 'Informations sur le véhicule',
                mileage: 'Kilométrage (/km)',
                licensePlate: 'Plaque d\'immatriculation #',
                platform: 'Plate-forme',
                offLineDate: 'Date hors ligne',
                vehicleUse: 'Utilisation du véhicule',
                refited: 'Modifié',
                nachgefüllt: 'Rechargé',
                nameplateNo: 'Numéro de plaque signalétique',
                purchaseDate: 'Date d\'achat',
                accidentInformation: 'Informations sur l\'accident',
                timeOfAccident: 'Heure de l\'accident',
                placeOfAccident: 'Lieu de l\'accident',
                roadConditions: 'Conditions routières',
                pavementType: 'Type de chaussée',
                weather: 'Météo',
                vehicleCondition: 'État du véhicule',
                vehicleOfTheAccident: 'Informations sur le véhicule au moment de l\'accident',
                vehicleCollisionPosition: 'Position de la collision du véhicule',
                vehicleRunningStatus: 'État de marche du véhicule',
                ownSpeed: 'Vitesse propre',
                vehicleSpeedOfTheOtherSide: 'Vitesse du véhicule de l\'autre côté',
                personnelInsideTheVehicle: 'Personnel à l\'intérieur du véhicule',
                numberOfPeopleInTheCar: 'Nombre de personnes dans la voiture',
                personalInjury: 'Blessure personnelle',
                distributionOfPersonnelInTheVehicle: 'Répartition du personnel dans le véhicule',
                descriptionOfPersonalInjury: 'Description de la blessure personnelle',
                airbagDeployment: 'Déploiement de l\'airbag',
                safetyBeltWearing: 'Port de la ceinture de sécurité',
                brakeBeforeTheAccident: 'Frein avant l\'accident',
                turnBeforeTheIncident: 'Virage avant l\'incident',
                whenCorrosionOccurs: 'Quand la corrosion se produit',
                parkingTimeHours: 'Temps de stationnement (heures)',
                parkingTimeMinutes: 'Temps de stationnement (minutes)',
                drivingHours: 'Heures de conduite',
                drivingMinutes: 'Minutes de conduite',
                drivingDistance: 'Distance de conduite',
                drivingSpeed: 'Vitesse de conduite',
                vehicleState: 'État du véhicule',
                fireFightingOrNot: 'Lutte contre l\'incendie ou non',
                numberOfInjured: 'Nombre de blessés',
                deathToll: 'Nombre de morts',
                vehicleHelp: 'Aide du véhicule',
                vehiclePassingAccident: 'Véhicule passant par accident',
                pastAccidents: 'Accidents passés',
                isTheWarningLampOnBeforeTheEvent:
                    'Le témoin d\'avertissement était-il allumé avant l\'événement?',
                specificIconContent: 'Contenu spécifique de l\'icône',
                didYouSmellFuelBeforeTheAccident: 'Avez-vous senti de l\'essence avant l\'accident?',
                didYouFeelAnyAbnormalVibrationBeforeTheAccident:
                    'Avez-vous ressenti une vibration anormale avant l\'accident?',
                abnormalSoundZone: 'Zone de bruit anormal',
                didYouHearAnyNoiseBeforeTheAccident: 'Avez-vous entendu du bruit avant l\'accident?',
                noiseDescription: 'Description du bruit',
                theAreaWhereTheVehicleSmokedBeforeTheAccident:
                    'Zone où le véhicule a fumé avant l\'accident',
                otherAreas: 'Autres zones',
                whetherTheInsuranceCompanyHasIssuedACertificate:
                    'L\'assureur a-t-il délivré un certificat?',
                whetherTheAuthorityIssuesTheCertificate: 'L\'autorité délivre-t-elle le certificat?',
                isVDSReportAvailable: 'Le rapport VDS est-il disponible?',
                accidentProcess: 'Processus d\'accident',
                accidentProcessPlaceholder:
                    'Déclarant Paraphrase de XXXX.\n' +
                    '20XX le xx xx xx, vers 20hxx, la partie XXXX conduisait un véhicule immatriculé XXXXXX ronway rx50 1.5 / at sur la route nationale en direction du Sud et du Nord (une personne à l\'intérieur du véhicule, un conducteur). Lorsque vous conduisez jusqu\'à la Section du village XX, Canton XX, comté XX, municipalité XX, Province XXX, de la fumée blanche a été trouvée dans le cockpit gauche.',
                accessoryMaterials: 'Matériaux accessoires',
                bulkDownload: 'Téléchargement en vrac',
                materialUpload: 'Téléchargement de matériel',
                userReadmeAttachment: 'Annexe du manuel utilisateur',
                sitePhotos: 'Photos de site',
                fireAccidentIdentificationLetter: 'Lettre d\'identification des accidents de feu',
                insuranceRecordAttachment: 'Annexe au dossier d\'assurance',
                diagnosticRecords: 'Dossiers de diagnostic',
                temperature: 'Température',
                please_select_the_reporting_type: 'Veuillez sélectionner le type d\'escalade',
                fire_accident_identification_letter_required:
                    'Certificat d\'accident d\'incendie obligatoire',
                please_upload_the_fire_accident_identification_letter:
                    'Veuillez télécharger une déclaration d\'accident d\'incendie',
                insurance_record_attachment_required:
                    'Pièce jointe au dossier d\'assurance obligatoire',
                please_upload_the_insurance_record_attachment:
                    'Veuillez télécharger une pièce jointe au dossier d\'assurance',
                please_upload_the_diagnostic_record_attachment:
                    'Veuillez télécharger une pièce jointe au dossier de diagnostic',
                please_upload_site_photos: 'Veuillez télécharger une photo du site',
            },
        },
        order: {
            claim: {
                creater: 'Créateur',
                brand: 'Marque',
                claimAmountAll: 'Prix total de la réclamation',
                creationDate: 'Date de création',
                currentState: 'Statut actuel du processus',
                claimNumber: 'Nombre de fois où la réclamation a été faite',
                claimName: 'Nom',
                claimNameCN: 'Nom en chinois',
                claimNameUS: 'Nom en anglais',
                Application: 'Numéro de la demande de pièces',
                orderNumber: 'Numéro de commande',
                amount: 'Veuillez entrer le montant',
                frequency: 'Veuillez entrer le nombre de fois',
                applicationNumber: 'Numéro de demande de pièces',
                creationDateIn: 'Date de création',
                new: 'Nouveau',
                applications: 'Liste de demandes de réclamation',
                creationTime: 'Heure de création',
                money: 'Devise',
                containerNumber: 'Numéro de PL',
                pack_noCode: 'Code de conteneur',
                suborder: 'Numéro de sous-commande',
                PartNumber: 'Numéro de pièce',
                claimType: 'Type de réclamation',
                orderQuantity: 'Quantité facturée',
                receivedQuantity: 'Quantité reçue',
                claimNumbers: 'Quantité réclamée',
                approvedQuantity: 'Quantité approuvée',
                purchaseAmount: 'Prix de vente (FOB)',
                subtotal: 'Prix total',
                scrapPhotos: 'Photo des pièces défectueuses',
                accessoriesPhotos: 'Photos des pièces',
                accessoriesLabel: 'Photos des étiquettes des pièces',
                accessoriesPackagingPhotos: 'Photos de l\'emballage des pièces',
                transportation: 'Photos de l\'emballage de transport',
                packingList: 'Photos de la liste de colisage',
                claimReason: 'Description du problème',
                DTNumber: 'Numéro de DT',
                claimMessage: 'Informations sur la réclamation',
                Circulationadvice: 'Conseil de circulation',
                General_generation_name: 'Nom générique de génération',
                claim_order: 'Commande de réclamation',
                audit_result: 'Résultat de l\'audit',
                OEMResult: 'Résultat d\'audit OEM',
                responsible: 'Responsable',
                modification_date: 'Date de modification',
                updated_date: 'Date de mise à jour',
                General_agent: 'Agent général',
                ATA_time: 'Jours ATA définis',
                claimAmount: 'Montant de la réclamation',
                pack_choose: 'Sélection du numéro de boîte',
                suborder_choose: 'Sélection du numéro de sous-commande',
                PartNumber_choose: 'Sélection du numéro de pièce',
                request_pack: 'Veuillez vérifier le numéro de liste de colisage',
                request_suborder: 'Veuillez vérifier le numéro de sous-commande',
                request_PartNumber: 'Veuillez vérifier le numéro de pièce',
                request_Claim: 'Veuillez vérifier la ligne de demande de réclamation',
                request_ATA: 'Pas de temps ATA, impossible de réclamer',
                information: 'Informations de base',
                completionStatus: 'Statut d\'achèvement',
                Handlingsuggestion: 'Suggestion de traitement',
                picture: 'Image',
                greater: 'Supérieur ou égal à',
                less: 'Inférieur ou égal à',
                week: 'Semaine',
                month: 'Mois',
                quarter: 'Trimestre',
                year: 'Année',
            },
            report: {
                logisticsEfficiency: {
                    dt_code: 'Code DT',
                    dt_name: 'Nom DT',
                    order_month: 'Mois de commande',
                    order_quantity: 'Quantité de commande',
                    total_order_amount: 'Montant total de la commande',
                    packaging_timeliness: 'Rapidité d\'emballage',
                    maritime_transport_general_cargo: 'Transport maritime - fret général',
                    air_transport: 'Transport aérien',
                    sea_transportation: 'Transport maritime',
                    express_delivery: 'Livraison express',
                    zh_order_quantity: 'Quantité de commande à temps',
                    punctualit_rate: 'Taux de ponctualité',
                    maritime_transport_dangerous_goods:
                        'Transport maritime - marchandises dangereuses',
                    otd_timeliness: 'Rapidité OTD',
                    transport_prescription: 'Prescription de transport',
                    lines: 'Nombre de lignes de commande',
                    amount: 'Montant de commande',
                },
                abnormalOrder: {
                    Overexpectationprocessing: 'Traitement de dépassement des attentes',
                    Overduehasbeenprocessed: 'En retard et traité',
                    packing: 'Emballage',
                    OTD: 'OTD',
                    transport: 'Transport',
                    Orderlinenumber: 'Numéro de ligne de commande',
                    Orderlinenumberall: 'Nombre total de lignes de commande',
                    Anomalyrate: 'Taux d\'anomalie',
                },
            },
            distributionManagement: {
                list_information: 'Informations de liste d\'emballage',
                container_scheme: 'Schéma de conteneur',
                logistics_registration: 'Enregistrement logistique',
                dt_code: 'Code DT',
                packing_list: 'Numéro de la liste d\'emballage',
                date_warehousing: 'Date d\'entreposage',
                name_voyage: 'Nom/voyage/express du navire',
                other_expenses: 'Autres frais',
                bill_lading: 'Numéro de connaissement',
                print_shipping_mark: 'Imprimer la marque d\'expédition',
                warehousing_confirmation: 'Confirmation d\'entreposage',
                fill_other_expenses: 'Remplir les autres frais',
                type_shipping: 'type d\'expédition',
                shipping_company: 'Compagnie maritime',
                ships_name: 'Nom du navire',
                voyages_flights: 'La séquence des voyages ou des vols',
                ship_number: 'Numéro de navire',
                customs_no: 'Numéro de douane',
                return_lading: 'Méthode de retour du connaissement',
                expected_date: 'Date de retour prévue',
                actual_date: 'Date de retour réelle',
                remark: 'remarque',
                container_no: 'Numéro de conteneur',
                container_type: 'Type de conteneur',
                customs_declaration: 'Numéro de déclaration en douane',
                container_nos: 'Numéros de conteneur',
                reason_modification: 'Raison de modification',
            },
            management: order.claim.management.frFR,
        },
        tech: {
            quality: {
                problemTracking: {
                    customs_declaration_unit: 'Unité de déclaration en douane',
                    theme: 'Thème',
                    model: 'Modèle',
                    problem_state: 'État du problème',
                    TAC_submit_date: 'Date de soumission TAC',
                    level: 'Niveau',
                    type: 'Type',
                    vehicle_involved: 'Véhicule impliqué',
                    activity_code: 'Code d\'activité',
                    activity_name: 'Nom de l\'activité',
                    connected_activities: 'Activités connectées',
                    system_problems: 'Problèmes système',
                    quantity: 'Quantité',
                    engine_type: 'Type de moteur',
                    gearbox_type: 'Type de boîte de vitesses',
                    country: 'Pays',
                    region: 'Région',
                    date_of_feedback_to_QA: 'Date de rétroaction à QA',
                    required_part_return_date: 'Date de retour de pièce requise',
                    date_of_parts_delivery_to_QA: 'Pièces livrées à la date QA',
                    solution_release_date: 'Date de publication de la solution',
                    solution_target_date: 'Date cible de la solution',
                    end_date: 'Date de fin',
                    difference: 'Différence',
                    QA_weekday: 'Jour de la semaine QA',
                    fault_description: 'Description de la faute',
                    reason: 'Raison',
                    solution: 'Solution',
                    breakpoint: 'Point de rupture VIN/CSN/en',
                    process: 'Processus',
                    details: 'Détails',
                    technical_document_attachments: 'Pièces jointes de document technique',
                },
                recallActivity: {
                    activity_code: 'Code de l\'activité',
                    activity_name: 'Nom de l\'activité',
                    responsible_party: 'Partie responsable',
                    release_date: 'Date de publication',
                    data_sources: 'Sources de données',
                    recall_campaign_type: 'Type de campagne de rappel',
                    service_activity_code: 'Code d\'activité de service',
                    service_activity_name: 'Nom d\'activité de service',
                    buyout_company: 'Société de rachat',
                    audit_company: 'Société d\'audit',
                    currency: 'Devise',
                    money: 'Montant',
                    start_date: 'Date de début',
                    end_date: 'Date de fin',
                    failure_mode: 'Mode de défaillance',
                    activity_programme: 'Programme d\'activité',
                    improve_handling: 'Améliorer la manipulation',
                    activity_information: 'Informations sur l\'activité',
                    vehicle_involved_list: 'Liste des véhicules impliqués',
                    template_file: 'Fichier modèle',
                    browse: 'Feuilleter',
                    download_template: 'Télécharger le modèle',
                    claim_vehicles_list: 'Liste des véhicules de réclamation',
                    claim_statistics: 'Statistiques de réclamation',
                    technical_document_attachments: 'Pièces jointes de document technique',
                    vin: 'VIN',
                    is_complete: 'Est complet',
                    warranty_claims: 'Réclamations de garantie',
                    customer: 'Client',
                    no_file_selected: 'Aucun fichier sélectionné',
                    no_import_file_selected: 'Aucun fichier d\'importation sélectionné',
                    claim_proportion: 'Proportion de réclamation',
                    claim_settled_amount: 'Montant réglé de la réclamation',
                    is_claim_again: 'Ré-réclamation oui non',
                    cancel_succeed: 'Annulation réussie',
                    build_by_oneself: 'Construire soi-même',
                    system_import: 'Importation de système de réclamation',
                    claim_system_import: 'Importation de système de réclamation',
                },
            },
            management: {
                newCarDefinition: {
                    activityNumber: 'Code de campagne',
                    activityName: 'Nom de la campagne',
                    activityStatus: 'Statut',
                    activityDate: 'Date d\'activité',
                    startDate: 'Date de début',
                    endDate: 'Date de fin',
                    remarks: 'Remarques',
                    activity_details: 'Détails de l\'activité',
                    activity_addition: 'Ajout d\'activité',
                    confirm_enable: 'Confirmer l\'activation',
                    confirm_outage: 'Confirmer la panne',
                    all: 'Tous',
                    not_started: 'Non commencé',
                    on_going: 'En cours',
                    closed: 'Fermé',
                },
                newCarlssue: {
                    all: 'Tous',
                    newly_built: 'Ajouter',
                    unpublished: 'Non publié',
                    validity: 'Validité',
                    modelInvolved: 'Modèle',
                    involvedArea: 'Région concernée',
                    state: 'État',
                    new_car_reporting_level: 'Nouveau niveau de reporting des véhicules',
                    confirm_release: 'Confirmer la publication',
                    maintenance: 'Maintenance',
                    model_maintenance: 'Maintenance du modèle',
                    area_maintenance: 'Maintenance de la région',
                    reporting_level: 'Niveau de reporting',
                    to_be_published: 'À publier',
                    published: 'Publié',
                    modified_to_be_published: 'Modifié à publier',
                },
                onSiteSupportHq: {
                    fieldSupportNumber: 'Numéro de support sur le terrain',
                    informationSlipNumber: 'Numéro de bulletin d\'information',
                    approvalStatus: 'Statut d\'approbation',
                    state: 'État',
                    selectApplicationDate: 'Sélectionnez la date de demande',
                    toBeProcessed: 'À traiter',
                    processed: 'Traité',
                    initiated: 'initié',
                    applicationDepartment: 'Service demandeur',
                    applicant: 'demandeur',
                    reasonApplication: 'Raison de la demande',
                    applicationTime: 'Heure de la demande',
                    all: 'Tous',
                    pending_approva: 'En attente d\'approbation',
                    agreed: 'Approuvé',
                    rejected: 'Rejeté',
                    view: {
                        application_department: 'Service demandeur',
                        responsible_person: 'Personne responsable',
                        application_person: 'postulant',
                        associated_enquiry_number: 'Numéro d\'enquête associé',
                        reason_application: 'Raison de la demande',
                        description: 'description(Feuille de signature de demande interne)',
                        file_name: 'Nom du fichier',
                        size: 'taille(MB)',
                        uploader: 'téléchargeur',
                        upload_time: 'Heure du téléchargement',
                        view: 'Vérifier',
                        download: 'Télécharger',
                        support_department: 'Service de support',
                        technical_personnel: 'Personnel technique',
                        contact_information: 'Informations de contact',
                        field_support_information: 'Informations de support sur le terrain',
                        remarks: 'Observations',
                        apply: 'Soumettre',
                        application_information: 'Informations de demande',
                        field_support_personnel: 'Personnel de support sur site',
                        approval_record: 'Historique d\'approbation',
                        submit_an_application: 'Soumettre une demande',
                        refuse: 'Refuser',
                        agree: 'Accepter',
                        apply_for: 'Demander',
                        field_support: 'Support sur le terrain',
                        reason_for_rejection: 'Raison du rejet',
                    },
                    dialog: {
                        on_site_application_details: 'Détails de la demande sur place',
                        on_site_application: 'Demande sur place',
                        reason_for_rejection: 'Raison du rejet',
                        field_support_application: 'Demande de support sur le terrain',
                        technical_personnel: 'Personnel technique',
                        contact_information: 'Téléphone de contact',
                        field_support_time: 'Heure de support sur le terrain',
                        note_information: 'Informations de note',
                    },
                },
                filterHq: {
                    filter_information: 'Informations de filtre',
                    filter_name: 'Nom du filtre',
                    user_name: 'Nom d\'utilisateur',
                    creation_time: 'Heure de création',
                    splicing_description: 'Description de montage',
                    enable_or_not: 'Activé ou désactivé',
                    association_symbol: 'Symbole de relation',
                    screening_condition: 'Condition de filtrage',
                    conditional_value: 'Valeur de condition de filtrage',
                    save: 'Enregistrer',
                    draft: 'Brouillon',
                    no_reply: 'Aucune réponse',
                    replied: 'Répondu',
                    closed: 'Fermé',
                    pre_sales: 'Avant-vente',
                    after_sales: 'Après-vente',
                    to_be_repaired: 'À réparer',
                    under_maintenance: 'En maintenance',
                    completed: 'Terminé',
                    not_included: 'Non inclus',
                    and: 'Et',
                    perhaps: 'Peut-être',
                    not: 'Ne pas',
                    enable: 'Activer',
                    judgement_logic: 'Logique de jugement',
                    order_number: 'N°',
                    relational_symbol: 'Symbole relationnel',
                    filter_criteria: 'Critères de filtrage',
                    please_add_judgment_logic: 'Veuillez ajouter une logique de jugement',
                    model_platform_is: 'La plateforme du modèle est',
                    subject_of_the_case_is: 'L\'objet du cas est',
                    overseas_branches_are: 'Les branches étrangères sont',
                    overseas_subsidiaries_are: 'Les filiales étrangères sont',
                    general_agent_are: 'Les distributeurs sont',
                    fault_symptom_is: 'Le symptôme de défaillance est',
                    cause_of_the_fault_is: 'La cause de la défaillance est',
                    vehicle_status_is: 'l\'état du véhicule est',
                    case_type_is: 'Le type de cas est',
                    case_status_is: 'L\'état du cas est',
                    all: 'Tous',
                },
                vehicleInfoHq: {
                    productionTimeList: 'Liste de temps de production',
                    vehicle_configuration_list: 'Liste de configuration des véhicules',
                    accurately_traceable_parts_list: 'Liste de pièces précisément traçables',
                    order_number: 'Numéro de commande',
                    order_type: 'Type de commande',
                    platform: 'Plateforme',
                    model_code: 'Code de modèle',
                    date_of_production: 'Date de production',
                    certificate_of_conformity: 'Certificat de conformité',
                    certificate_printing_time: 'Temps d\'impression du certificat',
                    vehicle_material_number: 'Numéro de matériau du véhicule',
                    description: 'Description',
                    time: 'Temps',
                    feature_ID: 'ID de fonctionnalité',
                    feature_description: 'Description de fonctionnalité',
                    feature_code: 'Code de fonctionnalité',
                    eigenvalue_description: 'Description de valeur propre',
                    chinese_description: 'Description en chinois',
                    supplier: 'Fournisseur',
                    bar_code_number: 'Numéro de code-barres',
                    scanning_time: 'Temps de numérisation',
                    key_component_type_code: 'Code de type de composant clé',
                    part_number: 'Numéro de pièce',
                    traceability_number: 'Numéro de traçabilité',
                    sub_key_type: 'Sous-type de clé',
                    software: 'Logiciel',
                    hardware: 'Matériel',
                    calibration_information: 'Informations de calibrage',
                    supplier_name: 'Nom du fournisseur',
                    pn: 'Code PIN',
                },
                progHistoryHq: {
                    name_of_maintenance_station: 'Nom de la station de maintenance',
                    mileage: 'Kilométrage',
                    controller_name: 'Nom du contrôleur',
                    function_name: 'Fonction',
                    time: 'Temps',
                    function_execution_result: 'Résultat d\'exécution de la fonction',
                    channel: 'Canal',
                    hardware_number: 'Numéro du matériel',
                    written_software_information: 'Informations du logiciel écrit',
                    software_information_before_refresh:
                        'Informations du logiciel avant actualisation',
                    updated_software_information: 'Informations du logiciel mis à jour',
                },
                salesRecordHq: {
                    material: 'Matériel',
                    sales_order: 'Commande de vente',
                    vehicle_type: 'Modèle',
                    shipping_area: 'Zone d\'expédition',
                    country_of_dispatch: 'Pays d\'expédition',
                    down_date: 'Date de mise hors ligne',
                    port_of_destination: 'Port de destination',
                    scheduled_date_of_shipment: 'ETD',
                    actual_date_of_shipment: 'ATD',
                    current_position: 'Position actuelle',
                    actual_arrival_date: 'ATA',
                    retail_date: 'Date de vente au détail',
                },
                claimHq: {
                    claim_information: 'Informations sur les réclamations',
                    Recall_and_service_activities: 'Campagne de rappel et de service technique',
                    marketing_activity_list: 'Campagne de marketing',
                    basic_information: 'Informations de base',
                    warranty_block: 'Bloc de garantie',
                    repair_block_code: 'Code de bloc de garantie',
                    claim_cycle_list: 'Liste de périodes de garantie',
                    WTY_Indicates_the_cycle_type: 'Type de période de garantie',
                    start_time: 'Heure de début',
                    end_time: 'Heure de fin',
                    WTY_mileage: 'Kilométrage de garantie',
                    List_of_claims: 'Historique de garantie',
                    customer: 'Client',
                    warranty_claim: 'Réclamation de garantie',
                    work_order: 'Ordre de travail',
                    date_of_receipt: 'Date de réception',
                    maintenance_technical_date: 'Date de réparation',
                    mileage: 'Kilométrage',
                    state: 'État',
                    master_working_position: 'Poste principal',
                    manual_code: 'Code de travail',
                    main_component: 'Composant principal',
                    part_name: 'Nom de pièce',
                    recall_and_service_activities: 'Campagne de rappel et de service technique',
                    recall_and_service_activity_code:
                        'Code de campagne de rappel et de service technique',
                    recall_and_service_activity_names:
                        'Nom de campagne de rappel et de service technique',
                    brand: 'Marque',
                    recall_type_code: 'Code de type de rappel',
                    recall_type_name: 'Nom de type de rappel',
                    release_date: 'Date de publication',
                    closing_date: 'Date de fermeture',
                    complete: 'Terminé',
                    warranty_claim_status: 'Statut de la réclamation de garantie',
                    end_of_maintenance_date: 'Date de fin de la dernière réparation',
                    marketing_activity_code: 'Code de campagne de marketing',
                    marketing_activity_name: 'Nom de campagne de marketing',
                    vehicle_warranty_period: 'Durée de garantie du véhicule',
                    marketing_activities: 'Activités du marché',
                    prohibition_of_claims: 'Réclamations interdites',
                    prohibited_claim_code: 'Code de réclamation interdit',
                    type_of_warranty_period: 'Type de période de garantie',
                    repair_end_date: 'Date de fin de réparation',
                    reception_date: 'Date de réception',
                    main_station_code: 'Code de la station principale',
                    main_workstation_name: 'Nom de la station principale',
                },

                // inquiryOd: {
                //     vin: 'VIN',
                //     inquiry_no: 'inquiry no',
                //     case_theme: 'case theme',
                //     inquiry_date: 'inquiry date',
                // },
                inquiryOd: tech.management.inquiry.frFR,
                permission: tech.management.permission.frFR,
            },
        },
        statistics: statistics.inquiryStatistics.frFR,
        systemClassifyA: statistics.systemClassifyA.frFR,
        systemClassifyB: statistics.systemClassifyB.frFR,
        task: {
            china: {
                holiday: {
                    holiday_name: 'Nom du festival',
                    holiady_time: 'Vacances',
                    end_time: 'Date de fin',
                    holiday_add: 'Ajout récent',
                    holiday_edit: 'Modifier',
                },
            },
            package: {
                calendar: {
                    packing: 'Journée d\'emballage SMPV',
                },
            },
        },
        logistics: {
            package: {
                cycle: {
                    transport_type: 'Transport type',
                    market_type: 'Market type',
                    market_name: 'Market Name',
                    market_code: 'Market Code',
                    transport_cycle: 'Transportation period (day)',
                    Packaging_aging: 'Packaging aging (day)',
                    OTD_aging: 'OTD aging (day)',
                },
            },
        },
        vehicle: {
            logistics: {
                expenses: {
                    atd_begin_date: 'Date de début de l\'ATD',
                    atd_end_date: 'Date de fin de l\'ATD',
                    atd_date: 'Date de l\'ATD',
                    country_of_target: 'Pays cible',
                    dn: 'Dn',
                    region: 'Région',
                    model_code: 'Code du modèle',
                    vin: 'VIN',
                    volumn: 'Volume',
                    vdc_name: 'Nom de la VDC',
                    pol_name: 'Nom du POL',
                    dt_content: 'Contenu du DT',
                    dt_distance_km: 'Distance DT (en km)',
                    vdc_vasc_material: 'Matériaux VASC de la VDC',
                    vdc_vasc_worker: 'Travailleur VASC de la VDC',
                    domestic_carry: 'Transfert national',
                    wharf_handle: 'Manipulation au quai',
                    wharf_sc: 'SC au quai',
                    wharf_box: 'Boîte au quai',
                    medicated_fumigation: 'Fumigation médicamenteuse',
                    wharf_dot_check: 'Vérification DOT du quai',
                    repair_maintenance: 'Réparation et maintenance',
                    refuel_storage: 'Stockage de carburant',
                    file_cert: 'Certificat de fichier',
                    supply_chain_integration: 'Intégration de la chaîne d\'approvisionnement',
                    other_domestic_carry: 'Autre transfert national',
                    insure: 'Assurer',
                    ship_money: 'Argent navire',
                    ship_money_type: 'Type d\'argent navire',
                    other_interal_charge: 'Autre charge interne',
                    other_interal_charge_type: 'Type de charge interne autre',
                    transport_company_code: 'Code de la compagnie de transport',
                    vessel_name_voyage: 'Nom du navire voyage',
                    mode_of_transport: 'Mode de transport',
                    remark: 'Remarque',
                    create_date: 'Date de création',
                },
                expensesRelationship: {
                    country_name: 'Nom du pays',
                    country_code: 'Code pays',
                    model_code: 'Code du modèle',
                    ology_code: 'Code d\'ology',
                    ology_name: 'Nom d\'ology',
                    status: 'Statut',
                    remark: 'Remarque',
                    create_by: 'Créé par',
                    create_name: 'Nom de création',
                    create_date: 'Date de création',
                    update_by: 'Mis à jour par',
                    update_name: 'Nom de mise à jour',
                    update_date: 'Date de mise à jour',
                    tache_code: 'Code de tache',
                    no: 'Non',
                    yes: 'Oui',
                },
                expenseAccount: {
                    ology_type: 'Type d\'ology',
                    pack_name: 'Nom du pack',
                },
                country: vehicle_country.zhCN,
            },
        },
    },
    validation: {
        common: {
            required: 'Cet élément est obligatoire',
            numeric: 'Doit être un nombre entier',
            decimal: 'Doit être numérique',
            positive_interger: 'Doit être un entier positif',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Au plus pour ${named('number')} Nombre de chiffres`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Ne peut dépasser ${named('number')} Mots`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Dépasse le nombre maximal de caractères (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Pas moins que ${named('number')} Mots`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Doit être ${named('number')} Mots`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Ne peut être supérieure à ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Ne peut être inférieure à ${named('number')}`,
            up_to_2_decimal_places: 'Jusqu\'à 2 décimales',
            retain_up:
                'Entrez jusqu\'à 10 chiffres entiers positifs et conservez jusqu\'à 2 chiffres décimaux',
            retain_ups:
                'Entrez jusqu\'à 11 chiffres entiers positifs et conservez jusqu\'à 4 chiffres décimaux',
            input_number: 'Veuillez entrer un numéro',
            only_number_a: 'Entrez uniquement des lettres ou des chiffres',
            translate: 'Uniquement pour les lettres',
            positive_integer: 'Entrez au moins un champ',
            at_least_one: 'Remplissez au moins un',
        },
        tip: {
            incomplete_information: 'Information Incomplète',
        },
    },
    import: {
        incorrect_file_type: 'Type de fichier incorrect',
        exceed: 'Au - delà',
        upload_success: 'Téléchargement réussi',
        upload_fail: 'Le téléchargement a échoué',
        again_upload:
            'Seuls les fichiers Image au format jpg、png、bmp、gif peuvent être téléchargés, veuillez les télécharger à nouveau',
    },
    export: {
        noDataToExport: 'Pas de données exportables',
    },
    select: {
        all: 'Tous',
    },
    checkbox: {
        select_all: 'Tout sélectionner',
    },
    dialog: {
        error_message: 'Informations erronées',
        accessory_selection: 'Sélection d\'accessoires',
    },
};
