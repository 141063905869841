import type { MessageFunction } from 'vue-i18n';
import order from './pages/order';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import { vehicle_country } from './pages/vehicle/logistics';

export default {
    license: {
        powered_by: 'Aangedreven door SMIL',
    },
    auth: {
        login: 'Inloggen',
        username: 'Gebruikersnaam',
        username_placeholder: 'Voer gebruikersnaam in',
        password: 'Wachtwoord',
        password_placeholder: 'Voer wachtwoord in',
        code: 'Verificatiecode',
        code_placeholder: 'Voer verificatiecode in',
        logout: 'Uitloggen',
        logout_success: 'Uitloggen geslaagd',
        day: 'Dag',
        password_expiration: 'Het wachtwoord is verlopen, pas het aan',
        expire: 'Blijven tot wachtwoordverval',
        conseils: 'Het wachtwoord moet hoofdletters, kleine letters, cijfers en speciale tekens ({msg}) bevatten en de lengte van het wachtwoord moet tussen 8 tot 16 tekens liggen',
    },
    button: {
        query: 'Query',
        change_password: 'Wijzig Wachtwoord',
        old_password: 'Oud wachtwoord',
        new_password: 'Nieuw wachtwoord',
        confirm_password: 'Wachtwoord bevestigen',
        cipher: 'Code',
        change_new_password: 'Vul het nieuwe wachtwoord in',
        show_theme_editor: 'Toon/Verberg Thema Editor',
        button: 'Knop',
        about: 'Over',
        back: 'Terug',
        go: 'GA',
        home: 'Home',
        toggle_dark: 'Wissel donkere modus',
        toggle_langs: 'Verander talen',
        confirm: 'Bevestigen',
        modify: 'Wijzigen',
        edit: 'Bewerk',
        new: 'Nieuw',
        cancel: 'Annuleren',
        close: 'Sluiten',
        search: 'Zoeken',
        reset: 'Reset',
        delete: 'Verwijderen',
        add: 'Toevoegen',
        collapse: 'Inklappen',
        expand: 'Meer',
        download_template: 'Sjabloon downloaden',
        batch_import: 'Batch importeren',
        import: 'Importeren',
        export: 'Exporteren',
        export_fail: 'Export Mislukt',
        batch_clear_sap_code: 'Batch SAP-code wissen',
        batch_clear_special_purchase_cycle: 'Batch wissen van speciale aankoopcyclus',
        detailed: 'Gedetailleerd',
        download: 'Downloaden',
        toView: 'Controleren',
        uploadAttachments: 'Bijlagen uploaden',
        selectFile: 'Bestand selecteren',
        fillingInstructions: 'Invulinstructies',
        release: 'Vrijgeven',
        save: 'Opslaan',
        send_back: 'Retourneren',
        examine: 'Onderzoeken',
        updateTime: 'Bijwerken geldigheidsperiode',
        update: 'Bijwerken',
        void: 'Ongeldig maken',
        more: 'Meer',
        refresh: 'Vernieuwen',
        submit_sap: 'SAP indienen',
        submit: 'Indienen',
        save_button: 'Opslaan en nieuw',
        insufficient: 'Of u wilt indienen als het accountfonds onvoldoende is',
        yes: 'Ja',
        deny: 'Weigeren',
        staging: 'Opslaan en sluiten',
        return: 'Terugkeren',
        table: 'Nee',
        select: 'Kiezen',
        all: 'Alles',
        create_sap: 'SAP aanmaken',
        enter: 'Voer in',
        selects: 'Selecteer',
        modify_ladder: 'Ladder wijzigen',
        pass: 'Passeren',
        serial_number: 'Nr.',
        individual: 'Individueel',
        upload_progress: 'Uploadvoortgang',
        port_choose: 'Poort kiezen',
        request_code: 'Controleer accessoires',
        request_discuss: 'Selecteer een leverancier',
        request_dt: 'Controleer DT',
        request_thing: 'Controleer de materiaalcategorie',
        translate: 'Alleen letters',
        accessories_information: 'Accessoires informatie',
        select_accessories: 'Accessoires selecteren',
        binding: 'Binden',
        tick_data: 'Controleer de gegevens',
        invalid: 'Ongeldig',
        empty_data: 'Gegenereerde gegevens wissen',
        confirm_number: 'Bevestig het pakbonnummer',
        variance_details: 'Variantie Details',
        daily_record: 'Dagelijks record',
        operation_type: 'Type bewerking',
        field_name: 'Veldnaam',
        edit_role: 'Rol bewerken',
        user_role: 'Gebruikersrol',
        assignment_role: 'Rol toewijzen',
        post: 'Post',
        please_settled: 'Selecteer het accessoire dat moet worden afgerekend',
        not_sent: 'Niet verzonden',
        sending: 'Bezig met verzenden',
        has_been_sent: 'Is verzonden',
        fail_in_send: 'Verzending mislukt',
        express_import: 'Express import',
        reset_password: 'Wachtwoord resetten',
    },
    radio: {
        enabled: 'Ingeschakeld',
        disabled: 'Uitgeschakeld',
        catalogue: 'Catalogus',
        individual: 'Individueel',
        all: 'Allemaal',
        tips: 'Tips',
        save: 'Opslaan',
        yes: 'Ja',
        no: 'Nee',
    },
    dropdown: {
        close_others: 'Anderen sluiten',
        close_right: 'Rechts sluiten',
        close_left: 'Links sluiten',
    },
    table: {
        action: 'Operatie',
        explain: 'Uitleg',
        fileName: 'Bestandsnaam',
        size: 'Grootte(MB)',
        uploader: 'Uploader',
        uploadTime: 'Upload Tijd',
        system: 'Systeem',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/pagina`,
        total: ({ named }: Parameters<MessageFunction>[0]) =>
            `${named('number')} records in totaal`,
    },
    confirm: {
        confirm_deletion: 'Bevestig verwijdering?',
        confirm_void: 'Bevestig annulering?',
        confirm_release: 'Bevestig vrijgave?',
        ack: 'Bevestigen',
        cancel: 'Annuleren',
        non_records: 'Selecteer ten minste één record',
    },
    request: {
        failed_placeholder: 'Verzoek mislukt, backend-fout',
        success_placeholder: 'Verzoek succesvol',
        auth: {
            login_success: 'Inloggen succesvol',
        },
        error: {
            '404': 'Verzoek-URL niet gevonden',
            '403': 'Toestemming geweigerd',
        },
    },
    page: {
        global: {
            page_search: 'Pagina zoeken',
        },
        error: {
            not_found: 'Niet gevonden',
            not_found_des: 'Er is hier niets',
            auth_failed: 'Authenticatie mislukt',
            auth_failed_des: 'Deze pagina is niet bereikbaar',
        },
        homepage: {
            homepage: homepage.homepage.nlNL,
        },
        system: {
            menu: {
                menu: 'Menu',
                menu_name: 'Menunaam',
                menu_type: 'Menutype',
                menu_new: 'Nieuw menu',
                menu_edit: 'Menu wijzigen',
                icon: 'Icoon',
                parent: 'Ouder',
                order_number: 'Bestelnummer',
                path: 'Pad',
                status: 'Status',
            },
            dict: {
                dictionary_name: 'Woordenboeknaam',
                creater: 'Maker',
                dictionary_code: 'Woordenboekcode',
                code_type: 'Code Type',
                code_type_name: 'Code Type Naam',
                creation_time: 'Aanmaaktijd',
                dict_new: 'Nieuw Woordenboek',
                dict_edit: 'Bewerk Woordenboek',
                lang: 'Taal',
                sort: 'Sorteren',
            },
            dept: {
                system_selection: 'Systeemselectie',
                organizational_type: 'Organisatietype',
                parent_dept: 'Bovenliggende afdeling',
                dept_name: 'Afdelingsnaam',
                leader: 'Leider',
                email: 'E-mail',
                create_by: 'Aangemaakt door',
                create_date: 'Aanmaakdatum',
                organization_editing: 'Organisatie bewerken',
                phone: 'Telefoon',
            },
            role: {
                menu_maintenance: 'Menu',
                role_name: 'Rollen Naam',
                role_key: 'Rechten Karakter',
                role_id: 'Weergave volgorde',
                status: 'Status',
                create_date: 'Aanmaak datum',
                role_sort: 'Sorteren',
                role_new: 'Nieuwe Rol',
                role_edit: 'Rol bewerken',
                role_bleak: 'Leegmaken',
            },
            user: {
                serial_no: 'Nr.',
                username: 'Gebruikersnaam',
                name: 'Naam',
                sex: 'Geslacht',
                phone_number: 'Telefoonnummer',
                email: 'E-mail',
                role: 'Rol',
                login_time: 'Status inschakelen',
                password: 'Wachtwoord',
                user_edit: 'Gebruiker bewerken',
            },
        },
        management: {
            purchasePosting: {
                order_status: 'SLK bestelstatus',
                charge_against: 'Kosten in rekening brengen',
                transportation_method:
                    'Besteltype, transportmethode en handelsvoorwaarden zijn verschillend. Controleer de bestelling met hetzelfde type!',
                whether_to_send: 'Of verzenden naar SAP',
                invoice_number: 'Factuurnummer',
                invoice_reversal: 'Factuur omkeren',
                sales_purchase_tax_code: 'Verkoop-/aankoopbelastingcode',
                send_sap: 'SAP verzenden',
                whether_notify_invoicing: 'Of facturatie moet worden gemeld',
                import_invoices: 'Factuur importeren',
                invoicing_notice: 'Facturatiebericht',
                import_information: 'Douane-informatie importeren',
                tax_rate: 'Belastingtarief',
                amount_excluding_tax: 'Bedrag exclusief belasting',
                amount_including_tax: 'Bedrag inclusief belasting',
                invoice_tax_amount: 'Factuur belastingbedrag',
                invoice_amount: 'Factuurbedrag',
                prepayment_note_no: 'Voorschotnota nr.',
                prepayment_not: 'Voorschot of niet',
                section: 'Sectie',
                approved: 'Goedgekeurd',
                order_submission: 'Bestelling indienen',
                list_to_be_invoiced: 'Te factureren lijst',
                invoiced_but_unpaid_list: 'Gefactureerde maar onbetaalde lijst',
                invoiced_and_paid_list: 'Gefactureerde en betaalde lijst',
                dn_no: 'DN nr.',
                sub_order_no: 'Sub-bestelnummer',
                packing_list_no: 'Paklijstnr.',
                brand: 'Merk',
                whether_invoicable: 'Factureerbaar of niet',
                payment_type: 'Betalingswijze',
                advance_payment: 'Voorschot',
                oem_order_no: 'OEM-bestelnummer',
                tax_amount: 'Belastingbedrag',
                amount_of_money: 'Bedrag',
                payment_time: 'Betalingsdatum',
                payment_order_no: 'Betalingsopdracht nr.',
                sap_voucher_No: 'SAP-voucher nr.',
                export_gold_tax: 'Export goud belasting',
                export_maxus: 'Export MAXUS factureringslijst',
                time_of_payment: 'Tijd van betaling',
                apply_for_payment: 'Betaling aanvragen',
            },
            preBooking: {
                booking: 'Reservering',
                booking_space: 'Ruimte boeken',
                packing_list_no: 'Verpakkingslijst nr.',
                job_no: 'Job nr.',
                dangerous_goods_or_not: 'Gevaarlijke goederen of niet',
                brand: 'Merk',
                type_of_shipping: 'Transportmodus',
                dt_code: 'DT-code',
                estimated_volume: 'Geschatte volume (m³)',
                estimated_gross_weight: 'Geschatte bruto gewicht (kg)',
                estimated_net_weight: 'Geschatte netto gewicht (kg)',
                estimated: 'Geschat volume',
                volume: 'Volume (m³)',
                gross_weight: 'Bruto gewicht (kg)',
                net_weight: 'Netto gewicht (kg)',
                shipping_schedule: 'Verzendschema Job nr.',
                shipping_schedules: 'Verzenddatum Job nr. kan niet leeg zijn',
                shipping_type: 'Verzendtype',
                box_quantity: 'Job nr. Geschatte doos hoeveelheid',
            },
            shoppingCart: {
                dcs_order_number: 'DCS ordernummer',
                vin_requires:
                    'VIN vereist een combinatie van 17 letters en cijfers, beginnend met 3 grote en kleine letters',
                part_type: 'Deeltype',
                submit_validation: 'Validatie indienen',
                maximum_value: 'Maximale waarde 9999999999',
                please_select_cart: 'Selecteer een accessoire om aan de winkelwagen toe te voegen',
                please_enter_format: 'Voer het formaat in als PDF, XLSX, XLS, WORD',
                please_format: 'Voer het formaat in als jpg, png, gif',
                please_enter_an_integer: 'Voer de juiste bestelhoeveelheid in',
                customized_orders: 'Aangepaste bestellingen',
                details_accessory: 'Details van accessoirecode',
                warning: 'bevestig verwijdering',
                please_payment_method: 'Selecteer een betaalmethode',
                please_port: 'Selecteer een poort',
                please_party: 'Selecteer de bezorgpartij',
                no_inventory: 'Geen voorraad:',
                notes: 'notities',
                note_the: 'Gebrek aan noodzakelijke informatie voor aangepaste onderdelen',
                download_template: 'Sjabloon downloaden',
                single_addition: 'Enkele toevoeging',
                material_category: 'Materiaalcategorie',
                Individual_addition: 'Individuele toevoeging',
                order_type: 'Soort bestelling',
                accessory_code: 'Artikelnummer',
                part_name: 'Deelnaam',
                term_of_trade: 'Handelsvoorwaarden',
                logistics_mode: 'Logistieke modus',
                type_of_fittings: 'Onderdeeltype',
                available_balance: 'Beschikbaar saldo',
                order_deadline: 'Besteldeadline',
                place_order: 'Indienen',
                order_information: 'Bestelinformatie',
                order_No: 'Bestelnummer',
                supplier: 'Leverancier',
                creation_date: 'Aanmaakdatum',
                type_of_shipping: 'Transportmodus',
                payment_method: 'Betalingsmethode',
                port: 'Bestemmingshaven',
                delivered_by: 'Feitelijke ontvanger adres',
                remarks: 'Notities',
                brand: 'Merk',
                currency: 'Valuta',
                number_accessories: 'Aantal accessoires',
                accessory_lines: 'Aantal accessoirelijnen',
                accessory: 'Artikelen',
                amount_accessories: 'Onderdeelbedrag',
                freight: 'Vracht (geschat)',
                premium: 'Verzekering',
                order_amount: 'Totaalbedrag',
                total_transportation: 'Totale volume (m³)',
                ordinary_goods: 'ETD van normale onderdelen',
                arrival_date: 'ETD van gevaarlijke onderdelen',
                accessories_information: 'Accessoires informatie',
                whole: 'Hele',
                routine: 'Routine',
                dangerous_goods: 'Gevaarlijke goederen',
                conventional_parts: 'Conventionele onderdelen',
                chemicals: 'Chemicaliën',
                oily_parts: 'Olieachtige onderdelen',
                commodity_inspection_parts: 'Commodity-inspectieonderdelen',
                payment: 'Betalingsvoucher nr. mag niet leeg zijn',
                incorrect: 'Onjuiste invoer gegevens',
                data_empty: 'Gegevens mogen niet leeg zijn',
                cannot_current_time: 'Mag niet groter zijn dan de huidige tijd',
                cannot_time: 'Mag niet kleiner zijn dan de huidige tijd',
                permits: 'Vergunningen',
                customized_parts: 'Aangepaste onderdelen',
                customized: 'Aangepast',
                accessories_selection: 'Accessoires selectie',
                forbidden_empty: 'Luchtverboden',
                add_cart: 'Toevoegen',
                order_number: 'Nr.',
                replacement: 'Vervanging',
                order_quantity: 'Bestelhoeveelheid',
                unit_price: 'Prijs',
                company: 'Eenheid',
                be_purchased: 'Aankoopbaar',
                quantity: 'Minimum bestelhoeveelheid',
                total_amount: 'Totaalbedrag',
                volumes: 'Volume (m³)',
                volume: 'Volume',
                enclosure: 'Bijlage',
                confirmed_quantity: 'Bevestigde hoeveelheid',
                courier: 'Vlucht/Ship-nummer/Koerier',
            },
            salesquery: {
                actual_delivery: 'Werkelijke leverdatum',
                estimated_delivery: 'Geschatte leverdatum',
                total_packages: 'Totale netto gewicht van verpakking',
                total_gross: 'Totale brutogewicht van verpakking',
                trade_mode: 'Handelswijze na transport',
                mode_before: 'Handelswijze voor transport',
                port_after_transshipment: 'Haven na overslag',
                port_diversion: 'Haven voor omleiding',
                outer: 'Pakket Hoogte',
                outer_box_size: 'Pakket Breedte',
                outer_box: 'Pakket Lengte',
                volume: 'Afmetingen(mm³)',
                gross_weights: 'Gewicht Bruto (KGS)',
                net_weight: 'Gewicht Netto (KGS)',
                shipment_quantity: 'Aantal verzendingen',
                ordering_parts_code: 'Oorspronkelijke bestelde onderdeelnummer',
                packing_code: 'Case nr.',
                container_no: 'Container nr.',
                sales_order_information: 'Verkooporderinformatie',
                packing_list_no: 'Packinglist nr.',
                accessory_code: 'Onderdeelnummer',
                order_no: 'Bestelnr.',
                merge_ci: 'Download samengevoegde CI&PL',
                export_ci: 'CI printen',
                export_pl: 'PL printen',
                currency: 'Valuta',
                parts_amount: 'Onderdeelbedrag',
                freight: 'Vracht',
                premium: 'Verzekering',
                other_expenses: 'Andere kosten',
                total_amoun: 'Totaalbedrag',
                creation_date: 'PL creatiedatum',
                ship_number: 'Schipnummer/vlucht/koerier',
                supplier: 'Leverancier',
                accessories: 'Artikelen',
                total_accessories: 'Aantal stuks',
                gross_weight: 'Gewicht Bruto (KGS)',
                total_weight: 'Gewicht Netto (KGS)',
                total_package: 'Totale Volume(m³)',
            },
            suborder: {
                approved: 'Goedgekeurd',
                packing_details: 'Verpakkingsdetails',
                order_detail: 'Besteldetails',
                arrive: 'Aangekomen',
                shipped: 'Verzonden',
                complete_packaging: 'Verpakkingsproces voltooid',
                passenger_order_number: 'Ordernummer voor personenauto',
                order_details: 'Sub-besteldetails',
                accessory: 'Accessoire',
                order_amount: 'Totaalbedrag',
                company: 'Bedrijf',
                name_accessories: 'Onderdeelnaam',
                accessory_code: 'Onderdeelnummer',
                serial_no: 'Serie No.',
                submit_smpv: 'SMPV indienen',
                sub_order_no: 'Sub-bestelnummer',
                sub_order_type: 'Sub-besteltype',
                brand: 'Merk',
                type_hipping: 'Type verzending',
                dt_code: 'DT-code',
                sub_order_amount: 'Sub-totaalbedrag',
                trade_terms: 'Handelsvoorwaarden',
                passenger_number: 'Ordernummer voor personenauto',
                dcs_order_number: 'DCS bestelnummer',
                remarks: 'Opmerkingen',
                submission_date: 'Datum van indiening',
                supplier: 'Leverancier',
                mode_of_trade: 'Handelswijze',
                port: 'Bestemmingshaven',
                to: 'Werkelijke ontvanger adres',
                currency: 'Valuta',
                cancel_date: 'Annuleringsdatum',
                accessory_information: 'Accessoire informatie',
            },
            query: {
                export_pi: 'Export PI',
                audit_status: 'Audit status',
                cancel: 'Annuleren',
                whether_submit: 'Of indienen',
                actual_arrival: 'Feitelijke aankomstdatum',
                estimated_arrival: 'Geschatte aankomstdatum',
                packaging_completion_date: 'Verpakkingsvoltooiingsdatum',
                order_no: 'Bestelnummer',
                order_status: 'Bestelstatus',
                rough_draft: 'Ruwe versie',
                funds_be_reviewed: 'Onvoldoende saldo',
                approved: 'Goedgekeurd',
                canceled: 'Geannuleerd',
                order_type: 'Besteltype',
                creation_date: 'Datum van creatie',
                accessory_code: 'Onderdeelnummer',
                mode_transport: 'Vervoerswijze',
                brand: 'Merk',
                export_order_details: 'Downloaden',
                dt_code: 'DT-code',
                order_amount: 'Bestelbedrag',
                term_of_trade: 'Handelsvoorwaarden',
                submission_date: 'Datum van indiening',
                DCS_order_number: 'DCS-bestelnummer',
                remarks: 'Opmerkingen',
                upload_credit: 'Uploaden brief van krediet',
                lc_no: 'L/C-nummer',
                term_validity: 'Geldigheidsduur',
                amount: 'L/C bedrag',
                picture: 'L/C afbeelding',
                empty: 'L/C-nummer kan niet leeg zijn',
                payment_registration: 'Betaling uploaden',
                payment_voucher_no: 'Betalingsnummer',
                payment_date: 'Betaaldatum',
                payment_amoun: 'Betaald bedrag',
                number_lines: 'Artikel',
                total_accessories: 'Aantal',
                estimated_premium: 'Geschatte verzekering',
                estimated_freight: 'Geschatte vracht',
                estimated_amount: 'Geschat totaalbedrag van de bestelling',
                order_tracking: 'Ordertracking',
                put_away: 'Inklappen',
                more: 'Meer',
                actually_delivered: 'Feitelijk geleverd onderdeelnummer',
                confirm_quantity: 'Bevestig hoeveelheid',
                package_quantity: 'Verpakkingshoeveelheid',
                shipment_quantity: 'Verzonden hoeveelheid',
                arrival_quantity: 'Aangekomen hoeveelheid',
                cancel_quantity: 'Geannuleerde hoeveelheid',
                bo_quantity: 'BO-hoeveelheid',
                order_submission: 'Indienen',
                warn: 'Opmerking: 1. Bij het overmaken van geld, markeer de betaling dan als reserveonderdelen of complete voertuigen;',
                warns: 'Het bedrag op de rekening is afhankelijk van het werkelijk ontvangen bedrag;',
                voucher: 'Voer het betalingsvoucher-nummer in',
                payment_dates: 'Voer de betaaldatum in',
                payment_amount: 'Voer het betaalbedrag in',
                credit_number: 'Voer het nummer van de brief van krediet in',
                validity_period: 'Voer de geldigheidsduur in',
                letter_credit: 'Voer het bedrag van de brief van krediet in',
                upload_picture: 'Upload een afbeelding van de brief van krediet',
                part_no: 'Onderdeelnummer',
                part_name: 'Naam van onderdeel',
                quantity: 'Bestelhoeveelheid',
                accessories: 'Details van accessoires',
                company: 'Verzendbedrijf',
                ships_name: 'Scheepsnaam',
                flights: 'De volgorde van reizen of vluchten',
                customs_no: 'Douane nummer',
                lading_no: 'Bill of Lading nr.',
                flight_number: 'Vluchtnummer',
                container_no: 'Container nr.',
                packing_list: 'Paklijst',
                packaging_quantity: 'Verpakkingshoeveelheid',
                packaging_completion: 'Datum verpakkingsvoltooiing',
                shipment_quantitys: 'Verzonden hoeveelheid',
                delivery_date: 'Geschatte leveringsdatum',
                shipment_dates: 'Feitelijke verzenddatum',
                arrival_quantitys: 'Aankomsthoeveelheid',
                arrival_date: 'Geschatte aankomstdatum',
                actual_arrival_date: 'Feitelijke aankomstdatum',
            },

            salesManagement: {
                additional_fee_amount: 'Bedrag van extra kosten',
                before_modifications: 'Vóór wijzigingen',
                after_modification: 'Na wijziging',
                continue_importing: 'Importeren blijven',
                quantity_difference: 'Hoeveelheidsverschil',
                customs_declaration_name: 'Naam van douaneaangifte',
                customs_declaration_not: 'Douaneaangifte of niet',
                invoice_no: 'Factuur nr.',
                contract_no: 'Contractnr.',
                attachment_fee_amount: 'Bedrag van bijlagekosten',
                expense_amount: 'Bedrag van kosten',
                import_packing: 'Importeer MAXUS-verpakkingslijst',
                import_list: 'Importeer MG-verpakkingslijst',
                sales_order_list: 'Verkooporderlijst',
                job_no_list: 'Job-nr. lijst',
                dt_code: 'DT-code',
                brand: 'Merk',
                packing_list_no: 'Verpakkingslijst nr.',
                packing_list_nos: 'Verpakkingslijst nr.:',
                bilateral_contract_no: 'Bilateraal contractnr.',
                contract_status: 'Contractstatus',
                inbound_delivery: 'Inkomende leveringsorder nr.',
                supplier: 'Leverancier',
                list_creation: 'Datum van verpakkingslijstcreatie',
                currency: 'valuta',
                transit_status: 'Doorvoerstatus',
                split_merge: 'Split/Merge',
                sap_return_information: 'SAP-terugkeerinformatie',
                send_booking: 'Of boekingsmail moet worden verzonden',
                lcl_or_not: 'LCL of niet',
                operate: 'Bedienen',
                modify_freight: 'Vracht wijzigen',
                modify_lc: 'LC wijzigen',
                detailed: 'Gedetailleerd',
                merge: 'Samenvoegen',
                split: 'Splitsen',
                diversion: 'Transport omleiding',
                lcl: 'Lcl',
                charges: 'Extra kosten',
                generation: 'One-click document generatie',
                deletion: 'One-click document verwijdering',
                bind_lc: 'LC binden',
                unbind_lc: 'LC ontkoppelen',
                merge_ci: 'Gecombineerde CI&PL downloaden',
                export_ci: 'CI exporteren',
                export_pl: 'PL exporteren',
                additional_charges: 'Extra kosten:',
                packing: 'Verpakkingslijst nr.:',
                expenses: 'transportkosten:',
                delivery: 'Uitgaande leveringsopdracht nr.:',
                binding_record: 'Wijziging LC-bindingsrecord',
                trade_terms: 'Handelsvoorwaarden:',
                logistics_mode: 'Logistieke modus:',
                port: 'Haven:',
                payment_method: 'Betalingsmethode:',
                please_select: 'Selecteer handelsvoorwaarden',
                please_logistics_mode: 'Selecteer de logistieke methode',
                please_port: 'Selecteer een haven',
                please_payment_method: 'Selecteer betalingsmethode',
                select_export_type: 'Selecteer exporttype',
                plase_select_export_type: 'Selecteer het exporttype',
                lc_code: 'LC-nr.',
                sap_return_status: 'SAP retourstatus',
                sap_voucher_code: 'SAP vouchercode',
                amount_difference: 'Bedragverschil',
                invoice_date: 'Factuurdatum',
                invoice_import_date: 'Factuurimportdatum',
                unbinding: 'Ontbinding',
                no_unbinding_lc: 'Geen LC om te ontbinden',
                sub_order_no: 'Suborder nr.',
                submit_sap: 'Of het naar SAP moet worden verzonden',
                case_no: 'Zaak nr.:',
                binding: 'Bindend',
                outbound_delivery: 'Uitgaande levering',
                document_type: 'Documenttype',
                accessory_code: 'Onderdeelnr.',
                accessory_name: 'Onderdeelnaam',
                sales_order_information: 'Verkooporderinformatie',
                total_lines_of_accessories: 'Totaal aantal regels van accessoires:',
                total_number_of_accessories: 'Totaal aantal accessoires:',
                total_amount: 'Totaalbedrag:',
                amount_of_accessories: 'Bedrag van accessoires:',
                premium: 'Premie:',
                volume: 'Totale pakketvolume (m³):',
                total_gross_weight: 'Totale bruto gewicht van pakket:',
                weight_of_package: 'Totale netto gewicht van pakket:',
                container_no: 'Container nr.',
                actually_delivered: 'Daadwerkelijk geleverd onderdeelnr.',
                order_parts_code: 'Originele orderonderdelennr.',
                shipment_quantity: 'Verzendingshoeveelheid',
                proforma_invoice: 'Pro-forma factuurnr.',
                customs_date: 'Douaneaangiftedatum',
                customs_no: 'Douaneaangifte nr.',
                net_weight: 'Nettogewicht (kg)',
                rough_weight: 'Bruto gewicht (kg)',
                volumes: 'Volume (mm³)',
                outer_bo_size: 'Buitenafmeting doos (lengte mm)',
                box_size: 'Buitenafmeting doos (breedte mm)',
                box_height: 'Buitenafmeting doos (hoogte mm)',
                modification_date: 'Wijzigingsdatum',
                modification_by: 'Gewijzigd door',
                before_modification: 'Vracht vóór wijziging',
                modified_freight: 'Gewijzigde vracht',
                sales_slip: 'Verkoopbon',
                modification_record: 'Vrachtwijzigingsrecord',
            },
            price: {
                DNNumber: 'DN-nummer',
                deliveryDate: 'Leveringsdatum',
                purchasePrice: 'Inkoopprijs',
                priceForDelivery: 'Eenheidsprijs voor levering van personenauto\'s',
            },
        },
        document: {
            document: 'Document',
            name: 'Naam',
            sort: 'Sorteren',
            directory: 'Directory',
            parent_directory: 'Ouder directory',
            new_directory: 'Nieuwe directory',
            edit_directory: 'Bewerk directory',
            primary_directory: 'Primaire directory',
            document_title: 'Document titel',
            vehicle_platform: 'Voertuig platform',
            status: 'Status',
            release_status: 'Publicatie status',
            create_time: 'Aanmaaktijd',
            secondary_directory: 'Secundaire directory',
            model: 'Model',
            attachment_name: 'Bestandsnaam',
            publishing_department: 'Publicatie afdeling',
            publisher: 'Uitgever',
            person_in_charge: 'Verantwoordelijke persoon',
            remarks: 'Opmerkingen',
            publish_objects: 'Publiceer naar',
            publish_mode: 'Publiceer modus',
            whether_the_document_is_downloadable: 'Of het document downloadbaar is',
            document_information: 'Document informatie',
            document_tag: 'Document tag',
            operation_record: 'Bedieningsrecord',
            operation_details: 'Bedieningsdetails',
            operating_time: 'Bedieningstijd',
            operator: 'Operator',
            to_be_released: 'Wordt vrijgegeven',
            published: 'Vrijgegeven',
            voided: 'Ongeldig',
        },
        announcement: {
            title: 'Titel',
            publishing_department: 'Uitgevende afdeling',
            status: 'Status',
            release_Date: 'Uitgiftedatum',
            serialNo: 'Nr.',
            publisher: 'Uitgever',
            file_Name: 'Bestandsnaam',
            details: 'Details',
            void: 'Ongeldig',
            information_detail: 'Informatiedetails',
            notification_Title: 'Meldingstitel',
            publish_Objects: 'Uitgeven aan',
            publish_Mode: 'Uitgiftetype',
            bulletin_id: 'Bulletin ID',
            publish_Content: 'Uitgeefinhoud',
            preview: 'Voorbeeld',
            attachmentUpload: 'Bijlage uploaden',
            supportedFormats: 'Ondersteunde bestandsformaten',
            have: 'Hebben',
            NoticeWaitingToBeRead: 'Kennisgevingen die wachten om te worden gelezen',
            released: 'Uitgegeven',
            voided: 'Ongeldig verklaard',
        },
        basic: {
            accessories: {
                masterdata: {
                    english_smpv: 'Engelse naam van SMPV',
                    accessory_code: 'Artikelnummer',
                    accessory_name: 'Onderdeelnaam',
                    accessory_name_zh: 'Chinese naam van accessoires',
                    accessory_name_en: 'Engelse naam van accessoires',
                    accessory_enm: 'Engelse beschrijving van accessoires',
                    brand: 'Merk',
                    sales_area: 'Verkoopgebied',
                    create_data: 'Creatie datum',
                    is_purchase: 'Is aankoop',
                    is_transportations: 'Transport',
                    purchase_attribute: 'Aankoop eigenschap',
                    is_marketable: 'Is verkoopbaar',
                    supplier_code: 'Leverancierscode',
                    supplier_name: 'Leveranciersnaam',
                    country_origin: 'Land van herkomst',
                    is_transportation: 'Is transport',
                    magnetic_inspection: 'Magnetische inspectie',
                    specialProcurementCycle: 'Speciale inkoopcyclus',
                    material: 'Materiaal',
                    is_max_overbooking: 'Beheer de maximale bestelhoeveelheid',
                    max_overbooking: 'Maximale bestelhoeveelheid toegestaan',
                    sap_code: 'SAP-code',
                    unit: 'Accessoire eenheid',
                    accessories_edit: 'Accessoires bewerken',
                    accessories_add: 'Nieuwe accessoires',
                    accessories_detailed: 'Details van accessoires',
                    special_procurement_cycle: 'Speciale inkoopcyclus',
                    accessory_unit: 'Accessoire eenheid',
                    name_of_customs_declaration_materials: 'Naam van douaneaangifte materialen',
                    customized_form: 'Aangepast formulier',
                    customs_declaration_unit: 'Douaneaangifte eenheid',
                    remarks: 'Opmerkingen',
                    customs_code: 'Douanecode',
                    license_material_name: 'Licentie Materiaalnaam',
                    create_by: 'Gemaakt door',
                    company: 'Bedrijfsnaam',
                    declare_ele: 'Specificaties',
                    base_cal_unit: 'Bedrijf',
                    base_cal_unit_cn: 'Chinese eenheid',
                    update_by: 'Aangepast door',
                    update_time: 'Aanpassingstijd',
                    accessories_picture: 'Accessoires afbeelding',
                    custom_accessories: 'Aangepaste accessoires',
                    declaration_elements: 'Aangifte-elementen',
                    transportation: 'Geen luchtvervoer',
                    accessories_selection: 'Accessoires selectie',
                    enable_or_not: 'Inschakelen of niet',
                    supplier_selection: 'Leveranciersselectie',
                    whether_glass: 'Of er glas is',
                },
                orderInvoicing: {
                    partCode: 'Artikelcode',
                    brand: 'Merk',
                    baseQuantity: 'Basis hoeveelheid',
                    baseUnit: 'Basis eenheid',
                    transferQuantity: 'Omzetting hoeveelheid',
                    transferUnit: 'Omzetting eenheid',
                    createBy: 'Gemaakt door',
                    createTime: 'Aanmaaktijd',
                    orderInvoicing_new: 'Nieuw toegevoegd',
                    orderInvoicing_edit: 'Bewerken',
                },
                billMaterials: {
                    dt_name: 'DT',
                    part_code: 'Onderdeelcode',
                    part_name_cn: 'Chinese naam van accessoires',
                    part_name_en: 'Engelse naam van accessoires',
                    apply_time: 'Datum van aanvraag',
                    brand: 'Merk',
                },
                replace: {
                    group_no: 'Groepsnummer',
                    item_no: 'Item nummer',
                    leading_products: 'Leidende producten',
                    description_of_leading_products: 'Beschrijving van leidende producten',
                    subsequent_products: 'Vervolgproducten',
                    subsequent_product_description: 'Beschrijving van vervolgproducten',
                    substitutive_relationship: 'Vervangingsrelatie',
                    exhaustion_strategy: 'Strategie voor uitputting',
                    depletion_date: 'Uitputtingsdatum',
                    effective_date: 'Ingangsdatum',
                    creation_date: 'Aanmaakdatum',
                    newly_added: 'Nieuw toegevoegd',
                    newly_edit: 'Bewerken',
                },
                mxreplace: {
                    group_no: 'Group nr',
                    project_no: 'Project nr',
                    part_code: 'Accessoire code',
                    part_name_cn: 'Chinese naam van accessoires',
                    new_part_code: 'Alternatieve accessoirecode',
                    new_part_name_cn: 'Chinese naam van vervangende selectie',
                    effective_time: 'Effectieve datum',
                    stock_deal: 'Advies over voorraadafhandeling',
                    create_time: 'Creatiedatum',
                    replace_relate: 'Vervangingsrelatie',
                    brand: 'Merk',
                    replace_remarks: 'Vervangingsopmerkingen',
                    newly_edit: 'Bewerken', // bewerken
                    part_codes: 'Accessoirecode',
                    new_part_codes: 'Vervangingsonderdeelcode',
                    new_part_name_cns: 'Chinese naam van vervangingsonderdelen',
                    stock_deals: 'Voorraadafhandeling suggesties',
                },
                sap: {
                    sap_part_id: 'Primary key', // 主键
                    part_id: 'Part id', // 零件id
                    brand: 'Merk', // 项目编号
                    part_code: 'Oud materiaal nr.', // 零件代码
                    material_type: 'ZTG2/SAP materiaalsoort', // ZTG2/SAP物料类型
                    customer_declare_material_name: 'Naam van douanedeclaratiematerialen', // 报关物料名称
                    material_name_en: 'Engelse materiaalnaam', // 英文物料名称
                    // brand: string; // 品牌
                    hs_code: 'HS-code', // HS编码
                    customer_declare_licence: 'Naam van vergunningsmateriaal', // 许可证物料名称
                    base_cal_unit: 'BUoM', // 基本计量单位
                    sap_code: 'SAP materiaalnummer', // SAP物料号
                    sap_codes: 'Sap-materiaalsoort', // SAP物料号
                    gross_weight: 'Bruto gewicht', // 毛重
                    declare_ele: 'Declaratie-elementen', // 申报要素
                    net_weight: 'Nettogewicht', // 净重
                    volume: 'Volume (mm³)', // 体积
                    specs: 'Specificaties', // 规格
                    danger_attr: 'Productattribuut 2 (gevaarlijke stoffen)', // 产品属性2(危险品)
                    abroad_danger_attr:
                        'Productattribuut 3 (identificatie van buitenlandse gevaarlijke stoffen)', // 产品属性3(国外危险品标识)
                    status: 'Creatiestatus', // 状态0-草稿，1-进行中，2-已完成
                    create_by: 'Gemaakt door', // 创建人
                    create_time: 'Aanmaaktijd',
                    update_by: 'Gewijzigd door', // 创建人
                    update_time: 'Wijzigingstijd',
                    sap_edit: 'Bewerk',
                    sap_add: 'Nieuw toegevoegd',
                    error_reasons: 'Sap-materiaalsoort',
                    sap_status: 'Sap retourstatus',
                    part_name_cn: 'Chinese materiaalbeschrijving',
                    sale_tax_class: 'Verkoopbelastingclassificatie',
                    error_reason: 'Retourinformatie',
                },
                coo: {
                    country_id: 'Land',
                    brand: 'Merk',
                    part_code: 'Toegangscode',
                    part_name_cn: 'Naam van accessoires',
                    origin_country: 'Land van herkomst',
                    create_by: 'Gemaakt door',
                    create_time: 'Aangemaakt op',
                    coo_add: 'Nieuw toegevoegd',
                    coo_edit: 'Bewerken',
                    accessories_selection: 'Accessoireselectie',
                },
                sapModify: {
                    gds_material_number: 'GDS materiaalnummer',
                    sap_material_number: 'SAP materiaalnummer',
                    chinese_name_of_material_zh: 'Chinese naam van materiaal ZH',
                    english_name_of_material_en: 'Engelse naam van materiaal EN',
                    material_declaration_name_z1: 'Materiaal declaratienaam Z1',
                    declaration_elements: 'Declaratie-elementen',
                    classification_proposal_no: 'Classificatie voorstel nummer',
                    period_of_validity_of_classification: 'Geldigheidsduur van classificatie',
                    tax_classification_code: 'Belastingclassificatiecode',
                    factory: 'Fabriek',
                    status: 'Status',
                    sap_status: 'SAP retourstatus',
                    commodity_import_code_number: 'Commodity / importcode nummer',
                    sap_modify_edit: 'Bewerken',
                    sap_modify_add: 'Nieuw toegevoegd',
                },
            },
            sales: {
                weight_ladder: 'Gewichtsladder',
                country: 'Land',
                port: 'Haven',
                portCode: 'HavenCode',
                portCN: 'ChineseName',
                portUS: 'EnglishName',
                EffectiveDate: 'Effectieve datum',
                ExpirationDate: 'Vervaldatum',
                oldEffectiveTime: 'Oude effectieve tijd',
                oldExpireTime: 'Oude vervaltijd',
                newEffectiveTime: 'Nieuwe effectieve tijd',
                newExpireTime: 'Nieuwe vervaltijd',
                freight: 'Vracht',
                KG: 'KG',
                KGabove: 'Boven KG',
                express: 'Express gebied',
                general: 'Algemeen',
                danger: 'Gevaar',
                GP20: '20GP eenheidsprijs (USD)',
                GP40: '40GP eenheidsprijs (USD)',
                hq40: '40HQ eenheidsprijs (USD)',
                region: 'Express gebied',
                currencyCode: 'Valutacode',
                description: 'Beschrijving',
                MinimumUnit: 'Minimale eenheid',
                ChineseDescription: 'Chinese beschrijving',
                symbol: 'Symbool',
                updated_date: 'Laatst bijgewerkte datum',
                price_benchmark: 'Prijs benchmark',
                part_description: 'Onderdeel beschrijving',
                excluding_tax: 'Aankoopprijs exclusief belasting',
                tax: 'Aankoopprijs inclusief belasting',
                price_including_tax: 'Binnenlandse verkoopprijs inclusief belasting',
                seller: 'Verkoper',
                selling_party: 'Verkopende partij',
                price: 'Prijs',
                sales_ratio: 'Verkoopverhouding(%)',
                cifShare: {
                    trade_clause: 'Handelsclausule',
                    order_type: 'Ordertype',
                    type_of_shipping: 'Type verzending',
                    dt: 'DT',
                    last_modified_date: 'Laatst gewijzigde datum',
                    creation_date: 'Creatie datum',
                    sales_price_proportion: 'Verkoopprijs proportie（%）',
                    seller: 'Verkoper',
                    dt_choose: 'DT kiezen',
                    dt_code: 'DT code',
                    dt_name: 'DT naam',
                    country: 'Land',
                },
                cifSingle: {
                    trade_clause: 'Handelsclausule',
                    accessory_type: 'Accessoiretype',
                    type_of_shipping: 'Type van verzending',
                    dt: 'DT',
                    freight_coefficient: 'Vrachtcijfer（%）',
                    premium_coefficient: 'Premiecoëfficiënt（%）',
                    air_freight0: 'Luchtvracht（0-100KG）',
                    air_freight100: 'Luchtvracht（100-500KG）',
                    air_freight500: 'Luchtvracht（500KG+）',
                    minimum_air_freight_charge: 'Minimale luchtvrachtkosten',
                    dangerous_goods_freight: 'Gevaarlijke goederen vracht',
                    dangerous_goods_minimum_freight: 'Minimum vracht voor gevaarlijke goederen',
                    dangerous_goods_ITEM_freight: 'Vracht voor gevaarlijke goederen ITEM',
                    dangerous_goods_qty_freight: 'Vracht voor gevaarlijke goederen hoeveelheid',
                    dt_choose: 'DT kiezen',
                    dt_code: 'DT code',
                    dt_name: 'DT naam',
                },
                triesLimit: {
                    dt: 'DT',
                    frequency_limit: 'Frequentielimiet',
                    price_ratio: 'Prijsratio（%）',
                    seller: 'Verkoper',
                    brand: 'Merk',
                    dt_choose: 'DT kiezen',
                    dt_code: 'DT-code',
                    dt_name: 'DT-naam',
                },
                dcs: {
                    partial_transmission: 'Gedeeltelijke overdracht',
                    full_transmission: 'volledige overdracht',
                    download_template: 'Sjabloon downloaden',
                    upload_Excel: 'Excel uploaden',
                    upload_files: 'Sestanden uploaden',
                    no_files_selected: 'Geen bestanden geselecteerd',
                    General_generation: 'Algemene generatie',
                    DCS_price_delivery_price: 'DCS prijs levering (FOB) prijs',
                },
            },

            sort: {
                maxmaterial: {
                    category_code: 'Materiaalcategorie',
                    // category_codes: '定价大类',
                    category_desc: 'Beschrijving',
                    maxmaterial_add: 'Nieuw toevoegen',
                    maxmaterial_edit: 'Bewerken',
                },
                minmaterial: {
                    no_air: 'Geen luchtvervoer',
                    category_code: 'Materiaalcategoriecode',
                    category_desc: 'Beschrijving van materiaalcategorie',
                    big_material_category_id: 'Materiaalcategorie',
                    pack_alone: 'Of het apart verpakt is',
                    minmaterial_add: 'Toevoegen',
                    minmaterial_edit: 'Bewerk',
                    material_category_selection: 'Selectie van materiaalcategorie',
                },
                model: {
                    type_code: 'Typecode',
                    brand: 'Merk',
                    parts_code: 'Onderdelen code',
                    parts_name: 'Onderdelen naam',
                    save_add: 'Opslaan en nieuw',
                },
                code: {
                    type_code: 'Type code',
                    model_grade: 'Model grade',
                    save_add: 'Opslaan en nieuw',
                },
                marialMaint: {
                    small_material_category_id: 'Materiaalcategoriecode', // 物料小类代码
                    brand: 'Merk',
                    create_time: 'Aanmaaktijd',
                    create_by: 'Aangemaakt door',
                    part_id: 'Accessoirecode', // 配件代码
                    marial_maint_add: 'Nieuw toevoegen',
                    marial_maint_edit: 'Bewerken',
                },
            },
            packing: {
                mpq: {
                    transport: 'Mode of transport',
                    category_code: 'Small class code',
                },
                information: {
                    transport_package_No: 'Transportpakketnummer',
                    name: 'Naam',
                    classification: 'Classificatie',
                    purpose: 'Doel',
                    length: 'Lengte(mm)',
                    width: 'Breedte(mm)',
                    height: 'Hoogte(mm)',
                    volume: 'Volume(CBM)',
                    specification_requirements: 'Specificatie vereisten',
                    brand: 'Merk',
                    creation_date: 'Creatie datum',
                    created_by: 'Aangemaakt door',
                    purchasing_unit: 'Inkoop eenheid',
                },
                volume: {
                    volume_factor: 'Volume factor',
                    shipping_type: 'Verzendtype',
                    brand: 'Merk',
                    creation_date: 'Creatiedatum',
                    created_by: 'Aangemaakt door',
                },
                cmaintain: {
                    packing_type_code: 'Verpakkingscode',
                    english_description: 'Engelse beschrijving',
                },
                sap: {
                    pack_material_code: 'Verpakkingsmateriaal nr.',
                    chinese_desc: 'Chinese naam',
                    english_desc: 'Engelse naam',
                    sap_add: 'Toevoegen',
                    sap_edit: 'Bewerken',
                },
                minmaterial: {
                    small_material_category_code: 'Materiaalcode',
                    small_material_category_desc: 'Itembeschrijving',
                    special_compute: 'Speciale berekening of niet',
                    create_time: 'Gemaakt op',
                    create_by: 'Gemaakt door',
                    small_code: 'Materiaalcategorie',
                    minmaterial_add: 'Toevoegen',
                    minmaterial_edit: 'Bewerken',
                },
                maintain: {
                    package_volume: 'Pakketvolume（mm³）',
                    part_code: 'Onderdeelcode',
                    brand: 'Merk',
                    minimum_number_of_packages: 'Minimaal aantal verpakkingen',
                    long: 'Lengte',
                    width: 'Breedte',
                    height: 'Hoogte',
                    gross_weight: 'Brutogewicht',
                    net_weight: 'Nettogewicht',
                    creation_date: 'Creatiedatum',
                    create_by: 'Aangemaakt door',
                    revision_date: 'Revisiedatum',
                    revision_by: 'Gereviseerd door',
                    package_type: 'Verpakkingstype',
                    unit_of_length: 'Lengte-eenheid',
                    unit_of_weight: 'Gewichtseenheid',
                    package_plan: 'Verpakkingsplan',
                },
                dangerInfo: {
                    accessory_code: 'Accessoirecode',
                    nam_accessories: 'Engelse naam van accessoire',
                    accessories_cn: 'Chinese naam van accessoire',
                    brand: 'Merk',
                    bonfire_experiment: 'Bonfire-experimentnummer',
                    bonfire_experiment_cn: 'Chinese naam van bonfire-experiment',
                    bonfire_experiment_en: 'Engelse naam van bonfire-experiment',
                    dangerous_goods_supplier: 'Leverancier van gevaarlijke goederen',
                    length: 'Lengte (mm)',
                    width: 'Breedte (mm)',
                    height: 'Hoogte (mm)',
                    packing_type: 'Verpakkingstype',
                },
            },
            customs: {
                hscode: {
                    hs_code: 'Douanecode',
                    hscode_unit: 'L/KG',
                    is_inspect: 'Commodity inspectie of niet',
                    tax_refund_rate: 'Teruggaafpercentage',
                    supervise_condition: 'Toezichtsvoorwaarden',
                    company: 'Bedrijf',
                    country_id: 'Land',
                    create_time: 'Aanmaaktijd',
                    hscode_add: 'Nieuw toegevoegd',
                    hscode_edit: 'Bewerken',
                },
                export: {
                    brand: 'Merk',
                    part_code: 'Toebehoren code',
                    conutry_code: 'Landcode',
                    hscode: 'Douanecode',
                    form: 'FORM-E',
                    tariff: 'TARIEF',
                },
                fluid: {
                    brand: 'Merk',
                    part_id: 'Toebehoren code',
                    part_id_name: 'Naam van toebehoren',
                    fluid_add: 'Nieuw toegevoegd',
                },
                danger: {
                    company: 'Bedrijf', // 公司
                    ship_type: 'Type van verzending', // 运输方式
                    part_code: 'Accessoirecode', // 配件代码
                    brand: 'Merk',
                    danger_type: 'Type gevaarlijke goederen', // 危险品类型
                    danger_category: 'Classificatie van gevaarlijke goederen', // 危险品分类
                    chinese_part_name: 'Chinese naam van accessoires',
                    english_part_name: 'Engelse naam van accessoires',
                    danger_add: 'Nieuw toegevoegd',
                    danger_edit: 'Bewerken',
                },
                choren: {
                    chinese_unit: 'Chinese eenheid',
                    english_unit: 'Engelse eenheid',
                    create_by: 'Gemaakt door',
                    create_time: 'Creatietijd',
                    choren_add: 'Nieuw toegevoegd',
                    choren_edit: 'Bewerken',
                },
                maintain: {
                    dt: 'DT',
                    material_no: 'Materiaalnummer',
                    brand: 'Merk',
                    customs_code: 'Douanecode',
                    form_e_mark: 'Formulier E-markering',
                    created_on: 'Gemaakt op',
                    created_by: 'Gemaakt door',
                    maintain_add: 'Nieuw toegevoegd',
                    maintain_edit: 'Bewerken',
                },
                chemicali: {
                    company: 'Bedrijf',
                    material_no: 'Materiaalnummer',
                    brand: 'Merk',
                    license_or_not: 'Vergunning of niet',
                    chemicali_add: 'Nieuw toegevoegd',
                    chemicali_edit: 'Bewerken',
                },
            },
            salesl: {
                fixedRate: {
                    local_currency: 'Lokale valuta',
                    name_of_local_currency: 'Naam van lokale valuta',
                    seller: 'Verkoper',
                    fixed_rate: 'Vaste koers',
                    settlement_currency: 'Afwikkelingsvaluta',
                    name_of_settlemen_currency: 'Naam van afwikkelingsvaluta',
                    dt: 'DT',
                    brand: 'Merk',
                    effective_date: 'Ingangsdatum',
                    expiration_date: 'Vervaldatum',
                    created_on: 'Aangemaakt op',
                    fixedRate_add: 'Nieuw toegevoegd',
                    fixedRate_edit: 'Bewerken',
                    fixedRate_select: 'Na het selecteren van DT mag het merk niet leeg zijn',
                },
                transport: {
                    dt: 'DT',
                    brand: 'Merk',
                    trade_terms: 'Handelsvoorwaarden',
                    type_of_shipping: 'Vervoerswijze',
                    transport_add: 'Nieuw toegevoegd',
                    created_on: 'Aangemaakt op',
                    transport_edit: 'Bewerken',
                },
                purchaseConfirm: {
                    accessory_code: 'Accessoirecode',
                    effective_date: 'Ingangsdatum',
                    expiration_date: 'Vervaldatum',
                    accessory_description: 'Accessoire beschrijving',
                    purchase_price: 'Aankoopprijs exclusief belasting',
                    tax_rate: 'Belastingtarief',
                    currency: 'Valuta',
                    corporation: 'Bedrijf',
                    created_on: 'Aangemaakt op',
                },
            },
            sapModify: {
                gds_material_number: 'GDS-materiaalnummer',
                sap_material_number: 'SAP-materiaalnummer',
                chinese_name_of_material_zh: 'Chinese naam van materiaal ZH',
                english_name_of_material_en: 'Engelse naam van materiaal EN',
                material_declaration_name_z1: 'Naam van materiaalverklaring Z1',
                declaration_elements: 'Declaratie-elementen',
                classification_proposal_no: 'Classificatie voorstel nummer',
                period_of_validity_of_classification: 'Geldigheidsperiode van classificatie',
                tax_classification_code: 'Belasting classificatiecode',
                factory: 'Fabriek',
                commodity_import_code_number: 'Goederen / importcode-nummer',
                sapModify_edit: 'Bewerk',
                sapModify_add: 'Nieuw toegevoegd',
            },

            customer: {
                fundPool: {
                    dt_code: 'DT-code',
                    dt_name: 'DT-naam',
                    dt_short_name: 'DT-korte naam',
                    currency: 'Valuta',
                    account_amount: 'Rekeningbedrag',
                    freeze_amount: 'Bevroren bedrag',
                    date: 'Datum',
                    fund_status: 'Dondsstatus',
                    fund_type: 'Fondstype',
                    payment_number: 'Betalingsnummer',
                    credit_number: 'Creditnummer',
                    suborder_number: 'Subordernummer',
                    container_number: 'Containernummer',
                    credit_validity_date: 'Credit geldigheidsdatum',
                    recorded: 'Geregistreerd / uitbetaling',
                    disbursement_money: 'Uitbetalingsbedrag',
                    wait_recorded_money: 'Wacht op registratiebedrag',
                    recorded_money: 'Geregistreerd bedrag',
                    service_charge: 'Servicekosten',
                    account_balance: 'Saldo',
                    freeze_balance: 'Bevroren saldo',
                    credit_picture: 'Credit afbeelding',
                    upload_list: 'Uploadlijst',
                    remark: 'Opmerking',
                    dt_payment_confirm: 'DT-betalingsbevestiging',
                    credit_confirm: 'Credit bevestiging',
                    credit_money: 'Credit bedrag',
                    arrival_amount: 'Aankomstbedrag',
                    dt_selection: 'DT-selectie',
                },
                customer: {
                    dt_code: 'DT-code',
                    dt_name: 'DT-naam',
                    dt_short_name: 'DT korte naam',
                    country: 'Land',
                    sap_code: 'Sap-code',
                    brand: 'Merk',
                    beneficiary_name: 'Begunstigde naam',
                    beneficiary_address: 'Begunstigde adres',
                    VAT: 'BTW（%）',
                    Name_of_bank_of_deposit: 'Naam van bank van storting',
                    VAT_number: 'BTW-nummer',
                    bank_address: 'Bank adres',
                    SWIFT_CODE: 'SWIFT CODE',
                    bank_account: 'Bankrekening',
                    Agent_bank_address: 'Adres agentenbank',
                    Agent_bank_account: 'Agentenbankrekening',
                    Agent_bank_name: 'Agentenbanknaam',
                    IBAN: 'IBAN',
                    Agent_bank_iban: 'Agentenbank IBAN',
                    Agent_bank_SWIFT_Code: 'Agentenbank SWIFT-code',
                    Bank: 'BANK',
                    inherit_general_generation: 'Overerven algemene generatie',
                    money: 'Geld',
                    A_24_hour_hotline: 'Een 24-uurs hotline',
                    facsimile: 'Fax',
                    telephone: 'Telefoon',
                    local_country: 'Lokale land',
                    local_city: 'Lokale stad',
                    local_area: 'Lokale gebied',
                    Postal_code: 'Postcode',
                    email_address: 'E-mailadres',
                    Affiliated_brand: 'Gelieerd merk',
                    Company_homepage: 'Bedrijfswebsite',
                    Discount_rate_for_parts_sales: 'Kortingspercentage voor onderdelverkoop',
                    Company_address: 'Bedrijfsadres',
                    Method_of_settlement: 'Betalingswijze',
                    Sales_territory: 'Verkoopgebied',
                    Express_freight_area: 'Express vrachtgebied',
                    Selling_party: 'Verkopende partij',
                    Address_of_the_selling_party: 'Adres van de verkopende partij',
                    Loan_agency_information: 'Leenbureau informatie',
                    Payment_terms: 'Betalingsvoorwaarden',
                    Account_period: 'Accountperiode',
                    supplier: 'Leverancier',
                    Shipping_address: 'Verzendadres',
                    Contact_name: 'Contactnaam',
                    File_delivery_address: 'Bestand bezorgadres',
                    Air_address: 'Luchtadres',
                    Express_address: 'Expressadres',
                    organization: 'Organisatie',
                    Trade_clause1: 'Handelsclausule 1',
                    Trade_clause2: 'Handelsclausule 2',
                    beneficiary: 'Begunstigde',
                    fmc: 'FMC',
                    Swift_Code: 'Swift-code',
                    USD_Account: 'USD-rekening',
                    EUR_Account: 'EUR-rekening',
                    RMB_Account: 'RMB-rekening',
                    Tax_number: 'Belastingnummer',
                    Order_clerk: 'Orderbediende',
                    Method_of_payment: 'Betalingsmethode',
                    Market_type: 'Markttype',
                    MX_business_model: 'MX-bedrijfsmodel',
                    label: 'Label',
                    Air_freight_rate: 'Luchtvrachttarief（%）',
                    Ocean_freight_rate: 'Zeevrachttarief（%）',
                    Express_rate: 'Expresstarief（%）',
                    Document_number_prefix: 'Documentnummer voorvoegsel',
                    TT_settlement_term: 'TT-betalingsvoorwaarde',
                    LC_settlement_term: 'LC betalingstermijn',
                    name: 'Naam',
                    Contact_person: 'Contactpersoon',
                    Contact_number: 'Contactnummer',
                    address: 'Adres',
                    city: 'Stad',
                    Zip_code: 'Postcode',
                    sap_consumer_query: 'Sap consumentenquery',
                    customer_code: 'Klantcode',
                    customer_name: 'Klantnaam',
                    sales_organization: 'Verkooporganisatie',
                    abbreviation: 'Afkorting',
                    organization_name: 'Organisatienaam',
                    customer_information: 'Klantinformatie',
                    delivered_by: 'Geleverd door',
                },
            },
        },
        components: {
            businessComponents: {
                documentType: 'Document type',
                vehicleType: 'Vehicle type',
                dealerInformation: 'Dealer informatie',
                reportType: 'Rapporttype',
                dealerContact: 'Dealer contact',
                contact: 'Contact #',
                occupation: 'Beroep',
                vehicleInformation: 'Voertuiginformatie',
                mileage: 'Kilometerstand (/km)',
                licensePlate: 'Kenteken #',
                platform: 'Platform',
                offLineDate: 'Offline datum',
                vehicleUse: 'Voertuiggebruik',
                refited: 'Gerenoveerd',
                nachgefüllt: 'Nachgefüllt',
                nameplateNo: 'Typegoedkeuringnummer',
                purchaseDate: 'Aankoopdatum',
                accidentInformation: 'Ongevalsinformatie',
                timeOfAccident: 'Tijdstip van ongeval',
                placeOfAccident: 'Plaats van ongeval',
                roadConditions: 'Wegomstandigheden',
                pavementType: 'Wegdektype',
                weather: 'Weer',
                vehicleCondition: 'Voertuigconditie',
                vehicleOfTheAccident: 'Voertuiginformatie op het moment van het ongeval',
                vehicleCollisionPosition: 'Positie van de voertuigbotsing',
                vehicleRunningStatus: 'Voertuigrijstatus',
                ownSpeed: 'Eigen snelheid',
                vehicleSpeedOfTheOtherSide: 'Voertuigsnelheid van de andere kant',
                personnelInsideTheVehicle: 'Personeel in het voertuig',
                numberOfPeopleInTheCar: 'Aantal personen in de auto',
                personalInjury: 'Persoonlijk letsel',
                distributionOfPersonnelInTheVehicle: 'Verdeling van personeel in het voertuig',
                descriptionOfPersonalInjury: 'Beschrijving van persoonlijk letsel',
                airbagDeployment: 'Airbag-ontplooiing',
                safetyBeltWearing: 'Veiligheidsgordel dragen',
                brakeBeforeTheAccident: 'Remmen voor het ongeval',
                turnBeforeTheIncident: 'Bocht voor het incident',
                whenCorrosionOccurs: 'Wanneer corrosie optreedt',
                parkingTimeHours: 'Parkeertijd (uren)',
                parkingTimeMinutes: 'Parkeertijd (minuten)',
                drivingHours: 'Rijuren',
                drivingMinutes: 'Rijminuten',
                drivingDistance: 'Rijafstand',
                drivingSpeed: 'Rijsnelheid',
                vehicleState: 'Voertuigstatus',
                fireFightingOrNot: 'Brandbestrijding of niet',
                numberOfInjured: 'Aantal gewonden',
                deathToll: 'Doodstol',
                vehicleHelp: 'Voertuighulp',
                vehiclePassingAccident: 'Voertuig dat het ongeval passeert',
                pastAccidents: 'Eerdere ongevallen',
                isTheWarningLampOnBeforeTheEvent:
                    'Is het waarschuwingslampje aan voor het evenement',
                specificIconContent: 'Specifieke pictograminhoud',
                didYouSmellFuelBeforeTheAccident: 'Heb je brandstof geroken voor het ongeval',
                didYouFeelAnyAbnormalVibrationBeforeTheAccident:
                    'Voelde u een abnormale trilling voor het ongeval',
                abnormalSoundZone: 'Zone met abnormaal geluid',
                didYouHearAnyNoiseBeforeTheAccident: 'Heeft u geluid gehoord voor het ongeval',
                noiseDescription: 'Beschrijving van het geluid',
                theAreaWhereTheVehicleSmokedBeforeTheAccident:
                    'Het gebied waar de auto rookte voor het ongeval',
                otherAreas: 'Andere gebieden',
                whetherTheInsuranceCompanyHasIssuedACertificate:
                    'Heeft de verzekeringsmaatschappij een certificaat afgegeven',
                whetherTheAuthorityIssuesTheCertificate: 'Of de autoriteit het certificaat afgeeft',
                isVDSReportAvailable: 'Is er een VDS-rapport beschikbaar',
                accidentProcess: 'Ongeval proces',
                accidentProcessPlaceholder:
                    'Geregistreerd door_ De parafrase van XXXX.\n' +
                    'Rond XX a.m. op XX/XX/20XX reed de partij XXXX met een voertuig met kentekennummer XXX Roewe RX50 1.5/AT over de nationale snelweg van zuid naar noord (met één persoon in het voertuig en één bestuurder). Bij het rijden naar XX Village, XX Town, XX County, XX City, XXX Province, bleek dat witte rook werd gegenereerd in de linkercockpit.',
                accessoryMaterials: 'Accessoire materialen',
                bulkDownload: 'Bulk download',
                materialUpload: 'Materiaal uploaden',
                userReadmeAttachment: 'Gebruikershandleiding bijlage',
                sitePhotos: 'Site foto\'s',
                fireAccidentIdentificationLetter: 'Identificatiebrief van brandongevallen',
                insuranceRecordAttachment: 'Verzekeringsregistratie bijlage',
                diagnosticRecords: 'Diagnostische gegevens',
                temperature: 'Temperatuur',
                please_select_the_reporting_type: 'Selecteer het type rapportage',
                fire_accident_identification_letter_required:
                    'Noodzakelijke identificatiebrief voor brandongevallen',
                please_upload_the_fire_accident_identification_letter:
                    'Upload het identificatieformulier voor brandongevallen',
                insurance_record_attachment_required: 'Vereist beslag op verzekeringsdocumenten',
                please_upload_the_insurance_record_attachment:
                    'Upload de bijlage van het verzekeringsdossier',
                please_upload_the_diagnostic_record_attachment:
                    'Upload de bijlage van de diagnostische record',
                please_upload_site_photos: 'Upload foto\'s ter plaatse',
            },
        },
        order: {
            claim: {
                creater: 'Aanmaker',
                brand: 'Merk',
                claimAmountAll: 'Totale schadeclaim bedrag',
                creationDate: 'Aanmaakdatum',
                currentState: 'Huidige processtatus',
                claimNumber: 'Aantal schadeclaims',
                claimName: 'Naam',
                claimNameCN: 'NaamCN',
                claimNameUS: 'NaamCN',
                Application: 'Onderdeel Claim Nummer',
                orderNumber: 'Bestelnummer',
                amount: 'Voer het bedrag in',
                frequency: 'Voer het aantal keer in',
                applicationNumber: 'Onderdeel Claim Nummer',
                creationDateIn: 'Aanmaakdatum',
                new: 'Nieuw',
                applications: 'Lijst met schadeclaims',
                creationTime: 'Aanmaaktijd',
                money: 'Valuta',
                containerNumber: 'PL-nummer',
                pack_noCode: 'Containercode',
                suborder: 'Suborder-nummer',
                PartNumber: 'Onderdeelnummer',
                claimType: 'Type schadeclaim',
                orderQuantity: 'Gefactureerde hoeveelheid',
                receivedQuantity: 'Ontvangen hoeveelheid',
                claimNumbers: 'Schadeclaim hoeveelheid',
                approvedQuantity: 'Goedgekeurde hoeveelheid',
                purchaseAmount: 'Verkoopprijs(FOB)',
                subtotal: 'Totaal bedrag',
                scrapPhotos: 'Foto\'s van schroot',
                accessoriesPhotos: 'Foto\'s van onderdelen',
                accessoriesLabel: 'Foto\'s van onderdeellabels',
                accessoriesPackagingPhotos: 'Foto\'s van onderdelenpakketten',
                transportation: 'Foto\'s van transportpakketten',
                packingList: 'Scherm van verpakkingslijst',
                claimReason: 'Probleemomschrijving',
                DTNumber: 'DT-nummer',
                claimMessage: 'Schadeclaim informatie',
                Circulationadvice: 'Circulatieadvies',
                General_generation_name: 'Algemene generatienaam',
                claim_order: 'Schadeclaimorder',
                audit_result: 'Auditresultaat',
                OEMResult: 'OEM-auditresultaat',
                responsible: 'Verantwoordelijk',
                modification_date: 'Wijzigingsdatum',
                updated_date: 'Bijgewerkte datum',
                General_agent: 'Algemene agent',
                ATA_time: 'ATA ingestelde dagen',
                claimAmount: 'Schadeclaim bedrag',
                pack_choose: 'Selectie van doosnummer',
                suborder_choose: 'Selectie van subordernummer',
                PartNumber_choose: 'Selectie van onderdeelnummer',
                request_pack: 'Controleer het nummer van de verpakkingslijst',
                request_suborder: 'Controleer het suborder nummer',
                request_PartNumber: 'Controleer het onderdeelnummer',
                request_Claim: 'Controleer de claimpartijlijn',
                request_ATA: 'Geen ATA-tijd, kan geen claim indienen',
                information: 'Basisinformatie',
                completionStatus: 'Voltooiingsstatus',
                Handlingsuggestion: 'Behandelingsvoorstel',
                picture: 'Afbeelding',
                greater: 'Groter dan of gelijk aan',
                less: 'Kleiner dan of gelijk aan',
                week: 'Week',
                month: 'Maand',
                quarter: 'Kwartaal',
                year: 'Jaar',
            },
            report: {
                logisticsEfficiency: {
                    dt_code: 'DT-code',
                    dt_name: 'DT-naam',
                    order_month: 'Ordermaand',
                    order_quantity: 'Order hoeveelheid',
                    total_order_amount: 'Totaal bestelbedrag',
                    packaging_timeliness: 'Verpakkingstijdigheid',
                    maritime_transport_general_cargo: 'Maritiem transport - algemene lading',
                    air_transport: 'Luchttransport',
                    sea_transportation: 'Zeetransport',
                    express_delivery: 'Express bezorging',
                    zh_order_quantity: 'Op tijd bestelde hoeveelheid',
                    punctualit_rate: 'Punctualiteitspercentage',
                    maritime_transport_dangerous_goods: 'Maritiem transport - gevaarlijke goederen',
                    otd_timeliness: 'OTD-tijdigheid',
                    transport_prescription: 'Transport voorschrift',
                    lines: 'Aantal orderregels',
                    amount: 'Bestelbedrag',
                },
                abnormalOrder: {
                    Overexpectationprocessing: 'Oververwachtingsverwerking',
                    Overduehasbeenprocessed: 'Achterstallig is verwerkt',
                    packing: 'Verpakking',
                    OTD: 'OTD',
                    transport: 'Transport',
                    Orderlinenumber: 'Orderregelnummer',
                    Orderlinenumberall: 'Alle orderregelnummers',
                    Anomalyrate: 'Afwijspercentage',
                },
            },
            distributionManagement: {
                list_information: 'Informatie van de verpakkingslijst',
                container_scheme: 'Container Schema',
                logistics_registration: 'Logistieke registratie',
                dt_code: 'DT Code',
                packing_list: 'Verpakkingslijstnummer',
                date_warehousing: 'Datum van opslag',
                name_voyage: 'Schip naam/voyage/express',
                other_expenses: 'Andere kosten',
                bill_lading: 'B/L-nummer',
                print_shipping_mark: 'Verzendmarkering afdrukken',
                warehousing_confirmation: 'Opslagbevestiging',
                fill_other_expenses: 'Vul andere kosten in',
                type_shipping: 'Type verzending',
                shipping_company: 'Verzendmaatschappij',
                ships_name: 'Scheepsnaam',
                voyages_flights: 'De reeks van reizen of vluchten',
                ship_number: 'Schip nummer',
                customs_no: 'Douanenummer',
                return_lading: 'Retourmethode van B/L',
                expected_date: 'Verwachte retourdatum',
                actual_date: 'Feitelijke retourdatum',
                remark: 'Opmerking',
                container_no: 'Container nummer',
                container_type: 'Containertype',
                customs_declaration: 'Douaneaangifte nummer',
                container_nos: 'Container nummers',
                reason_modification: 'Reden voor wijziging',
            },
            management: order.claim.management.nlNL,
        },
        tech: {
            quality: {
                problemTracking: {
                    customs_declaration_unit: 'Douane-aangifte-eenheid',
                    theme: 'Thema',
                    model: 'Model',
                    problem_state: 'Probleemstatus',
                    TAC_submit_date: 'TAC indieningsdatum',
                    level: 'Niveau',
                    type: 'Type',
                    vehicle_involved: 'Betrokken voertuig',
                    activity_code: 'Activiteitencode',
                    activity_name: 'Activiteitsnaam',
                    connected_activities: 'Verbonden activiteiten',
                    system_problems: 'Systeemproblemen',
                    quantity: 'Hoeveelheid',
                    engine_type: 'Motortype',
                    gearbox_type: 'Versnellingsbaktype',
                    country: 'Land',
                    region: 'Regio',
                    date_of_feedback_to_QA: 'Datum van feedback aan QA',
                    required_part_return_date: 'Datum vereiste onderdeelretourzending',
                    date_of_parts_delivery_to_QA: 'Datum van levering van onderdelen aan QA',
                    solution_release_date: 'Oplossing vrijgavedatum',
                    solution_target_date: 'Oplossingsdoelstelling datum',
                    end_date: 'Einddatum',
                    difference: 'Verschil',
                    QA_weekday: 'QA weekdag',
                    fault_description: 'Foutomschrijving',
                    reason: 'Reden',
                    solution: 'Oplossing',
                    breakpoint: 'Onderbrekingspunt VIN / CSN / en',
                    process: 'Proces',
                    details: 'Details',
                    technical_document_attachments: 'Technische documentbijlagen',
                },
                recallActivity: {
                    activity_code: 'Activiteit code',
                    activity_name: 'Activiteit naam',
                    responsible_party: 'Verantwoordelijke partij',
                    release_date: 'Uitgiftedatum',
                    data_sources: 'Gegevensbronnen',
                    recall_campaign_type: 'Terugroep Type',
                    service_activity_code: 'SERVICE campagne CODE',
                    service_activity_name: 'SERVICE campagne naam',
                    buyout_company: 'Uitkoopbedrijf',
                    audit_company: 'Goedkeuringsbedrijf',
                    currency: 'Valuta',
                    money: 'Bedrag',
                    start_date: 'Startdatum',
                    end_date: 'Einddatum',
                    failure_mode: 'Foutmodus',
                    activity_programme: 'Campagneplan',
                    improve_handling: 'Verbeteringsmaatregelen',
                    activity_information: 'Activiteitsinformatie',
                    vehicle_involved_list: 'Betrokken voertuigen',
                    template_file: 'Sjabloonbestand',
                    browse: 'Bladeren',
                    download_template: 'Sjabloon downloaden',
                    claim_vehicles_list: 'Voertuigen waarvoor een claim is ingediend',
                    claim_statistics: 'Claimstatistieken',
                    technical_document_attachments: 'Technische documentbijlagen',
                    vin: 'VIN',
                    is_complete: 'Voltooid',
                    warranty_claims: 'Garantieclaim',
                    customer: 'Klant',
                    no_file_selected: 'Geen bestand geselecteerd',
                    no_import_file_selected: 'Geen importbestand geselecteerd',
                    claim_proportion: 'Claimverhouding',
                    claim_settled_amount: 'Afgehandeld bedrag van claim',
                    is_claim_again: 'Of opnieuw claimen',
                    cancel_succeed: 'Annuleren gelukt',
                    build_by_oneself: 'Zelf opbouwen',
                    system_import: 'Claim systeem importeren',
                    claim_system_import: 'Claim systeem importeren',
                },
            },
            management: {
                newCarDefinition: {
                    activityNumber: 'Campagnecode',
                    activityName: 'Campagnenaam',
                    activityStatus: 'Status',
                    activityDate: 'Activiteitsdatum',
                    startDate: 'Startdatum',
                    endDate: 'Einddatum',
                    remarks: 'Opmerkingen',
                    activity_details: 'Activiteitsdetails',
                    activity_addition: 'Activiteit toevoeging',
                    confirm_enable: 'Bevestig inschakelen',
                    confirm_outage: 'Bevestig uitschakelen',
                    all: 'Alle',
                    not_started: 'Niet gestart',
                    on_going: 'Bezig',
                    closed: 'Gesloten',
                },
                newCarlssue: {
                    all: 'Allemaal',
                    newly_built: 'Toevoegen',
                    unpublished: 'Niet gepubliceerd',
                    validity: 'Geldigheid',
                    modelInvolved: 'Model',
                    involvedArea: 'Regio',
                    state: 'Staat',
                    new_car_reporting_level: 'Nieuw auto rapportageniveau',
                    confirm_release: 'Bevestig vrijgave',
                    maintenance: 'Onderhoud',
                    model_maintenance: 'Model onderhoud',
                    area_maintenance: 'Regio onderhoud',
                    reporting_level: 'Rapportage niveau',
                    to_be_published: 'Te publiceren',
                    published: 'Gepubliceerd',
                    modified_to_be_published: 'Gewijzigd om te publiceren',
                },
                onSiteSupportHq: {
                    fieldSupportNumber: 'Veldondersteuningsnummer',
                    informationSlipNumber: 'Informatie slipnummer',
                    approvalStatus: 'Goedkeuringsstatus',
                    state: 'Staat',
                    selectApplicationDate: 'Selecteer de aanvraagdatum',
                    toBeProcessed: 'Te verwerken',
                    processed: 'Verwerkt',
                    initiated: 'Geïnitieerd',
                    applicationDepartment: 'Afdeling aanvraag',
                    applicant: 'Aanvrager',
                    reasonApplication: 'Reden voor aanvraag',
                    applicationTime: 'Aanvraagtijd',
                    all: 'Alle',
                    pending_approva: 'In afwachting van goedkeuring',
                    agreed: 'Overeengekomen',
                    rejected: 'Afgewezen',
                    view: {
                        application_department: 'Afdeling aanvraag',
                        responsible_person: 'Verantwoordelijke persoon',
                        application_person: 'Aanvraagpersoon',
                        associated_enquiry_number: 'Bijbehorend vraagnummer',
                        reason_application: 'Reden voor aanvraag',
                        description: 'Beschrijving(Interne aanvraagondertekening)',
                        file_name: 'Bestandsnaam',
                        size: 'Grootte(MB)',
                        uploader: 'Uploader',
                        upload_time: 'Uploadtijd',
                        view: 'Check',
                        download: 'download',
                        support_department: 'Ondersteuningsafdeling',
                        technical_personnel: 'Technisch personeel',
                        contact_information: 'Contactgegevens',
                        field_support_information: 'Veldondersteuningsinformatie',
                        remarks: 'Opmerkingen',
                        apply: 'Solliciteren',
                        application_information: 'Aanvraaginformatie',
                        field_support_personnel: 'Ondersteuningspersoneel ter plaatse',
                        approval_record: 'Goedkeuringsgeschiedenis',
                        submit_an_application: 'Dien een aanvraag in',
                        refuse: 'Weigeren',
                        agree: 'Overeenkomen',
                        apply_for: 'Toepassen voor',
                        field_support: 'Veldondersteuning',
                        reason_for_rejection: 'Reden van afwijzing',
                    },
                    dialog: {
                        on_site_application_details: 'Details van de aanvraag op locatie',
                        on_site_application: 'Aanvraag op locatie',
                        reason_for_rejection: 'Reden van afwijzing',
                        field_support_application: 'Veldondersteuningsaanvraag',
                        technical_personnel: 'Technisch personeel',
                        contact_information: 'Contact telefoon',
                        field_support_time: 'Tijd van ondersteuning ter plaatse',
                        note_information: 'Notitie informatie',
                    },
                },
                filterHq: {
                    filter_information: 'Filterinformatie',
                    filter_name: 'Filternaam',
                    user_name: 'Gebruikersnaam',
                    creation_time: 'Creatietijd',
                    splicing_description: 'Samenstellingsbeschrijving',
                    enable_or_not: 'Inschakelen of niet',
                    association_symbol: 'Verbonden symbool',
                    screening_condition: 'Filtervoorwaarde',
                    conditional_value: 'Filtervoorwaarde waarde',
                    save: 'Opslaan',
                    draft: 'Concept',
                    no_reply: 'Geen antwoord',
                    replied: 'Beantwoord',
                    closed: 'Gesloten',
                    pre_sales: 'Voor verkoop',
                    after_sales: 'Na verkoop',
                    to_be_repaired: 'Te repareren',
                    under_maintenance: 'In onderhoud',
                    completed: 'Voltooid',
                    not_included: 'Niet inbegrepen',
                    and: 'En',
                    perhaps: 'Misschien',
                    not: 'Niet',
                    enable: 'Inschakelen',
                    judgement_logic: 'Oordeelslogica',
                    order_number: 'Nr.',
                    relational_symbol: 'Relatie symbool',
                    filter_criteria: 'Filtercriteria',
                    please_add_judgment_logic: 'Voeg alstublieft oordeelslogica toe',
                    model_platform_is: 'Het modelplatform is',
                    subject_of_the_case_is: 'Het onderwerp van de zaak is',
                    overseas_branches_are: 'Overzeese filialen zijn',
                    overseas_subsidiaries_are: 'Overzeese dochterondernemingen zijn',
                    general_agent_are: 'Distributeurs zijn',
                    fault_symptom_is: 'Fout verschijnsel is',
                    cause_of_the_fault_is: 'Oorzaak van de fout is',
                    vehicle_status_is: 'Voertuigstatus is',
                    case_type_is: 'Zaaktype is',
                    case_status_is: 'Zaakstatus is',
                    all: 'Alle',
                },
                vehicleInfoHq: {
                    productionTimeList: 'Productietijdlijst',
                    vehicle_configuration_list: 'Voertuigconfiguratielijst',
                    accurately_traceable_parts_list: 'Nauwkeurig traceerbare onderdelenlijst',
                    order_number: 'Bestelnummer',
                    order_type: 'Besteltype',
                    platform: 'Platform',
                    model_code: 'Modelcode',
                    date_of_production: 'Productiedatum',
                    certificate_of_conformity: 'Certificaat van overeenstemming',
                    certificate_printing_time: 'Tijd van afdrukken certificaat',
                    vehicle_material_number: 'Voertuigmateriaalnummer',
                    description: 'Beschrijving',
                    time: 'Tijd',
                    feature_ID: 'Kenmerk ID',
                    feature_description: 'Kenmerkbeschrijving',
                    feature_code: 'Kenmerkcode',
                    eigenvalue_description: 'Eigenwaarde beschrijving',
                    chinese_description: 'Chinese beschrijving',
                    supplier: 'Leverancier',
                    bar_code_number: 'Barcode nummer',
                    scanning_time: 'Scan tijd',
                    key_component_type_code: 'Code van sleutelcomponenttype',
                    part_number: 'Onderdeelnummer',
                    traceability_number: 'Traceerbaarheidsnummer',
                    sub_key_type: 'Subsleuteltype',
                    software: 'Software',
                    hardware: 'Hardware',
                    calibration_information: 'Kalibratie informatie',
                    supplier_name: 'Leveranciersnaam',
                    pn: 'PIN-code',
                },
                progHistoryHq: {
                    name_of_maintenance_station: 'Naam van de onderhoudsstation',
                    mileage: 'Kilometerstand',
                    controller_name: 'Controller naam',
                    function_name: 'Functienaam',
                    time: 'Tijd',
                    function_execution_result: 'Functie uitvoeringsresultaat',
                    channel: 'Kanaal',
                    hardware_number: 'Hardware nummer',
                    written_software_information: 'Geschreven software informatie',
                    software_information_before_refresh: 'Software informatie voor update',
                    updated_software_information: 'Geüpdatete software informatie',
                },
                salesRecordHq: {
                    material: 'Materiaal',
                    sales_order: 'Verkooporder',
                    vehicle_type: 'Model',
                    shipping_area: 'Verzendgebied',
                    country_of_dispatch: 'Verzendland',
                    down_date: 'Offline datum',
                    port_of_destination: 'Bestemmingshaven',
                    scheduled_date_of_shipment: 'ETD',
                    actual_date_of_shipment: 'ATD',
                    current_position: 'Huidige positie',
                    actual_arrival_date: 'ATA',
                    retail_date: 'Verkoopdatum',
                },
                claimHq: {
                    claim_information: 'Claim informatie',
                    Recall_and_service_activities: 'Terugroep- & servicecampagne',
                    marketing_activity_list: 'Marketingcampagne',
                    basic_information: 'Basisinformatie',
                    warranty_block: 'Garantieblokkering',
                    repair_block_code: 'Code garantieblokkering',
                    claim_cycle_list: 'Garantieperiodelijst',
                    WTY_Indicates_the_cycle_type: 'WTY-periode type',
                    start_time: 'Starttijd',
                    end_time: 'Eindtijd',
                    WTY_mileage: 'WTY kilometerstand',
                    List_of_claims: 'WTY-geschiedenis',
                    customer: 'Klant',
                    warranty_claim: 'Garantieclaim',
                    work_order: 'Werkorder',
                    date_of_receipt: 'Ontvangst datum',
                    maintenance_technical_date: 'Reparatiedatum',
                    mileage: 'kilometerstand',
                    state: 'Status',
                    master_working_position: 'Hoofdwerkzaamheid',
                    manual_code: 'Arbeidscode',
                    main_component: 'Hoofdonderdeel',
                    part_name: 'Onderdeelnaam',
                    recall_and_service_activities: 'Terugroep- & servicecampagne',
                    recall_and_service_activity_code: 'Terugroep- & servicecampagnecode',
                    recall_and_service_activity_names: 'Terugroep- & servicecampagnenaam',
                    brand: 'Merk',
                    recall_type_code: 'Terugroep type code',
                    recall_type_name: 'Terugroep type naam',
                    release_date: 'Releasedatum',
                    closing_date: 'Sluitingsdatum',
                    complete: 'voltooid',
                    warranty_claim_status: 'Garantieclaim status',
                    end_of_maintenance_date: 'Einddatum van laatste reparatie',
                    marketing_activity_code: 'Marketing activiteitscode',
                    marketing_activity_name: 'Marketing activiteitsnaam',
                    vehicle_warranty_period: 'Garantieperiode voor voertuigen',
                    marketing_activities: 'Marketingactiviteiten',
                    prohibition_of_claims: 'Verbod op vorderingen',
                    prohibited_claim_code: 'Code verboden claims',
                    type_of_warranty_period: 'Soort garantieperiode',
                    repair_end_date: 'inddatum herstellen',
                    reception_date: 'Ontvangstdatum',
                    main_station_code: 'Code van het hoofdstation',
                    main_workstation_name: 'Naam hoofdwerkstation',
                },

                // inquiryOd: {
                //     vin: 'VIN',
                //     inquiry_no: 'inquiry no',
                //     case_theme: 'case theme',
                //     inquiry_date: 'inquiry date',
                // },
                inquiryOd: tech.management.inquiry.nlNL,
                permission: tech.management.permission.nlNL,
            },
        },
        statistics: statistics.inquiryStatistics.nlNL,
        systemClassifyA: statistics.systemClassifyA.nlNL,
        systemClassifyB: statistics.systemClassifyB.nlNL,
        task: {
            china: {
                holiday: {
                    holiday_name: 'Festival name',
                    holiady_time: 'Vakantie',
                    end_time: 'Einddatum',
                    holiday_add: 'Nieuw toegevoegd',
                    holiday_edit: 'Bewerk',
                },
            },
            package: {
                calendar: {
                    packing: 'SMPV-pakdag',
                },
            },
        },
        logistics: {
            package: {
                cycle: {
                    transport_type: 'Transporttype',
                    market_type: 'Markttype',
                    market_name: 'Marktnaam',
                    market_code: 'Marktcode',
                    transport_cycle: 'Transportperiode (dag)',
                    Packaging_aging: 'Verpakkingsveroudering (dag)',
                    OTD_aging: 'OTD veroudering (dag)',
                },
            },
        },
        vehicle: {
            logistics: {
                expenses: {
                    atd_begin_date: 'Startdatum atd',
                    atd_end_date: 'Einddatum atd',
                    atd_date: 'Atd datum',
                    country_of_target: 'Land van bestemming',
                    dn: 'Dn',
                    region: 'Regio',
                    model_code: 'Modelcode',
                    vin: 'VIN',
                    volumn: 'Volume',
                    vdc_name: 'Vdc naam',
                    pol_name: 'Pol naam',
                    dt_content: 'Dt inhoud',
                    dt_distance_km: 'Dt afstand km',
                    vdc_vasc_material: 'Vdc vasc materiaal',
                    vdc_vasc_worker: 'Vdc vasc werknemer',
                    domestic_carry: 'Binnenlands vervoer',
                    wharf_handle: 'Wharf handling',
                    wharf_sc: 'Wharf sc',
                    wharf_box: 'Wharf box',
                    medicated_fumigation: 'Gemotiveerde fumigatie',
                    wharf_dot_check: 'Wharf dot check',
                    repair_maintenance: 'Reparatie onderhoud',
                    refuel_storage: 'Opslag van brandstof',
                    file_cert: 'Bestandscertificaat',
                    supply_chain_integration: 'Integratie van supply chain',
                    other_domestic_carry: 'Ander binnenlands vervoer',
                    insure: 'Verzekeren',
                    ship_money: 'Scheepsgeld',
                    ship_money_type: 'Scheepsgeld type',
                    other_interal_charge: 'Andere interne kosten',
                    other_interal_charge_type: 'Andere interne kosten type',
                    transport_company_code: 'Transportbedrijf code',
                    vessel_name_voyage: 'Scheepsnaam reis',
                    mode_of_transport: 'Vervoerswijze',
                    remark: 'Opmerking',
                    create_date: 'Aangemaakt datum',
                },
                expensesRelationship: {
                    country_name: 'Landnaam',
                    country_code: 'Landcode',
                    model_code: 'Modelcode',
                    ology_code: 'Ologiecode',
                    ology_name: 'Ologienaam',
                    status: 'Status',
                    remark: 'Opmerking',
                    create_by: 'Gemaakt door',
                    create_name: 'Creatie naam',
                    create_date: 'Creatie datum',
                    update_by: 'Bijgewerkt door',
                    update_name: 'Update naam',
                    update_date: 'Update datum',
                    tache_code: 'Tache code',
                    no: 'Nee',
                    yes: 'Ja',
                },
                expenseAccount: {
                    ology_type: 'Ologie type',
                    pack_name: 'Pakket naam',
                },
                country: vehicle_country.zhCN,
            },
        },
    },
    validation: {
        common: {
            required: 'Item is verplicht',
            numeric: 'Moet een geheel getal zijn',
            decimal: 'Moet een getal zijn',
            positive_interger: 'Moet een positief geheel getal zijn',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Tot aan ${named('number')} cijfers`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Meer dan ${named('number')} karakters`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Maximale bytes overschrijden (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Minder dan ${named('number')} karakters`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Moet wel zijn. ${named('number')} karakters`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Groter dan ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Minder dan ${named('number')}`,
            up_to_2_decimal_places: 'Tot 2 decimalen',
            retain_up: 'Voer tot 10 positieve gehele getallen in en behoud tot 4 decimalen',
            retain_ups: 'Voer tot 11 positieve gehele getallen in en behoud tot 4 decimalen',
            input_number: 'Voer het nummer in',
            only_number_a: 'Alleen letters of cijfers kunnen worden ingevoerd',
            positive_integer: 'Voer tot 10 positieve gehele getallen in',
            at_least_one: 'Vul minstens één in',
        },
        tip: {
            incomplete_information: 'Onvolledige informatie',
            MG_information: 'MG-besteller mag niet leeg zijn',
            MAXUS_information: 'MAXUS-besteller mag niet leeg zijn',
        },
    },
    import: {
        incorrect_file_type: 'Fout bestandstype',
        exceed: 'Verder gaan',
        upload_success: 'Upload succesvol',
        upload_fail: 'Upload mislukt',
        again_upload: 'Alleen afbeeldingen in jpg, png, bmp of gif formaten kunnen worden geüpload',
    },
    export: {
        noDataToExport: 'Geen gegevens om te exporteren',
    },
    select: {
        all: 'Alle',
    },
    checkbox: {
        select_all: 'Alles selecteren',
    },
    dialog: {
        error_message: 'Foutmelding',
        accessory_selection: 'Accessoire selectie',
    },
};
