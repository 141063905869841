export default {
    enUS: {
        query_time: 'Query time',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        serial_number: 'Serial number',
        organization_code: 'Organization CODE',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        wait_for_superiors_to_reply_cases: 'Wait for superiors to reply cases',
        percentage_wait_for_superiors_to_reply_cases: 'Wait for superiors to reply cases(%)',
        superior_replied_casess: 'Superior replied cases',
        percentage_superior_replied_casess: 'Superior replied cases(%)',
        closed_cases: 'Closed cases',
        percentage_closed_cases: 'Closed cases(%)',
        average_replied_cases: 'Average replied cases',
        engineer_name: 'Engineer name',
        total_cases: 'Total cases',
        waiting_for_reply_cases: 'Waiting for reply cases',
        percentage_waiting_for_reply_cases: 'Waiting for reply cases (%)',
        replied_cases: 'Replied cases',
        percentage_replied_cases: 'Replied cases (%)',
        failure_parts: 'Failure parts',
        total_of_failure_parts: 'Total of failure parts',
        of_organization: '% of Organization',
        of_total: '% of Total',
        percentage_of_replied_cases: 'Percentage of replied cases',
        percentage_of_No_Responses: 'Percentage of No Responses',
        number_of_qualified_cases: 'Number of qualified cases',
        percentage_of_qualified_cases: 'Percentage of Qualified Cases',
        number_of_unqualified_cases: 'Number of unqualified cases',
        percentage_of_unqualified_cases: 'Percentage of unqualified cases',
        timely_reply_to_the_number_of_cases: 'Timely reply to the number of cases',
        prompt_response_percentage: 'Prompt response percentage',
        the_number_of_cases_was_not_timely_replied: 'The number of cases was not timely replied',
        percentage_not_responding_in_time: 'Percentage not responding in time',
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
    },
    nlNL: {
        query_time: 'Query time',
        reporting_organization_name: 'Rapporterende organisatienaam',
        full_query: 'Volledige query',
        serial_number: 'Seriële nummer',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Aantal gevallen',
        wait_for_superiors_to_reply_cases: 'Wachten op antwoord van superieuren',
        percentage_wait_for_superiors_to_reply_cases: 'Wachten op antwoord van superieuren(%)',
        superior_replied_casess: 'Superieure beantwoorde gevallen',
        percentage_superior_replied_casess: 'Superieure beantwoorde gevallen(%)',
        closed_cases: 'Gesloten gevallen',
        percentage_closed_cases: 'Gesloten gevallen(%)',
        average_replied_cases: 'Gemiddelde beantwoorde gevallen',
        engineer_name: 'Ingenieursnaam',
        total_cases: 'Totaal aantal gevallen',
        waiting_for_reply_cases: 'Wachten op antwoord gevallen',
        percentage_waiting_for_reply_cases: 'Wachten op antwoord gevallen (%)',
        replied_cases: 'Beantwoorde gevallen',
        percentage_replied_cases: 'Beantwoorde gevallen (%)',
        failure_parts: 'Falende onderdelen',
        total_of_failure_parts: 'Totaal van falende onderdelen',
        of_organization: '% van organisatie',
        of_total: '% van totaal',
        percentage_of_replied_cases: 'Percentage beantwoorde zaken',
        percentage_of_No_Responses: 'Percentage van geen reacties',
        number_of_qualified_cases: 'Aantal gekwalificeerde gevallen',
        percentage_of_qualified_cases: 'Percentage gekwalificeerde zaken',
        number_of_unqualified_cases: 'Aantal niet-conforme gevallen',
        percentage_of_unqualified_cases: 'Percentage niet-conforme gevallen',
        timely_reply_to_the_number_of_cases: 'Aantal gevallen',
        prompt_response_percentage: 'Percentage tijdige antwoorden',
        the_number_of_cases_was_not_timely_replied:
            'Aantal gevallen waarin niet tijdig is gereageerd',
        percentage_not_responding_in_time: 'Percentage niet-tijdige antwoorden',
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
    },
    deDE: {
        query_time: 'Abfragezeit',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        serial_number: 'Seriennummer',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Anzahl der Fälle',
        wait_for_superiors_to_reply_cases: 'Fälle, auf Antwort der Vorgesetzten warten',
        percentage_wait_for_superiors_to_reply_cases:
            'Fälle, auf Antwort der Vorgesetzten warten (%)',
        superior_replied_casess: 'Vorgesetzte beantwortete Fälle',
        percentage_superior_replied_casess: 'Vorgesetzte beantwortete Fälle (%)',
        closed_cases: 'Geschlossene Fälle',
        percentage_closed_cases: 'Geschlossene Fälle (%)',
        average_replied_cases: 'Durchschnittlich beantwortete Fälle',
        engineer_name: 'Name des Ingenieurs',
        total_cases: 'Gesamtzahl der Fälle',
        waiting_for_reply_cases: 'Fälle, die auf Antwort warten',
        percentage_waiting_for_reply_cases: 'Fälle, die auf Antwort warten (%)',
        replied_cases: 'Beantwortete Fälle',
        percentage_replied_cases: 'Beantwortete Fälle (%)',
        failure_parts: 'Defekte Teile',
        total_of_failure_parts: 'Anzahl der defekten Teile',
        of_organization: '% der Organisation',
        of_total: '% vom Gesamtwert',
        percentage_of_replied_cases: 'Prozentsatz der beanstandeten Fälle',
        percentage_of_No_Responses: 'Prozentsatz der fehlenden Antworten',
        number_of_qualified_cases: 'Anzahl qualifizierter Fälle',
        percentage_of_qualified_cases: 'Prozentsatz qualifizierter Fälle',
        number_of_unqualified_cases: 'Anzahl der nicht konformen Fälle',
        percentage_of_unqualified_cases: 'Prozentsatz der nicht konformen Fälle',
        timely_reply_to_the_number_of_cases: 'Rechtzeitig auf die zahl der fälle reagieren',
        prompt_response_percentage: 'Prozentwert rechtzeitig wiederhergestellt',
        the_number_of_cases_was_not_timely_replied:
            'Wird die anzahl der fälle nicht rechtzeitig gemeldet',
        percentage_not_responding_in_time: 'Wurde nicht rechtzeitig über die prozent berichtet',
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
    },
    frFR: {
        query_time: 'Temps de requête',
        reporting_organization_name: 'Nom de l\'organisation de rapport',
        full_query: 'Requête complète',
        serial_number: 'Numéro de série',
        organization_code: 'Code d\'organisation',
        organization_name: 'Nom d\'organisation',
        country: 'Pays',
        number_of_cases: 'Nombre de cas',
        wait_for_superiors_to_reply_cases: 'Cas en attente de réponse des supérieurs',
        percentage_wait_for_superiors_to_reply_cases: 'Cas en attente de réponse des supérieurs(%)',
        superior_replied_casess: 'Cas auxquels les supérieurs ont répondu',
        percentage_superior_replied_casess: 'Cas auxquels les supérieurs ont répondu(%)',
        closed_cases: 'Cas fermés',
        percentage_closed_cases: 'Cas fermés(%)',
        average_replied_cases: 'Moyenne des cas auxquels on a répondu',
        engineer_name: 'Nom de l\'ingénieur',
        total_cases: 'Total des cas',
        waiting_for_reply_cases: 'Cas en attente de réponse',
        percentage_waiting_for_reply_cases: 'Cas en attente de réponse(%)',
        replied_cases: 'Cas auxquels on a répondu',
        percentage_replied_cases: 'Cas auxquels on a répondu(%)',
        failure_parts: 'Pièces défectueuses',
        total_of_failure_parts: 'Total des pièces défectueuses',
        of_organization: '% de l\'organisation',
        of_total: '% du total',
        percentage_of_replied_cases: 'Pourcentage de cas répondus',
        percentage_of_No_Responses: 'Pourcentage du nombre de non - réponses',
        number_of_qualified_cases: 'Nombre de cas admissibles',
        percentage_of_qualified_cases: 'Pourcentage de cas admissibles',
        number_of_unqualified_cases: 'Nombre de cas de non - conformité',
        percentage_of_unqualified_cases: 'Pourcentage de cas de non - conformité',
        timely_reply_to_the_number_of_cases: 'Nombre de cas traités en temps opportun',
        prompt_response_percentage: 'Pourcentage de réponses rapides',
        the_number_of_cases_was_not_timely_replied: 'Nombre de cas en retard de réponse',
        percentage_not_responding_in_time: 'Pourcentage de réponses en retard',
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
    },
    zhCN: {
        query_time: '查询时间',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        serial_number: '序号',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案件总数',
        wait_for_superiors_to_reply_cases: '等待上级答复案例数量',
        percentage_wait_for_superiors_to_reply_cases: '等待上级答复案例百分比',
        superior_replied_casess: '上级已答复案例数量',
        percentage_superior_replied_casess: '上级已答复案例数量百分比',
        closed_cases: '已结案案例数量',
        percentage_closed_cases: '已结案数量百分比',
        average_replied_cases: '工程师单据平均答复单数',
        engineer_name: '工程师',
        total_cases: '工程师案例总数',
        waiting_for_reply_cases: '未答复案例数量',
        percentage_waiting_for_reply_cases: '未答复案例百分比',
        replied_cases: '已答复案例数量',
        percentage_replied_cases: '已答复数量百分比',
        failure_parts: '故障件名称',
        total_of_failure_parts: '故障件数量',
        of_organization: '组织占比',
        of_total: '全量占比',
        percentage_of_replied_cases: '已答复案例百分比',
        percentage_of_No_Responses: '未答复数量百分比',
        number_of_qualified_cases: '合格案例数量',
        percentage_of_qualified_cases: '合格案例百分比',
        number_of_unqualified_cases: '不合格案例数量',
        percentage_of_unqualified_cases: '不合格案例百分比',
        timely_reply_to_the_number_of_cases: '及时回复案件数量',
        prompt_response_percentage: '及时回复百分比',
        the_number_of_cases_was_not_timely_replied: '未及时回复案例数量',
        percentage_not_responding_in_time: '未及时回复百分比',
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
    },
    itIT: {
        query_time: 'Tempo di Query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        serial_number: 'Numero Seriale',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero Totale di Casi',
        wait_for_superiors_to_reply_cases:
            'Numero di Casi in Attesa di Risposta da Parte dei Superiori',
        percentage_wait_for_superiors_to_reply_cases:
            'Percentuale di Casi in Attesa di Risposta da Parte dei Superiori',
        superior_replied_casess: 'Numero di Casi a cui i Superiori hanno Risposto',
        percentage_superior_replied_casess: 'Percentuale di Casi a cui i Superiori hanno Risposto',
        closed_cases: 'Numero di Casi Chiusi',
        percentage_closed_cases: 'Percentuale di Casi Chiusi',
        average_replied_cases: 'Numero Medio di Casi Risposti per Dipendente',
        engineer_name: 'Nome Dipendente',
        total_cases: 'Numero Totale di Casi dei Dipendenti',
        waiting_for_reply_cases: 'Numero di Casi in Attesa di Risposta',
        percentage_waiting_for_reply_cases: 'Percentuale di Casi in Attesa di Risposta',
        replied_cases: 'Numero di Casi ai Quali è Stata Fornita una Risposta',
        percentage_replied_cases: 'Percentuale di Casi ai Quali è Stata Fornita una Risposta',
        failure_parts: 'Nome Pezzi Guasti',
        total_of_failure_parts: 'Numero Totale di Pezzi Guasti',
        of_organization: 'Percentuale per Organizzazione',
        of_total: 'Percentuale Totale',
        percentage_of_replied_cases: 'Percentuale di casi risolti',
        percentage_of_No_Responses: 'Percentuale di nessuna risposta',
        number_of_qualified_cases: 'Numero di casi ammissibili',
        percentage_of_qualified_cases: 'Percentuale di casi qualificati',
        number_of_unqualified_cases: 'Numero di casi non conformi',
        percentage_of_unqualified_cases: 'Percentuale di casi non conformi',
        timely_reply_to_the_number_of_cases: 'Risposta tempestiva al numero di casi',
        prompt_response_percentage: 'Percentuale di risposta tempestiva',
        the_number_of_cases_was_not_timely_replied:
            'Numero di casi che non hanno risposto tempestivamente',
        percentage_not_responding_in_time: 'Percentuale di risposte ritardate',
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
    },
};
