export default {
    enUS: {
        query_time: 'Query time',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        serial_number: 'Serial number',
        organization_code: 'Organization CODE',
        organization_name: 'Organization name',
        country: 'Country',
        total_number_of_cases: 'Total Number of cases',
        percentage_of_cases: 'Percentage of cases',
        system_classification_Transmission: 'System classification-Transmission',
        system_classification_Chassis: 'System_classification_Chassis',
        system_classification_HAVC: 'System_classification_HAVC',
        system_classification_Body: 'System_classification_Body',
        system_classification_Electrical: 'System_classification_Electrical',
        system_classification_Engine: 'System_classification_Engine',
        manual_transmission: 'Manual transmission',
        automatic_transmission: 'Automatic transmission',
        AMT_transmission: 'AMT transmission',
        dual_clutch_transmission: 'Dual clutch transmission',
        drive_shaft_system: 'Drive shaft system',
        compression_engine: 'Compression engine',
        pipeline_and_distribution: 'Pipeline and distribution',
        air_conditioning_control_system: 'Air conditioning control system',
        secondary_classification_1: 'Secondary classification 1',
        secondary_classification_2: 'Secondary classification 1',
        number_of_cases: 'Number of cases',
        proportion: 'Proportion',
    },
    nlNL: {
        query_time: 'Query time',
        reporting_organization_name: 'Meldende organisatie naam',
        full_query: 'Volledige query',
        serial_number: 'Serienummer',
        organization_code: 'Organisatiecode',
        organization_name: 'Organisatienaam',
        country: 'Land',
        total_number_of_cases: 'Totaal aantal gevallen',
        percentage_of_cases: 'Percentage van gevallen',
        system_classification_Transmission: 'Systeemclassificatie-Transmissie',
        system_classification_Chassis: 'Systeemclassificatie-Chassis',
        system_classification_HAVC: 'Systeemclassificatie-HAVC',
        system_classification_Body: 'Systeemclassificatie-Karosserie',
        system_classification_Electrical: 'Systeemclassificatie-Elektrisch',
        system_classification_Engine: 'Systeemclassificatie-Motor',
        manual_transmission: 'Handgeschakelde transmissie',
        automatic_transmission: 'Automatische transmissie',
        AMT_transmission: 'AMT transmissie',
        dual_clutch_transmission: 'DCT transmissie',
        drive_shaft_system: 'Aandrijfas systeem',
        compression_engine: 'Compressie motor',
        pipeline_and_distribution: 'Pijpleiding en distributie',
        air_conditioning_control_system: 'Airconditioning controlesysteem',
        secondary_classification_1: 'Secundaire classificatie 1',
        secondary_classification_2: 'Secundaire classificatie 2',
        number_of_cases: 'Aantal gevallen',
        proportion: 'Verhouding',
    },
    deDE: {
        query_time: 'Abfragezeit',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        serial_number: 'Seriennummer',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        total_number_of_cases: 'Gesamtzahl der Fälle',
        percentage_of_cases: 'Prozentsatz der Fälle',
        system_classification_Transmission: 'Systemklassifizierung-Übertragung',
        system_classification_Chassis: 'Systemklassifizierung-Chassis',
        system_classification_HAVC: 'Systemklassifizierung-Klimaanlage',
        system_classification_Body: 'Systemklassifizierung-Karosserie',
        system_classification_Electrical: 'Systemklassifizierung-Elektrisch',
        system_classification_Engine: 'Systemklassifizierung-Motor',
        manual_transmission: 'Manuelle Übertragung',
        automatic_transmission: 'Automatische Übertragung',
        AMT_transmission: 'AMT Übertragung',
        dual_clutch_transmission: 'Doppelkupplungsgetriebe',
        drive_shaft_system: 'Antriebswellensystem',
        compression_engine: 'Verdichtungsmotor',
        pipeline_and_distribution: 'Pipeline und Verteilung',
        air_conditioning_control_system: 'Klimaanlagensteuersystem',
        secondary_classification_1: 'Sekundäre Klassifizierung 1',
        secondary_classification_2: 'Sekundäre Klassifizierung 1',
        number_of_cases: 'Anzahl der Fälle',
        proportion: 'Anteil',
    },
    frFR: {
        query_time: 'Heure de requête',
        reporting_organization_name: 'Nom de l\'organisation de signalement',
        full_query: 'Requête complète',
        serial_number: 'Numéro de série',
        organization_code: 'Code d\'organisation',
        organization_name: 'Nom de l\'organisation',
        country: 'Pays',
        total_number_of_cases: 'Nombre total de cas',
        percentage_of_cases: 'Pourcentage de cas',
        system_classification_Transmission: 'Classification du système-Transmission',
        system_classification_Chassis: 'Classification du système-Châssis',
        system_classification_HAVC: 'Classification du système-CLIM',
        system_classification_Body: 'Classification du système-Carrosserie',
        system_classification_Electrical: 'Classification du système-Electricité',
        system_classification_Engine: 'Classification du système-Moteur',
        manual_transmission: 'Transmission manuelle',
        automatic_transmission: 'Transmission automatique',
        AMT_transmission: 'Transmission AMT',
        dual_clutch_transmission: 'Transmission à double embrayage',
        drive_shaft_system: 'Système d\'arbre de transmission',
        compression_engine: 'Moteur à compression',
        pipeline_and_distribution: 'Tuyauterie et distribution',
        air_conditioning_control_system: 'Système de contrôle de la climatisation',
        secondary_classification_1: 'Classification secondaire 1',
        secondary_classification_2: 'Classification secondaire 1',
        number_of_cases: 'Nombre de cas',
        proportion: 'Proportion',
    },
    zhCN: {
        query_time: '查询时间',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        serial_number: '序号',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        total_number_of_cases: '案件总数',
        percentage_of_cases: '案例占比',
        system_classification_Transmission: '系统分类-Transmission',
        system_classification_Chassis: '系统分类-Chassis',
        system_classification_HAVC: '系统分类-HAVC',
        system_classification_Body: '系统分类-Body',
        system_classification_Electrical: '系统分类-Electrical',
        system_classification_Engine: '系统分类-Engine',
        manual_transmission: '手动变速器',
        automatic_transmission: '自动变速器',
        AMT_transmission: 'AMT变速器',
        dual_clutch_transmission: '双离合变速器',
        drive_shaft_system: '驱动轴系统',
        compression_engine: '压缩机',
        pipeline_and_distribution: '管路及分配',
        air_conditioning_control_system: '空调控制系统',
        secondary_classification_1: '二级分类1',
        secondary_classification_2: '二级分类2',
        number_of_cases: '案例数量',
        proportion: '占比',
    },
    itIT: {
        query_time: 'Tempo di Query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        serial_number: 'Numero Seriale',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        total_number_of_cases: 'Numero Totale di Casi',
        percentage_of_cases: 'Percentuale di Casi',
        system_classification_Transmission: 'Classificazione Sistema-Transmission',
        system_classification_Chassis: 'Classificazione Sistema-Chassis',
        system_classification_HAVC: 'Classificazione Sistema-HAVC',
        system_classification_Body: 'Classificazione Sistema-Body',
        system_classification_Electrical: 'Classificazione Sistema-Elettrico',
        system_classification_Engine: 'Classificazione Sistema-Motore',
        manual_transmission: 'Trasmissione Manuale',
        automatic_transmission: 'Trasmissione Automatica',
        AMT_transmission: 'Trasmissione AMT',
        dual_clutch_transmission: 'Trasmissione a Doppia Frizione',
        drive_shaft_system: 'Sistema dell\'Albero di Trasmissione',
        compression_engine: 'Motore a Compressione',
        pipeline_and_distribution: 'Tubazione e Distribuzione',
        air_conditioning_control_system: 'Sistema di Controllo dell\'Aria Condizionata',
        secondary_classification_1: 'Classificazione Secondaria 1',
        secondary_classification_2: 'Classificazione Secondaria 2',
        number_of_cases: 'Numero di Casi',
        proportion: 'Percentuale',
    },
};
