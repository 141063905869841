import type { MessageFunction } from 'vue-i18n';
import order from './pages/order';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import { vehicle_country } from './pages/vehicle/logistics';

export default {
    license: {
        powered_by: 'Alimentato da SMIL',
    },
    auth: {
        login: 'Accesso',
        username: 'Nome utente',
        username_placeholder: 'Inserisci nome utente',
        password: 'Password',
        password_placeholder: 'Inserisci password',
        code: 'Codice di verifica',
        code_placeholder: 'Inserire il codice di verifica',
        logout: 'Disconnettersi',
        logout_success: 'Logout effettuato con successo',
        day: 'giorno',
        password_expiration: 'La password è scaduta, per favore modificala',
        expire: 'Rimanere fino alla scadenza della password',
        conseils: 'La password deve contenere lettere maiuscole, minuscole, numeri e caratteri speciali ({msg}), e la lunghezza della password deve essere compresa tra 8 e 16 caratteri',
    },
    button: {
        editing_position: 'Modifica posizione',
        query: 'Richiesta',
        old_password: 'Vecchia password',
        new_password: 'Nuova password',
        confirm_password: 'Conferma password',
        change_password: 'Cambia password',
        cipher: 'Cifra',
        change_new_password: 'Inserisci nuova password',
        show_theme_editor: 'Mostra/Nascondi editor di tema',
        button: 'Pulsante',
        about: 'Informazioni',
        back: 'Indietro',
        go: 'VAI',
        home: 'Home',
        toggle_dark: 'Commuta modalità scura',
        toggle_langs: 'Cambia lingue',
        confirm: 'Conferma',
        modify: 'Modifica',
        edit: 'Modifica',
        new: 'Nuovo',
        cancel: 'Annulla',
        close: 'Chiuso',
        search: 'Cerca',
        reset: 'Reimposta',
        delete: 'Elimina',
        add: 'Aggiungi',
        collapse: 'Comprimi',
        expand: 'Altro',
        download_template: 'Scarica modello',
        batch_import: 'Importazione batch',
        import: 'Importa',
        export: 'Esporta',
        export_fail: 'Esportazione fallita',
        batch_clear_sap_code: 'Cancellazione batch codice SAP',
        batch_clear_special_purchase_cycle: 'Cancellazione batch ciclo acquisti speciale',
        detailed: 'Dettagliato',
        download: 'Scarica',
        toView: 'Controlla',
        toViews: 'Controlla',
        uploadAttachments: 'Carica',
        selectFile: 'Seleziona file',
        fillingInstructions: 'Istruzioni di compilazione',
        release: 'Rilascio',
        save: 'Salva',
        send_back: 'Ritorna',
        examine: 'Esamina',
        updateTime: 'Aggiorna periodo di validità',
        update: 'Aggiorna',
        void: 'Annulla',
        more: 'Altro',
        refresh: 'Aggiorna',
        submit_sap: 'Invia SAP',
        submit: 'Invia',
        save_button: 'Salva e nuovo',
        insufficient: 'Il fondo di conto è insufficiente per presentare',
        yes: 'Sì',
        deny: 'Rifiuta',
        staging: 'Salva e chiudi',
        return: 'Ritorna',
        table: 'No',
        select: 'Scegli',
        all: 'Tutto',
        create_sap: 'Crea SAP',
        enter: 'Inserisci',
        selects: 'Seleziona',
        please_search: 'Clicca per cercare',
        modify_ladder: 'Modifica scala',
        pass: 'Passa attraverso',
        serial_number: 'No',
        individual: 'Individuale',
        upload_progress: 'Progresso caricamento',
        port_choose: 'Scelta porta',
        request_code: 'Controlla gli accessori',
        request_discuss: 'Seleziona un fornitore',
        request_dt: 'Controlla DT',
        request_thing: 'Controlla la categoria dei materiali',
        translate: 'Solo lettere',
        accessories_information: 'Informazioni sugli accessori',
        select_accessories: 'Seleziona accessori',
        binding: 'Vincolante',
        tick_data: 'Controlla i dati',
        invalid: 'Non valido',
        empty_data: 'Cancella dati generati',
        confirm_number: 'Conferma numero lista imballaggi',
        variance_details: 'Dettagli della varianza',
        daily_record: 'Registro quotidiano',
        operation_type: 'Tipo di operazione',
        field_name: 'Nome del campo',
        edit_role: 'Modifica ruolo',
        user_role: 'Ruolo utente',
        assignment_role: 'Ruolo',
        post: 'Posta',
        please_settled: 'Seleziona gli accessori da sistemare',
        not_sent: 'Non inviato',
        sending: 'Inviando',
        has_been_sent: 'È stato inviato',
        fail_in_send: 'Invio fallito',
        express_import: 'Importazione espressa',
        reset_password: 'Reimposta password',
        display_or_not: 'Mostra o non mostra',
    },
    radio: {
        enabled: 'Abilitato',
        disabled: 'Disabilitato',
        catalogue: 'Catalogo',
        individual: 'Individuale',
        all: 'Tutto',
        tips: 'Consigli',
        save: 'Salva',
        yes: 'Sì',
        no: 'No',
    },
    dropdown: {
        close_others: 'Chiudi Altri',
        close_right: 'Chiudi a Destra',
        close_left: 'Chiudi a Sinistra',
    },
    table: {
        action: 'Operazione',
        explain: 'Spiegazione',
        fileName: 'Nome File',
        size: 'Dimensione(MB)',
        uploader: 'Caricatore',
        uploadTime: 'Ora di Caricamento',
        system: 'Sistema',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/pagina`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} record in totale`,
    },
    confirm: {
        confirm_deletion: 'Conferma la cancellazione?',
        confirm_void: 'Conferma vuoto?',
        confirm_release: 'Conferma rilascio?',
        ack: 'Conferma',
        cancel: 'Annulla',
        non_records: 'Seleziona almeno un pezzo di dati',
    },
    request: {
        failed_placeholder: 'Richiesta fallita, errore del backend',
        success_placeholder: 'Richiesta riuscita',
        auth: {
            login_success: 'Accesso riuscito',
        },
        error: {
            '404': 'URL Richiesta non trovato',
            '403': 'Permesso negato',
        },
    },
    page: {
        global: {
            page_search: 'Ricerca Pagina',
        },
        error: {
            not_found: 'Non trovato',
            not_found_des: 'Non c\'è niente qui',
            auth_failed: 'Autenticazione fallita',
            auth_failed_des: 'Questa pagina non è raggiungibile',
        },
        homepage: {
            homepage: homepage.homepage.itIT,
        },
        system: {
            menu: {
                menu: 'Menu',
                menu_name: 'Nome del Menu',
                menu_type: 'Tipo di Menu',
                menu_new: 'Nuovo Menu',
                menu_edit: 'Modifica Menu',
                icon: 'Icona',
                parent: 'Menu Principale',
                order_number: 'Ordinamento',
                path: 'Indirizzo del Percorso',
                status: 'Stato del Menu',
            },
            dict: {
                dictionary_name: 'Nome del Dizionario',
                creater: 'Creatore',
                dictionary_code: 'Codice del Dizionario',
                code_type: 'Tipo di Codice',
                code_type_name: 'Nome del Tipo di Codice',
                creation_time: 'Data di Creazione',
                dict_new: 'Nuovo Dizionario',
                dict_edit: 'Modifica Dizionario',
                lang: 'Lingua',
                sort: 'Ordinamento',
            },
            dept: {
                system_selection: 'Selezione del Sistema',
                parent_dept: 'Dipartimento Superiore',
                dept_name: 'Organizzazione',
                leader: 'Responsabile',
                email: 'Email',
                create_by: 'Creatore',
                create_date: 'Data di Creazione',
                organization_editing: 'Modifica Organizzazione',
                phone: 'Telefono',
                organizational_type: 'Tipo di Organizzazione',
            },
            role: {
                menu_maintenance: 'Menu',
                role_name: 'Nome del Ruolo',
                role_key: 'Codice del Ruolo',
                role_id: 'Ordine di Visualizzazione',
                status: 'Stato',
                create_date: 'Data di Creazione',
                role_sort: 'Ordinamento',
                role_new: 'Nuovo Ruolo',
                role_edit: 'Modifica Ruolo',
                role_bleak: 'Menu',
            },
            user: {
                serial_no: 'Numero di Serie',
                username: 'Nome Utente',
                name: 'Nome',
                sex: 'Sesso',
                phone_number: 'Numero di Telefono',
                email: 'Email',
                role: 'Ruolo',
                login_time: 'Abilita stato',
                password: 'Password',
                user_edit: 'Modifica Utente',
            },
        },
        management: {
            purchasePosting: {
                order_status: 'Stato dell\' Ordine SLK',
                transportation_method:
                    'Tipo di Ordine, Metodo di Trasporto, Termini Commerciali diversi, si prega di selezionare gli Ordini con lo stesso tipo!',
                whether_to_send: 'Inviare a SAP?',
                invoice_number: 'Numero Fattura',
                sales_purchase_tax_code: 'Codice Fiscale Acquisto/Vendita',
                invoice_reversal: 'Annullamento Fattura',
                charge_against: 'Storno',
                send_sap: 'Invio a SAP',
                whether_notify_invoicing: 'Notificare la fatturazione',
                import_invoices: 'Importa Fatture',
                import_information: 'Importa Informazioni Doganali',
                invoicing_notice: 'Avviso di Fatturazione',
                tax_rate: 'Aliquota Fiscale',
                section: 'Section',
                prepayment_not: 'Acconto non Pagato',
                prepayment_note_no: 'Acconto non Pagato No.',
                list_to_be_invoiced: 'Elenco Fatture da Emettere',
                invoiced_but_unpaid_list: 'Lista Fatture Emesse ma non Pagate',
                invoiced_and_paid_list: 'Lista Fatture Emesse e Pagate',
                dn_no: 'Numero DN',
                sub_order_no: 'Numero Ordine Figlio',
                packing_list_no: 'Numero Bolla di Spedizione',
                brand: 'Brand',
                whether_invoicable: 'Fatturabile?',
                payment_type: 'Tipo di Pagamento',
                advance_payment: 'Pagamento Anticipato',
                oem_order_no: 'Numero Ordine OEM',
                tax_amount: 'Ammontare Imposta',
                invoice_amount: 'Ammontare Fattura',
                invoice_tax_amount: 'Ammontare Imposta Fattura',
                amount_including_tax: 'Ammontare Inclusa l\'imposta',
                amount_excluding_tax: 'Ammontare Esclusa l\'imposta',
                amount_of_money: 'Ammontare',
                payment_time: 'Orario Pagamento',
                payment_order_no: 'Numero Ordine di Pagamento',
                sap_voucher_No: 'Numero Voucher SAP',
                export_gold_tax: 'Esportazione Gold tax',
                export_maxus: 'Esportazione elenco fatturazione MAXUS',
                time_of_payment: 'Orario Pagamento',
                apply_for_payment: 'Richiesta di Pagamento',
            },
            preBooking: {
                booking: 'Prenotazione Cabina',
                booking_space: 'Prenotazione Spazio',
                packing_list_no: 'Numero Bolla di Spedizione',
                job_no: 'Numero Job',
                dangerous_goods_or_not: 'Merce Pericolosa o Non Pericolosa?',
                brand: 'Brand',
                type_of_shipping: 'Tipo di Spedizione',
                dt_code: 'Codice DT',
                estimated_volume: 'Volume Stimato (m³)',
                estimated_gross_weight: 'Peso Lordo Stimato (kg)',
                estimated_net_weight: 'Peso Netto Stimato (kg)',
                estimated: 'Quantità Box Stimata',
                volume: 'Volume (m³)',
                gross_weight: 'Peso Lordo (kg)',
                net_weight: 'Peso Netto (kg)',
                shipping_schedule: 'Calendario Spedizioni Job No',
                shipping_schedules: 'Il Calendario Spedizioni Job No non può essere vuoto.',
                shipping_type: 'Tipo di Trasporto',
                box_quantity: 'Quantità Box Stimata Job No',
            },
            shoppingCart: {
                dcs_order_number: 'DCS Ordine Numero',
                vin_requires:
                    'VIN Richiede 17 Caratteri con una Combinazione di 3 Lettere Maiuscole e Numeri',
                part_type: 'Tipo di Pezzo di Ricambio',
                submit_validation: 'Invia Validazione',
                maximum_value: 'Massimo Valore 9999999999',
                please_select_cart:
                    'Si Prega di Selezionare le Parti che si Desidera Aggiungere al Carrello',
                please_enter_format:
                    'Si Prega di Inserire un Formato in PDF, XLSX, XLS Format, WORD Format',
                please_format: 'Si Prega di Inserire un Formato in JPG, PNG, GIF',
                please_enter_an_integer:
                    'Si Prega di Inserire il Numero di Pezzi Ordinati Corretto',
                customized_orders: 'Ordini Personalizzati',
                details_accessory: 'Dettagli del Codice dell\'Accessorio',
                warning: 'Confermare la Cancellazione?',
                please_payment_method: 'Si Prega di Selezionare un Metodo di Pagamento',
                please_port: 'Si Prega di Selezionare il Porto',
                please_party: 'Si Prega di Selezionare la Parte di Consegna',
                no_inventory: 'Nessuna Scorta',
                notes: 'Note',
                note_the: 'Il Pezzo Personalizzato non Ha un VIN/Accessorio Associato',
                download_template: 'Scarica il Modello',
                single_addition: 'Aggiunta Singola',
                material_category: 'Categoria del Materiale',
                order_type: 'Tipo di Ordine',
                accessory_code: 'Codice dell\'Accessorio',
                part_name: 'Nome Pezzo di Ricambio',
                term_of_trade: 'Termini Commerciali',
                logistics_mode: 'Modalità di Logistica',
                type_of_fittings: 'Tipo di Accessorio',
                available_balance: 'Saldo Disponibile',
                order_deadline: 'Termine di Scadenza dell\'Ordine',
                place_order: 'Invia Ordine',
                order_information: 'Informazioni sull\'Ordine',
                order_No: 'Numero d\'ordine',
                supplier: 'Fornitore',
                creation_date: 'Data di Creazione',
                type_of_shipping: 'Tipo di Spedizione',
                payment_method: 'Metodo di Pagamento',
                port: 'Porto',
                delivered_by: 'Consegnato Da',
                remarks: 'Osservazioni',
                brand: 'Marca',
                currency: 'Moneta',
                accessory: 'Riga di Accessorio',
                number_accessories: 'Numero di Accessori',
                amount_accessories: 'Importo degli Accessori',
                freight: 'Spedizione (Stima)',
                premium: 'Premio Assicurativo',
                order_amount: 'Importo dell\'Ordine',
                total_transportation: 'Volume Totale della Spedizione (m³)',
                ordinary_goods: 'Data di Spedizione Stimata per Merce Ordinaria',
                arrival_date: 'Data di Spedizione Stimata per Merce Pericolosa',
                accessories_information: 'Informazioni sull\'Accessorio',
                whole: 'Tutto',
                routine: 'Rutine',
                conventional_parts: 'Parti Convenzionali',
                dangerous_goods: 'Merce Pericolosa',
                customized: 'Personalizzato',
                customized_parts: 'Parti Personalizzate',
                chemicals: 'Prodotti Chimici',
                oily_parts: 'Parti Oleose',
                commodity_inspection_parts: 'Parti di Controllo Commerciale',
                incorrect: 'Dati di Input Errati',
                data_empty: 'Dati non Devono Essere Vuoti',
                cannot_time: 'Non Può Essere Inferiore al Tempo Corrente',
                cannot_current_time: 'Non Può Essere Superiore al Tempo Corrente',
                payment: 'Il Numero del Documento di Pagamento Non Deve Essere Vuoto',
                permits: 'Documenti di Autorizzazione',
                accessories_selection: 'Selezione dell\'Accessorio',
                forbidden_empty: 'Vietato Vuoto',
                add_cart: 'Aggiungi al Carrello',
                order_number: 'Numero di Ordine',
                replacement: 'Pezzo di Ricambio di Sostituzione',
                order_quantity: 'Quantità Ordinata',
                unit_price: 'Prezzo Unitario',
                company: 'Azienda',
                be_purchased: 'Acquistabile o Meno',
                quantity: 'Quantità Minima Ordinabile',
                total_amount: 'Importo Totale',
                volumes: 'Volume (m³)',
                volume: 'Volume',
                enclosure: 'Accessorio',
                confirmed_quantity: 'Quantità Confermata',
                courier: 'Volo/Nave/Corriere Espresso',
            },
            salesquery: {
                actual_delivery: 'Data di Consegna Effettiva',
                estimated_delivery: 'Data di Consegna Stimata',
                total_package: 'Peso Lordo Totale dell\'Imballaggio',
                total_gross: 'Peso Lordo Totale',
                trade_mode: 'Modalità di Commercio dopo il Trasporto',
                mode_before: 'Modalità di Commercio Prima del Trasporto',
                port_after_transshipment: 'Porto Dopo il Trasporto',
                port_diversion: 'Porto Prima del Trasporto',
                outer: 'Dimensioni Esterne della Scatola (Altezza mm)',
                outer_box_size: 'Dimensioni Esterne della Scatola (Larghezza mm)',
                outer_box: 'Dimensioni Esterne della Scatola (Lunghezza mm)',
                volume: 'Volume (mm³)',
                gross_weight: 'Peso Lordo (kg)',
                net_weight: 'Peso Netto (kg)',
                shipment_quantity: 'Quantità di Spedizione',
                ordering_parts_code: 'Codice Pezzi Ordinati',
                packing_code: 'Numero di Imballaggio',
                container_no: 'Numero di Container',
                sales_order_information: 'Informazioni dell\'Ordine di Vendita',
                packing_list_no: 'Numero di Packing List',
                accessory_code: 'Codice Accessorio',
                order_no: 'Numero Ordine',
                merge_ci: 'Unisci CI',
                export_ci: 'Esporta CI',
                export_pl: 'Esporta PL',
                currency: 'Valuta',
                parts_amount: 'Importo Accessori',
                freight: 'Spedizione',
                premium: 'Premio Assicurativo',
                other_expenses: 'Altri Costi',
                total_amoun: 'Importo Totale',
                creation_date: 'Data di Creazione della Packing List',
                ship_number: 'Numero Nave/Volo/Corriere',
                supplier: 'Fornitore',
                accessories: 'Numero Totale di Accessori',
                total_accessories: 'Quantità Totale di Accessori',
                gross_weights: 'Peso Lordo Totale',
                total_weight: 'Peso Netto Totale',
                total_packages: 'Volume Totale dell\'Imballaggio (m³)',
            },
            suborder: {
                packing_details: 'Dettagli dell\'Elenco di Imballaggio',
                arrive: 'Arrivo',
                shipped: 'Spedito',
                complete_packaging: 'Imballaggio Completo',
                passenger_order_number: 'Numero d\'Ordine Passeggeri',
                order_detail: 'Dettaglio Ordine',
                order_details: 'Dettagli Sottoordine',
                approved: 'Approvato',
                order_submission: 'Invio Ordine',
                accessory: 'Accessorio',
                order_amount: 'Importo Ordine',
                company: 'Azienda',
                name_accessories: 'Nome Accessorio',
                accessory_code: 'Codice Accessorio',
                serial_no: 'Numero di Serie',
                submit_smpv: 'Invia SMPV',
                sub_order_no: 'Numero Sottoordine',
                sub_order_type: 'Tipo di Sottoordine',
                brand: 'Brand',
                type_hipping: 'Modalità di Spedizione',
                dt_code: 'Codice DT',
                sub_order_amount: 'Importo Sottoordine',
                trade_terms: 'Termini di Commercio',
                passenger_number: 'Numero Ordine Passeggeri',
                dcs_order_number: 'Numero d\'Ordine DCS',
                remarks: 'Note',
                submission_date: 'Data di Invio',
                supplier: 'Fornitore',
                mode_of_trade: 'Modalità di Commercio',
                port: 'Porto',
                to: 'Destinatario',
                currency: 'Valuta',
                cancel_date: 'Data di Annullamento',
                accessory_information: 'Informazioni Accessorio',
            },
            query: {
                export_pi: 'Esporta PI',
                audit_status: 'Stato di Revisione',
                cancel: 'Annulla',
                whether_submit: 'Invio',
                actual_arrival: 'Data di Arrivo Effettiva',
                estimated_arrival: 'Data di Arrivo Stimata',
                packaging_completion_date: 'Data di Completamento Imballaggio',
                order_no: 'Numero di Ordine',
                order_status: 'Stato dell\'Ordine',
                rough_draft: 'Bozza',
                funds_be_reviewed: 'Fondi in Attesa di Revisione',
                approved: 'Approvato',
                canceled: 'Annullato',
                order_type: 'Tipo di Ordine',
                creation_date: 'Data di Creazione',
                accessory_code: 'Codice Accessorio',
                mode_transport: 'Modalità di Trasporto',
                brand: 'Marca',
                export_order_details: 'Esporta Dettagli Ordine',
                dt_code: 'Codice DT',
                order_amount: 'Importo dell\'Ordine',
                term_of_trade: 'Termini del Commercio',
                submission_date: 'Data di Invio',
                DCS_order_number: 'Numero d\'Ordine DCS',
                remarks: 'Note',
                upload_credit: 'Carica Lettera di Credito',
                lc_no: 'Numero di Lettera di Credito',
                term_validity: 'Periodo di Validità',
                amount: 'Importo della Lettera di Credito',
                picture: 'Immagine della Lettera di Credito',
                empty: 'Il Numero di Lettera di Credito non può essere vuoto',
                payment_registration: 'Registrazione Pagamento',
                payment_voucher_no: 'Numero del Voucher di Pagamento',
                payment_date: 'Data di Pagamento',
                payment_amoun: 'Importo del Pagamento',
                number_lines: 'Numero della Linea di Accessori',
                total_accessories: 'Quantità Totale di Accessori',
                estimated_premium: 'Premio Stimato',
                estimated_freight: 'Spedizione Stimata',
                estimated_amount: 'Importo dell\'Ordine Stimato',
                order_tracking: 'Tracciamento dell\'Ordine',
                put_away: 'Collassare',
                more: 'Di Più',
                actually_delivered: 'Codice Accessorio Effettivamente Consegnato',
                confirm_quantity: 'Conferma Quantità',
                package_quantity: 'Quantità d\'Imballaggio',
                shipment_quantity: 'Quantità della Spedizione',
                arrival_quantity: 'Quantità di Arrivo',
                cancel_quantity: 'Quantità Annullata',
                bo_quantity: 'Quantità BO',
                order_submission: 'Invio dell\'Ordine',
                warn: 'Nota: 1.Indicare l\'importo Accessorio o del Veicolo durante il trasferimento;',
                warns: '2.Il saldo dell\'account è basato sull\'importo effettivo ricevuto;',
                voucher: 'Inserisci il Numero del Voucher di Pagamento',
                payment_dates: 'Inserisci la Data di Pagamento',
                payment_amount: 'Inserisci l\'Importo del Pagamento',
                credit_number: 'Inserisci il Numero di Lettera di Credito',
                validity_period: 'Inserisci il Periodo di Validità',
                letter_credit: 'Inserisci l\'Importo della Lettera di Credito',
                upload_picture: 'Carica Immagine della Lettera di Credito',
                part_no: 'Codice Accessorio',
                part_name: 'Nome Accessorio',
                quantity: 'Quantità d\'Ordine',
                accessories: 'Dettagli Accessorio',
                company: 'Società di Navigazione',
                ships_name: 'Nome della Nave',
                flights: 'Voli',
                customs_no: 'Numero di Sdoganamento',
                lading_no: 'Numero del Conosciuto di Carico',
                flight_number: 'Numero del Volo',
                container_no: 'Numero del Contenitore',
                packing_list: 'Numero Elenco di Imballaggio',
                packaging_quantity: 'Quantità di Imballaggio',
                packaging_completion: 'Data di Completamento Imballaggio',
                shipment_quantitys: 'Quantità della Spedizione',
                delivery_date: 'Data di Consegna Stimata',
                shipment_dates: 'Data di Spedizione Effettiva',
                arrival_quantitys: 'Quantità di Arrivo',
                arrival_date: 'Data di Arrivo Stimata',
                actual_arrival_date: 'Data di Arrivo Effettiva',
            },
            salesManagement: {
                booking_email: 'Email prenotazione',
                additional_fee_amount: 'Importo spese aggiuntive',
                before_modifications: 'Prima della modifica',
                after_modification: 'Dopo la modifica',
                continue_importing: 'Continua l\'importazione',
                customs_declaration_name: 'Nome dichiarazione doganale',
                customs_declaration_not: 'Dichiarazione doganale',
                invoice_no: 'Numero fattura',
                contract_no: 'Numero contratto',
                attachment_fee_amount: 'Importo spese accessorie:',
                expense_amount: 'Importo spese',
                import_list: 'Importa lista contenitori MG',
                import_packing: 'Importa lista contenitori MAXUS',
                sales_order_list: 'Lista ordini di vendita',
                job_no_list: 'Lista numeri di lavoro',
                dt_code: 'Codice DT',
                brand: 'Marca',
                packing_list_no: 'Numero lista contenitori',
                packing_list_nos: 'Numero lista contenitori originale:',
                bilateral_contract_no: 'Numero contratto bilaterale',
                contract_status: 'Stato contratto',
                inbound_delivery: 'Numero consegna in ingresso',
                supplier: 'Fornitore',
                list_creation: 'Data creazione lista contenitori',
                currency: 'Valuta',
                transit_status: 'Stato consegna',
                split_merge: 'Dividi/Fondi',
                sap_return_information: 'Informazioni ritorno SAP',
                send_booking: 'Invia email di prenotazione',
                lcl_or_not: 'Carico LCL',
                operate: 'Operare',
                modify_freight: 'Modifica spedizione',
                modify_lc: 'Modifica LC',
                detailed: 'Dettagliato',
                merge: 'Unisci',
                split: 'Dividi',
                diversion: 'Consegna alternativa',
                lcl: 'Carico LCL',
                charges: 'Spese accessorie',
                generation: 'Crea i documenti in un solo clic',
                deletion: 'Elimina i documenti in un solo clic',
                bind_lc: 'Associa LC',
                unbind_lc: 'Dissocia LC',
                merge_ci: 'Unisci CI',
                export_ci: 'Esporta CI',
                export_pl: 'Esporta lista contenitori',
                additional_charges: 'Spese accessorie:',
                packing: 'Numero lista contenitori:',
                expenses: 'Spedizione:',
                delivery: 'Numero consegna in uscita:',
                binding_record: 'Modifica record associazione LC',
                trade_terms: 'Termini commerciali:',
                logistics_mode: 'Modalità di logistica:',
                port: 'Porto:',
                payment_method: 'Metodo di pagamento:',
                please_select: 'Seleziona termini commerciali',
                please_logistics_mode: 'Seleziona modalità di logistica',
                please_port: 'Seleziona Porto',
                please_payment_method: 'Seleziona metodo di pagamento',
                select_export_type: 'Seleziona tipo di esportazione',
                plase_select_export_type: 'Seleziona tipo di esportazione',
                lc_code: 'Codice LC',
                sap_return_status: 'Stato ritorno SAP',
                amount_difference: 'Differenza d\'importo',
                invoice_date: 'Data fattura',
                invoice_import_date: 'Data importazione fattura',
                sap_voucher_code: 'Codice voucher SAP',
                unbinding: 'Dissociazione',
                no_unbinding_lc: 'Non ci sono LC da dissociare',
                sub_order_no: 'Numero sottordine',
                submit_sap: 'Invia a SAP',
                binding: 'Associazione',
                case_no: 'Numero contenitore:',
                outbound_delivery: 'Consegna in uscita',
                document_type: 'Tipo documento',
                quantity_difference: 'Differenza quantità',
                accessory_code: 'Codice accessorio',
                accessory_name: 'Nome accessorio',
                sales_order_information: 'Informazioni ordini di vendita',
                total_lines_of_accessories: 'Totale righe accessori:',
                total_number_of_accessories: 'Totale numero accessori:',
                total_amount: 'Importo totale:',
                amount_of_accessories: 'Importo accessori:',
                premium: 'Premio:',
                volume: 'Volume totale del pacchetto (m³):',
                total_gross_weight: 'Peso lordo totale del pacchetto:',
                weight_of_package: 'Peso netto totale del pacchetto:',
                container_no: 'Numero contenitore',
                actually_delivered: 'Codice accessorio effettivamente consegnato',
                order_parts_code: 'Codice accessory degli ordini',
                shipment_quantity: 'Quantità di spedizione',
                proforma_invoice: 'Numero fattura proforma',
                customs_date: 'Data dichiarazione doganale',
                customs_no: 'Numero dichiarazione doganale',
                net_weight: 'Peso netto (kg)',
                rough_weight: 'Peso lordo (kg)',
                volumes: 'Volume (mm³)',
                outer_bo_size: 'Dimensione scatola esterna (lunghezza mm)',
                box_size: 'Dimensione scatola esterna (larghezza mm)',
                box_height: 'Dimensione scatola esterna (altezza mm)',
                modification_date: 'Data modifica',
                modification_by: 'Modificato da',
                before_modification: 'Spedizione precedente alla modifica',
                modified_freight: 'Spedizione modificata',
                sales_slip: 'Scontrino di vendita',
                modification_record: 'Record di modifica della spedizione',
            },
            price: {
                DNNumber: 'Numero DN',
                deliveryDate: 'Data di consegna',
                purchasePrice: 'Prezzo d\'acquisto',
                priceForDelivery: 'Prezzo unitario di consegna auto passeggeri',
            },
        },
        document: {
            document: 'Documento',
            name: 'Nome',
            sort: 'Ordinamento',
            directory: 'Directory del documento',
            parent_directory: 'Directory genitore',
            new_directory: 'Nuova directory',
            edit_directory: 'Modifica directory',
            primary_directory: 'Directory di primo livello',
            document_title: 'Titolo del documento',
            vehicle_platform: 'Piattaforma veicolo',
            status: 'Stato',
            release_status: 'Stato di pubblicazione',
            create_time: 'Data di creazione',
            secondary_directory: 'Directory di secondo livello',
            model: 'Modello',
            attachment_name: 'Nome allegato',
            publishing_department: 'Reparto pubblicazioni',
            publisher: 'Pubblicatore',
            person_in_charge: 'Responsabile',
            remarks: 'Note',
            publish_objects: 'Oggetti di pubblicazione',
            publish_mode: 'Modalità di pubblicazione',
            whether_the_document_is_downloadable: 'Il documento è scaricabile?',
            document_information: 'Informazioni documento',
            document_tag: 'Tag del documento',
            operation_record: 'Registro operazioni',
            operation_details: 'Dettagli operazioni',
            operating_time: 'Tempo di operazione',
            operator: 'Operatore',
            to_be_released: 'In attesa di pubblicazione',
            published: 'Pubblicato',
            voided: 'Annullato',
        },
        announcement: {
            title: 'Titolo',
            publishing_department: 'Reparto Pubblicazione',
            status: 'Stato',
            release_Date: 'Data di Rilascio',
            serialNo: 'N°',
            publisher: 'Editore',
            file_Name: 'Nome File',
            details: 'Dettagli',
            void: 'Annulla',
            information_detail: 'Dettaglio Informazione',
            notification_Title: 'Titolo Notifica',
            publish_Objects: 'Destinatari',
            publish_Mode: 'Modalità di Rilascio',
            bulletin_id: 'ID Bollettino',
            publish_Content: 'Contenuto Rilascio',
            preview: 'Anteprima',
            attachmentUpload: 'Caricamento Allegato',
            supportedFormats: 'Formati Supportati',
            have: 'Hai',
            NoticeWaitingToBeRead: 'notifiche in attesa di lettura',
            released: 'Rilasciato',
            voided: 'Annullato',
        },
        basic: {
            accessories: {
                masterdata: {
                    english_smpv: 'SMPV Nome Inglese',
                    accessory_code: 'Codice Accessorio',
                    accessory_name: 'Nome Accessorio',
                    accessory_name_zh: 'Nome Cinese Accessorio',
                    accessory_name_en: 'Nome Inglese Accessorio',
                    accessory_enm: 'Descrizione Inglese Accessorio',
                    brand: 'Marchio',
                    sales_area: 'Zona di Vendita',
                    create_data: 'Data di Creazione',
                    is_purchase: 'Acquistabile?',
                    purchase_attribute: 'Attributo di Acquisto',
                    is_marketable: 'Vendibile?',
                    supplier_code: 'Codice Fornitore',
                    supplier_name: 'Nome Fornitore',
                    country_origin: 'Paese di Origine',
                    is_transportation: 'Trasporto Aereo Vietato?',
                    is_transportations: 'Vietato Trasporto Aereo?',
                    magnetic_inspection: 'Ispezione Magnetica',
                    material: 'Materiale',
                    is_max_overbooking: 'Quantità Massima Ordinabile Controllata?',
                    max_overbooking: 'Massima Quantità Ordinabile Consentita',
                    sap_code: 'Codice SAP',
                    unit: 'Unità Accessorio',
                    accessories_edit: 'Modifica Accessorio',
                    accessories_add: 'Aggiungi Accessorio',
                    accessories_detailed: 'Dettagli Accessorio',
                    special_procurement_cycle: 'Ciclo di Approvvigionamento Speciale',
                    name_of_customs_declaration_materials: 'Nome Materiali Dichiarazione Doganale',
                    customized_form: 'Ordine Personalizzato',
                    customs_declaration_unit: 'Unità Dichiarazione Doganale',
                    remarks: 'Osservazioni',
                    customs_code: 'Codice Doganale',
                    license_material_name: 'Nome Materiale Licenza',
                    create_by: 'Creato Da',
                    company: 'Azienda',
                    declare_ele: 'Elementi Dichiarativi',
                    base_cal_unit: 'Unità Accessorio',
                    base_cal_unit_cn: 'Unità Cinese Dichiarazione Doganale',
                    update_by: 'Aggiornato Da',
                    update_time: 'Data Aggiornamento',
                    accessories_picture: 'Immagine Accessorio',
                    custom_accessories: 'Accessori Personalizzati',
                    declaration_elements: 'Elementi di Dichiarazione',
                    transportation: 'Vietato Trasporto Aereo',
                    accessories_selection: 'Selezione Accessori',
                    enable_or_not: 'Abilitato?',
                    supplier_selection: 'Selezione Fornitore',
                    whether_glass: 'Contiene Vetro?',
                },
                orderInvoicing: {
                    partCode: 'Codice Accessorio',
                    brand: 'Marchio',
                    baseQuantity: 'Quantità di Base',
                    baseUnit: 'Unità di Base',
                    transferQuantity: 'Quantità di Trasferimento',
                    transferUnit: 'Unità di Trasferimento',
                    createBy: 'Creato Da',
                    createTime: 'Data di Creazione',
                    orderInvoicing_new: 'Nuovo',
                    orderInvoicing_edit: 'Modifica',
                },
                billMaterials: {
                    dt_name: 'DT', // Nome DT
                    part_code: 'Codice Accessorio', // Codice della parte
                    part_name_cn: 'Nome cinese accessori', // Nome cinese
                    part_name_en: 'Nome inglese accessori', // Nome inglese
                    apply_time: 'Data di applicazione', // Data di domanda
                    brand: 'Marchio', // Marchio
                },
                replace: {
                    group_no: 'N° Gruppo',
                    item_no: 'N° Articolo',
                    leading_products: 'Prodotti leader',
                    description_of_leading_products: 'Descrizione dei prodotti leader', // Descrizione dei prodotti leader
                    subsequent_products: 'Prodotti successivi', // Prodotti successivi
                    subsequent_product_description: 'Descrizione prodotto successivo', // Descrizione del prodotto successivo
                    substitutive_relationship: 'Relazione di sostituzione', // Relazione di sostituzione
                    exhaustion_strategy: 'Strategia di esaurimento', // Strategia di esaurimento
                    depletion_date: 'Data di esaurimento', // Data di esaurimento
                    effective_date: 'Data di efficacia', // Data di efficacia
                    creation_date: 'Data di creazione', // Data di creazione
                    newly_added: 'nuovi articoli aggiunti', // nuovi articoli aggiunti
                    newly_edit: 'modifica', // modifica
                },
                mxreplace: {
                    group_no: 'N° Gruppo',
                    project_no: 'N° Articolo',
                    part_code: 'Codice Accessorio',
                    part_name_cn: 'Nome cinese accessori',
                    new_part_code: 'Codice accessorio alternativo',
                    new_part_name_cn: 'Nome cinese di sostituzione select',
                    effective_time: 'Data di efficacia',
                    stock_deal: 'Suggerimenti per lo smaltimento delle scorte',
                    create_time: 'Data di creazione',
                    replace_relate: 'Relazione di sostituzione',
                    brand: 'Marchio',
                    replace_remarks: 'Sostituire le osservazioni',
                    newly_edit: 'modifica', // modifica
                    part_codes: 'Codice Accessorio',
                    new_part_codes: 'Codice parte di ricambio',
                    new_part_name_cns: 'Nome in cinese dei pezzi di ricambio',
                    stock_deals: 'Suggerimenti per la gestione delle scorte',
                },
                sap: {
                    sap_part_id: 'Chiave primaria', // 主键
                    part_id: 'ID parte', // 零件id
                    brand: 'Marchio', // 项目编号
                    part_code: 'Vecchio numero di materiale', // 零件代码
                    material_type: 'Tipo di materiale SAP', // ZTG2/Tipo di materiale SAP
                    customer_declare_material_name:
                        'Nome del materiale per la dichiarazione doganale', // 报关物料名称
                    material_name_en: 'Nome del materiale in inglese', // 英文物料名称
                    hs_code: 'Codice HS', // HS编码
                    customer_declare_licence:
                        'Nome della licenza del materiale dichiarato dal cliente', // 许可证物料名称
                    base_cal_unit: 'Unità di misura di base', // 基本计量单位
                    sap_code: 'Numero del materiale SAP', // SAP物料号
                    sap_codes: 'Tipo di materiale SAP', // SAP物料号
                    gross_weight: 'Peso lordo', // 毛重
                    declare_ele: 'Elementi dichiarati', // 申报要素
                    net_weight: 'Peso netto', // 净重
                    volume: 'Volume (mm³)', // 体积
                    specs: 'Specifiche', // 规格
                    danger_attr: 'Attributo prodotto 2 (merci pericolose)', // 产品属性2(危险品)
                    abroad_danger_attr:
                        'Attributo prodotto 3 (segnaletica prodotto pericoloso estero)', // 产品属性3(国外危险品标识)
                    status: 'Stato di creazione', // 状态0-草稿，1-进行中，2-已完成
                    create_by: 'Creato da', // 创建人
                    create_time: 'Data di creazione',
                    update_by: 'Modificato da', // 创建人
                    update_time: 'Data di modifica',
                    sap_edit: 'Modificare',
                    sap_add: 'Aggiungi',
                    error_reason: 'Messaggio di ritorno',
                    sap_status: 'Stato di ritorno SAP',
                    part_name_cn: 'Descrizione del materiale in cinese',
                    sale_tax_class: 'Classificazione fiscale delle vendite',
                    error_reasons: 'Tipo di materiale SAP',
                },
                coo: {
                    country_id: 'Paese',
                    brand: 'Marchio',
                    part_code: 'Codice accessorio',
                    part_name_cn: 'Nome cinese dell\'accessorio',
                    origin_country: 'Paese di origine',
                    create_by: 'Creato da',
                    create_time: 'Data di creazione',
                    coo_add: 'Aggiungi',
                    coo_edit: 'Modificare',
                    accessories_selection: 'Selezione degli accessori',
                },
                sapModify: {
                    gds_material_number: 'Numero di materiale GDS',
                    sap_material_number: 'Numero di materiale SAP',
                    chinese_name_of_material_zh: 'Nome in cinese del materiale ZH',
                    english_name_of_material_en: 'Nome in inglese del materiale EN',
                    material_declaration_name_z1: 'Nome della dichiarazione di materiale Z1',
                    declaration_elements: 'Elementi della dichiarazione',
                    classification_proposal_no: 'Numero di proposta di classificazione',
                    period_of_validity_of_classification:
                        'Periodo di validità della classificazione',
                    tax_classification_code: 'Codice di classificazione fiscale',
                    factory: 'Fabbrica',
                    status: 'Stato',
                    sap_status: 'Stato di ritorno SAP',
                    commodity_import_code_number: 'Numero codice importazione/merce',
                    sap_modify_edit: 'Modifica',
                    sap_modify_add: 'Aggiungi',
                },
            },
            sales: {
                weight_ladder: 'Scaletta di Peso',
                country: 'Paese',
                port: 'Porto',
                portCode: 'Codice Porto',
                portCN: 'Nome in cinese',
                portUS: 'Nome in inglese',
                EffectiveDate: 'Data di entrata in vigore',
                ExpirationDate: 'Data di Scadenza',
                oldEffectiveTime: 'Vecchio tempo effettivo',
                oldExpireTime: 'Vecchio tempo di scadenza',
                newEffectiveTime: 'Nuovo tempo effettivo',
                newExpireTime: 'Nuovo tempo di scadenza',
                freight: 'Mercurio',
                KG: 'KG',
                KGabove: 'Sopra KG',
                express: 'Area espressa',
                general: 'Generale',
                danger: 'Pericoloso',
                GP20: 'Prezzo unitario 20GP (USD)',
                GP40: 'Prezzo unitario 40GP (USD)',
                hq40: 'Prezzo unitario 40hq (USD)',
                region: 'Area espressa',
                currencyCode: 'Codice valuta',
                description: 'Descrizione',
                MinimumUnit: 'Unità minima',
                ChineseDescription: 'Descrizione cinese',
                symbol: 'Simbolo',
                updated_date: 'Ultima data di aggiornamento',
                price_benchmark: 'Prezzo di Riferimento',
                part_description: 'Descrizione Parte',
                excluding_tax: 'Prezzo d\'acquisto escluso tassa',
                tax: 'Prezzo d\'acquisto incluso tassa',
                price_including_tax: 'Prezzo di vendita nazionale incluso tassa',
                seller: 'Venditore',
                selling_party: 'Parte vendente',
                price: 'Prezzo',
                sales_ratio: 'Rapporto di vendita(%)',
                cifShare: {
                    trade_clause: 'Trade Clause',
                    order_type: 'Order Type',
                    type_of_shipping: 'Tipo di Spedizione',
                    dt: 'DT',
                    last_modified_date: 'Data ultima modifica',
                    creation_date: 'Data creazione',
                    sales_price_proportion: 'Proporzione prezzo di vendita（%）',
                    seller: 'Venditore',
                    dt_choose: 'Seleziona DT',
                    dt_code: 'Codice DT',
                    dt_name: 'Nome DT',
                    country: 'Paese',
                },
                cifSingle: {
                    trade_clause: 'Trade Clause',
                    accessory_type: 'Tipo di accessorio',
                    type_of_shipping: 'Tipo di Spedizione',
                    dt: 'DT',
                    freight_coefficient: 'Coefficiente di spedizione（%）',
                    premium_coefficient: 'Coefficiente premio（%）',
                    air_freight0: 'Spedizione aerea（0-100KG）',
                    air_freight100: 'Spedizione aerea（100-500KG）',
                    air_freight500: 'Spedizione aerea（500KG+）',
                    minimum_air_freight_charge: 'Minimo addebito spedizione aerea',
                    dangerous_goods_freight: 'Spedizione merci pericolose',
                    dangerous_goods_minimum_freight: 'Minima spedizione merci pericolose',
                    dangerous_goods_ITEM_freight: 'Spedizione merce ITEM pericolosa',
                    dangerous_goods_qty_freight: 'Spedizione merce qty pericolosa',
                    dt_choose: 'Seleziona DT',
                    dt_code: 'Codice DT',
                    dt_name: 'Nome DT',
                },
                triesLimit: {
                    dt: 'DT',
                    frequency_limit: 'Limite frequenza',
                    price_ratio: 'Rapporto prezzo（%）',
                    seller: 'Venditore',
                    brand: 'Marca',
                    dt_choose: 'Seleziona DT',
                    dt_code: 'Codice DT',
                    dt_name: 'Nome DT',
                },
                dcs: {
                    partial_transmission: 'Trasmissione parziale',
                    full_transmission: 'Trasmissione completa',
                    download_template: 'Scarica modello',
                    upload_Excel: 'Carica Excel',
                    upload_files: 'Carica file',
                    no_files_selected: 'Nessun file selezionato',
                    General_generation: 'Generazione generale',
                    DCS_price_delivery_price: 'DCS prezzo di consegna (FOB)',
                },
            },
            sort: {
                maxmaterial: {
                    category_code: 'Codice categoria del materiale',
                    category_desc: 'Descrizione',
                    maxmaterial_add: 'Aggiunto di recente',
                    maxmaterial_edit: 'Modifica',
                },
                minmaterial: {
                    no_air: 'Nessun trasporto aereo',
                    category_code: 'Codice Categoria Materiale',
                    category_desc: 'Descrizione della categoria materiale',
                    big_material_category_id: 'Categoria materiale',
                    pack_alone: 'Se è confezionato separatamente',
                    minmaterial_add: 'Aggiunto di recente',
                    minmaterial_edit: 'Modifica',
                    material_category_selection: 'Selezione della categoria di materiale',
                },
                model: {
                    type_code: 'Codice tipo',
                    brand: 'Marca',
                    parts_code: 'Codice parti',
                    parts_name: 'Nome delle parti',
                    save_add: 'Salva e nuovo',
                },
                code: {
                    type_code: 'Codice tipo',
                    model_grade: 'Grado di modello',
                    save_add: 'Salva e nuovo',
                },
                marialMaint: {
                    small_material_category_id: 'Codice categoria materiale', // 物料小类代码
                    brand: 'Marca',
                    create_time: 'Tempo di creazione',
                    create_by: 'Creato da',
                    part_id: 'Codice accessorio', // 配件代码
                    marial_maint_add: 'Aggiunto di recente',
                    marial_maint_edit: 'Modifica',
                },
            },
            packing: {
                mpq: {
                    transport: 'Tipo di trasporto',
                    category_code: 'Codice della categoria',
                },
                information: {
                    transport_package_No: 'Numero di imballaggio di trasporto',
                    classification: 'Classificazione',
                    name: 'Nome',
                    purpose: 'Scopo',
                    length: 'Lunghezza(mm)',
                    width: 'Larghezza(mm)',
                    height: 'Altezza(mm)',
                    volume: 'Volume(CBM)',
                    specification_requirements: 'Requisiti di specifica',
                    brand: 'Marca',
                    creation_date: 'Data di creazione',
                    created_by: 'Creato da',
                    purchasing_unit: 'Unità di acquisto',
                },
                volume: {
                    volume_factor: 'Fattore di volume dell\'imballaggio di trasporto',
                    shipping_type: 'Tipo di spedizione',
                    brand: 'Marca',
                    creation_date: 'Data di creazione',
                    created_by: 'Creato da',
                },
                cmaintain: {
                    packing_type_code: 'Codice tipo di imballaggio',
                    english_description: 'Descrizione inglese',
                },
                sap: {
                    pack_material_code: 'Numero materiale di imballaggio',
                    chinese_desc: 'Nome cinese',
                    english_desc: 'Nome inglese',
                    sap_add: 'Aggiungi',
                    sap_edit: 'Modifica',
                },
                minmaterial: {
                    small_material_category_code: 'Codice della piccola categoria materiale',
                    small_material_category_desc: 'Descrizione della piccola categoria materiale',
                    special_compute: 'Calcolo speciale',
                    create_time: 'Data di creazione',
                    create_by: 'Creato da',
                    small_code: 'Codice della piccola categoria',
                    minmaterial_add: 'Aggiungi',
                    minmaterial_edit: 'Modifica',
                },
                maintain: {
                    package_volume: 'Volume dell\'imballaggio(mm³)',
                    part_code: 'Codice della parte',
                    brand: 'Marca',
                    minimum_number_of_packages: 'Quantità minima di imballaggi',
                    long: 'Lunghezza',
                    width: 'Larghezza',
                    height: 'Altezza',
                    gross_weight: 'Peso lordo',
                    net_weight: 'Peso netto',
                    creation_date: 'Data di creazione',
                    create_by: 'Creato da',
                    revision_date: 'Data di modifica',
                    revision_by: 'Modificato da',
                    package_type: 'Tipo di imballaggio',
                    unit_of_length: 'Unità di lunghezza',
                    unit_of_weight: 'Unità di peso',
                    package_plan: 'Piano di imballaggio',
                },
                dangerInfo: {
                    accessory_code: 'Codice accessorio',
                    nam_accessories: 'Nome inglese accessorio',
                    accessories_cn: 'Nome cinese dell\'accessorio',
                    brand: 'Marca',
                    bonfire_experiment: 'Numero sperimentale di falò',
                    bonfire_experiment_cn: 'Nome cinese del prodotto sperimentale di falò',
                    bonfire_experiment_en: 'Nome inglese del prodotto sperimentale di falò',
                    dangerous_goods_supplier: 'Fornitore di merci pericolose',
                    length: 'Lunghezza(mm)',
                    width: 'Larghezza(mm)',
                    height: 'Altezza(mm)',
                    packing_type: 'Tipo di imballaggio',
                },
            },
            customs: {
                hscode: {
                    hs_code: 'Codice doganale', // 海关代码
                    hscode_unit: 'L/KG', // L/KG
                    is_inspect: 'Ispezione merce o no', // 是否商检，Y-是，N-否
                    tax_refund_rate: 'Tasso di rimborso fiscale', // 退税率
                    supervise_condition: 'Condizioni di regolamentazione', // 监管条件
                    company: 'Azienda', // 公司
                    country_id: 'Nazione', // 国家
                    create_time: 'Data di creazione',
                    hscode_add: 'Nuovamente aggiunto',
                    hscode_edit: 'Modifica',
                },
                export: {
                    brand: 'Marca', // 品牌
                    part_code: 'Codice accessorio', // 配件代码
                    conutry_code: 'Codice del paese', // 国家代码
                    hscode: 'Codice doganale', // 海关编码
                    form: 'FORM-E', // FORM-E
                    tariff: 'TARIFF', // TARIFF
                },
                fluid: {
                    brand: 'Marca',
                    part_id: 'Codice accessorio',
                    part_id_name: 'Nome degli accessori',
                    fluid_add: 'Nuovamente aggiunto',
                },
                danger: {
                    company: 'Azienda', // 公司
                    ship_type: 'Tipo di spedizione', // 运输方式
                    part_code: 'Codice accessorio', // 配件代码
                    brand: 'Marca',
                    danger_type: 'Tipo di materiale pericoloso', // 危险品类型
                    danger_category: 'Classificazione delle merci pericolose', // 危险品分类
                    chinese_part_name: 'Nome cinese degli accessori',
                    english_part_name: 'Nome inglese degli accessori',
                    danger_add: 'Nuovamente aggiunto',
                    danger_edit: 'Modifica',
                },
                choren: {
                    chinese_unit: 'Unità cinese',
                    english_unit: 'Unità inglese',
                    create_by: 'Creato da',
                    create_time: 'Data di creazione',
                    choren_add: 'Nuovamente aggiunto',
                    choren_edit: 'Modifica',
                },
                maintain: {
                    dt: 'DT',
                    material_no: 'Numero materiale',
                    brand: 'Marca',
                    customs_code: 'Codice doganale',
                    form_e_mark: 'Form E Mark',
                    created_on: 'Creato il',
                    created_by: 'Creato da',
                    maintain_add: 'Nuovamente aggiunto',
                    maintain_edit: 'Modifica',
                },
                chemicali: {
                    company: 'Azienda',
                    material_no: 'Numero materiale',
                    brand: 'Marca',
                    license_or_not: 'Con licenza o no',
                    chemicali_add: 'Nuovamente aggiunto',
                    chemicali_edit: 'Modifica',
                },
            },
            salesl: {
                fixedRate: {
                    local_currency: 'Valuta locale',
                    name_of_local_currency: 'Nome della valuta locale',
                    seller: 'Venditore',
                    fixed_rate: 'Tasso fisso',
                    settlement_currency: 'Valuta di regolamento',
                    name_of_settlemen_currency: 'Nome della valuta di regolamento',
                    dt: 'DT',
                    brand: 'Marca',
                    effective_date: 'Data di inizio validità',
                    expiration_date: 'Data di scadenza',
                    created_on: 'Data di creazione',
                    fixedRate_add: 'Aggiungi nuova',
                    fixedRate_edit: 'Modifica',
                    fixedRate_select: 'Dopo aver selezionato DT, la marca non è vuota',
                },
                transport: {
                    dt: 'DT',
                    brand: 'Marca',
                    trade_terms: 'Termini commerciali',
                    type_of_shipping: 'Modalità di trasporto',
                    transport_add: 'Aggiungi nuova',
                    created_on: 'Data di creazione',
                    transport_edit: 'Modifica',
                },
                purchaseConfirm: {
                    accessory_code: 'Codice accessorio',
                    effective_date: 'Data di inizio validità',
                    expiration_date: 'Data di scadenza',
                    accessory_description: 'Descrizione accessorio',
                    purchase_price: 'Prezzo di acquisto escluso il iva',
                    tax_rate: 'Tasso fiscale',
                    currency: 'Valuta',
                    corporation: 'Azienda',
                    created_on: 'Data di creazione',
                },
            },
            sapModify: {
                gds_material_number: 'Numero di materiale GDS',
                sap_material_number: 'Numero di materiale SAP',
                chinese_name_of_material_zh: 'Nome in cinese del materiale ZH',
                english_name_of_material_en: 'Nome in inglese del materiale EN',
                material_declaration_name_z1: 'Nome della dichiarazione di materiale Z1',
                declaration_elements: 'Elementi della dichiarazione',
                classification_proposal_no: 'Numero di proposta di classificazione',
                period_of_validity_of_classification: 'Periodo di validità della classificazione',
                tax_classification_code: 'Codice di classificazione fiscale',
                factory: 'Fabbrica',
                status: 'Stato',
                sap_status: 'Stato di ritorno SAP',
                commodity_import_code_number: 'Numero codice importazione/merce',
                sap_modify_edit: 'Modifica',
                sap_modify_add: 'Aggiungi',
            },

            customer: {
                fundPool: {
                    dt_code: 'Codice DT',
                    dt_name: 'Nome DT',
                    dt_short_name: 'Nome breve DT',
                    currency: 'Valuta',
                    account_amount: 'Importo dell\'account',
                    freeze_amount: 'Importo in blocco',
                    date: 'Data',
                    fund_status: 'Stato del fondo',
                    fund_type: 'Tipo di fondo',
                    payment_number: 'Numero di pagamento',
                    credit_number: 'Numero di credito',
                    suborder_number: 'Numero di sottordine',
                    container_number: 'Numero di contenitore',
                    credit_validity_date: 'Data di validità del credito',
                    recorded: 'Registrato / Disbursement',
                    disbursement_money: 'Denaro per il rilascio',
                    wait_recorded_money: 'Denaro in attesa per la registrazione',
                    recorded_money: 'Denaro registrato',
                    service_charge: 'Commissione di servizio',
                    account_balance: 'Saldo dell\'account',
                    freeze_balance: 'Saldo bloccato',
                    credit_picture: 'Immagine di credito',
                    upload_list: 'Carica lista',
                    remark: 'Nota',
                    dt_payment_confirm: 'Conferma di pagamento DT',
                    credit_confirm: 'Conferma di credito',
                    credit_money: 'Denaro di credito',
                    arrival_amount: 'Importo di arrivo',
                    dt_selection: 'Selezione DT',
                },
                customer: {
                    dt_code: 'Codice DT',
                    dt_name: 'Nome DT',
                    dt_short_name: 'Nome breve DT',
                    country: 'Paese',
                    sap_code: 'Codice SAP',
                    brand: 'Marca',
                    beneficiary_name: 'Nome del beneficiario',
                    beneficiary_address: 'Indirizzo del beneficiario',
                    VAT: 'IVA（%）',
                    Name_of_bank_of_deposit: 'Nome della banca di deposito',
                    VAT_number: 'Numero di partita IVA',
                    bank_address: 'Indirizzo della banca',
                    SWIFT_CODE: 'SWIFT CODE',
                    bank_account: 'Conto bancario',
                    Agent_bank_address: 'Indirizzo della banca dell\'agente',
                    Agent_bank_account: 'Conto bancario dell\'agente',
                    Agent_bank_name: 'Nome della banca dell\'agente',
                    IBAN: 'IBAN',
                    Agent_bank_iban: 'IBAN della banca dell\'agente',
                    Agent_bank_SWIFT_Code: 'Codice SWIFT della banca dell\'agente',
                    Bank: 'BANCA',
                    inherit_general_generation: 'Eredità generale della generazione',
                    money: 'Denaro',
                    A_24_hour_hotline: 'Una linea diretta 24 ore su 24',
                    facsimile: 'Fax',
                    telephone: 'Telefono',
                    local_country: 'Paese locale',
                    local_city: 'Città locale',
                    local_area: 'Zona locale',
                    Postal_code: 'Codice postale',
                    email_address: 'Indirizzo email',
                    Affiliated_brand: 'Marca affiliata',
                    Company_homepage: 'Homepage dell\'azienda',
                    Discount_rate_for_parts_sales: 'Tasso di sconto per le vendite di parti',
                    Company_address: 'Indirizzo aziendale',
                    Method_of_settlement: 'Metodo di regolamento',
                    Sales_territory: 'Territorio di vendita',
                    Express_freight_area: 'Area di spedizione espresso',
                    Selling_party: 'Parte venditrice',
                    Address_of_the_selling_party: 'Indirizzo della parte venditrice',
                    Loan_agency_information: 'Informazioni sull\'agenzia di prestito',
                    Payment_terms: 'Termini di pagamento',
                    Account_period: 'Periodo contabile',
                    supplier: 'Fornitore',
                    Shipping_address: 'Indirizzo di spedizione',
                    Contact_name: 'Nome del contatto',
                    File_delivery_address: 'Indirizzo di consegna file',
                    Air_address: 'Indirizzo dell\'aria',
                    Express_address: 'Indirizzo espresso',
                    organization: 'Organizzazione',
                    Trade_clause1: 'Classe commerciale 1',
                    Trade_clause2: 'Classe commerciale 2',
                    beneficiary: 'Beneficiario',
                    fmc: 'FMC',
                    Swift_Code: 'Codice Swift',
                    USD_Account: 'Conto in USD',
                    EUR_Account: 'Conto in EUR',
                    RMB_Account: 'Conto in RMB',
                    Tax_number: 'Numero di contribuente',
                    Order_clerk: 'Clerk dell\'ordine',
                    Method_of_payment: 'Metodo di pagamento',
                    Market_type: 'Tipo di mercato',
                    MX_business_model: 'Modello di business MX',
                    label: 'Etichetta',
                    Air_freight_rate: 'Tariffa di trasporto aereo（%）',
                    Ocean_freight_rate: 'Tariffa di trasporto via mare（%）',
                    Express_rate: 'Tariffa express（%）',
                    Document_number_prefix: 'Prefisso del numero di documento',
                    TT_settlement_term: 'Termine di regolamento TT',
                    LC_settlement_term: 'Termine di regolamento LC',
                    name: 'Nome',
                    Contact_person: 'Persona di contatto',
                    Contact_number: 'Numero di contatto',
                    address: 'Indirizzo',
                    city: 'Città',
                    Zip_code: 'Codice postale',
                    sap_consumer_query: 'Query dei consumatori SAP',
                    customer_code: 'Codice cliente',
                    customer_name: 'Nome del cliente',
                    sales_organization: 'Organizzazione vendite',
                    abbreviation: 'Abbreviazione',
                    organization_name: 'Nome dell\'organizzazione',
                    customer_information: 'Informazioni sul cliente',
                    delivered_by: 'Consegnato da',
                },
            },
        },
        components: {
            businessComponents: {
                documentType: 'Tipo di documento',
                vehicleType: 'Tipo di veicolo',
                dealerInformation: 'Informazioni sul concessionario',
                reportType: 'Tipo di rapporto',
                dealerContact: 'Contatto del concessionario',
                contact: 'Contatto telefonico',
                occupation: 'Occupazione',
                vehicleInformation: 'Informazioni sul veicolo',
                mileage: 'Chilometraggio/km',
                licensePlate: 'Targa',
                platform: 'Piattaforma del modello',
                offLineDate: 'Data di uscita di produzione',
                vehicleUse: 'Utilizzo del veicolo',
                refited: 'Modifiche eseguite',
                nachgefüllt: 'Situazione assicurativa',
                nameplateNo: 'Numero di targa',
                purchaseDate: 'Data di acquisto',
                accidentInformation: 'Informazioni sull\'incidente',
                timeOfAccident: 'Orario in cui si è verificato l\'incidente',
                placeOfAccident: 'Luogo dell\'incidente',
                roadConditions: 'Condizioni della strada',
                pavementType: 'Tipo di pavimentazione',
                weather: 'Condizioni meteorologiche',
                vehicleCondition: 'Condizioni del veicolo',
                vehicleOfTheAccident: 'Informazioni sul veicolo coinvolto nell\'incidente',
                vehicleCollisionPosition: 'Posizione di impatto del veicolo',
                vehicleRunningStatus: 'Stato di marcia del veicolo',
                ownSpeed: 'Velocità del proprio veicolo',
                vehicleSpeedOfTheOtherSide: 'Velocità del veicolo dell\'altro conducente',
                personnelInsideTheVehicle:
                    'Situazione delle persone all\'interno del veicolo coinvolto nell\'incidente',
                numberOfPeopleInTheCar: 'Numero di persone a bordo del veicolo',
                personalInjury: 'Condizioni delle persone ferite',
                distributionOfPersonnelInTheVehicle:
                    'Posizione delle persone ferite all\'interno del veicolo',
                descriptionOfPersonalInjury: 'Descrizione delle condizioni delle persone ferite',
                airbagDeployment: 'Attivazione dell\'airbag',
                safetyBeltWearing: 'Utilizzo delle cinture di sicurezza',
                brakeBeforeTheAccident: 'Utilizzo dei freni prima dell\'incidente',
                turnBeforeTheIncident: 'Svolta prima dell\'incidente',
                whenCorrosionOccurs: 'Quando si è verificata la corrosione',
                parkingTimeHours: 'Tempo di parcheggio (ore)',
                parkingTimeMinutes: 'Tempo di parcheggio (minuti)',
                drivingHours: 'Ore di guida',
                drivingMinutes: 'Minuti di guida',
                drivingDistance: 'Distanza percorsa',
                drivingSpeed: 'Velocità di guida',
                vehicleState: 'Condizioni del veicolo al momento dell\'incidente',
                fireFightingOrNot: 'Intervento antincendio',
                numberOfInjured: 'Numero di feriti',
                deathToll: 'Numero di morti',
                vehicleHelp: 'Richiesta di aiuto per il veicolo',
                vehiclePassingAccident: 'Veicolo coinvolto in incidenti precedenti',
                pastAccidents: 'Incidenti precedenti',
                isTheWarningLampOnBeforeTheEvent:
                    'Spia di avvertimento accesa prima dell\'incidente',
                specificIconContent: 'Contenuto degli icone specifici',
                didYouSmellFuelBeforeTheAccident:
                    'Hai percepito odore di carburante prima dell\'incidente',
                didYouFeelAnyAbnormalVibrationBeforeTheAccident:
                    'Hai percepito vibrazioni anomale prima dell\'incidente',
                abnormalSoundZone: 'Zona di rumore anomalo',
                didYouHearAnyNoiseBeforeTheAccident: 'Hai sentito rumori prima dell\'incidente',
                noiseDescription: 'Descrizione del rumore',
                theAreaWhereTheVehicleSmokedBeforeTheAccident:
                    'Zona fumante del veicolo prima dell\'incidente',
                otherAreas: 'Altre zone',
                whetherTheInsuranceCompanyHasIssuedACertificate:
                    'L\'azienda di assicurazione ha emesso il certificato\'',
                whetherTheAuthorityIssuesTheCertificate: 'L\'autorità ha emesso il certificato',
                isVDSReportAvailable: 'Disponibilità del rapporto VDS',
                accidentProcess: 'Processo dell\'incidente',
                accidentProcessPlaceholder:
                    'Segnalato da_ La parafrasi di XXXX.\n' +
                    'Verso le ore 20.00 del XX/XX/20XX, la festa XXXX guidava un veicolo con targa XXX Roewe RX50 1.5/AT lungo l\'autostrada nazionale da sud a nord (con una persona nel veicolo e un autista). Durante la guida verso XX Village, XX Town, XX County, XX City, XXX Province, è stato scoperto che il fumo bianco era generato nella cabina di pilotaggio sinistra.',
                accessoryMaterials: 'Materiale accessorio',
                bulkDownload: 'Download in blocco',
                materialUpload: 'Caricamento materiale',
                userReadmeAttachment: 'Allegato di presentazione dell\'utente',
                sitePhotos: 'Foto del sito',
                fireAccidentIdentificationLetter:
                    'Lettera di identificazione dell\'incidente di incendio',
                insuranceRecordAttachment: 'Allegato record assicurativo',
                diagnosticRecords: 'Registri diagnostici',
                temperature: 'Temperatura',
                please_select_the_reporting_type: 'Seleziona il tipo di segnalazione',
                fire_accident_identification_letter_required:
                    'Lettera di identificazione degli incidenti antincendio richiesta',
                please_upload_the_fire_accident_identification_letter:
                    'Si prega di caricare il modulo di identificazione degli incidenti antincendio',
                insurance_record_attachment_required:
                    'Obbligo di allegare il registro assicurativo',
                please_upload_the_insurance_record_attachment:
                    'Si prega di caricare l\'allegato della scheda assicurativa',
                please_upload_the_diagnostic_record_attachment:
                    'Carica l\'allegato della scheda diagnostica',
                please_upload_site_photos: 'Si prega di caricare foto in loco',
            },
        },
        order: {
            claim: {
                creater: 'Creatore',
                brand: 'Marca',
                claimAmountAll: 'Importo totale richiesta',
                creationDate: 'Data di creazione',
                currentState: 'Stato attuale del processo',
                claimNumber: 'Numero di richieste',
                claimName: 'Nome',
                claimNameCN: 'Nome in cinese',
                claimNameUS: 'Nome in inglese',
                Application: 'N° richiesta ricambi',
                orderNumber: 'Numero ordine',
                amount: 'Inserisci l\'importo',
                frequency: 'Inserisci il numero di volte',
                applicationNumber: 'N° richiesta ricambi',
                creationDateIn: 'Data di creazione',
                new: 'Nuovo',
                applications: 'Elenco richieste ricambi',
                creationTime: 'Ora di creazione',
                money: 'Valuta',
                containerNumber: 'N° lista imballaggio',
                pack_noCode: 'Codice numero contenitore',
                suborder: 'Numero sottordine',
                PartNumber: 'N° accessori',
                claimType: 'Tipo di richiesta',
                orderQuantity: 'Quantità fatturata',
                receivedQuantity: 'Quantità ricevuta',
                claimNumbers: 'Quantità richiesta',
                approvedQuantity: 'Quantità approvata',
                purchaseAmount: 'Prezzo di vendita (FOB)',
                subtotal: 'Subtotale',
                scrapPhotos: 'Foto componenti scartati',
                accessoriesPhotos: 'Foto ricambi',
                accessoriesLabel: 'Foto etichette ricambi',
                accessoriesPackagingPhotos: 'Foto confezioni ricambi',
                transportation: 'Foto imballaggio e trasporto',
                packingList: 'Foto lista imballaggio',
                claimReason: 'Descrizione problema',
                DTNumber: 'Numero DT',
                claimMessage: 'Informazioni richiesta',
                Circulationadvice: 'Consigli di gestione',
                General_generation_name: 'Nome generazione generale',
                claim_order: 'Ordine della richiesta',
                audit_result: 'Esito dell\'audit',
                OEMResult: 'Esito dell\'audit OEM',
                responsible: 'Responsabile',
                modification_date: 'Data di modifica',
                updated_date: 'Data di aggiornamento',
                General_agent: 'Agente generale',
                ATA_time: 'ATA impostato giorni',
                claimAmount: 'Importo richiesta',
                pack_choose: 'Selezione numero scatola',
                suborder_choose: 'Selezione numero sottordine',
                PartNumber_choose: 'Selezione numero parti',
                request_pack: 'Si prega di verificare il numero della lista imballaggio',
                request_suborder: 'Si prega di verificare il numero del sottordine',
                request_PartNumber: 'Si prega di verificare il numero della parte',
                request_Claim: 'Si prega di verificare la linea della parte della richiesta',
                request_ATA: 'Nessun tempo ata, impossibile richiedere',
                information: 'Informazioni di base',
                completionStatus: 'Stato di completamento',
                Handlingsuggestion: 'Suggerimento di gestione',
                picture: 'Immagine',
                greater: 'Maggiore o uguale a',
                less: 'Minore o uguale a',
                week: 'Questa settimana',
                month: 'Questo mese',
                quarter: 'Questo trimestre',
                year: 'Quest\'anno',
            },
            report: {
                logisticsEfficiency: {
                    dt_code: 'Codice DT',
                    dt_name: 'Nome DT',
                    order_month: 'Mese dell\'ordine',
                    order_quantity: 'Quantità ordine',
                    total_order_amount: 'Importo totale ordine',
                    packaging_timeliness: 'Puntualità imballaggio',
                    maritime_transport_general_cargo: 'Trasporto marittimo - merce generale',
                    air_transport: 'Aereo',
                    sea_transportation: 'Mare',
                    express_delivery: 'Espresso',
                    zh_order_quantity: 'Quantità di ordine in tempo',
                    punctualit_rate: 'Tasso di puntualità',
                    maritime_transport_dangerous_goods: 'Trasporto marittimo - merci pericolose',
                    otd_timeliness: 'Puntualità OTD',
                    transport_prescription: 'Prescrizione di trasporto',
                    lines: 'Numero di linee di ordine',
                    amount: 'Importo ordine',
                },
                abnormalOrder: {
                    Overexpectationprocessing: 'Elaborazione sopra le aspettative',
                    Overduehasbeenprocessed: 'Scaduto è stato elaborato',
                    packing: 'Imballaggio',
                    OTD: 'OTD',
                    transport: 'Trasporto',
                    Orderlinenumber: 'Numero linea di ordine',
                    Orderlinenumberall: 'Numero totale linee di ordine',
                    Anomalyrate: 'Tasso di anomalia',
                },
            },
            distributionManagement: {
                list_information: 'Informazioni sulla lista di imballaggio',
                container_scheme: 'Schema del contenitore',
                logistics_registration: 'Registrazione logistica',
                dt_code: 'Codice DT',
                packing_list: 'Numero lista di imballaggio',
                date_warehousing: 'Data di stoccaggio',
                name_voyage: 'Nome nave/viaggio/espresso',
                other_expenses: 'Altre spese',
                bill_lading: 'Numero di polizza di carico',
                print_shipping_mark: 'Stampa il segno di spedizione',
                warehousing_confirmation: 'Conferma di stoccaggio',
                fill_other_expenses: 'Compilare altre spese',
                type_shipping: 'Tipo di spedizione',
                shipping_company: 'Società di spedizioni',
                ships_name: 'Nome della nave',
                voyages_flights: 'La sequenza di viaggi o voli',
                ship_number: 'Numero di nave',
                customs_no: 'Numero doganale',
                return_lading: 'Metodo di restituzione della polizza di carico',
                expected_date: 'Data di ritorno prevista',
                actual_date: 'Data di ritorno effettiva',
                remark: 'Osservazione',
                container_no: 'Numero di contenitore',
                container_type: 'Tipo di contenitore',
                customs_declaration: 'Dichiarazione doganale N.',
                container_nos: 'Numero di contenitore',
                reason_modification: 'Motivo della modifica',
            },
            management: order.claim.management.itIT,
        },
        tech: {
            quality: {
                problemTracking: {
                    customs_declaration_unit: 'Unità dichiarazione doganale',
                    theme: 'Tema',
                    model: 'Modello',
                    problem_state: 'Stato del problema',
                    TAC_submit_date: 'Data di invio TAC',
                    level: 'Livello',
                    type: 'Tipo',
                    vehicle_involved: 'Veicolo coinvolto',
                    activity_code: 'Codice attività',
                    activity_name: 'Nome attività',
                    connected_activities: 'Attività collegate',
                    system_problems: 'Problemi di sistema',
                    quantity: 'Quantità',
                    engine_type: 'Tipo di motore',
                    gearbox_type: 'Tipo di cambio',
                    country: 'Paese',
                    region: 'Regione',
                    date_of_feedback_to_QA: 'Data di feedback a QA',
                    required_part_return_date: 'Data di restituzione della parte richiesta',
                    date_of_parts_delivery_to_QA: 'Data di selezione della consegna a QA',
                    solution_release_date: 'Data di rilascio della soluzione',
                    solution_target_date: 'Data prevista per la soluzione',
                    end_date: 'Data di fine',
                    difference: 'Differenza',
                    QA_weekday: 'Giorno lavorativo QA',
                    fault_description: 'Descrizione del difetto',
                    reason: 'Motivo',
                    solution: 'Soluzione',
                    breakpoint: 'Punto di interruzione VIN/CSN/en',
                    process: 'Processo',
                    details: 'Dettagli',
                    technical_document_attachments: 'Allegati documento tecnico',
                },
                recallActivity: {
                    activity_code: 'Codice attività',
                    activity_name: 'Nome attività',
                    responsible_party: 'Parte responsabile',
                    release_date: 'Data di rilascio',
                    data_sources: 'Fonti dati',
                    recall_campaign_type: 'Tipo richiamo',
                    service_activity_code: 'Codice delle attività di servizio',
                    service_activity_name: 'Nome campagna di servizio',
                    buyout_company: 'Società di acquisto',
                    audit_company: 'Società di approvazione',
                    currency: 'Valuta',
                    money: 'Importo',
                    start_date: 'Data di inizio',
                    end_date: 'Data di fine',
                    failure_mode: 'Modalità di guasto',
                    activity_programme: 'Piano della campagna',
                    improve_handling: 'Miglioramento delle misure',
                    activity_information: 'Informazioni sull\'attività',
                    vehicle_involved_list: 'Veicoli coinvolti',
                    template_file: 'File di modello',
                    browse: 'Sfoglia',
                    download_template: 'Scarica il modello',
                    claim_vehicles_list: 'Veicoli che hanno avuto un reclamo',
                    claim_statistics: 'Statistiche sui reclami',
                    technical_document_attachments: 'Allegati documento tecnico',
                    vin: 'VIN',
                    is_complete: 'Completato',
                    warranty_claims: 'Reclami per garanzia',
                    customer: 'Cliente',
                    no_file_selected: 'Nessun file selezionato',
                    no_import_file_selected: 'Nessun file di importazione selezionato',
                    claim_proportion: 'Proporzione di reclami',
                    claim_settled_amount: 'Importo risolto del reclamo',
                    is_claim_again: 'Se riclaimare',
                    cancel_succeed: 'Annullamento riuscito',
                    build_by_oneself: 'Costruire da soli',
                    system_import: 'Importazione del sistema reclami',
                    claim_system_import: 'Importazione del sistema reclami',
                },
            },
            management: {
                newCarDefinition: {
                    activityNumber: 'Codice campagna',
                    activityName: 'Nome della campagna',
                    activityStatus: 'Stato',
                    activityDate: 'Data di attività',
                    startDate: 'Data di inizio',
                    endDate: 'Data di fine',
                    remarks: 'Osservazioni',
                    activity_details: 'Dettagli dell\'attività',
                    activity_addition: 'Aggiunta di attività',
                    confirm_enable: 'Conferma abilitazione',
                    confirm_outage: 'Conferma interruzione',
                    all: 'tutto',
                    not_started: 'Non avviato',
                    on_going: 'In corso',
                    closed: 'Chiuso',
                },
                newCarlssue: {
                    all: 'Tutto',
                    newly_built: 'Aggiungi',
                    unpublished: 'Inédito',
                    validity: 'Validità',
                    modelInvolved: 'Modello',
                    involvedArea: 'Regione',
                    state: 'Stato',
                    new_car_reporting_level: 'Livello di segnalazione del nuovo veicolo',
                    confirm_release: 'Conferma rilascio',
                    maintenance: 'Mantenimento',
                    model_maintenance: 'Manutenzione del modello',
                    area_maintenance: 'Manutenzione dell\'area\'',
                    reporting_level: 'Livello di segnalazione',
                    to_be_published: 'Da pubblicare',
                    published: 'Pubblicato',
                    modified_to_be_published: 'Modificato da pubblicare',
                },
                onSiteSupportHq: {
                    fieldSupportNumber: 'Numero di supporto sul campo',
                    informationSlipNumber: 'Numero del modulo informazioni',
                    approvalStatus: 'Stato di approvazione',
                    state: 'Stato',
                    selectApplicationDate: 'Seleziona la data di richiesta',
                    toBeProcessed: 'Da elaborare',
                    processed: 'Elaborato',
                    initiated: 'Iniziato',
                    applicationDepartment: 'Dipartimento di applicazione',
                    applicant: 'Richiedente',
                    reasonApplication: 'Motivo della richiesta',
                    applicationTime: 'Tempo di applicazione',
                    all: 'Tutto',
                    pending_approva: 'Approvazione in sospeso',
                    agreed: 'Convenuto',
                    rejected: 'Rifiutato',
                    view: {
                        application_department: 'Dipartimento di applicazione',
                        responsible_person: 'Persona responsabile',
                        application_person: 'Persona che fa la domanda',
                        associated_enquiry_number: 'Numero di indagine associato',
                        reason_application: 'Motivo della richiesta',
                        description: 'Descrizione (foglio firma interna della domanda)',
                        file_name: 'Nome del file',
                        size: 'Dimensione (MB)',
                        uploader: 'Caricatore',
                        upload_time: 'Tempo di caricamento',
                        view: 'Verifica',
                        download: 'Scarica',
                        support_department: 'Dipartimento di supporto',
                        technical_personnel: 'Personale tecnico',
                        contact_information: 'Informazioni di contatto',
                        field_support_information: 'Informazioni sul supporto sul campo',
                        remarks: 'Osservazioni',
                        apply: 'Applicare',
                        application_information: 'Informazioni sulla richiesta',
                        field_support_personnel: 'Personale di supporto sul sito',
                        approval_record: 'Storia dell\'approvazione',
                        submit_an_application: 'Presentare una domanda',
                        refuse: 'Rifiutare',
                        agree: 'Convenire',
                        apply_for: 'Richiedere',
                        field_support: 'Supporto sul campo',
                        reason_for_rejection: 'Motivo del rifiuto',
                    },
                    dialog: {
                        on_site_application_details: 'Dettagli della richiesta sul sito',
                        on_site_application: 'Richiesta sul sito',
                        reason_for_rejection: 'Motivo del rifiuto',
                        field_support_application: 'Domanda di supporto sul campo',
                        technical_personnel: 'Personale tecnico',
                        contact_information: 'Telefono di contatto',
                        field_support_time: 'Tempo di supporto sul campo',
                        note_information: 'Informazioni sulla nota',
                    },
                },
                filterHq: {
                    filter_information: 'Informazioni sul filtro',
                    filter_name: 'Nome del filtro',
                    user_name: 'Username',
                    creation_time: 'Data di creazione',
                    splicing_description: 'Descrizione di giunzione',
                    enable_or_not: 'Abilitato o disabilitato',
                    association_symbol: 'Simbolo di associazione',
                    screening_condition: 'Condizione di filtraggio',
                    conditional_value: 'Valore di condizionamento di filtraggio',
                    save: 'Salva',
                    draft: 'Bozza',
                    no_reply: 'Nessuna risposta',
                    replied: 'Risposto',
                    closed: 'Chiuso',
                    pre_sales: 'Pre vendita',
                    after_sales: 'Dopo le vendite',
                    to_be_repaired: 'da riparare',
                    under_maintenance: 'In manutenzione',
                    completed: 'completato',
                    not_included: 'Non incluso',
                    and: 'E',
                    perhaps: 'Forse',
                    not: 'Non',
                    enable: 'Abilitare',
                    judgement_logic: 'Logica di giudizio',
                    order_number: 'N.',
                    relational_symbol: 'Simbolo relazionale',
                    filter_criteria: 'Criteri di filtro',
                    please_add_judgment_logic: 'Si prega di aggiungere la logica di giudizio',
                    model_platform_is: 'La piattaforma del modello è',
                    subject_of_the_case_is: 'L\'oggetto del caso è',
                    overseas_branches_are: 'Le filiali all\'estero sono',
                    overseas_subsidiaries_are: 'Le filiali all\'estero sono',
                    general_agent_are: 'Distributori',
                    fault_symptom_is: 'Il sintomo del guasto è',
                    cause_of_the_fault_is: 'La causa del guasto è',
                    vehicle_status_is: 'Lo stato del veicolo è',
                    case_type_is: 'Il tipo di caso è',
                    case_status_is: 'Lo stato del caso è',
                    all: 'Tutto',
                },
                vehicleInfoHq: {
                    productionTimeList: 'Elenco tempi di produzione',
                    vehicle_configuration_list: 'Elenco configurazioni veicolo',
                    accurately_traceable_parts_list: 'Elenco parti accuratamente rintracciabili',
                    order_number: 'Numero d\'ordine',
                    order_type: 'Tipo d\'ordine',
                    platform: 'Piattaforma',
                    model_code: 'Codice modello',
                    date_of_production: 'Data di produzione',
                    certificate_of_conformity: 'Certificato di conformità',
                    certificate_printing_time: 'Tempo di stampa del certificato',
                    vehicle_material_number: 'Numero materiale veicolo',
                    description: 'Descrizione',
                    time: 'Tempo',
                    feature_ID: 'ID funzione',
                    feature_description: 'Descrizione funzione',
                    feature_code: 'Codice funzione',
                    eigenvalue_description: 'Descrizione valore caratteristico',
                    chinese_description: 'Descrizione cinese',
                    supplier: 'Fornitore',
                    bar_code_number: 'Numero codice a barre',
                    scanning_time: 'Tempo di scansione',
                    key_component_type_code: 'Codice tipo componente chiave',
                    part_number: 'Numero parte',
                    traceability_number: 'Numero tracciabilità',
                    sub_key_type: 'Sotto-tipo chiave',
                    software: 'Software',
                    hardware: 'Hardware',
                    calibration_information: 'Informazioni di calibrazione',
                    supplier_name: 'Nome fornitore',
                    pn: 'Codice PIN',
                },
                progHistoryHq: {
                    name_of_maintenance_station: 'Nome stazione di assistenza',
                    mileage: 'Chilometraggio',
                    controller_name: 'Nome controllore',
                    function_name: 'Funzione',
                    time: 'Tempo',
                    function_execution_result: 'Risultato esecuzione funzione',
                    channel: 'Canale',
                    hardware_number: 'Numero hardware',
                    written_software_information: 'Informazioni software scritte',
                    software_information_before_refresh: 'Informazioni software prima del refresh',
                    updated_software_information: 'Informazioni software aggiornate',
                },
                salesRecordHq: {
                    material: 'Materiale',
                    sales_order: 'Ordine di vendita',
                    vehicle_type: 'Modello',
                    shipping_area: 'Zona di spedizione',
                    country_of_dispatch: 'Paese di spedizione',
                    down_date: 'Data fuori produzione',
                    port_of_destination: 'Porto di destinazione',
                    scheduled_date_of_shipment: 'ETD',
                    actual_date_of_shipment: 'ATD',
                    current_position: 'Posizione attuale',
                    actual_arrival_date: 'ATA',
                    retail_date: 'Data di vendita al dettaglio',
                },
                claimHq: {
                    claim_information: 'Informazioni reclamo',
                    Recall_and_service_activities: 'Campagna richiami e servizio',
                    marketing_activity_list: 'Campagna di marketing',
                    basic_information: 'Informazioni di base',
                    warranty_block: 'Blocco garanzia',
                    repair_block_code: 'Codice blocco garanzia',
                    claim_cycle_list: 'Elenco periodi garanzia',
                    WTY_Indicates_the_cycle_type: 'Tipo di periodo garanzia',
                    start_time: 'Ora di inizio',
                    end_time: 'Ora di fine',
                    WTY_mileage: 'Kilometraggio garanzia',
                    List_of_claims: 'Storico garanzia',
                    customer: 'Cliente',
                    warranty_claim: 'Reclamo garanzia',
                    work_order: 'Ordine di lavoro',
                    date_of_receipt: 'Data di ricezione',
                    maintenance_technical_date: 'Data di riparazione',
                    mileage: 'Chilometraggio',
                    state: 'Stato',
                    master_working_position: 'Lavoro principale',
                    manual_code: 'Codice manuale',
                    main_component: 'Componente principale',
                    part_name: 'Nome parte',
                    recall_and_service_activities: 'Campagna richiami e servizio',
                    recall_and_service_activity_code: 'Codice campagna richiami e servizio',
                    recall_and_service_activity_names: 'Nome campagna richiami e servizio',
                    brand: 'Marchio',
                    recall_type_code: 'Codice tipo richiamo',
                    recall_type_name: 'Nome tipo richiamo',
                    release_date: 'Data di rilascio',
                    closing_date: 'Data di chiusura',
                    complete: 'Completa',
                    warranty_claim_status: 'Stato reclamo garanzia',
                    end_of_maintenance_date: 'Data di fine dell\'ultima riparazione',
                    marketing_activity_code: 'Codice attività di marketing',
                    marketing_activity_name: 'Nome attività di marketing',
                    vehicle_warranty_period: 'Periodo di garanzia del veicolo',
                    marketing_activities: 'Attività di marketing',
                    prohibition_of_claims: 'Divieto di rivendicazioni',
                    prohibited_claim_code: 'Codice delle richieste vietate',
                    type_of_warranty_period: 'Tipo di periodo di garanzia',
                    repair_end_date: 'Data di fine riparazione',
                    reception_date: 'Data di ricevimento',
                    main_station_code: 'Codice della stazione centrale',
                    main_workstation_name: 'Nome della stazione di lavoro principale',
                },

                // inquiryOd: {
                //     vin: 'VIN',
                //     inquiry_no: 'inquiry no',
                //     case_theme: 'case theme',
                //     inquiry_date: 'inquiry date',
                // },
                inquiryOd: tech.management.inquiry.itIT,
                permission: tech.management.permission.itIT,
            },
        },
        statistics: statistics.inquiryStatistics.itIT,
        systemClassifyA: statistics.systemClassifyA.itIT,
        systemClassifyB: statistics.systemClassifyB.itIT,
        task: {
            china: {
                holiday: {
                    holiday_name: 'Nome Festa',
                    holiady_time: 'Giorno Festivo',
                    end_time: 'Data di Fine',
                    holiday_add: 'Aggiungi Nuovo',
                    holiday_edit: 'Modifica',
                },
            },
            package: {
                calendar: {
                    packing: 'Data Elaborazione Imballaggio SMPV',
                },
            },
        },
        logistics: {
            package: {
                cycle: {
                    transport_type: 'Tipo di Trasporto',
                    market_type: 'Tipo di Mercato',
                    market_name: 'Nome del Mercato',
                    market_code: 'Codice del Mercato',
                    transport_cycle: 'Periodo di Trasporto (giorni)',
                    Packaging_aging: 'Tempo Limite per l\'Imballaggio (giorni)',
                    OTD_aging: 'Tempo Limite per OTD (giorni)',
                },
            },
        },
        vehicle: {
            logistics: {
                expenses: {
                    atd_begin_date: 'Data di Inizio ATD',
                    atd_end_date: 'Data di Fine ATD',
                    atd_date: 'Data di ATD',
                    country_of_target: 'Paese di Destinazione',
                    dn: 'DN',
                    region: 'Regione',
                    model_code: 'Codice Modello',
                    vin: 'VIN',
                    volumn: 'Volume',
                    vdc_name: 'Nome VDC',
                    pol_name: 'Nome POL',
                    dt_content: 'Contenuto DT',
                    dt_distance_km: 'Distanza KM DT',
                    vdc_vasc_material: 'Materiale VAS DDC',
                    vdc_vasc_worker: 'Operatore VAS DDC',
                    domestic_carry: 'Trasporto Nazionale',
                    wharf_handle: 'Gestione Banchina',
                    wharf_sc: 'SC Banchina',
                    wharf_box: 'Box Banchina',
                    medicated_fumigation: 'Fumigazione Medicata',
                    wharf_dot_check: 'Controllo DOT Banchina',
                    repair_maintenance: 'Manutenzione e Riparazione',
                    refuel_storage: 'Rifornimento e Immagazzinamento',
                    file_cert: 'Certificazione File',
                    supply_chain_integration: 'Integrazione Catena di Fornitura',
                    other_domestic_carry: 'Altri Trasporti Nazionali',
                    insure: 'Assicurazione',
                    ship_money: 'Spese di Spedizione',
                    ship_money_type: 'Tipo di Spese di Spedizione',
                    other_interal_charge: 'Altro Costo Interno',
                    other_interal_charge_type: 'Tipo di Altro Costo Interno',
                    transport_company_code: 'Codice Compagnia di Trasporto',
                    vessel_name_voyage: 'Nome Nave - Viaggio',
                    mode_of_transport: 'Modalità di Trasporto',
                    remark: 'Nota',
                    create_date: 'Data di Creazione',
                },
                expensesRelationship: {
                    country_name: 'Nome Paese',
                    country_code: 'Codice Paese',
                    model_code: 'Codice Modello',
                    ology_code: 'Codice Ologia',
                    ology_name: 'Nome Ologia',
                    status: 'Stato',
                    remark: 'Nota',
                    create_by: 'Creato Da',
                    create_name: 'Nome Creatore',
                    create_date: 'Data di Creazione',
                    update_by: 'Aggiornato Da',
                    update_name: 'Nome Aggiornatore',
                    update_date: 'Data di Aggiornamento',
                    tache_code: 'Codice Tache',
                    no: 'No',
                    yes: 'Sì',
                },
                expenseAccount: {
                    ology_type: 'Tipo di Ologia',
                    pack_name: 'Nome Pacchetto',
                },
                country: vehicle_country.zhCN,
            },
        },
    },
    validation: {
        common: {
            required: 'Questo elemento è richiesto',
            numeric: 'Deve essere un numero intero',
            decimal: 'Deve essere un numero',
            positive_interger: 'Deve essere un numero intero positivo',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Fino a ${named('number')} cifre`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Più di ${named('number')} caratteri`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Superare byte max (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Meno di ${named('number')} caratteri`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Deve essere ${named('number')} caratteri`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Maggiore di ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `Meno di ${named('number')}`,
            up_to_2_decimal_places: 'Fino a 2 decimali',
            retain_up: 'Inserisci fino a 10 numeri interi positivi e mantieni fino a 2 decimali',
            retain_ups: 'Inserisci fino a 11 numeri interi positivi e mantieni fino a 4 decimali',
            input_number: 'Inserisci un numero',
            only_number_a: 'Possono essere inseriti solo lettere o numeri',
            translate: 'Possono essere solo lettere',
            positive_integer: 'Inserisci almeno un campo',
            at_least_one: 'Compilare almeno uno',
        },
        tip: {
            incomplete_information: 'Informazioni incomplete compilate',
            MG_information: 'Il rappresentante dell\'ordine MG non può essere vuoto',
            MAXUS_information: 'Il rappresentante dell\'ordine MAXUS non può essere vuoto',
        },
    },
    import: {
        incorrect_file_type: 'Tipo di file errato',
        exceed: 'Andare oltre',
        upload_success: 'Caricamento riuscito',
        upload_fail: 'Caricamento fallito',
        again_upload: 'È possibile caricare solo file di immagine in formato jpg, png, bmp o gif.',
    },
    export: {
        noDataToExport: 'Nessun dato da esportare',
    },
    select: {
        all: 'Intero',
    },
    checkbox: {
        select_all: 'Seleziona tutto',
    },
    dialog: {
        error_message: 'Messaggio di errore',
        accessory_selection: 'Selezione accessori',
    },
};
