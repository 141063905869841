import type { MessageFunction } from 'vue-i18n';
import order from './pages/order';
import homepage from './pages/homepage/index';
import tech from './pages/tech/index';
import statistics from './pages/statistics/index';
import { vehicle_country } from './pages/vehicle/logistics';

export default {
    license: { powered_by: 'Angetrieben von SMIL' },
    auth: {
        login: 'Anmelden',
        username: 'Nutzername',
        username_placeholder: 'Benutzernamen eingeben',
        password: 'Passwort',
        password_placeholder: 'Passwort eingeben',
        code: 'Überprüfungscode',
        code_placeholder: 'Überprüfungscode eingeben',
        logout: 'Abmelden',
        logout_success: 'Abmeldung erfolgreich',
        day: 'Tag',
        password_expiration: 'Das Passwort ist abgelaufen, bitte ändern Sie es',
        expire: 'Bleiben bis zum Ablauf des Passworts',
        conseils: 'Das Passwort muss Großbuchstaben, Kleinbuchstaben, Zahlen und Sonderzeichen ({msg}) enthalten, und die Länge des Passworts sollte zwischen 8 bis 16 Zeichen liegen.',
    },
    button: {
        query: 'Abfragen',
        show_theme_editor: 'Themeneditor ein-/ausblenden',
        change_password: 'Passwort ändern',
        old_password: 'Altes Passwort',
        new_password: 'Neues Passwort',
        confirm_password: 'Passwort bestätigen',
        cipher: 'Passwort',
        change_new_password: 'Bitte geben Sie das neue Passwort ein',
        button: 'Taste',
        about: 'Über',
        back: 'Zurück',
        go: 'LOS',
        home: 'Startseite',
        toggle_dark: 'Dunklen Modus umschalten',
        toggle_langs: 'Sprachen ändern',
        confirm: 'Bestätigen',
        modify: 'Bearbeiten',
        edit: 'Bearbeiten',
        new: 'Neu',
        cancel: 'Abbrechen',
        close: 'Geschlossen',
        search: 'Suche',
        reset: 'Zurücksetzen',
        delete: 'Löschen',
        add: 'Hinzufügen',
        collapse: 'Zusammenklappen',
        expand: 'Mehr',
        download_template: 'Vorlage herunterladen',
        batch_import: 'Stapelimport',
        import: 'Importieren',
        export: 'Exportieren',
        export_fail: 'Export fehlgeschlagen',
        batch_clear_sap_code: 'Stapel-SAP-Code löschen',
        batch_clear_special_purchase_cycle: 'Batch spezieller Einkaufszyklus',
        detailed: 'Detailliert',
        download: 'Herunterladen',
        toView: 'Prüfen',
        uploadAttachments: 'Anhänge hochladen',
        selectFile: 'Datei auswählen',
        fillingInstructions: 'Füllanweisungen',
        release: 'Freigeben',
        save: 'Speichern',
        send_back: 'Zurücksenden',
        examine: 'überprüfen',
        updateTime: 'Gültigkeitsdauer aktualisieren',
        update: 'Aktualisieren',
        void: 'Ungültig',
        more: 'Mehr',
        refresh: 'Aktualisieren',
        submit_sap: 'SAP einreichen',
        submit: 'Einreichen',
        save_button: 'Speichern und Neu',
        insufficient: 'Ob bei unzureichenden Kontofonds eingereicht werden soll',
        yes: 'Ja',
        deny: 'Ablehnen',
        staging: 'Speichern und Schließen',
        return: 'Zurückkehren',
        table: 'Nr.',
        select: 'Wählen',
        all: 'Alle',
        create_sap: 'CreateSap',
        enter: 'Bitte eingeben',
        selects: 'Bitte auswählen',
        modify_ladder: 'Leiter ändern',
        pass: 'Durchlaufen',
        serial_number: 'Nr.',
        individual: 'Individuell',
        upload_progress: 'Upload-Fortschritt',
        port_choose: 'Port wählen',
        request_code: 'Bitte Zubehör überprüfen',
        request_discuss: 'Lieferanten auswählen',
        request_dt: 'Bitte DT prüfen',
        request_thing: 'Bitte Kategorie des Materials prüfen',
        translate: 'Nur Buchstaben',
        accessories_information: 'Zubehörinformationen',
        select_accessories: 'Zubehör auswählen',
        binding: 'Bindung',
        tick_data: 'Bitte überprüfen Sie die Daten',
        invalid: 'Ungültig',
        empty_data: 'Generierte Daten löschen',
        confirm_number: 'Bestätigen Sie die Packliste Nummer',
        variance_details: 'Abweichungsdetails',
        daily_record: 'Tagesaufzeichnung',
        operation_type: 'Operationstyp',
        field_name: 'Feldname',
        edit_role: 'Rolle bearbeiten',
        user_role: 'Benutzerrolle',
        assignment_role: 'Rolle einstellen',
        please_settled: 'Bitte wählen Sie das abzurechnende Zubehör aus',
    },
    radio: {
        enabled: 'Aktiviert',
        disabled: 'Deaktiviert',
        catalogue: 'Katalog',
        individual: 'Individuell',
        all: 'Alle',
        tips: 'Tipps',
        save: 'Speichern',
        yes: 'Ja',
        no: 'Nein',
    },
    dropdown: {
        close_others: 'Andere schließen',
        close_right: 'Nach rechts schließen',
        close_left: 'Nach links schließen',
    },
    table: {
        action: 'Aktion',
        explain: 'Erklärung',
        fileName: 'Dateiname',
        size: 'Größe(MB)',
        uploader: 'Uploader',
        uploadTime: 'Uploadzeit',
        system: 'System',
    },
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/page`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')} records in total`,
    },
    confirm: {
        confirm_deletion: 'Löschen bestätigen?',
        confirm_void: 'Freigabe bestätigen?',
        confirm_release: 'Freigabe bestätigen?',
        ack: 'Bestätigen',
        cancel: 'Abbrechen',
        non_records: 'Bitte wählen Sie mindestens einen Eintrag',
    },
    request: {
        failed_placeholder: 'Anfrage fehlgeschlagen, Backend-Fehler',
        success_placeholder: 'Anfrage erfolgreich',
        auth: {
            login_success: 'Anmeldung erfolgreich',
        },
        error: {
            '404': 'Anforderungs-URL nicht gefunden',
            '403': 'Zugriff verweigert',
        },
    },

    // wei
    page: {
        global: {
            page_search: 'Seitensuche',
        },
        error: {
            not_found: 'Nicht gefunden',
            not_found_des: 'Hier gibt es nichts',
            auth_failed: 'Authentifizierung fehlgeschlagen',
            auth_failed_des: 'Diese Seite ist nicht erreichbar',
        },
        homepage: {
            homepage: homepage.homepage.deDE,
        },
        system: {
            menu: {
                menu: 'Menü',
                menu_name: 'Menüname',
                menu_type: 'Menütyp',
                menu_new: 'Neues Menü',
                menu_edit: 'Menü bearbeiten',
                icon: 'Symbol',
                parent: 'Eltern',
                order_number: 'Reihenfolge',
                path: 'Pfad',
                status: 'Status',
            },
            dict: {
                dictionary_name: 'Wörterbuchname',
                creater: 'Ersteller',
                dictionary_code: 'Wörterbuchcode',
                code_type: 'Code Typ',
                code_type_name: 'Code Typ Name',
                creation_time: 'Erstellungszeit',
                dict_new: 'Neues Wörterbuch',
                dict_edit: 'Wörterbuch bearbeiten',
                lang: 'Sprache',
                sort: 'Sortieren',
            },
            dept: {
                parent_dept: 'Übergeordnete Abteilung',
                dept_name: 'Abteilungsname',
                leader: 'Leiter',
                email: 'E-Mail',
                create_by: 'Erstellt von',
                create_date: 'Erstellungsdatum',
                organization_editing: 'Organisation bearbeiten',
                phone: 'Telefon',
            },
            role: {
                menu_maintenance: 'Menüpflege',
                role_name: 'Rollenname',
                role_key: 'Berechtigungszeichen',
                role_id: 'Anzeigereihenfolge',
                status: 'Status',
                create_date: 'Erstellungszeit',
                role_sort: 'Sortieren',
                role_new: 'Neue Rolle',
                role_edit: 'Rolle bearbeiten',
                role_bleak: 'Düster',
            },
            user: {
                serial_no: 'Nr.',
                username: 'Benutzername',
                name: 'Name',
                sex: 'Geschlecht',
                phone_number: 'Telefonnummer',
                email: 'E-Mail',
                role: 'Rolle',
                login_time: 'Status aktivieren',
                password: 'Passwort',
                user_edit: 'Benutzer bearbeiten',
            },
        },
        management: {
            purchasePosting: {
                charge_against: 'Gegen Gebühr',
                transportation_method:
                    'Auftragstyp, Transportmethode und Handelsbedingungen sind unterschiedlich. Bitte überprüfen Sie den Auftrag mit demselben Typ!',
                whether_to_send: 'Ob an SAP senden',
                invoice_number: 'Rechnungsnummer',
                invoice_reversal: 'Rechnungsumkehrung',
                sales_purchase_tax_code: 'Umsatz-/Einkaufssteuercode',
                send_sap: 'SAP-Sendung',
                whether_notify_invoicing: 'Ob Benachrichtigung der Rechnungsstellung erfolgen soll',
                import_invoices: 'Rechnungen importieren',
                invoicing_notice: 'Rechnungsstellungshinweis',
                import_information: 'Import von Zolldeklarationsinformationen',
                tax_rate: 'Steuersatz',
                amount_excluding_tax: 'Betrag ohne Steuern',
                amount_including_tax: 'Betrag inklusive Steuern',
                invoice_tax_amount: 'Rechnungssteuerbetrag',
                invoice_amount: 'Rechnungsbetrag',
                prepayment_note_no: 'Anzahlungshinweis Nr.',
                prepayment_not: 'Keine Anzahlung',
                section: 'Abschnitt',
                approved: 'Genehmigt',
                order_submission: 'Auftragseinreichung',
                list_to_be_invoiced: 'Liste zur Rechnungsstellung',
                invoiced_but_unpaid_list: 'Gelistet, aber unbezahlt',
                invoiced_and_paid_list: 'Gelistet und bezahlt',
                dn_no: 'DN-Nr.',
                sub_order_no: 'Teilbestellnummer',
                packing_list_no: 'Packliste Nr.',
                brand: 'Marke',
                whether_invoicable: 'Ob berechnungsfähig',
                payment_type: 'Zahlungsart',
                advance_payment: 'Vorauszahlungsgebühr',
                oem_order_no: 'OEM-Auftragsnummer',
                tax_amount: 'Steuerbetrag',
                amount_of_money: 'Geldbetrag',
                payment_time: 'Zahlungszeit',
                payment_order_no: 'Zahlungsauftragsnummer',
                sap_voucher_No: 'SAP-Gutschein-Nr.',
                export_gold_tax: 'Export Goldsteuer',
                export_maxus: 'Export MAXUS-Rechnungsstellung Liste',
                time_of_payment: 'Zahlungszeitpunkt',
                apply_for_payment: 'Zahlung beantragen',
            },
            preBooking: {
                booking: 'Buchung',
                booking_space: 'Buchungsraum',
                packing_list_no: 'Packliste Nr.',
                job_no: 'Job-Nr.',
                dangerous_goods_or_not: 'Gefährliche Güter oder nicht',
                brand: 'Marke',
                type_of_shipping: 'Transportart',
                dt_code: 'DT-Code',
                estimated_volume: 'Volumen (m³) geschätzt',
                estimated_gross_weight: 'Bruttogewicht (kg) geschätzt',
                estimated_net_weight: 'Nettogewicht (kg) geschätzt',
                estimated: 'Geschätztes Volumen',
                volume: 'Volumen (m³)',
                gross_weight: 'Bruttogewicht (kg)',
                net_weight: 'Nettogewicht (kg)',
                shipping_schedule: 'Job-Nr. Versandplanung',
                shipping_schedules: 'Der Job Nr. Versandtermin darf nicht leer sein',
                shipping_type: 'Versandart',
                box_quantity: 'Geschätzte Anzahl von Boxen für Job Nr.',
            },
            shoppingCart: {
                vin_requires:
                    'VIN benötigt eine Kombination aus 17 Buchstaben und Zahlen, beginnend mit 3 großen und kleinen Buchstaben',
                part_type: 'Teiletyp',
                submit_validation: 'Validierung senden',
                maximum_value: 'Maximalwert 9999999999',
                please_select_cart:
                    'Bitte wählen Sie ein Zubehör, um es dem Warenkorb hinzuzufügen',
                please_enter_format: 'Bitte geben Sie das Format PDF, XLSX, XLS, WORD ein',
                please_format: 'Bitte geben Sie das Format jpg, png, gif ein',
                please_enter_an_integer: 'Bitte geben Sie die korrekte Bestellmenge ein',
                customized_orders: 'Individuelle Bestellungen',
                details_accessory: 'Details zum Zubehörcode',
                warning: 'Bestätigung der Löschung',
                please_payment_method: 'Bitte wählen Sie die Zahlungsmethode',
                please_port: 'Bitte wählen Sie einen Hafen',
                please_party: 'Bitte wählen Sie den Lieferpartner',
                no_inventory: 'Keine Lagerbestände:',
                notes: 'Anmerkungen',
                note_the: 'Fehlende notwendige Informationen für individuelle Teile',
                download_template: 'Vorlage herunterladen',
                single_addition: 'Einzelfügung',
                material_category: 'Materialkategorie',
                Individual_addition: 'Individuelle Ergänzung',
                order_type: 'Bestellart',
                accessory_code: 'Zubehör-Code',
                part_name: 'Name des Zubehörs',
                term_of_trade: 'Handelsbedingungen',
                logistics_mode: 'Logistikmodus',
                type_of_fittings: 'Teiletyp',
                available_balance: 'Verfügbarer Saldo',
                order_deadline: 'Bestellfrist',
                place_order: 'Bestellen',
                order_information: 'Bestellinformationen',
                order_No: 'Bestellnummer',
                supplier: 'Lieferant',
                creation_date: 'Erstellungsdatum',
                type_of_shipping: 'Transportmodus ',
                payment_method: 'Zahlungsmethode',
                port: 'Bestimmungshafen',
                delivered_by: 'Tatsächliche Empfängeradresse',
                remarks: 'Anmerkungen',
                brand: 'Marke',
                currency: 'Währung',
                accessory_lines: 'Anzahl der Zubehörzeilen',
                accessory: 'Artikel',
                amount_accessories: 'Teilemenge',
                freight: 'Fracht (geschätzt)',
                premium: 'Versicherung',
                order_amount: 'Bestellmenge',
                total_transportation: 'Gesamtvolumen(m³)',
                ordinary_goods: 'ETD der normalen Teile',
                arrival_date: 'ETD der gefährlichen Teile',
                accessories_information: 'Informationen zu Zubehörteilen',
                whole: 'Ganz',
                routine: 'Routine',
                dangerous_goods: 'Gefahrgut',
                conventional_parts: 'Konventionelle Teile',
                chemicals: 'Chemikalien',
                oily_parts: 'Ölige Teile',
                commodity_inspection_parts: 'Handelsinspektions-Teile',
                payment: 'Zahlungsbelegnummer darf nicht leer sein',
                incorrect: 'Falsche Eingabedaten',
                data_empty: 'Daten dürfen nicht leer sein',
                cannot_current_time: 'Darf nicht größer als die aktuelle Zeit sein',
                cannot_time: 'Darf nicht kleiner als die aktuelle Zeit sein',
                permits: 'Genehmigungen',
                customized_parts: 'Individuelle Teile',
                customized: 'individuell',
                accessories_selection: 'Accessoires auswählen',
                forbidden_empty: 'Luftverbot',
                add_cart: 'Hinzufügen',
                order_number: 'Nr.',
                replacement: 'Ersatz',
                order_quantity: 'Bestellmenge',
                unit_price: 'Preis',
                company: 'Einheit',
                be_purchased: 'Kaufbar',
                quantity: 'Mindestbestellmenge',
                total_amount: 'Gesamtmenge',
                volumes: 'Volumen (m³)',
                volume: 'Band',
                enclosure: 'Anhang',
                confirmed_quantity: 'Bestätigte Menge',
                courier: 'Flug-/Schiffnummer/Kurier',
            },
            salesquery: {
                actual_delivery: 'Tatsächliches Lieferdatum',
                estimated_delivery: 'Voraussichtliches Lieferdatum',
                total_packages: 'Gesamt-Nettogewicht der Verpackung',
                total_gross: 'Gesamt-Bruttogewicht der Verpackung',
                trade_mode: 'Handelsmodus nach dem Transport',
                mode_before: 'Handelsmodus vor dem Transport',
                port_after_transshipment: 'Hafen nach dem Transshipment',
                port_diversion: 'Hafen vor der Umleitung',
                outer: 'Paket-Höhe',
                outer_box_size: 'Box-Breite',
                outer_box: 'Box-Länge',
                volume: 'Messung(mm3)',
                gross_weights: 'G.W.(KGS)',
                net_weight: 'N.W.(KGS)',
                shipment_quantity: 'Menge der Sendung',
                ordering_parts_code: 'Bestellcode der Teile',
                packing_code: 'Koffer-Nr.',
                container_no: 'Container-Nr.',
                sales_order_information: 'Verkaufsauftragsinformationen',
                packing_list_no: 'PL-Nr.',
                accessory_code: 'Teilenummer',
                order_no: 'Bestellnummer',
                merge_ci: 'Zusammengeführte CI&PL herunterladen',
                export_ci: 'CI exportieren',
                export_pl: 'PL exportieren',
                currency: 'Währung',
                parts_amount: 'Teilmenge',
                freight: 'Fracht',
                premium: 'Versicherung',
                other_expenses: 'Andere Gebühren',
                total_amoun: 'Gesamtmenge',
                creation_date: 'PL-Erstellungsdatum',
                ship_number: 'Versandnummer/Flug/Express',
                supplier: 'Lieferant',
                accessories: 'Artikel',
                total_accessories: 'Anzahl',
                gross_weight: 'G.W.(KGS)',
                total_weight: 'N.W.(KGS)',
                total_package: 'Gesamtvolumen(m³)',
            },
            suborder: {
                packing_details: 'Verpackungsdetails',
                order_detail: 'Bestelldetails',
                arrive: 'Angekommen',
                shipped: 'Verschifft',
                complete_packaging: 'Verpackung abgeschlossen',
                passenger_order_number: 'Passagierwagen-Bestellnummer',
                order_details: 'Unterbestelldetails',
                accessory: 'Zusatzartikel',
                order_amount: 'Gesamtbetrag',
                company: 'Unternehmen',
                name_accessories: 'Name des Zubehörs',
                accessory_code: 'Zubehör-Code',
                serial_no: 'Nein.',
                submit_smpv: 'SMPV einreichen',
                sub_order_no: 'Unterbestellnummer',
                sub_order_type: 'Unterbestelltyp',
                brand: 'Marke',
                type_hipping: 'Versandart',
                dt_code: 'DT-Code',
                sub_order_amount: 'Unterbestellmenge',
                trade_terms: 'Handelsbedingungen',
                passenger_number: 'Passagierwagen-Bestellnummer',
                dcs_order_number: 'DCS-Bestellnummer',
                remarks: 'Bemerkungen',
                submission_date: 'Einreichungsdatum',
                supplier: 'Lieferant',
                mode_of_trade: 'Handelsmodus',
                port: 'Hafen',
                to: 'An',
                currency: 'Währung',
                cancel_date: 'Stornierungsdatum',
                accessory_information: 'Zusatzartikel-Informationen',
            },
            query: {
                export_pi: 'Ausfuhr PI',
                audit_status: 'Prüfstatus',
                cancel: 'Abbrechen',
                whether_submit: 'Ob einreichen',
                actual_arrival: 'Tatsächliches Ankunftsdatum',
                estimated_arrival: 'Voraussichtliches Ankunftsdatum',
                packaging_completion_date: 'Verpackungsvollendungsdatum',
                order_no: 'Auftragsnummer',
                order_status: 'Auftragsstatus',
                rough_draft: 'Rohentwurf',
                funds_be_reviewed: 'Unzureichendes Guthaben',
                approved: 'Genehmigt',
                canceled: 'Storniert',
                order_type: 'Auftragstyp',
                creation_date: 'Erstellungsdatum',
                accessory_code: 'Teilenummer',
                mode_transport: 'Transportart',
                brand: 'Marke',
                export_order_details: 'Herunterladen',
                dt_code: 'DT-Code',
                order_amount: 'Auftragsbetrag',
                term_of_trade: 'Handelsbedingungen',
                submission_date: 'Einsendungsdatum',
                DCS_order_number: 'DCS-Auftragsnummer',
                remarks: 'Bemerkungen',
                upload_credit: 'Briefakkreditiv hochladen',
                lc_no: 'Akkreditivnummer',
                term_validity: 'Gültigkeitsdauer',
                amount: 'Akkreditivbetrag',
                picture: 'Akkreditivbild',
                empty: 'Akkreditivnummer darf nicht leer sein',
                payment_registration: 'Zahlungsregistrierung',
                payment_voucher_no: 'Zahlungsnummer',
                payment_date: 'Zahlungsdatum',
                payment_amoun: 'Zahlungsbetrag',
                number_lines: 'Anzahl der Zubehörleitungen',
                total_accessories: 'Gesamtanzahl des Zubehörs',
                estimated_premium: 'Versicherung schätzen',
                estimated_freight: 'Fracht schätzen',
                estimated_amount: 'Geschätzter Gesamtauftragsbetrag',
                order_tracking: 'Auftragsverfolgung',
                put_away: 'Einklappen',
                more: 'Mehr',
                actually_delivered: 'Tatsächlich gelieferte Teilenummer',
                confirm_quantity: 'Menge bestätigen',
                package_quantity: 'Paketmenge',
                shipment_quantity: 'Versandmenge',
                arrival_quantity: 'Ankunftsmenge',
                cancel_quantity: 'Stornierte Menge',
                bo_quantity: 'BO-Menge',
                order_submission: 'Einreichen',
                warn: 'Hinweis: 1. Bitte markieren Sie bei der Überweisung von Geldern die Zahlung für Ersatzteile oder komplette Fahrzeuge;',
                warns: '2. Der Kontobetrag richtet sich nach dem tatsächlich erhaltenen Betrag;',
                voucher: 'Bitte geben Sie die Zahlungsbelegnummer ein',
                payment_dates: 'Bitte geben Sie das Zahlungsdatum ein',
                payment_amount: 'Bitte geben Sie den Zahlungsbetrag ein',
                credit_number: 'Bitte geben Sie die Akkreditivnummer ein',
                validity_period: 'Bitte geben Sie den Gültigkeitszeitraum ein',
                letter_credit: 'Bitte geben Sie den Betrag des Akkreditivs ein',
                upload_picture: 'Bitte laden Sie ein Bild des Akkreditivs hoch',
                part_no: 'Teilenummer',
                part_name: 'Teilname',
                quantity: 'Bestellmenge',
                accessories: 'Zubehördetails',
                company: 'Versandgesellschaft',
                ships_name: 'Schiffsname',
                flights: 'Die Reihenfolge der Reisen oder Flüge',
                customs_no: 'Zollnummer',
                lading_no: 'Frachtbriefnummer',
                flight_number: 'Flugnummer',
                container_no: 'Behälternummer',
                packing_list: 'Packliste',
                packaging_quantity: 'Verpackungsmenge',
                packaging_completion: 'Verpackungsvollendungsdatum',
                shipment_quantitys: 'Versandmenge',
                delivery_date: 'Voraussichtliches Lieferdatum',
                shipment_dates: 'Tatsächliches Versanddatum',
                arrival_quantitys: 'Ankunftsmenge',
                arrival_date: 'Voraussichtliches Ankunftsdatum',
                actual_arrival_date: 'Tatsächliches Ankunftsdatum',
            },
            salesManagement: {
                before_modifications: 'Vor der Änderung',
                after_modification: 'Nach der Änderung',
                continue_importing: 'Import fortsetzen',
                quantity_difference: 'Menge Unterschied',
                customs_declaration_name: 'Zollanmeldung Name',
                customs_declaration_not: 'Zollanmeldung Ja/Nein',
                invoice_no: 'Rechnungs-Nr.',
                contract_no: 'Vertrags-Nr.',
                attachment_fee_amount: 'Anhang Gebührenbetrag',
                xpense_amount: 'Ausgabenbetrag',
                import_packing: 'Import MAXUS Verpackungsliste',
                import_list: 'Import MG Verpackungsliste',
                sales_order_list: 'Verkaufsauftragsliste',
                job_no_list: 'Job Nr.-Liste',
                dt_code: 'DT-Code',
                brand: 'Marke',
                packing_list_no: 'Packing-Liste-Nr.',
                packing_list_nos: 'Packing-Liste-Nr.:',
                bilateral_contract_no: 'Bilateraler Vertrag Nr.',
                contract_status: 'Vertragsstatus',
                inbound_delivery: 'Eingangs-Lieferauftrags-Nr.',
                supplier: 'Lieferant',
                list_creation: 'Erstellungsdatum der Verpackungsliste',
                currency: 'Währung',
                transit_status: 'Transitstatus',
                split_merge: 'Aufteilen/Zusammenführen',
                sap_return_information: 'SAP Rückgabeinformationen',
                send_booking: 'Sende Buchungs-E-Mail?',
                lcl_or_not: 'LCL oder nicht',
                operate: 'Betreiben',
                modify_freight: 'Fracht ändern',
                modify_lc: 'LC ändern',
                detailed: 'detailliert',
                merge: 'zusammenführen',
                split: 'aufteilen',
                diversion: 'Transportumleitung',
                lcl: 'LCL',
                charges: 'Zusätzliche Kosten',
                generation: 'One-Click-Dokumentgenerierung',
                deletion: 'One-Click-Dokumentlöschung',
                bind_lc: 'LC binden',
                unbind_lc: 'LC lösen',
                merge_ci: 'Fusionierte CI & PL herunterladen',
                export_ci: 'CI exportieren',
                export_pl: 'PL exportieren',
                additional_charges: 'Zusätzliche Kosten:',
                packing: 'Packing-Liste-Nr.:',
                expenses: 'Transportausgaben:',
                delivery: 'Ausgangs-Lieferauftrags-Nr.:',
                binding_record: 'LC-Bindungsprotokoll ändern',
                trade_terms: 'Handelsbedingungen:',
                logistics_mode: 'Logistikmodus:',
                port: 'Port:',
                payment_method: 'Zahlungsmethode:',
                please_select: 'Bitte wählen Sie die Handelsbedingungen.',
                please_logistics_mode: 'Bitte wählen Sie die Logistikmethode aus.',
                please_port: 'Bitte wählen Sie einen Hafen aus.',
                please_payment_method: 'Bitte wählen Sie die Zahlungsmethode aus.',
                select_export_type: 'Exporttyp auswählen',
                plase_select_export_type: 'Bitte wählen Sie den Exporttyp.',
                lc_code: 'LC Nr.',
                sap_return_status: 'SAP-Rückgabestatus',
                sap_voucher_code: 'SAP-Gutscheincode',
                amount_difference: 'Betragsunterschied',
                invoice_date: 'Rechnungsdatum',
                invoice_import_date: 'Importdatum der Rechnung',
                unbinding: 'Lösung der Bindung',
                no_unbinding_lc: 'Kein Lösungs-LC',
                sub_order_no: 'Sub-Auftrags-Nr.',
                submit_sap: 'SAP einreichen?',
                case_no: 'Fall Nr.:',
                binding: 'Bindung',
                outbound_delivery: 'Ausgangs-Lieferung',
                document_type: 'Dokumenttyp',
                accessory_code: 'Teil-Nr.',
                accessory_name: 'Teil-Name',
                sales_order_information: 'Verkaufsauftragsinformationen',
                total_lines_of_accessories: 'Gesamtanzahl der Zubehörzeilen:',
                total_number_of_accessories: 'Gesamtzahl der Zubehörteile:',
                total_amount: 'Gesamtbetrag:',
                amount_of_accessories: 'Betrag des Zubehörs:',
                premium: 'Prämie:',
                volume: 'Gesamtpaketvolumen (m³):',
                total_gross_weight: 'Gesamtbruttogewicht des Pakets:',
                weight_of_package: 'Gesamtgewicht des Pakets:',
                container_no: 'Container Nr.',
                actually_delivered: 'Tatsächlich gelieferte Teilenummer',
                order_parts_code: 'Ursprünglicher Auftragsteil-Nr.',
                shipment_quantity: 'Versandmenge',
                proforma_invoice: 'Proforma-Rechnung Nr.',
                customs_date: 'Zolldeklarationsdatum',
                customs_no: 'Zollanmeldung Nr.',
                net_weight: 'Nettogewicht (kg)',
                rough_weight: 'Bruttogewicht(kg)',
                volumes: 'Volumen(mm³)',
                outer_bo_size: 'Äußere Kastengröße (Länge mm)',
                box_size: 'Äußere Kastengröße (Breite mm)',
                box_height: 'Äußere Kastengröße (Höhe mm)',
                modification_date: 'Änderungsdatum',
                modification_by: 'Von bearbeitet',
                before_modification: 'Fracht vor der Änderung',
                modified_freight: 'Geänderte Fracht',
                sales_slip: 'Verkaufsbeleg',
                modification_record: 'Frachtänderungsprotokoll',
            },
            price: {
                DNNumber: 'DN-Nummer',
                deliveryDate: 'Lieferdatum',
                purchasePrice: 'Einkaufspreis',
                priceForDelivery: 'Preis pro Lieferungseinheit für Personenkraftwagen',
            },
        },
        document: {
            document: 'Dokument',
            name: 'Name',
            sort: 'Sortierung',
            directory: 'Verzeichnis',
            parent_directory: 'übergeordnetes Verzeichnis',
            new_directory: 'Neues Verzeichnis',
            edit_directory: 'Verzeichnis bearbeiten',
            primary_directory: 'Hauptverzeichnis',
            document_title: 'Dokumententitel',
            vehicle_platform: 'Fahrzeugplattform',
            status: 'Status',
            release_status: 'Freigabestatus',
            create_time: 'Erstellungszeit',
            secondary_directory: 'zweites Verzeichnis',
            model: 'Modell',
            attachment_name: 'Dateiname',
            publishing_department: 'Veröffentlichende Abteilung',
            publisher: 'Veröffentlicher',
            person_in_charge: 'Verantwortliche Person',
            remarks: 'Bemerkungen',
            publish_objects: 'Freigabe an',
            publish_mode: 'Freigabemodus',
            whether_the_document_is_downloadable: 'Ob das Dokument heruntergeladen werden kann',
            document_information: 'Dokumenteninformationen',
            document_tag: 'Dokumenten-Tag',
            operation_record: 'Betriebsaufzeichnung',
            operation_details: 'Betriebsdetails',
            operating_time: 'Betriebszeit',
            operator: 'Betriebsführer',
            to_be_released: 'Zur Veröffentlichung vorgesehen',
            published: 'Veröffentlicht',
            voided: 'Ungültig',
        },
        announcement: {
            title: 'Titel',
            publishing_department: 'Herausgebende Abteilung',
            status: 'Status',
            release_Date: 'Veröffentlichungsdatum',
            serialNo: 'Nr.',
            publisher: 'Herausgeber',
            file_Name: 'Dateiname',
            details: 'Details',
            void: 'Ungültig',
            information_detail: 'Information Detail',
            notification_Title: 'Benachrichtigungstitel',
            publish_Objects: 'Freigabe an',
            publish_Mode: 'Freigabemodus',
            bulletin_id: 'Bulletin-ID',
            publish_Content: 'Freigegebener Inhalt',
            preview: 'Vorschau',
            attachmentUpload: 'Anhang hochladen',
            supportedFormats: 'Unterstützte Formate',
            have: 'haben',
            NoticeWaitingToBeRead: 'Benachrichtigungen wartend auf Lektüre',
            released: 'Freigegeben',
            voided: 'Ungültig',
        },
        basic: {
            accessories: {
                masterdata: {
                    english_smpv: 'Englischer Name von SMPV',
                    accessory_code: 'Teilenummer',
                    accessory_name: 'Teilname',
                    accessory_name_zh: 'Chinesischer Name der Accessoires',
                    accessory_name_en: 'Englische Bezeichnung der Accessoires',
                    accessory_enm: 'Englische Beschreibung der Accessoires',
                    brand: 'Marke',
                    sales_area: 'Verkaufsgebiet',
                    create_data: 'Erstellungsdatum',
                    is_purchase: 'ist kaufbar',
                    is_transportations: 'Transport',
                    purchase_attribute: 'Kaufattribute',
                    is_marketable: 'ist marktfähig',
                    supplier_code: 'Lieferantencode',
                    supplier_name: 'Lieferantenname',
                    country_origin: 'Ursprungsland',
                    is_transportation: 'ist Transport',
                    magnetic_inspection: 'Magnetinspektion',
                    special_procurement_cycle: 'Spezieller Beschaffungszyklus',
                    material: 'Material',
                    is_max_overbooking: 'Steuerung der maximalen Bestellmenge',
                    max_overbooking: 'Maximale Bestellmenge zulässig',
                    sap_code: 'SAP-Code',
                    unit: 'Zubehöreinheit',
                    accessories_edit: 'Accessoires bearbeiten',
                    accessories_add: 'Neue Accessoires',
                    accessories_detailed: 'Details zu Accessoires',
                    accessory_unit: 'Zubehöreinheit',
                    name_of_customs_declaration_materials: 'Name der Zollanmeldematerialien',
                    customized_form: 'Individuelles Formular',
                    customs_declaration_unit: 'Zolldeklarationseinheit',
                    remarks: 'Bemerkungen',
                    customs_code: 'Zollcode',
                    license_material_name: 'Lizenzmaterialname',
                    create_by: 'Erstellt von',
                    company: 'Firmenname',
                    declare_ele: 'Spezifikationen',
                    base_cal_unit: 'Unternehmen',
                    base_cal_unit_cn: 'Chinesische Einheit',
                    update_by: 'Bearbeitet von',
                    update_time: 'Bearbeitungszeit',
                    accessories_picture: 'Accessoires Bild',
                    custom_accessories: 'Benutzerdefinierte Accessoires',
                    declaration_elements: 'Anmeldeelemente',
                    transportation: 'Kein Lufttransport',
                    accessories_selection: 'Accessoires-Auswahl',
                    enable_or_not: 'Aktivieren oder nicht',
                    supplier_selection: 'Lieferanten-Auswahl',
                    whether_glass: 'Ob Glas',
                },
                orderInvoicing: {
                    partCode: 'Teilcode',
                    brand: 'Marke',
                    baseQuantity: 'Grundlegende Menge',
                    baseUnit: 'Grundeinheit',
                    transferQuantity: 'Umwandlungsmenge',
                    transferUnit: 'Umwandeleinheiten',
                    createBy: 'Erstellt von',
                    createTime: 'Erstellungszeit',
                    orderInvoicing_new: 'neu hinzugefügt',
                    orderInvoicing_edit: 'bearbeiten',
                },
                billMaterials: {
                    dt_name: 'DT',
                    part_code: 'Zubehör Code',
                    part_name_cn: 'Chinesischer Name der Accessoires',
                    part_name_en: 'Englischer Name der Accessoires',
                    apply_time: 'Anwendungszeitpunkt',
                    brand: 'Marke',
                },
                replace: {
                    group_no: 'Gruppennummer',
                    item_no: 'Artikelnummer',
                    leading_products: 'führende Produkte',
                    description_of_leading_products: 'Beschreibung der führenden Produkte', // Beschreibung der führenden Produkte
                    subsequent_products: 'nachfolgende Produkte', // Nachfolgeprodukte
                    subsequent_product_description: 'Beschreibung des nachfolgenden Produkts', // Beschreibung des nachfolgenden Produkts
                    substitutive_relationship: 'Ersatzbeziehung', // Ersatzbeziehung
                    exhaustion_strategy: 'Erschöpfungsstrategie', // Erschöpfungsstrategie
                    depletion_date: 'Entleerungsdatum', // Entleerungsdatum
                    effective_date: 'Inkrafttretungsdatum', // Inkrafttretungsdatum
                    creation_date: 'Erstellungsdatum', // Erstellungsdatum
                    newly_added: 'neu hinzugefügt', // neu hinzugefügt
                    newly_edit: 'bearbeiten', // bearbeiten
                },
                mxreplace: {
                    group_no: 'Gruppennummer',
                    project_no: 'Artikelnummer',
                    part_code: 'Zubehör Code',
                    part_name_cn: 'Chinesischer Name des Zubehörs',
                    new_part_code: 'Alternativer Zubehör-Code',
                    new_part_name_cn: 'Chinesischer Name der Ersatzauswahl',
                    effective_time: 'Inkrafttretungsdatum',
                    stock_deal: 'Vorschläge zur Bestandsentsorgung',
                    create_time: 'Erstellungsdatum',
                    replace_relate: 'Ersatzbeziehung',
                    brand: 'Marke',
                    replace_remarks: 'Ersatzbemerkungen',
                    newly_edit: 'bearbeiten', // bearbeiten
                    part_codes: 'Zubehör Code',
                    new_part_codes: 'Ersatzteilcode',
                    new_part_name_cns: 'Chinesischer Name des Ersatzteils',
                    stock_deals: 'Bestandsbearbeitungsvorschläge',
                },
                sap: {
                    sap_part_id: 'Primärschlüssel', // 主键
                    part_id: 'Teil ID', // 零件id
                    brand: 'Marke', // 项目编号
                    part_code: 'Alter Materialno', // 零件代码
                    material_type: 'ZTG2/SAP Materialtyp', // ZTG2/SAP物料类型
                    customer_declare_material_name: 'Name der Zolldeklarationsmaterialien', // 报关物料名称
                    material_name_en: 'Englischer Materialname', // 英文物料名称
                    // brand: string; // 品牌
                    hs_code: 'HS-Code', // HS编码
                    customer_declare_licence: 'Lizenz-Materialname', // 许可证物料名称
                    base_cal_unit: 'BUoM', // 基本计量单位
                    sap_code: 'SAP Materialno', // SAP物料号
                    sap_codes: 'Sap Materialtyp', // SAP物料号
                    gross_weight: 'Bruttogewicht', // 毛重
                    declare_ele: 'Deklarationselemente', // 申报要素
                    net_weight: 'Nettogewicht', // 净重
                    volume: 'Volumen (mm³)', // 体积
                    specs: 'Spezifikationen', // 规格
                    danger_attr: 'Produktattribut 2 (gefährliche Güter)', // 产品属性2(危险品)
                    abroad_danger_attr:
                        'Produktattribut 3 (Identifikation ausländischer gefährlicher Güter)', // 产品属性3(国外危险品标识)
                    status: 'Erstellungsstatus', // 状态0-草稿，1-进行中，2-已完成
                    create_by: 'Erstellt von', // 创建人
                    create_time: 'Erstellungszeit',
                    update_by: 'Bearbeitet von', // 创建人
                    update_time: 'Bearbeitungszeit',
                    sap_edit: 'Bearbeiten',
                    sap_add: 'Neu hinzugefügt',
                    error_reasons: 'Sap Materialtyp',
                    sap_status: 'Sap Rückgabe-Status',
                    part_name_cn: 'Chinesische Materialbeschreibung',
                    sale_tax_class: 'Umsatzsteuerklassifikation',
                    error_reason: 'Rückgabeinformationen',
                },
                coo: {
                    country_id: 'Land',
                    brand: 'Marke',
                    part_code: 'Zubehör Code',
                    part_name_cn: 'Name des Zubehörs',
                    origin_country: 'Ursprungsland',
                    create_by: 'Erstellt von',
                    create_time: 'Erstellt am',
                    coo_add: 'Neu hinzugefügt',
                    coo_edit: 'Bearbeiten',
                    accessories_selection: 'Zubehörauswahl',
                },
                sapModify: {
                    gds_material_number: 'GDS-Materialnummer',
                    sap_material_number: 'SAP-Materialnummer',
                    chinese_name_of_material_zh: 'Chinesischer Name des Materials ZH',
                    english_name_of_material_en: 'Englischer Name des Materials EN',
                    material_declaration_name_z1: 'Materialdeklarationsname Z1',
                    declaration_elements: 'Deklarationselemente',
                    classification_proposal_no: 'Klassifizierungsvorschlag Nr',
                    period_of_validity_of_classification: 'Gültigkeitsdauer der Klassifizierung',
                    tax_classification_code: 'Steuerschlüssel',
                    factory: 'Fabrik',
                    status: 'Status',
                    sap_status: 'SAP-Rückgabestatus',
                    commodity_import_code_number: 'Wareneinfuhrungscode-Nummer',
                    sap_modify_edit: 'Bearbeiten',
                    sap_modify_add: 'Neu hinzugefügt',
                },
            },
            sales: {
                weight_ladder: 'Gewichtsleiter',
                country: 'Land',
                port: 'Hafen',
                portCode: 'Hafencode',
                portCN: 'chinesischer Name',
                portUS: 'englischer Name',
                EffectiveDate: 'Wirksamkeitsdatum',
                ExpirationDate: 'Ablaufdatum',
                oldEffectiveTime: 'alter Wirksamkeitszeitpunkt',
                oldExpireTime: 'alter Ablaufzeitpunkt',
                newEffectiveTime: 'neuer Wirksamkeitszeitpunkt',
                newExpireTime: 'neuer Ablaufzeitpunkt',
                freight: 'Fracht',
                KG: 'KG',
                KGabove: 'Über KG',
                express: 'Expressbereich',
                general: 'allgemein',
                danger: 'Gefahr',
                GP20: 'Preis für 20GP-Einheit (USD)',
                GP40: 'Preis für 20GP-Einheit (USD)',
                hq40: 'Preis für 40hq-Einheit (USD)',
                region: 'Expressbereich',
                currencyCode: 'Währungscode',
                description: 'Beschreibung',
                MinimumUnit: 'Mindesteinheit',
                ChineseDescription: 'Chinesische Beschreibung',
                symbol: 'Symbol',
                updated_date: 'Letztes Aktualisierungsdatum',
                price_benchmark: 'Preisbenchmark',
                part_description: 'Teilbeschreibung',
                excluding_tax: 'Kaufpreis ohne Steuern',
                tax: 'Kaufpreis inklusive Steuern',
                price_including_tax: 'Inlandsverkaufspreis inklusive Steuern',
                seller: 'Verkäufer',
                selling_party: 'verkaufende Partei',
                price: 'Preis',
                sales_ratio: 'Umsatzanteil (%)',
                cifShare: {
                    trade_clause: 'Handelsklausel',
                    order_type: 'Bestelltyp',
                    type_of_shipping: 'Versandart',
                    dt: 'DT',
                    last_modified_date: 'letztes Änderungsdatum',
                    creation_date: 'Erstellungsdatum',
                    sales_price_proportion: 'Anteil des Verkaufspreises (%)',
                    seller: 'Verkäufer',
                    dt_choose: 'DT wählen',
                    dt_code: 'DT-Code',
                    dt_name: 'DT-Name',
                    country: 'Land',
                },
                cifSingle: {
                    trade_clause: 'Handelsklausel',
                    accessory_type: 'Zubehörtype',
                    type_of_shipping: 'Versandart',
                    dt: 'DT',
                    freight_coefficient: 'Fracht-Koeffizient (%)',
                    premium_coefficient: 'Prämienkoeffizient (%)',
                    air_freight0: 'Luftfracht (0-100KG)',
                    air_freight100: 'Luftfracht (100-500KG)',
                    air_freight500: 'Luftfracht (über 500KG)',
                    minimum_air_freight_charge: 'Mindestluftfrachtgebühr',
                    dangerous_goods_freight: 'Gefahrgutfracht',
                    dangerous_goods_minimum_freight: 'Mindestgefahrgutfracht',
                    dangerous_goods_ITEM_freight: 'Fracht für Gefahrgutartikel',
                    dangerous_goods_qty_freight: 'Fracht für Gefahrgutmenge',
                    dt_choose: 'DT wählen',
                    dt_code: 'DT-Code',
                    dt_name: 'DT-Name',
                },
                triesLimit: {
                    dt: 'DT',
                    frequency_limit: 'Frequenzlimit',
                    price_ratio: 'Preisverhältnis (%)',
                    seller: 'Verkäufer',
                    brand: 'Marke',
                    dt_choose: 'DT wählen',
                    dt_code: 'DT-Code',
                    dt_name: 'DT-Name',
                },
                dcs: {
                    partial_transmission: 'Teilübertragung',
                    full_transmission: 'Vollständige Übertragung',
                    download_template: 'Vorlage herunterladen',
                    upload_Excel: 'Excel hochladen',
                    upload_files: 'Dateien hochladen',
                    no_files_selected: 'Keine Dateien ausgewählt',
                    General_generation: 'Allgemeine Erzeugung',
                    DCS_price_delivery_price: 'DCS-Preislieferungspreis (FOB-Preis)',
                },
            },
            sort: {
                maxmaterial: {
                    category_code: 'Materielle Kategorie',
                    // category_codes: 'Preis-Hauptkategorie',
                    category_desc: 'beschreiben',
                    maxmaterial_add: 'hinzugefügt',
                    maxmaterial_edit: 'bearbeiten',
                },
                minmaterial: {
                    no_air: 'Kein Lufttransport',
                    category_code: 'Materialkategorie-Code',
                    category_desc: 'Beschreibung der Materialkategorie',
                    big_material_category_id: 'Materialkategorie',
                    pack_alone: 'Ob es separat verpackt ist',
                    minmaterial_add: 'hinzugefügt',
                    minmaterial_edit: 'bearbeiten',
                    material_category_selection: 'Auswahl der Materialkategorie',
                },
                model: {
                    type_code: 'Typencode',
                    brand: 'Marke',
                    parts_code: 'Teilcode',
                    parts_name: 'Teilname',
                    save_add: 'speichern und neu',
                },
                code: {
                    type_code: 'Typencode',
                    model_grade: 'Modellqualität',
                    save_add: 'speichern und neu',
                },
                marialMaint: {
                    small_material_category_id: 'Materialkategorie-Code', // 物料小类代码
                    brand: 'Marke',
                    create_time: 'Erstellungszeit',
                    create_by: 'Erstellt durch',
                    part_id: 'Zubehör-Code', // 配件代码
                    marial_maint_add: 'hinzugefügt',
                    marial_maint_edit: 'bearbeiten',
                },
            },
            packing: {
                mpq: {
                    transport: 'Transportart',
                    category_code: 'Kleinste Klassifizierungsnummer',
                },
                information: {
                    transport_package_No: 'Transport-Paketnummer',
                    name: 'Name',
                    classification: 'Klassifikation',
                    purpose: 'Zweck',
                    length: 'Länge(mm)',
                    width: 'Breite(mm)',
                    height: 'Höhe(mm)',
                    volume: 'Volumen(CBM)',
                    specification_requirements: 'Spezifikationsanforderungen',
                    brand: 'Marke',
                    creation_date: 'Erstelldatum',
                    created_by: 'Erstellt durch',
                    purchasing_unit: 'Einkaufseinheit',
                },
                volume: {
                    volume_factor: 'Volumenfaktor',
                    shipping_type: 'Versandart',
                    brand: 'Marke',
                    creation_date: 'Erstelldatum',
                    created_by: 'Erstellt durch',
                },
                cmaintain: {
                    packing_type_code: 'Verpackungsart Code',
                    english_description: 'Englische Beschreibung',
                },
                sap: {
                    pack_material_code: 'Verpackungsmaterialnummer',
                    chinese_desc: 'Chinesischer Name',
                    english_desc: 'Englischer Name',
                    sap_add: 'Neu hinzugefügt',
                    sap_edit: 'Bearbeiten',
                },
                minmaterial: {
                    small_material_category_code: 'Materialcode',
                    small_material_category_desc: 'Artikelbeschreibung',
                    special_compute: 'Spezielle Berechnung oder nicht',
                    create_time: 'Erstellt am',
                    create_by: 'Erstellt durch',
                    small_code: 'Materialkategorie',
                    minmaterial_add: 'Neu hinzugefügt',
                    minmaterial_edit: 'Bearbeiten',
                },
                maintain: {
                    package_volume: 'Paketvolumen（mm³）',
                    part_code: 'Teilenummer',
                    brand: 'Marke',
                    minimum_number_of_packages: 'Mindestanzahl der Pakete',
                    long: 'Länge',
                    width: 'Breite',
                    height: 'Höhe',
                    gross_weight: 'Bruttogewicht',
                    net_weight: 'Nettogewicht',
                    creation_date: 'Erstelldatum',
                    create_by: 'Erstellt von',
                    revision_date: 'Änderungsdatum',
                    revision_by: 'Geändert durch',
                    package_type: 'Verpackungstyp',
                    unit_of_length: 'Einheit der Länge',
                    unit_of_weight: 'Gewichtseinheit',
                    package_plan: 'Verpackungsplan',
                },
                dangerInfo: {
                    accessory_code: 'Zubehör-Code',
                    nam_accessories: 'Englischer Name des Zubehörs',
                    accessories_cn: 'Chinesischer Name des Zubehörs',
                    brand: 'Marke',
                    bonfire_experiment: 'Brandexperimentnummer',
                    bonfire_experiment_cn: 'Chinesischer Name des Brandexperiments',
                    bonfire_experiment_en: 'Englischer Name des Brandexperiments',
                    dangerous_goods_supplier: 'Lieferant für gefährliche Güter',
                    length: 'Länge (mm)',
                    width: 'Breite (mm)',
                    height: 'Höhe (mm)',
                    packing_type: 'Verpackungstyp',
                },
            },
            customs: {
                hscode: {
                    hs_code: 'Zolltarifnummer', // 海关代码
                    hscode_unit: 'L/KG', // L/KG
                    is_inspect: 'Wareninspektion oder nicht', // 是否商检，Y-是，N-否
                    tax_refund_rate: 'Steuererstattungsrate', // 退税率
                    supervise_condition: 'Regulierungsbedingungen', // 监管条件
                    company: 'Firma', // 公司
                    country_id: 'Land', // 国家
                    create_time: 'Erstellungszeit',
                    hscode_add: 'Neu hinzugefügt',
                    hscode_edit: 'Bearbeiten',
                },
                export: {
                    brand: 'Marke', // 品牌
                    part_code: 'Zubehör Code', // 配件代码
                    conutry_code: 'Landescode', // 国家代码
                    hscode: 'Zolltarifnummer', // 海关编码
                    form: 'FORM-E', // FORM-E
                    tariff: 'TARIF', // TARIFF
                },
                fluid: {
                    brand: 'Marke',
                    part_id: 'Zubehör Code',
                    part_id_name: 'Name des Zubehörs',
                    fluid_add: 'Neu hinzugefügt',
                },
                danger: {
                    company: 'Firma', // 公司
                    ship_type: 'Versandart', // 运输方式
                    part_code: 'Zubehör Code', // 配件代码
                    brand: 'Marke',
                    danger_type: 'Art der Gefahrengüter', // 危险品类型
                    danger_category: 'Klassifizierung von Gefahrengütern', // 危险品分类
                    chinese_part_name: 'Chinesischer Name des Zubehörs',
                    english_part_name: 'Englischer Name des Zubehörs',
                    danger_add: 'Neu hinzugefügt',
                    danger_edit: 'Bearbeiten',
                },
                choren: {
                    chinese_unit: 'Chinesische Einheit',
                    english_unit: 'Englische Einheit',
                    create_by: 'Erstellt von',
                    create_time: 'Erstellungszeit',
                    choren_add: 'Neu hinzugefügt',
                    choren_edit: 'Bearbeiten',
                },
                maintain: {
                    dt: 'DT',
                    material_no: 'Material No',
                    brand: 'Marke',
                    customs_code: 'Zolltarifnummer',
                    form_e_mark: 'Form E Vermerk',
                    created_on: 'Erstellt am',
                    created_by: 'Erstellt von',
                    maintain_add: 'Neu hinzugefügt',
                    maintain_edit: 'Bearbeiten',
                },
                chemicali: {
                    company: 'Firma',
                    material_no: 'Material No',
                    brand: 'Marke',
                    license_or_not: 'Lizenz vorhanden oder nicht',
                    chemicali_add: 'Neu hinzugefügt',
                    chemicali_edit: 'Bearbeiten',
                },
            },
            salesl: {
                fixedRate: {
                    local_currency: 'Lokale Währung',
                    name_of_local_currency: 'Name der lokalen Währung',
                    seller: 'Verkäufer',
                    fixed_rate: 'Festpreis',
                    settlement_currency: 'Abrechnungswährung',
                    name_of_settlemen_currency: 'Name der Abrechnungswährung',
                    dt: 'DT',
                    brand: 'Marke',
                    effective_date: 'Wirksamkeitsdatum',
                    expiration_date: 'Ablaufdatum',
                    created_on: 'Erstellt am',
                    fixedRate_add: 'Neu hinzugefügt',
                    fixedRate_edit: 'Bearbeiten',
                    fixedRate_select: 'Nach Auswahl von DT ist die Marke nicht leer',
                },
                transport: {
                    dt: 'DT',
                    brand: 'Marke',
                    trade_terms: 'Handelsbedingungen',
                    type_of_shipping: 'Versandart',
                    transport_add: 'Neu hinzugefügt',
                    created_on: 'Erstellt am',
                    transport_edit: 'Bearbeiten',
                },
                purchaseConfirm: {
                    accessory_code: 'Zubehör-Code',
                    effective_date: 'Wirksamkeitsdatum',
                    expiration_date: 'Ablaufdatum',
                    accessory_description: 'Zubehörbeschreibung',
                    purchase_price: 'Kaufpreis ohne Steuern',
                    tax_rate: 'Steuersatz',
                    currency: 'Währung',
                    corporation: 'Unternehmen',
                    created_on: 'Erstellt am',
                },
            },
            sapModify: {
                gds_material_number: 'GDS Materialnummer',
                sap_material_number: 'SAP Materialnummer',
                chinese_name_of_material_zh: 'Chinesischer Name des Materials ZH',
                english_name_of_material_en: 'Englischer Name des Materials EN',
                material_declaration_name_z1: 'Materialdeklarationsname Z1',
                declaration_elements: 'Deklarationselemente',
                classification_proposal_no: 'Klassifizierungsvorschlagsnummer',
                period_of_validity_of_classification: 'Gültigkeitszeitraum der Klassifizierung',
                tax_classification_code: 'Steuerklassifikationscode',
                factory: 'Fabrik',
                commodity_import_code_number: 'Waren-/Importcode-Nummer',
                sapModify_edit: 'Bearbeiten',
                sapModify_add: 'Neu hinzufügen',
            },
            customer: {
                fundPool: {
                    dt_code: 'DT-Code',
                    dt_name: 'DT-Name',
                    dt_short_name: 'DT-Kurzname',
                    currency: 'Währung',
                    account_amount: 'Kontobetrag',
                    freeze_amount: 'Einfrierbetrag',
                    date: 'Datum',
                    fund_status: 'Fondstatus',
                    fund_type: 'Fondtyp',
                    payment_number: 'Zahlungsnummer',
                    credit_number: 'Kreditnummer',
                    suborder_number: 'Teilbestellnummer',
                    container_number: 'Containernummer',
                    credit_validity_date: 'Gültigkeitsdatum des Kredits',
                    recorded: 'aufgezeichnet/Auszahlung',
                    disbursement_money: 'Auszahlungsgeld',
                    wait_recorded_money: 'warten auf aufgezeichnetes Geld',
                    recorded_money: 'aufgezeichnetes Geld',
                    service_charge: 'Service-Gebühr',
                    account_balance: 'Kontostand',
                    freeze_balance: 'Einfrierstand',
                    credit_picture: 'Kreditbild',
                    upload_list: 'Upload-Liste',
                    remark: 'Bemerkung',
                    dt_payment_confirm: 'DT-Zahlungsbestätigung',
                    credit_confirm: 'Kreditbestätigung',
                    credit_money: 'Kreditgeld',
                    arrival_amount: 'Ankunftsbetrag',
                    dt_selection: 'DT-Auswahl',
                },
                customer: {
                    dt_code: 'DT-Code',
                    dt_name: 'DT-Name',
                    dt_short_name: 'DT-Kurzname',
                    country: 'Land',
                    sap_code: 'SAP-Code',
                    brand: 'Marke',
                    beneficiary_name: 'Begünstigter Name',
                    beneficiary_address: 'Begünstigte Adresse',
                    VAT: 'Mehrwertsteuer（%）',
                    Name_of_bank_of_deposit: 'Name der Einzahlungsstelle',
                    VAT_number: 'Mehrwertsteuernummer',
                    bank_address: 'Bankadresse',
                    SWIFT_CODE: 'SWIFT-CODE',
                    bank_account: 'Bankkonto',
                    Agent_bank_address: 'Agentur-Bankadresse',
                    Agent_bank_account: 'Agentur-Bankkonto',
                    Agent_bank_name: 'Agentur-Bankname',
                    IBAN: 'IBAN',
                    Agent_bank_iban: 'Agentur-Bank-IBAN',
                    Agent_bank_SWIFT_Code: 'Agentur-Bank SWIFT-Code',
                    Bank: 'BANK',
                    inherit_general_generation: 'erben allgemeine Generation',
                    money: 'Geld',
                    A_24_hour_hotline: 'eine 24-Stunden-Hotline',
                    facsimile: 'Fax',
                    telephone: 'Telefon',
                    local_country: 'Lokales Land',
                    local_city: 'Lokale Stadt',
                    local_area: 'Lokales Gebiet',
                    Postal_code: 'Postleitzahl',
                    email_address: 'E-Mail-Adresse',
                    Affiliated_brand: 'Verbundene Marke',
                    Company_homepage: 'Firmenhomepage',
                    Discount_rate_for_parts_sales: 'Rabattrate für Teileverkäufe',
                    Company_address: 'Firmenadresse',
                    Method_of_settlement: 'Abwicklungsmethode',
                    Sales_territory: 'Verkaufsgebiet',
                    Express_freight_area: 'Bereich für Expressfracht',
                    Selling_party: 'Verkaufspartei',
                    Address_of_the_selling_party: 'Adresse der Verkaufspartei',
                    Loan_agency_information: 'Kreditagentur-Informationen',
                    Payment_terms: 'Zahlungsbedingungen',
                    Account_period: 'Rechnungsperiode',
                    supplier: 'Lieferant',
                    Shipping_address: 'Versandadresse',
                    Contact_name: 'Kontaktname',
                    File_delivery_address: 'Datei-Lieferadresse',
                    Air_address: 'Luftadresse',
                    Express_address: 'Expressadresse',
                    organization: 'Organisation',
                    Trade_clause1: 'Handelsklausel 1',
                    Trade_clause2: 'Handelsklausel 2',
                    beneficiary: 'Begünstigter',
                    fmc: 'FMC',
                    Swift_Code: 'Swift-Code',
                    USD_Account: 'USD-Konto',
                    EUR_Account: 'EUR-Konto',
                    RMB_Account: 'RMB-Konto',
                    Tax_number: 'Steuer-Identifikationsnummer',
                    Order_clerk: 'Bestellangestellter',
                    Method_of_payment: 'Zahlungsmethode',
                    Market_type: 'Markttyp',
                    MX_business_model: 'MX-Geschäftsmodell',
                    label: 'Etikette',
                    Air_freight_rate: 'Luftfrachtrate（%）',
                    Ocean_freight_rate: 'Seefrachtrate（%）',
                    Express_rate: 'Expressrate（%）',
                    Document_number_prefix: 'Dokumentennummerpräfix',
                    TT_settlement_term: 'TT-Abwicklungsbegriff',
                    LC_settlement_term: 'LC-Abwicklungsbegriff',
                    name: 'Name',
                    Contact_person: 'Kontaktperson',
                    Contact_number: 'Kontaktnummer',
                    address: 'Adresse',
                    city: 'Stadt',
                    Zip_code: 'Postleitzahl',
                    sap_consumer_query: 'SAP-Verbraucheranfrage',
                    customer_code: 'Kundennummer',
                    customer_name: 'Kundenname',
                    sales_organization: 'Verkaufsorganisation',
                    abbreviation: 'Abkürzung',
                    organization_name: 'Organisationsname',
                    customer_information: 'Kundeninformationen',
                    delivered_by: 'Geliefert von',
                },
            },
        },
        components: {
            businessComponents: {
                documentType: 'Dokumenttyp',
                vehicleType: 'Fahrzeugtyp',
                dealerInformation: 'Händlerinformationen',
                reportType: 'Berichtstyp',
                dealerContact: 'Händlerkontakt',
                contact: 'Kontakt #',
                occupation: 'Besetzung',
                vehicleInformation: 'Fahrzeuginformationen',
                mileage: 'Laufleistung (/km)',
                licensePlate: 'Kennzeichen #',
                platform: 'Plattform',
                offLineDate: 'Off-line Datum',
                vehicleUse: 'Fahrzeugnutzung',
                refited: 'Umrüstung',
                nachgefüllt: 'Nachgefüllt',
                nameplateNo: 'Typschild Nr',
                purchaseDate: 'Kaufdatum',
                accidentInformation: 'Unfallinformationen',
                timeOfAccident: 'Zeit des Unfalls',
                placeOfAccident: 'Unfallort',
                roadConditions: 'Straßenverhältnisse',
                pavementType: 'Straßentyp',
                weather: 'Wetter',
                vehicleCondition: 'Fahrzeugzustand',
                vehicleOfTheAccident: 'Fahrzeuginformationen zum Zeitpunkt des Unfalls',
                vehicleCollisionPosition: 'Fahrzeugkollisionsposition',
                vehicleRunningStatus: 'Fahrzeugbetriebsstatus',
                ownSpeed: 'Eigene Geschwindigkeit',
                vehicleSpeedOfTheOtherSide: 'Fahrzeuggeschwindigkeit auf der anderen Seite',
                personnelInsideTheVehicle: 'Personal im Fahrzeug',
                numberOfPeopleInTheCar: 'Anzahl der Personen im Auto',
                personalInjury: 'Personenschaden',
                distributionOfPersonnelInTheVehicle: 'Verteilung des Personals im Fahrzeug',
                descriptionOfPersonalInjury: 'Beschreibung der Personenschäden',
                airbagDeployment: 'Airbag-Deployment',
                safetyBeltWearing: 'Sicherheitsgurt tragen',
                brakeBeforeTheAccident: 'Bremsen vor dem Unfall',
                turnBeforeTheIncident: 'Drehung vor dem Vorfall',
                whenCorrosionOccurs: 'Wenn Korrosion auftritt',
                parkingTimeHours: 'Parkzeit (Stunden)',
                parkingTimeMinutes: 'Parkzeit (Minuten)',
                drivingHours: 'Fahrstunden',
                drivingMinutes: 'Fahrminuten',
                drivingDistance: 'Fahrstrecke',
                drivingSpeed: 'Fahrgeschwindigkeit',
                vehicleState: 'Fahrzeugstatus',
                fireFightingOrNot: 'Feuerbekämpfung oder nicht',
                numberOfInjured: 'Anzahl der Verletzten',
                deathToll: 'Todesfälle',
                vehicleHelp: 'Fahrzeughilfe',
                vehiclePassingAccident: 'Fahrzeug überholen Unfall',
                pastAccidents: 'Vergangene Unfälle',
                isTheWarningLampOnBeforeTheEvent:
                    'Ist die Warnleuchte vor dem Ereignis eingeschaltet',
                specificIconContent: 'Spezifischer Symbolinhalt',
                didYouSmellFuelBeforeTheAccident: 'Hast du vor dem Unfall Benzin gerochen',
                didYouFeelAnyAbnormalVibrationBeforeTheAccident:
                    'Haben Sie vor dem Unfall eine abnormale Vibration gespürt',
                abnormalSoundZone: 'Abnormale Klangzone',
                didYouHearAnyNoiseBeforeTheAccident: 'Haben Sie vor dem Unfall ein Geräusch gehört',
                noiseDescription: 'Geräuschbeschreibung',
                theAreaWhereTheVehicleSmokedBeforeTheAccident:
                    'Der Bereich, in dem das Fahrzeug vor dem Unfall geraucht hat',
                otherAreas: 'Andere Bereiche',
                whetherTheInsuranceCompanyHasIssuedACertificate:
                    'Hat die Versicherungsgesellschaft eine Bescheinigung ausgestellt',
                whetherTheAuthorityIssuesTheCertificate:
                    'Ob die Behörde die Bescheinigung ausstellt',
                isVDSReportAvailable: 'Steht ein VDS-Bericht zur Verfügung',
                accidentProcess: 'Unfallprozess',
                accidentProcessPlaceholder:
                    'Berichtet von_ Die Paraphrase von XXXX.\n' +
                    'Um ca. XX Uhr auf XX/XX/20XX fuhr die Party XXXX ein Fahrzeug mit Kennzeichen XXX Roewe RX50 1.5/AT entlang der Bundesstraße von Süden nach Norden (mit einer Person im Fahrzeug und einem Fahrer). Bei der Fahrt nach XX Village, XX Town, XX County, XX City, XXX Province wurde festgestellt, dass weißer Rauch im linken Cockpit erzeugt wurde.',
                accessoryMaterials: 'Zubehörmaterialien',
                bulkDownload: 'Massendownload',
                materialUpload: 'Material-Upload',
                userReadmeAttachment: 'Benutzer-Leseanleitung Anhang',
                sitePhotos: 'Standortfotos',
                fireAccidentIdentificationLetter: 'Feuerunfall-Identifikationsbrief',
                insuranceRecordAttachment: 'Versicherungsrekord-Anhang',
                diagnosticRecords: 'Diagnostikunterlagen',
                temperature: 'Temperatur',
                please_select_the_reporting_type: 'Bitte wählen Sie die Art der Berichterstattung',
                fire_accident_identification_letter_required:
                    'Kennzeichnungsschreiben für Brandunfälle erforderlich',
                please_upload_the_fire_accident_identification_letter:
                    'Bitte laden Sie das Brandunfallidentifikationsformular hoch',
                insurance_record_attachment_required:
                    'Anhang der Versicherungsunterlagen erforderlich',
                please_upload_the_insurance_record_attachment:
                    'Bitte laden Sie den Anhang der Versicherungsakte hoch',
                please_upload_the_diagnostic_record_attachment:
                    'Bitte laden Sie den Anhang zur Diagnose hoch',
                please_upload_site_photos: 'Bitte laden Sie Fotos vor Ort hoch',
            },
        },
        order: {
            claim: {
                creater: 'Ersteller',
                brand: 'Marke',
                claimAmountAll: 'Gesamtschadenpreis',
                creationDate: 'Erstellungsdatum',
                currentState: 'Aktueller Prozessstatus',
                claimNumber: 'Anzahl der Schäden',
                claimName: 'Name',
                claimNameCN: 'NameCN',
                claimNameUS: 'NameCN',
                Application: 'Teilenummernanspruch',
                orderNumber: 'Bestellnummer',
                amount: 'Bitte geben Sie den Betrag ein',
                frequency: 'Bitte geben Sie die Anzahl der Vorkommnisse ein',
                applicationNumber: 'Teilenummernanspruch',
                creationDateIn: 'Erstellungsdatum',
                new: 'Neu',
                applications: 'Liste der Ansprüche',
                creationTime: 'Erstellungszeit',
                money: 'Währung',
                containerNumber: 'PL-Nr.',
                pack_noCode: 'Containercode',
                suborder: 'Unterauftragsnummer',
                PartNumber: 'Teilenummer',
                claimType: 'Schadensart',
                orderQuantity: 'Rechnungsquantität',
                receivedQuantity: 'Erhaltene Menge',
                claimNumbers: 'Anspruchsmenge',
                approvedQuantity: 'Genehmigte Menge',
                purchaseAmount: 'Verkaufspreis (FOB)',
                subtotal: 'Gesamtpreis',
                scrapPhotos: 'Schrottbild',
                accessoriesPhotos: 'Fotos von Teilen',
                accessoriesLabel: 'Fotos von Teileetiketten',
                accessoriesPackagingPhotos: 'Fotos von Teileverpackungen',
                transportation: 'Fotos von Transportverpackungen',
                packingList: 'Verpackungslistenbildschirm',
                claimReason: 'Problem Beschreibung',
                DTNumber: 'DT-Nummer',
                claimMessage: 'Anspruchsinformationen',
                Circulationadvice: 'Umlaufempfehlung',
                General_generation_name: 'Allgemeiner Generationsname',
                claim_order: 'Anspruchsbestellung',
                audit_result: 'Prüfergebnis',
                OEMResult: 'OEM-Prüfergebnis',
                responsible: 'Verantwortlich',
                modification_date: 'Änderungsdatum',
                updated_date: 'Aktualisierungsdatum',
                General_agent: 'Allgemeiner Agent',
                ATA_time: 'ATA Set Tage',
                claimAmount: 'Schadensbetrag',
                pack_choose: 'Auswahl der Boxnummer',
                suborder_choose: 'Auswahl der Unterauftragsnummer',
                PartNumber_choose: 'Teilenummerauswahl',
                request_pack: 'Bitte überprüfen Sie die Verpackungslistennummer',
                request_suborder: 'Bitte überprüfen Sie die Unterauftragsnummer',
                request_PartNumber: 'Bitte überprüfen Sie die Teilenummer',
                request_Claim: 'Bitte überprüfen Sie die Anspruchspartiezeile',
                request_ATA: 'Keine ATA-Zeit, nicht erstattungsfähig',
                information: 'Grundinformationen',
                completionStatus: 'Fertigstellungsstatus',
                Handlingsuggestion: 'Bearbeitungsempfehlungen',
                picture: 'Bilder',
                greater: 'Größer oder gleich',
                less: 'Kleiner oder gleich',
                week: 'Woche',
                month: 'Monat',
                quarter: 'Quartal',
                year: 'Jahr',
            },
            report: {
                logisticsEfficiency: {
                    dt_code: 'DT-Code',
                    dt_name: 'DT-Name',
                    order_month: 'Bestellmonat',
                    order_quantity: 'Bestellmenge',
                    total_order_amount: 'Gesamtbestellmenge',
                    packaging_timeliness: 'Verpackungstermin',
                    maritime_transport_general_cargo: 'Seetransport - Allgemeine Fracht',
                    air_transport: 'Lufttransport',
                    sea_transportation: 'Seetransport',
                    express_delivery: 'Express-Lieferung',
                    zh_order_quantity: 'Pünktliche Bestellmenge',
                    punctualit_rate: 'Pünktlichkeitsrate',
                    maritime_transport_dangerous_goods: 'Seetransport - Gefahrgut',
                    otd_timeliness: 'OTD-Termin',
                    transport_prescription: 'Transportvorschrift',
                    lines: 'Anzahl der Bestellzeilen',
                    amount: 'Bestellmenge',
                },
                abnormalOrder: {
                    Overexpectationprocessing: 'Übererwartete Verarbeitung',
                    Overduehasbeenprocessed: 'Überfällig wurde verarbeitet',
                    packing: 'Verpackung',
                    OTD: 'OTD',
                    transport: 'Transport',
                    Orderlinenumber: 'Bestellzeilennummer',
                    Orderlinenumberall: 'Bestellzeilennummer Gesamt',
                    Anomalyrate: 'Abweichungsrate',
                },
            },
            distributionManagement: {
                list_information: 'Verpackungslisten-Informationen',
                container_scheme: 'Container-Schema',
                logistics_registration: 'Logistik-Registrierung',
                dt_code: 'DT-Code',
                packing_list: 'Verpackungslisten-Nr.',
                date_warehousing: 'Einlagerungsdatum',
                name_voyage: 'Schiffsname/Voyage/Express',
                other_expenses: 'Andere Ausgaben',
                bill_lading: 'Ladelisten-Nr.',
                print_shipping_mark: 'Frachtmarkierung drucken',
                warehousing_confirmation: 'Einlagerungsbestätigung',
                fill_other_expenses: 'Andere Ausgaben ausfüllen',
                type_shipping: 'Versandart',
                shipping_company: 'Speditionsunternehmen',
                ships_name: 'Schiffsname',
                voyages_flights: 'Folge von Reisen oder Flügen',
                ship_number: 'Schiffsnr.',
                customs_no: 'Zoll-Nr.',
                return_lading: 'Rückgabe des Bills of Lading',
                expected_date: 'Erwartetes Rückgabedatum',
                actual_date: 'Tatsächliches Rückgabedatum',
                remark: 'Bemerkung',
                container_no: 'Container-Nr.',
                container_type: 'Container-Art',
                customs_declaration: 'Zolldeklaration Nr.',
                container_nos: 'Container-Nr.',
                reason_modification: 'Grund für die Änderung',
            },
            management: order.claim.management.deDE,
        },
        tech: {
            quality: {
                problemTracking: {
                    customs_declaration_unit: 'Zolldeklarationseinheit',
                    theme: 'Thema',
                    model: 'Modell',
                    problem_state: 'Problemzustand',
                    TAC_submit_date: 'TAC-Einreichungsdatum',
                    level: 'Ebene',
                    type: 'Typ',
                    vehicle_involved: 'Fahrzeug beteiligt',
                    activity_code: 'Aktivitätscode',
                    activity_name: 'Aktivitätsname',
                    connected_activities: 'verbundene Aktivitäten',
                    system_problems: 'Systemprobleme',
                    quantity: 'Menge',
                    engine_type: 'Motortyp',
                    gearbox_type: 'Getriebetyp',
                    country: 'Land',
                    region: 'Region',
                    date_of_feedback_to_QA: 'Datum der Rückmeldung an QA',
                    required_part_return_date: 'Erforderliches Rücksendedatum des Teils',
                    date_of_parts_delivery_to_QA: 'Datum der Auslieferung an QA',
                    solution_release_date: 'Lösungsveröffentlichungsdatum',
                    solution_target_date: 'Zieldatum für Lösung',
                    end_date: 'Enddatum',
                    difference: 'Unterschied',
                    QA_weekday: 'QA-Wochentag',
                    fault_description: 'Fehlerbeschreibung',
                    reason: 'Grund',
                    solution: 'Lösung',
                    breakpoint: 'Breakpoint VIN / CSN / en',
                    process: 'Prozess',
                    details: 'Details',
                    technical_document_attachments: 'Anlagen technischer Dokumente',
                },
                recallActivity: {
                    activity_code: 'Aktivitätscode',
                    activity_name: 'Aktivitätsname',
                    responsible_party: 'verantwortliche Partei',
                    release_date: 'Veröffentlichungsdatum',
                    data_sources: 'Datenquellen',
                    recall_campaign_type: 'Rückrufart',
                    service_activity_code: 'SERVICE-KampagnenCODE',
                    service_activity_name: 'SERVICE-Kampagnenname',
                    buyout_company: 'Buy-off-Unternehmen',
                    audit_company: 'Genehmigungsunternehmen',
                    currency: 'Währung',
                    money: 'Betrag',
                    start_date: 'Startdatum',
                    end_date: 'Enddatum',
                    failure_mode: 'Fehlermodus',
                    activity_programme: 'Kampagnenplan',
                    improve_handling: 'Verbesserungsmaßnahmen',
                    activity_information: 'Aktivitätsinformationen',
                    vehicle_involved_list: 'Beteiligte Fahrzeuge',
                    template_file: 'Template-Datei',
                    browse: 'Durchsuchen',
                    download_template: 'Vorlage herunterladen',
                    claim_vehicles_list: 'Fahrzeuge, bei denen Ansprüche geltend gemacht wurden',
                    claim_statistics: 'Anspruchsstatistik',
                    technical_document_attachments: 'Anlagen technischer Dokumente',
                    vin: 'VIN',
                    is_complete: 'Abgeschlossen',
                    warranty_claims: 'Garantieanspruch',
                    customer: 'Kunde',
                    no_file_selected: 'Keine Datei ausgewählt',
                    no_import_file_selected: 'Keine Importdatei ausgewählt',
                    claim_proportion: 'Proportion der Ansprüche',
                    claim_settled_amount: 'beglichene Ansprüche',
                    is_claim_again: 'Ob Anspruch erneut geltend gemacht wird',
                    cancel_succeed: 'Stornierung erfolgreich',
                    build_by_oneself: 'Selbst erstellen',
                    system_import: 'Anspruchsystemimport',
                    claim_system_import: 'Anspruchsystemimport',
                },
            },
            management: {
                newCarDefinition: {
                    activityNumber: 'Kampagnencode',
                    activityName: 'Kampagnenname',
                    activityStatus: 'Status',
                    activityDate: 'Aktivitätsdatum',
                    startDate: 'Startdatum',
                    endDate: 'Enddatum',
                    remarks: 'Bemerkungen',
                    activity_details: 'Aktivitätsdetails',
                    activity_addition: 'Aktivitätszusatz',
                    confirm_enable: 'Bestätigung aktivieren',
                    confirm_outage: 'Bestätigung der Störung',
                    all: 'Alle',
                    not_started: 'Nicht gestartet',
                    on_going: 'In Arbeit',
                    closed: 'Geschlossen',
                },
                newCarlssue: {
                    all: 'Alle',
                    newly_built: 'Hinzugefügt',
                    unpublished: 'Unveröffentlicht',
                    validity: 'Gültigkeit',
                    modelInvolved: 'Modell',
                    involvedArea: 'Region',
                    state: 'Status',
                    new_car_reporting_level: 'Meldungsebene für Neuwagen',
                    confirm_release: 'Freigabe bestätigen',
                    maintenance: 'Wartung',
                    model_maintenance: 'Model- Wartung',
                    area_maintenance: 'Bereichswartung',
                    reporting_level: 'Berichtsebene',
                    to_be_published: 'Zu veröffentlichen',
                    published: 'Veröffentlicht',
                    modified_to_be_published: 'Geändert zu veröffentlichen',
                },
                onSiteSupportHq: {
                    fieldSupportNumber: 'Feldunterstützungsnummer',
                    informationSlipNumber: 'Informationszettelnummer',
                    approvalStatus: 'Genehmigungsstatus',
                    state: 'Zustand',
                    selectApplicationDate: 'Wählen Sie das Anwendungsdatum aus',
                    toBeProcessed: 'Zu bearbeiten',
                    processed: 'Verarbeitet',
                    initiated: 'Initiiert',
                    applicationDepartment: 'Antragsabteilung',
                    applicant: 'Antragsteller',
                    reasonApplication: 'Grund für den Antrag',
                    applicationTime: 'Antragszeit',
                    all: 'Alle',
                    pending_approva: 'Ausstehende Genehmigung',
                    agreed: 'Stimmt zu',
                    rejected: 'Abgelehnt',
                    view: {
                        application_department: 'Antragsabteilung',
                        responsible_person: 'Verantwortliche Person',
                        application_person: 'Antragsteller',
                        associated_enquiry_number: 'Zugehörige Anfragenummer',
                        reason_application: 'Grund für den Antrag',
                        description: 'Beschreibung (Interner Antragssignaturbogen)',
                        file_name: 'Dateiname',
                        size: 'Größe (MB)',
                        uploader: 'Uploader',
                        upload_time: 'Upload-Zeit',
                        view: 'Prüfen',
                        download: 'Herunterladen',
                        support_department: 'Unterstützungsabteilung',
                        technical_personnel: 'Technisches Personal',
                        contact_information: 'Kontaktinformationen',
                        field_support_information: 'Informationen zur Feldunterstützung',
                        remarks: 'Bemerkungen',
                        apply: 'Bewerben',
                        application_information: 'Anwendungsinformationen',
                        field_support_personnel: 'Vor-Ort-Supportpersonal',
                        approval_record: 'Genehmigungsverlauf',
                        submit_an_application: 'Ein Antrag stellen',
                        refuse: 'Ablehnen',
                        agree: 'Zustimmen ',
                        apply_for: 'Antrag auf ',
                        field_support: 'Feldunterstützung',
                        reason_for_rejection: 'Grund für die Ablehnung',
                    },
                    dialog: {
                        on_site_application_details: 'Details zur Vor-Ort-Anwendung',
                        on_site_application: 'Vor-Ort-Anwendung',
                        reason_for_rejection: 'Grund für die Ablehnung',
                        field_support_application: 'Antrag auf Feldunterstützung',
                        technical_personnel: 'Technisches Personal',
                        contact_information: 'Kontakt Telefon',
                        field_support_time: 'Zeitpunkt der Vor-Ort-Unterstützung',
                        note_information: 'Notizinformationen',
                    },
                },

                filterHq: {
                    filter_information: 'Filterinformationen',
                    filter_name: 'Filtername',
                    user_name: 'Benutzername',
                    creation_time: 'Erstellungszeit',
                    splicing_description: 'Verbindungsbeschreibung',
                    enable_or_not: 'Aktiviert oder deaktiviert',
                    association_symbol: 'Beziehungssymbol',
                    screening_condition: 'Filterbedingung',
                    conditional_value: 'Filterbedingungswert',
                    save: 'Speichern',
                    draft: 'Entwurf',
                    no_reply: 'Keine Antwort',
                    replied: 'Antwortete',
                    closed: 'geschlossen',
                    pre_sales: 'Vorverkauf',
                    after_sales: 'Kundendienst',
                    to_be_repaired: 'Zu reparieren',
                    under_maintenance: 'In Wartung',
                    completed: 'Abgeschlossen',
                    not_included: 'Nicht enthalten',
                    and: 'Und',
                    perhaps: 'Vielleicht',
                    not: 'Nicht',
                    enable: 'Aktiveren',
                    judgement_logic: 'Beurteilungslogik',
                    order_number: 'Nr.',
                    relational_symbol: 'Relationssymbol',
                    filter_criteria: 'Filterkriterien',
                    please_add_judgment_logic: 'Bitte Beurteilungslogik hinzufügen',
                    model_platform_is: 'Das Modellplattform ist',
                    subject_of_the_case_is: 'Das Thema des Falls ist',
                    overseas_branches_are: 'Übersee-Filialen sind',
                    overseas_subsidiaries_are: 'Übersee-Tochtergesellschaften sind',
                    general_agent_are: 'Distributor sind',
                    fault_symptom_is: 'Fehlererscheinung ist',
                    cause_of_the_fault_is: 'Fehlerursache ist',
                    vehicle_status_is: 'Fahrzeugstatus ist',
                    case_type_is: 'Falltyp ist',
                    case_status_is: 'Fallstatus ist',
                    all: 'Alle',
                },
                vehicleInfoHq: {
                    productionTimeList: 'Produktionszeitliste',
                    vehicle_configuration_list: 'Fahrzeugkonfigurationsliste',
                    accurately_traceable_parts_list: 'Genau nachverfolgbare Teileliste',
                    order_number: 'Bestellnummer',
                    order_type: 'Bestelltyp',
                    platform: 'Plattform',
                    model_code: 'Modellcode',
                    date_of_production: 'Produktionsdatum',
                    certificate_of_conformity: 'Konformitätsbescheinigung',
                    certificate_printing_time: 'Zeitpunkt des Zertifikatsdrucks',
                    vehicle_material_number: 'Fahrzeugmaterialnummer',
                    description: 'Beschreibung',
                    time: 'Zeit',
                    feature_ID: 'Merkmals-ID',
                    feature_description: 'Merkmalsbeschreibung',
                    feature_code: 'Merkmalcode',
                    eigenvalue_description: 'Eigenwertbeschreibung',
                    chinese_description: 'Chinesische Beschreibung',
                    supplier: 'Lieferant',
                    bar_code_number: 'Barcode-Nummer',
                    scanning_time: 'Scanzeit',
                    key_component_type_code: 'Code des Schlüsselkomponententyps',
                    part_number: 'Teilenummer',
                    traceability_number: 'Rückverfolgbarkeitsnummer',
                    sub_key_type: 'Untertyp des Schlüssels',
                    software: 'Software',
                    hardware: 'Hardware',
                    calibration_information: 'Kalibrierungsinformationen',
                    supplier_name: 'Lieferantenname',
                    pn: 'PIN-Code',
                },
                progHistoryHq: {
                    name_of_maintenance_station: 'Name der Service-Station',
                    mileage: 'Kilometerstand',
                    controller_name: 'Controller-Name',
                    function_name: 'Funktionsname',
                    time: 'Zeit',
                    function_execution_result: 'Ergebnis der Funktionsausführung',
                    channel: 'Kanal',
                    hardware_number: 'Hardware-Nummer',
                    written_software_information: 'Geschriebene Software-Informationen',
                    software_information_before_refresh:
                        'Software-Informationen vor der Aktualisierung',
                    updated_software_information: 'Aktualisierte Software-Informationen',
                },
                // 未
                salesRecordHq: {
                    material: 'Material',
                    sales_order: 'Verkaufsauftrag',
                    vehicle_type: 'Modell',
                    shipping_area: 'Versandgebiet',
                    country_of_dispatch: 'Versandland',
                    down_date: 'Offline-Datum',
                    port_of_destination: 'Zielhafen',
                    scheduled_date_of_shipment: 'Geplantes Versanddatum',
                    actual_date_of_shipment: 'Tatsächliches Versanddatum',
                    current_position: 'Aktuelle Position',
                    actual_arrival_date: 'Tatsächliches Ankunftsdatum',
                    retail_date: 'Einzelhandelsdatum',
                },
                claimHq: {
                    claim_information: 'Schadensinformation',
                    Recall_and_service_activities: 'Rückruf & Service-Kampagne',
                    marketing_activity_list: 'Marketing-Kampagnenliste',
                    basic_information: 'Grundinformationen',
                    warranty_block: 'Garantieblock',
                    repair_block_code: 'Garantie-Blockcode',
                    claim_cycle_list: 'WTY-Zeitraum Liste',
                    WTY_Indicates_the_cycle_type: 'WTY-Zeitraumtyp',
                    start_time: 'Startzeit',
                    end_time: 'Endzeit',
                    WTY_mileage: 'WTY-Kilometerstand',
                    List_of_claims: 'WTY-Verlauf',
                    customer: 'Kunde',
                    warranty_claim: 'Garantieanspruch',
                    work_order: 'Arbeitsauftrag',
                    date_of_receipt: 'Empfangsdatum',
                    maintenance_technical_date: 'Wartungsdatum',
                    mileage: 'Kilometerstand',
                    state: 'Status',
                    master_working_position: 'Hauptarbeit',
                    manual_code: 'Arbeitscode',
                    main_component: 'Hauptkomponente',
                    part_name: 'Teilname',
                    recall_and_service_activities: 'Rückruf & Service-Kampagne',
                    recall_and_service_activity_code: 'Rückruf & Service-Kampagnencode',
                    recall_and_service_activity_names: 'Rückruf & Service-Kampagnenname',
                    brand: 'Marke',
                    recall_type_code: 'Rückruf-Typ-Code',
                    recall_type_name: 'Rückruf-Typ-Name',
                    release_date: 'Veröffentlichungsdatum',
                    closing_date: 'Abschlussdatum',
                    complete: 'vollständig',
                    warranty_claim_status: 'Garantieanspruchstatus',
                    end_of_maintenance_date: 'Enddatum der letzten Reparatur',
                    marketing_activity_code: 'Marketingaktivitätscode',
                    marketing_activity_name: 'Marketingaktivitätsname',
                    vehicle_warranty_period: 'Garantiezeit für Fahrzeuge',
                    marketing_activities: 'Marketingaktivitäten',
                    prohibition_of_claims: 'Verbot von Ansprüchen',
                    prohibited_claim_code: 'Code für verbotene Ansprüche',
                    type_of_warranty_period: 'Art der Garantiezeit',
                    repair_end_date: 'Enddatum der Reparatur',
                    reception_date: 'Empfangsdatum',
                    main_station_code: 'Hauptbahnhofcode',
                    main_workstation_name: 'Name des Hauptplatzes',
                },

                // inquiryOd: {
                //     vin: 'VIN',
                //     inquiry_no: 'inquiry no',
                //     case_theme: 'case theme',
                //     inquiry_date: 'inquiry date',
                // },
                inquiryOd: tech.management.inquiry.deDE,
                permission: tech.management.permission.deDE,
            },
        },
        statistics: statistics.inquiryStatistics.deDE,
        systemClassifyA: statistics.systemClassifyA.deDE,
        systemClassifyB: statistics.systemClassifyB.deDE,
        task: {
            china: {
                holiday: {
                    holiday_name: 'Festivalsname',
                    holiady_time: 'Feiertag',
                    end_time: 'Enddatum',
                    holiday_add: 'neu hinzugefügt',
                    holiday_edit: 'bearbeiten',
                },
            },
            package: {
                calendar: {
                    packing: 'SMPV-Verpackungstag',
                },
            },
        },
        logistics: {
            package: {
                cycle: {
                    transport_type: 'Transporttyp',
                    market_type: 'Markttyp',
                    market_name: 'Marktname',
                    market_code: 'Marktcode',
                    transport_cycle: 'Transportzeitraum (Tag)',
                    Packaging_aging: 'Verpackungsreife (Tag)',
                    OTD_aging: 'OTD-Reife (Tag)',
                },
            },
        },
        vehicle: {
            logistics: {
                expenses: {
                    atd_begin_date: 'ATD-Beginndatum',
                    atd_end_date: 'ATD-Enddatum',
                    atd_date: 'ATD-Datum',
                    country_of_target: 'Zielland',
                    dn: 'DN',
                    region: 'Region',
                    model_code: 'Modellcode',
                    vin: 'VIN',
                    volumn: 'Volumen',
                    vdc_name: 'VDC-Name',
                    pol_name: 'POL-Name',
                    dt_content: 'DT-Inhalt',
                    dt_distance_km: 'DT-Entfernung (km)',
                    vdc_vasc_material: 'VDC-VASC-Material',
                    vdc_vasc_worker: 'VDC-VASC-Arbeiter',
                    domestic_carry: 'Binnenfracht',
                    wharf_handle: 'Hafenbearbeitung',
                    wharf_sc: 'Schwerlastkran (WHARF-SC)',
                    wharf_box: 'Containerstauung (WHARF-BOX)',
                    medicated_fumigation: 'Medikamentöse Schädlingsbekämpfung',
                    wharf_dot_check: 'DOT-Kontrolle (WHARF-DOT-CHECK)',
                    repair_maintenance: 'Reparatur und Wartung',
                    refuel_storage: 'Betankung und Lagerung',
                    file_cert: 'Datei-Zertifikat',
                    supply_chain_integration: 'Supply-Chain-Integration',
                    other_domestic_carry: 'Andere Binnenfracht',
                    insure: 'Versichert',
                    ship_money: 'Versandkosten',
                    ship_money_type: 'Versandkostentyp',
                    other_interal_charge: 'Andere interne Gebühr',
                    other_interal_charge_type: 'Andere interne Gebühr Typ',
                    transport_company_code: 'Transportunternehmen-Code',
                    vessel_name_voyage: 'Schiffsname und Reise',
                    mode_of_transport: 'Transportart',
                    remark: 'Bemerkung',
                    create_date: 'Erstellungsdatum',
                },
                expensesRelationship: {
                    country_name: 'Landesname',
                    country_code: 'Ländercode',
                    model_code: 'Modellcode',
                    ology_code: 'Ologiecode',
                    ology_name: 'Ologiename',
                    status: 'Status',
                    remark: 'Bemerkung',
                    create_by: 'Erstellt von',
                    create_name: 'Name des Erstellers',
                    create_date: 'Erstellungsdatum',
                    update_by: 'Aktualisiert von',
                    update_name: 'Name des Aktualisierers',
                    update_date: 'Aktualisierungsdatum',
                    tache_code: 'Tachencode',
                    no: 'Nein',
                    yes: 'Ja',
                },
                expenseAccount: {
                    ology_type: 'Ologie-Typ',
                    pack_name: 'Paket-Name',
                },
                country: vehicle_country.zhCN,
            },
        },
    },
    validation: {
        common: {
            required: 'Artikel ist erforderlich',
            numeric: 'Muss eine ganze Zahl sein',
            decimal: 'Muss eine Zahl sein',
            positive_interger: 'Muss positive ganze Zahl sein',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Bis zu ${named('number')} Ziffern`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Mehr als ${named('number')} Zeichen`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Höchste Bytes überschreiten (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Weniger als${named('number')} Zeichen`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Muss sein ${named('number')} Zeichen`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Größer als ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Weniger als ${named('number')}`,
            up_to_2_decimal_places: 'Bis zu 2 Dezimalstellen',
            retain_up:
                'Geben Sie bis zu 10 positive Ganzzahlen ein und behalten Sie bis zu 4 Dezimalstellen',
            retain_ups:
                'Geben Sie bis zu 11 positive Ganzzahlen ein und behalten Sie bis zu 4 Dezimalstellen',
            input_number: 'Bitte geben Sie die Nummer ein',
            only_number_a: 'Es können nur Buchstaben oder Zahlen eingegeben werden',
            positive_integer: 'Geben Sie bis zu 10 positive Ganzzahlen ein',
            at_least_one: 'Füllen Sie mindestens eine aus',
        },
        tip: {
            incomplete_information: 'Unvollständige Informationen',
        },
    },
    import: {
        incorrect_file_type: 'Falscher Dateityp',
        exceed: 'Weiter gehen',
        upload_success: 'Hochladen erfolgreich',
        upload_fail: 'Hochladen fehlgeschlagen',
        again_upload:
            'Es können nur Bilddateien im jpg, png, bmp oder gif Format hochgeladen werden',
    },
    export: {
        noDataToExport: 'Keine Daten zum Exportieren',
    },
    select: {
        all: 'Alle',
    },
    checkbox: {
        select_all: 'Alles auswählen',
    },
    dialog: {
        error_message: 'Fehlermeldung',
        accessory_selection: 'Zubehörauswahl',
    },
};
