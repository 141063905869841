import { useRoute } from 'vue-router'
import { useUserStore } from './user'
import type { NavDataOrigin } from '~/utils/router';
import { commonRequests } from '~/requests/common/common.request';
import { resHandler } from '~/utils/http/handler';
import { formatRoutes, routeMap } from '~/utils/router';
import { useAuthToken } from '~/utils/token';
import { useStorageLocale } from '~/utils/i18n';

export const useCommonStore = defineStore('common', () => {
    const { authToken } = useAuthToken();
    const userStore = useUserStore();
    const userInfo = computed(() => userStore.userInfo);
    const { defaultLocale: locale } = useStorageLocale();

    const showMenu = ref(true);
    const route = useRoute()
    const formattedRoutes = ref<NavDataOrigin[]>([]);
    const routeQueryMap = ref<{ [K: string]: {
        query: string;
        path: string;
    } }>({})

    const menuPaginationInfo = ref<{ [K: string]: {
        // pageNum: number;
        pageSize: number;
        id?: number;
    } }>({})
    const entriesRoutes = computed(() => {
        return toTreeArray(formattedRoutes.value);
    });

    const currentRouteKey = ref('');
    const cachedRouteList = ref<string[]>([]);
    const iframeList = computed(() =>
        cachedRouteList.value.filter((i) => {
            // return i.includes('ServiceCdc');
            return ['ServiceCdc', 'ServiceEpc', 'ServiceDiagnosis'].some((path) =>
                i.includes(path),
            );
        }),
    );
    console.log(iframeList.value);
    watch(
        iframeList.value,
        (val) => {
            console.log(val);
        },
        {
            deep: true,
        },
    );

    const setAuthToken = async (token: string) => {
        authToken.value = token;
    };

    const clearAuth = async () => {
        authToken.value = '';
    };

    const reFetchRoutes = async () => {
        const response = await commonRequests.getRoutes();
        await resHandler(response, {
            onSuccess: ({ res }) => {
                routeMap.clear();
                formattedRoutes.value = formatRoutes(res);
                getRouteQueryMap()
            },
        });
    };
    function getRouteQueryMap() {
        formattedRoutes.value?.forEach(first => {
            if (first.path) {
                routeQueryMap.value[first.path] = {
                    path: first.path,
                    query: first.query || '',
                }
            }
            if (first.children && first.children.length > 0) {
                first.children.forEach(second => {
                    if (second.path) {
                        routeQueryMap.value[second.path] = {
                            path: second.path,
                            query: second.query || '',
                        }
                    }

                    if (second.children && second.children.length > 0) {
                        second.children.forEach(three => {
                            if (three.path) {
                                routeQueryMap.value[three.path] = {
                                    path: three.path,
                                    query: three.query || '',
                                }
                            }
                        })
                    }
                })
            }
        })
    }
    async function updateOrSavePageRecord(p: {
        id?: number;
        // pageNum: number;
        pageSize: number;
        path: string
      }) {
        const menuKey = routeQueryMap.value[p.path || ''].query;
        const menuCacheInfo = menuPaginationInfo.value[menuKey]
        console.log('updateOrSavePageRecord menuCacheInfo', menuCacheInfo)
        const res = await commonRequests.updateOrSavePageRecord({
            ...p,
            menuName: menuKey,
            userId: userInfo.value?.userId || 0,
            id: menuCacheInfo?.id || undefined,
        })
        if (!menuCacheInfo) {
            getCurrentMenuInfo(p.path)
        }
        console.log('res', res)
    }
    async function getCurrentMenuInfo(path?: string): Promise<{
        // pageNum: number;
        pageSize: number;
    }> {
        try {
            console.log('path', path)
            if (!path) return { pageSize: -1 };
            if (Object.keys(routeQueryMap.value).length === 0) {
                getRouteQueryMap()
            }
            const menuKey = routeQueryMap.value[path || ''].query;
            const menuCacheInfo = menuPaginationInfo.value[menuKey]
            console.log('getCurrentMenuInfo menuCacheInfo', menuCacheInfo)
            const res = await commonRequests.queryUserPageInfo({
                id: menuCacheInfo ? menuCacheInfo.id : null,
                userId: userInfo.value?.userId || 0,
                menuName: menuKey,
            })
            if (res.code === 20000) {
                menuPaginationInfo.value[menuKey] = {
                    id: res.res?.id || undefined,
                    // pageNum: res.res?.pageNum || -1,
                    pageSize: res.res?.pageSize || -1,
                }
                return {
                    // pageNum: res.res?.pageNum || -1,
                    pageSize: res.res?.pageSize || -1,
                }
            }
            return {
                // pageNum: -1,
                pageSize: -1,
            }
        } catch (e) {
            return {
                // pageNum: -1,
                pageSize: -1,
            }
        }
    }

    return {
        authToken,
        setAuthToken,
        formattedRoutes,
        entriesRoutes,
        locale,
        reFetchRoutes,
        currentRouteKey,
        cachedRouteList,
        clearAuth,
        showMenu,
        iframeList,
        getCurrentMenuInfo,
        updateOrSavePageRecord,
    };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot));
