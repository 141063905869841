export default {
    enUS: {
        vin: 'VIN',
        inquiry_no: 'Case No',
        case_theme: 'Case subject',
        department_name: 'Department name',
        department_type: 'Department type',
        document_status: 'Document status',
        inquiry_date: 'Inquiry date',
        organization_name: 'Organization name',
        name: 'Name',
        sap_code: 'SAP code',
        failed_part: 'Affected',
        case_type: 'Case type',
        vehicle_status: 'Vehicle status',
        case_owner: 'Case Owner',
        country: 'Country',
        fault_cause: 'Fault cause',
        symptoms: 'Fault phenomenon',
        is_the_document_qualified: 'Is the document qualified',
        is_the_document_replied_in_a_timely_manner: 'Is the document replied in a timely manner',
        filter: 'Filter',
        system_classification_I: 'System 1',
        system_classification_II: 'System 2',
        full_query: 'Full query',
        fuzzy_query: 'Fuzzy query',
        whether_belongs_new_car_market: 'New car launched',
        caseStatus: 'Case status',
        all: 'All',
        pre_sales: 'Pre-sales',
        after_sales: 'After-sales',
        to_be_repaired: 'To be repaired',
        under_maintenance: 'Under maintenance',
        completed: 'Completed',
        correct: 'Correct',
        deny: 'Deny',
        not_closed: 'Open',
        closed: 'Closed',
        drafts: 'Draft box',
        create_time: 'Create time',
        create_user: 'Created by',
        parent_of_document: 'Parent of the document',
        document_owner: 'Document owner',
        case_subject: 'Case subject',
        receipt_info: 'Receipt info',
        superior_of_case: ' Superior of case',
        inquiry_time: 'Inquiry time',
        status: 'Status',
        look_over: 'Look over',
        overseas_company: 'Sales company name',
        general_agent_name: 'Distributor name',
        dealer_code: 'Dealer code',
        dealer_name: 'Dealer name',
        name_of_subsidiary: 'Name of subsidiary',
        contacts: 'Contact person',
        contact_information: 'Contact information',
        training_certification: 'Training certification',
        driving_mileage: 'Mileage',
        plate_number: 'License plate',
        material_no: 'Vehicle Material code',
        material_desc: 'Material description',
        offline_date: 'Off-line date',
        model_platform: 'Platform',
        engine_number: 'Engine Number',
        transmission_type: 'Transmission',
        assembly_no: 'CSN  No',
        fault_info_desc: 'Fault infoDesc',
        fault_handle_process: 'Fault handleProcess',
        solution: 'Solution',
        remark: 'Remark',
        document_information: 'Case information',
        basic_information_of_service_center: 'Service center information',
        basic_vehicle_information: 'Vehicle basic information',
        problem_description: 'Problem description',
        common_links: 'Common links',
        claim_information: 'Claim information',
        diagnostic_report_record: 'Diagnostic report',
        vehicle_history: 'Technical inquiry history',
        programming_history: 'Programming history',
        vehicle_sales_logistics_information: 'Vehicle sales and logistics information',
        diagnostic_software_report: 'Diagnostic software report',
        new_vehicle_information: 'New vehicle information',
        attachment_upload: 'Attachment upload',
        diagnostic_report: 'Diagnostic report',
        bulk_download: 'Bulk download',
        document_View: 'Document View',
        inquiry_content: 'Inquiry detail',
        subordinate_record: 'Subordinate record',
        superior_record: 'Superior record',
        send_content: 'Send content',
        send_time: 'Send time',
        sent_username: 'Sender',
        receive_name: 'Send to',
        dept_name: 'Level',
        enclosure: 'Attachment',
        wind_case: 'Wind case',
        case_reporting: 'Case reporting',
        save: 'Save',
        send: 'Send',
        edit: 'Edit',
        pdf_preview: 'PDF Preview',
        SIS_maintenance: 'Major accident maintenance',
        Please_fill_in_the_inquiry_content: 'Please fill in the inquiry content',
        sending_an_email: 'Sending an email',
        copy: 'Copy',
        delete: 'Delete',
        repeat: 'Vin repeat',
        inconsistent: 'Inconsistent vehicle platform',
        sendTo: 'Send To',
        reply: 'Enter content for inquiry/reply',
        please_check_the_exported_document: 'Please check the exported document',
        please_upload_diagnostic_report: 'Please upload diagnostic report',
        please_enter_the_vin: 'Please enter the VIN',
        placeholderDTC: 'Example：P080800 P087D00 ------',
        placeholderFaultInfoDesc: '1.Verify customer concerns (just to ensure issues arise)',
        placeholderFaultHandleProcess:
            '2.Identify relevant symptoms (identify and record all symptoms that may be related to the problem)\n' +
            '3.Analyze symptoms (carefully observe relevant symptoms to identify common causes)\n' +
            '4.Confirm the problem (narrow down the scope of your results to ensure you have solved the correct problem)',
        placeholderSolution:
            '5.Fix the problem (carry out repairs)\n' +
            '6.Verify vehicle operation (check to ensure that your repairs have resolved the issue)',
    },
    nlNL: {
        vin: 'VIN',
        inquiry_no: 'Geval Nr',
        case_theme: 'Onderwerp van geval',
        department_name: 'Afdelingsnaam',
        department_type: 'Afdelingstype',
        document_status: 'Documentstatus',
        inquiry_date: 'Datum van onderzoek',
        organization_name: 'Organisatienaam',
        name: 'Naam',
        sap_code: 'SAP-code',
        failed_part: 'Getroffen onderdeel',
        case_type: 'Gevaltype',
        vehicle_status: 'Voertuigstatus',
        case_owner: 'Geval-eigenaar',
        country: 'Land',
        fault_cause: 'Foutoorzaak',
        symptoms: 'Foutverschijnselen',
        is_the_document_qualified: 'Is het document gekwalificeerd',
        is_the_document_replied_in_a_timely_manner: 'Is het document tijdig beantwoord?',
        filter: 'Filter',
        system_classification_I: 'Systeem 1',
        system_classification_II: 'Systeem 2',
        full_query: 'Volledige zoekopdracht',
        fuzzy_query: 'Zoeken op overeenkomsten',
        whether_belongs_new_car_market: 'Nieuw voertuig geïntroduceerd',
        caseStatus: 'Case status',
        all: 'Alle',
        pre_sales: 'Pre-sales',
        after_sales: 'After-sales',
        to_be_repaired: 'Te repareren',
        under_maintenance: 'In onderhoud',
        completed: 'Voltooid',
        correct: 'Correct',
        deny: 'Ontkennen',
        not_closed: 'Open',
        closed: 'Gesloten',
        drafts: 'Concepten',
        create_time: 'Aanmaaktijd',
        create_user: 'Gemaakt door',
        parent_of_document: 'Ouder van het document',
        document_owner: 'Documenteigenaar',
        case_subject: 'Geval onderwerp',
        receipt_info: 'Ontvangstinfo',
        superior_of_case: 'Superieur van het geval',
        inquiry_time: 'Onderzoekstijd',
        status: 'Status',
        look_over: 'Bekijken',
        overseas_company: 'Verkoopmaatschappij naam',
        general_agent_name: 'Distributeur naam',
        dealer_code: 'Dealer code',
        dealer_name: 'Dealer naam',
        name_of_subsidiary: 'Naam van dochteronderneming',
        contacts: 'Contactpersoon',
        contact_information: 'Contactgegevens',
        training_certification: 'Training certificering',
        driving_mileage: 'Kilometerstand',
        plate_number: 'Kenteken',
        material_no: 'Voertuigmateriaalcode',
        material_desc: 'Materiaalomschrijving',
        offline_date: 'Offline datum',
        model_platform: 'Modelplatform',
        engine_number: 'Motornummer',
        transmission_type: 'Transmissie',
        assembly_no: 'CSN nr.',
        fault_info_desc: 'Foutinformatie beschrijving',
        fault_handle_process: 'Foutafhandelingsproces',
        solution: 'Oplossing',
        remark: 'Opmerking',
        document_information: 'Dossier informatie',
        basic_information_of_service_center: 'Basisinformatie van servicecentrum',
        basic_vehicle_information: 'Basisvoertuiginformatie',
        problem_description: 'Probleemomschrijving',
        common_links: 'Veelvoorkomende links',
        claim_information: 'Claim informatie',
        diagnostic_report_record: 'Diagnostisch rapport',
        vehicle_history: 'Technische onderzoekshistorie',
        programming_history: 'Programmeerhistorie',
        vehicle_sales_logistics_information: 'Voertuig verkoop- en logistieke informatie',
        diagnostic_software_report: 'Diagnostisch software rapport',
        new_vehicle_information: 'Nieuwe voertuiginformatie',
        attachment_upload: 'Bijlage uploaden',
        diagnostic_report: 'Diagnostisch rapport',
        bulk_download: 'Bulk download',
        document_View: 'Documentweergave',
        inquiry_content: 'Onderzoeksdetails',
        subordinate_record: 'Ondergeschikte records',
        superior_record: 'Superieure records',
        send_content: 'Verstuur inhoud',
        send_time: 'Verstuurtijd',
        sent_username: 'Verzender',
        receive_name: 'Ontvanger',
        dept_name: 'Niveau',
        enclosure: 'Bijlage',
        wind_case: 'Wind case',
        case_reporting: 'Rapportage van het geval',
        save: 'Opslaan',
        send: 'Verzenden',
        edit: 'Bewerken',
        pdf_preview: 'PDF Voorbeeld',
        SIS_maintenance: 'Belangrijk onderhoud na een ongeval',
        Please_fill_in_the_inquiry_content: 'Vul de onderzoeksinhoud in',
        sending_an_email: 'Een e-mail versturen',
        copy: 'Kopie',
        delete: 'verwijderen',
        repeat: 'Vin herhalen',
        inconsistent: 'Onconsistent voertuigplatform',
        sendTo: 'Verzenden naar',
        reply: 'Inhoud invoeren voor onderzoek/antwoord',
        please_check_the_exported_document: 'Controleer het geëxporteerde document',
        please_upload_diagnostic_report: 'Upload een diagnoserapport',
        please_enter_the_vin: 'Voer het VIN in',
        placeholderDTC: 'Voorbeeld：P080800 P087D00 ------',
        placeholderFaultInfoDesc:
            '1.Controleer de zorgen van klanten (alleen om ervoor te zorgen dat er problemen ontstaan)',
        placeholderFaultHandleProcess:
            '2.Identificeer relevante symptomen (identificeer en registreer alle symptomen die gerelateerd kunnen zijn aan het probleem)\n' +
            '3.Symptomen analyseren (relevante symptomen zorgvuldig observeren om veelvoorkomende oorzaken te identificeren)\n' +
            '4.Bevestig het probleem (beperk de reikwijdte van uw resultaten om ervoor te zorgen dat u het juiste probleem hebt opgelost)',
        placeholderSolution:
            '5.Het probleem oplossen (reparaties uitvoeren)\n' +
            '6.Controleer de werking van het voertuig (controleer of uw reparaties het probleem hebben opgelost)',
    },
    deDE: {
        vin: 'VIN',
        inquiry_no: 'Anfragenummer',
        case_theme: 'Betreff',
        department_name: 'Abteilungsname',
        department_type: 'Abteilungstyp',
        document_status: 'Dokumentenstatus',
        inquiry_date: 'Datum der Anfrage',
        organization_name: 'Organisationsname',
        name: 'Name',
        sap_code: 'SAP-Code',
        failed_part: 'Betroffen',
        case_type: 'Falltyp',
        vehicle_status: 'Fahrzeugstatus',
        case_owner: 'Fallbesitzer',
        country: 'Land',
        fault_cause: 'Fehlerursache',
        symptoms: 'Fehlerphänomen',
        is_the_document_qualified: 'Ist das Dokument qualifiziert',
        is_the_document_replied_in_a_timely_manner: 'Wird das Dokument rechtzeitig beantwortet',
        filter: 'Filter',
        system_classification_I: 'System 1',
        system_classification_II: 'System 2',
        full_query: 'vollständige Abfrage',
        fuzzy_query: 'fuzzy-Abfrage',
        whether_belongs_new_car_market: 'Neues Auto gestartet',
        caseStatus: 'Fallstatus',
        all: 'Alle',
        pre_sales: 'Vorverkauf',
        after_sales: 'Nach dem Verkauf',
        to_be_repaired: 'Zu reparieren',
        under_maintenance: 'In Wartung',
        completed: 'Abgeschlossen',
        correct: 'Richtig',
        deny: 'Ablehnen',
        not_closed: 'Offen',
        closed: 'Geschlossen',
        drafts: 'Entwurfskasten',
        create_time: 'Erstellungszeit',
        create_user: 'Erstellt von',
        parent_of_document: 'Übergeordneter Dokumententyp',
        document_owner: 'Dokumenteneigentümer',
        case_subject: 'Fallthema',
        receipt_info: 'Empfangsinformationen',
        superior_of_case: 'Übergeordnete Fallgruppe',
        inquiry_time: 'Anfragezeit',
        status: 'Status',
        look_over: 'Überblick',
        overseas_company: 'Verkaufsfirmenname',
        general_agent_name: 'Distributeur-Name',
        dealer_code: 'Händlercode',
        dealer_name: 'Händlername',
        name_of_subsidiary: 'Name der Tochtergesellschaft',
        contacts: 'Kontaktperson',
        contact_information: 'Kontaktinformationen',
        training_certification: 'Schulungszertifizierung',
        driving_mileage: 'Fahrleistung',
        plate_number: 'Kennzeichen',
        material_no: 'Fahrzeugmaterialcode',
        material_desc: 'Materialbeschreibung',
        offline_date: 'Off-line Datum',
        model_platform: 'Plattform',
        engine_number: 'Motornummer',
        transmission_type: 'Getriebe',
        assembly_no: 'CSN-Nr.',
        fault_info_desc: 'Fehlerinfo-Beschreibung',
        fault_handle_process: 'Fehlerbehandlungsprozess',
        solution: 'Lösung',
        remark: 'Bemerkung',
        document_information: 'Fallinformationen',
        basic_information_of_service_center: 'Servicecenter-Informationen',
        basic_vehicle_information: 'Fahrzeuggrundinformationen',
        problem_description: 'Problem Beschreibung',
        common_links: 'Allgemeine Links',
        claim_information: 'Reklamationsinformationen',
        diagnostic_report_record: 'Diagnosebericht',
        vehicle_history: 'Technische Anfragengeschichte',
        programming_history: 'Programmierungsverlauf',
        vehicle_sales_logistics_information: 'Fahrzeugverkaufs- und Logistikinformationen',
        diagnostic_software_report: 'Diagnose-Software-Bericht',
        new_vehicle_information: 'Informationen für neue Fahrzeuge',
        attachment_upload: 'Anhang hochladen',
        diagnostic_report: 'Diagnosebericht',
        bulk_download: 'Massendownload',
        document_View: 'Dokument anzeigen',
        inquiry_content: 'Anfrageinhalt',
        subordinate_record: 'Untergeordneter Eintrag',
        superior_record: 'Übergeordneter Eintrag',
        send_content: 'Senden Inhalt',
        send_time: 'Sendezeit',
        sent_username: 'Absender',
        receive_name: 'Empfänger',
        dept_name: 'Ebene',
        enclosure: 'Anlage',
        wind_case: 'Windkoffer',
        case_reporting: 'Fallbericht',
        save: 'Speichern',
        send: 'Senden',
        edit: 'Bearbeiten',
        pdf_preview: 'PDF-Vorschau',
        SIS_maintenance: 'Hauptunfallinstandhaltung',
        Please_fill_in_the_inquiry_content: 'Bitte füllen Sie den Inhalt der Anfrage aus',
        sending_an_email: 'Envoyer un courriel',
        copy: 'Kopie',
        delete: 'Löschen',
        repeat: 'Vin Wiederholen',
        inconsistent: 'Inkonsistente Fahrzeugplattform',
        sendTo: 'Senden an',
        reply: 'Inhalt für Anfrage/Antwort eingeben',
        please_check_the_exported_document: 'Bitte überprüfen Sie das exportierte Dokument',
        please_upload_diagnostic_report: 'Bitte laden Sie einen Diagnosebericht hoch',
        please_enter_the_vin: 'Bitte geben Sie die VIN ein',
        placeholderDTC: 'Beispiel：P080800 P087D00 ------',
        placeholderFaultInfoDesc:
            '1.Überprüfen Sie Kundenanliegen (nur um sicherzustellen, dass Probleme auftreten)',
        placeholderFaultHandleProcess:
            '2.Relevante Symptome identifizieren (alle Symptome identifizieren und aufzeichnen, die mit dem Problem zusammenhängen können)\n' +
            '3.Symptome analysieren (relevante Symptome sorgfältig beobachten, um häufige Ursachen zu identifizieren)\n' +
            '4.Bestätigen Sie das Problem (schränken Sie den Umfang Ihrer Ergebnisse ein, um sicherzustellen, dass Sie das richtige Problem gelöst haben)',
        placeholderSolution:
            '5.Beheben Sie das Problem (führen Sie Reparaturen durch)\n' +
            '6.Überprüfen Sie den Fahrzeugbetrieb (überprüfen Sie, ob Ihre Reparaturen das Problem behoben haben)',
    },
    frFR: {
        vin: 'VIN',
        inquiry_no: 'N° de cas',
        case_theme: 'Sujet du cas',
        department_name: 'Nom du service',
        department_type: 'Type de service',
        document_status: 'Statut du document',
        inquiry_date: 'Date de l\'enquête',
        organization_name: 'Nom de l\'organisation',
        name: 'Nom',
        sap_code: 'Code SAP',
        failed_part: 'Pièce défectueuse',
        case_type: 'Type de cas',
        vehicle_status: 'Statut du véhicule',
        case_owner: 'Propriétaire du cas',
        country: 'Pays',
        fault_cause: 'Cause de la panne',
        symptoms: 'Symptômes de la panne',
        is_the_document_qualified: 'Le document est - il conforme',
        is_the_document_replied_in_a_timely_manner:
            'Les documents répondent - ils en temps opportun',
        filter: 'Filtre',
        system_classification_I: 'Système 1',
        system_classification_II: 'Système 2',
        full_query: 'Recherche complète',
        fuzzy_query: 'Recherche floue',
        whether_belongs_new_car_market: 'Nouvelle voiture lancée',
        caseStatus: 'État du cas',
        all: 'Tous',
        pre_sales: 'Pré-vente',
        after_sales: 'Après-vente',
        to_be_repaired: 'À réparer',
        under_maintenance: 'Sous maintenance',
        completed: 'Terminé',
        correct: 'Correct',
        deny: 'Refuser',
        not_closed: 'Ouvert',
        closed: 'Fermé',
        drafts: 'Brouillons',
        create_time: 'Date de création',
        create_user: 'Créé par',
        parent_of_document: 'Parent du document',
        document_owner: 'Propriétaire du document',
        case_subject: 'Sujet du cas',
        receipt_info: 'Information de réception',
        superior_of_case: 'Supérieur du cas',
        inquiry_time: 'Heure de l\'enquête',
        status: 'Statut',
        look_over: 'Regarder',
        overseas_company: 'Nom de l’entreprise à l’étranger',
        general_agent_name: 'Nom du distributeur',
        dealer_code: 'Code du revendeur',
        dealer_name: 'Nom du revendeur',
        name_of_subsidiary: 'Nom de la filiale',
        contacts: 'Personne de contact',
        contact_information: 'Information de contact',
        training_certification: 'Certification de formation',
        driving_mileage: 'Kilométrage',
        plate_number: 'Plaque d’immatriculation',
        material_no: 'Code matériau du véhicule',
        material_desc: 'Description du matériau',
        offline_date: 'Date hors ligne',
        model_platform: 'Plateforme',
        engine_number: 'Numéro de moteur',
        transmission_type: 'Transmission',
        assembly_no: 'Numéro CSN',
        fault_info_desc: 'Description des infos sur la panne',
        fault_handle_process: 'Dépannage après',
        solution: 'Solution',
        remark: 'Remarque',
        document_information: 'Informations sur le cas',
        basic_information_of_service_center: 'Informations de base du centre de service',
        basic_vehicle_information: 'Informations de base du véhicule',
        problem_description: 'Description du problème',
        common_links: 'Liens communs',
        claim_information: 'Informations de réclamation',
        diagnostic_report_record: 'Rapport de diagnostic',
        vehicle_history: 'Historique des demandes techniques',
        programming_history: 'Historique de programmation',
        vehicle_sales_logistics_information:
            'Informations sur les ventes et la logistique des véhicules',
        diagnostic_software_report: 'Rapport de logiciel de diagnostic',
        new_vehicle_information: 'Informations sur les nouveaux véhicules',
        attachment_upload: 'Téléchargement d\'attachement',
        diagnostic_report: 'Rapport de diagnostic',
        bulk_download: 'Téléchargement en masse',
        document_View: 'Affichage du document',
        inquiry_content: 'Détails de la demande',
        subordinate_record: 'Enregistrement subordonné',
        superior_record: 'Enregistrement supérieur',
        send_content: 'Contenu envoyé',
        send_time: 'Heure d\'envoi',
        sent_username: 'Expéditeur',
        receive_name: 'Envoyer à',
        dept_name: 'Niveau',
        enclosure: 'Annexe',
        wind_case: 'Cas de vent',
        case_reporting: 'Reporting de cas',
        save: 'Sauvegarder',
        send: 'Envoyer',
        edit: 'Modifier',
        pdf_preview: 'Aperçu PDF',
        SIS_maintenance: 'Entretien des accidents majeurs',
        sending_an_email: 'Envoyer un email',
        copy: 'Copier',
        delete: 'Supprimer',
        repeat: 'Répéter le VIN',
        inconsistent: 'Plate-forme de modèle incohérente',
        sendTo: 'Envoyer à',
        reply: 'Entrez le contenu pour demander / répondre',
        please_check_the_exported_document: 'Veuillez cocher le document exporté',
        please_upload_diagnostic_report: 'Veuillez télécharger le rapport de diagnostic',
        please_enter_the_vin: 'Veuillez entrer le VIN',
        placeholderDTC: 'Exemples：P080800 P087D00 ------',
        placeholderFaultInfoDesc:
            '1.Vérifiez les préoccupations des clients (juste pour vous assurer que quelque chose ne va pas)',
        placeholderFaultHandleProcess:
            '2.Identifier les symptômes associés (trouver et documenter tous les symptômes qui peuvent être associés au problème)\n' +
            '3.Analyser les symptômes (Regardez attentivement les symptômes pertinents pour trouver les causes communes)\n' +
            '4.Confirmer le problème (rétrécissez vos résultats pour vous assurer que vous avez résolu le bon problème)',
        placeholderSolution:
            '5.Résoudre les problèmes (effectuer des réparations)\n' +
            '6.Vérifiez le fonctionnement du véhicule (Vérifiez que les correctifs que vous avez effectués ont résolu le problème)',
    },
    zhCN: {
        vin: 'VIN',
        inquiry_no: '问询单号',
        case_theme: '案件主题',
        department_name: '部门名称',
        department_type: '部门类型',
        document_status: '单据状态',
        inquiry_date: '问询日期',
        organization_name: '组织名称',
        name: '名称',
        sap_code: 'SAP编码',
        failed_part: '失效零件',
        case_type: '案列类型',
        vehicle_status: '车辆状态',
        case_owner: '案件负责人',
        country: '国家',
        fault_cause: '故障原因',
        symptoms: '故障现象',
        is_the_document_qualified: '单据是否合格',
        is_the_document_replied_in_a_timely_manner: '单据是否及时答复',
        filter: '筛选器',
        system_classification_I: '系统分类I',
        system_classification_II: '系统分类II',
        full_query: '全量查询',
        fuzzy_query: '模糊查询',
        whether_belongs_new_car_market: '是否属于新车上市',
        caseStatus: '案例状态',
        all: '全部',
        pre_sales: '售前',
        after_sales: '售后',
        to_be_repaired: '待维修',
        under_maintenance: '维修中',
        completed: '已完工',
        correct: '是',
        deny: '否',
        not_closed: '未结案',
        closed: '已结案',
        drafts: '草稿箱',
        create_time: '创建时间',
        create_user: '创建人',
        parent_of_document: '单据所属上级',
        document_owner: '单据负责人',
        case_subject: '案件主题',
        receipt_info: '单据信息',
        superior_of_case: '案件所属上级',
        inquiry_time: '问询时间',
        status: '状态',
        look_over: '查看',
        overseas_company: '海外公司名称',
        general_agent_name: '总代名称',
        dealer_code: '经销商编码',
        dealer_name: '经销商名称',
        name_of_subsidiary: '子公司名称',
        contacts: '联系人',
        contact_information: '联系方式',
        training_certification: '培训认证',
        driving_mileage: '行驶里程',
        plate_number: '车牌号',
        material_no: '物料号',
        material_desc: '物料描述',
        offline_date: '下线日期',
        model_platform: '车型平台',
        engine_number: '发动机号',
        transmission_type: '变速箱型',
        assembly_no: '总装号',
        fault_info_desc: '故障信息描述',
        fault_handle_process: '故障处理经过',
        solution: '解决方案',
        remark: '备注',
        document_information: '单据信息',
        basic_information_of_service_center: '服务中心基本信息',
        basic_vehicle_information: '车辆基本信息',
        problem_description: '问题描述',
        common_links: '常用链接',
        claim_information: '索赔信息',
        diagnostic_report_record: '诊断报告记录',
        vehicle_history: '车辆历史问询单据',
        programming_history: '编程历史',
        vehicle_sales_logistics_information: '车辆销售及物流信息',
        diagnostic_software_report: '诊断软件报告',
        new_vehicle_information: '新增车辆信息',
        attachment_upload: '附件上传',
        diagnostic_report: '诊断报告',
        bulk_download: '批量下载',
        document_View: '文档查看',
        inquiry_content: '问询内容',
        subordinate_record: '下级问答记录',
        superior_record: '上级问答记录',
        send_content: '发送内容',
        send_time: '发送时间',
        sent_username: '发送人',
        receive_name: '发送至',
        dept_name: '级别',
        enclosure: '附件',
        wind_case: '结案',
        case_reporting: '案件上报',
        save: '保存',
        send: '发送',
        edit: '修改',
        pdf_preview: 'PDF预览',
        SIS_maintenance: '重大事故维护',
        Please_fill_in_the_inquiry_content: '请填写询问内容',
        sending_an_email: '发送邮件',
        copy: '复制',
        delete: '删除',
        repeat: 'Vin 重复',
        inconsistent: '车型平台不一致',
        sendTo: '发送至',
        reply: '输入内容进行问询/回复',
        please_check_the_exported_document: '请勾选导出的单据',
        please_upload_diagnostic_report: '请上传诊断报告',
        please_enter_the_vin: '请输入VIN',
        placeholderDTC: '示例：P080800 P087D00 ------',
        placeholderFaultInfoDesc: '1.核实客户关注的问题(只是为了确保出现问题)',
        placeholderFaultHandleProcess:
            '2.确定相关症状(查找并记录可能与问题相关的所有症状)\n' +
            '3.分析症状(仔细观察相关症状，找出常见原因)\n' +
            '4.确认问题(缩小你的结果范围，以确保你解决了正确的问题)',
        placeholderSolution:
            '5.修复问题(进行维修)\n' + '6.验证车辆运行情况(检查以确保您所做的修复已解决问题)',
    },
    itIT: {
        vin: 'Vin',
        inquiry_no: 'Numero di richiesta',
        case_theme: 'Tema del caso',
        department_name: 'Nome del dipartimento',
        department_type: 'Tipo di dipartimento',
        document_status: 'Stato del documento',
        inquiry_date: 'Data della richiesta',
        organization_name: 'Nome dell\'organizzazione',
        name: 'Nome',
        sap_code: 'Codice SAP',
        failed_part: 'Parte difettosa',
        case_type: 'Tipo di caso',
        vehicle_status: 'Stato del veicolo',
        case_owner: 'Responsabile del caso',
        country: 'Paese',
        fault_cause: 'Causa del malfunzionamento',
        symptoms: 'Sintomi del malfunzionamento',
        is_the_document_qualified: 'Il documento è qualificato',
        is_the_document_replied_in_a_timely_manner: 'Il documento risponde tempestivamente',
        filter: 'Filtro',
        system_classification_I: 'Classificazione di sistema I',
        system_classification_II: 'Classificazione di sistema II',
        full_query: 'Query completa',
        fuzzy_query: 'Query sfuocata',
        whether_belongs_new_car_market: 'Appartiene al nuovo mercato dell\'auto',
        caseStatus: 'Stato del caso',
        all: 'Tutti',
        pre_sales: 'Pre-vendita',
        after_sales: 'Post-vendita',
        to_be_repaired: 'Da riparare',
        under_maintenance: 'In manutenzione',
        completed: 'Completato',
        correct: 'Si',
        deny: 'No',
        not_closed: 'Non chiuso',
        closed: 'Chiuso',
        drafts: 'Bozze',
        create_time: 'Data di creazione',
        create_user: 'Creatore',
        parent_of_document: 'Documento genitore',
        document_owner: 'Proprietario del documento',
        case_subject: 'Oggetto del caso',
        receipt_info: 'Informazioni di ricevimento',
        superior_of_case: 'Superiore del caso',
        inquiry_time: 'Orario della richiesta',
        status: 'Stato',
        look_over: 'Visualizza',
        overseas_company: 'Nome dell\'azienda estera',
        general_agent_name: 'Nome dell\'agente generale',
        dealer_code: 'Codice del concessionario',
        dealer_name: 'Nome del concessionario',
        name_of_subsidiary: 'Nome della sussidiaria',
        contacts: 'Contatti',
        contact_information: 'Informazioni di contatto',
        training_certification: 'Certificazione di formazione',
        driving_mileage: 'Chilometraggio di guida',
        plate_number: 'Numero di targa',
        material_no: 'Numero di materiale',
        material_desc: 'Descrizione del materiale',
        offline_date: 'Data offline',
        model_platform: 'Piattaforma del modello',
        engine_number: 'Numero del motore',
        transmission_type: 'Tipo di trasmissione',
        assembly_no: 'Numero di assemblaggio',
        fault_info_desc: 'Descrizione informazioni malfunzionamento',
        fault_handle_process: 'Processo di gestione di malfunzionamento',
        solution: 'Soluzione',
        remark: 'Nota',
        document_information: 'Informazioni del documento',
        basic_information_of_service_center: 'Informazioni di base del centro assistenza',
        basic_vehicle_information: 'Informazioni di base del veicolo',
        problem_description: 'Descrizione del problema',
        common_links: 'Link comuni',
        claim_information: 'Informazioni sulla richiesta',
        diagnostic_report_record: 'Record di rapporto diagnostico',
        vehicle_history: 'Documento di richiesta storica del veicolo',
        programming_history: 'Storia della programmazione',
        vehicle_sales_logistics_information:
            'Informazioni sulle vendite e sulla logistica del veicolo',
        diagnostic_software_report: 'Rapporto di software diagnostico',
        new_vehicle_information: 'Informazioni sui veicoli nuovi',
        attachment_upload: 'Upload degli allegati',
        diagnostic_report: 'Rapporto diagnostico',
        bulk_download: 'Download in blocco',
        document_View: 'Visualizza documento',
        inquiry_content: 'Contenuto della richiesta',
        subordinate_record: 'Record delle risposte subordinate',
        superior_record: 'Record delle risposte superiori',
        send_content: 'Invia contenuto',
        send_time: 'Orario di invio',
        sent_username: 'Mittente',
        receive_name: 'Destinatario',
        dept_name: 'Livello',
        enclosure: 'Allegato',
        wind_case: 'Chiudere il caso',
        case_reporting: 'Rapporto del caso',
        save: 'Salva',
        send: 'Invia',
        edit: 'Modifica',
        pdf_preview: 'Anteprima PDF',
        SIS_maintenance: 'Manutenzione di incidenti significativi',
        Please_fill_in_the_inquiry_content: 'Si prega di compilare il contenuto della richiesta',
        sending_an_email: 'Invio di una email',
        copy: 'Copia',
        delete: 'Elimina',
        repeat: 'Vin ripeti',
        inconsistent: 'Piattaforma del veicolo incoerente',
        sendTo: 'Invia a',
        reply: 'Inserisci il contenuto per la richiesta/risposta',
        please_check_the_exported_document: 'Controlla il documento esportato',
        please_upload_diagnostic_report: 'Carica un rapporto diagnostico',
        please_enter_the_vin: 'Inserire il VIN',
        placeholderDTC: 'Esempio：P080800 P087D00 ------',
        placeholderFaultInfoDesc:
            '1.Verificare le preoccupazioni dei clienti (solo per assicurarsi che sorgano problemi)',
        placeholderFaultHandleProcess:
            '2.Identificare i sintomi rilevanti (identificare e registrare tutti i sintomi che possono essere correlati al problema)\n' +
            '3.Analizzare i sintomi (osservare attentamente i sintomi rilevanti per identificare le cause comuni)\n' +
            '4.Confermare il problema (restringere la portata dei risultati per assicurarsi di aver risolto il problema corretto)',
        placeholderSolution:
            '5.Risolvere il problema (effettuare riparazioni)\n' +
            '6.Verificare il funzionamento del veicolo (verificare che le riparazioni abbiano risolto il problema)',
    },
};
