// import store from "../../stores";
import type { DirectiveBinding } from 'vue';
import { useUserStore } from '~/stores/user';

export const permissionDirective = {
    mounted(el: HTMLElement, { value }: DirectiveBinding) {
        // console.log('el', value);
        const uerStore = useUserStore();
        const store = null;
        const parentEle = el.parentElement;
        try {
            let show = false;
            const permissions = uerStore.permissions || [];
            // console.log('permissions', value, permissions, 4343434, permissions.includes('*:*:*'))
            if (permissions.includes('*:*:*')) {
                show = true;
            }
            if (value === undefined) {
                return el.style.display = 'unset';
            }
            if (Array.isArray(value)) {
                for (const code of value) {
                    if (permissions.includes(code)) {
                        show = true;
                        break;
                    }
                }
            } else {
                if (permissions.includes(Number(value))) show = true;
            }
            if (!show) {
                if (parentEle) {
                    parentEle.style.display = 'none';
                }
                el.style.display = 'none'
            }
        } catch (e) {
            console.warn('权限控制失败: ', e);
            el.style.display = 'none';
            if (parentEle) {
                parentEle.style.display = 'none';
            }
        }
    },
}
