export default {
    enUS: {
        year: 'Year',
        month: 'Month',
        category_name: 'Category name',
        number_of_cases: 'Number of cases',
        percentage_of_cases: 'Percentage of cases',
    },
    nlNL: {
        year: 'Jaar',
        month: 'Maand',
        category_name: 'Categorie naam',
        number_of_cases: 'Aantal gevallen',
        percentage_of_cases: 'Percentage van gevallen',
    },
    deDE: {
        year: 'Jahr',
        month: 'Monat',
        category_name: 'Kategoriename',
        number_of_cases: 'Anzahl der Fälle',
        percentage_of_cases: 'Prozentsatz der Fälle',
    },
    frFR: {
        year: 'Année',
        month: 'Mois',
        category_name: 'Nom de catégorie',
        number_of_cases: 'Nombre de cas',
        percentage_of_cases: 'Pourcentage de cas',
    },
    zhCN: {
        year: '年',
        month: '月',
        category_name: '分类名称',
        number_of_cases: '案例数量',
        percentage_of_cases: '案例占比',
    },
    itIT: {
        year: 'Anno',
        month: 'Mese',
        category_name: 'Nome Categoria',
        number_of_cases: 'Numero di Casi',
        percentage_of_cases: 'Percentuale di Casi',
    },
};
